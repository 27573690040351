const backendTranslationsES = {
  "ByWindow": "En la ventana",
  "Failed": "Fallido",
  "ForHowManyPeopleTable": "Número de tapas",
  "NewReservationCouldNotBeCreated": "Error al crear una nueva reserva",
  "NewReservationHasBeenCreated": "Se ha creado una nueva reserva",
  "NonSmoking": "Para no fumadores",
  "Outdoor": "En el exterior",
  "ReservationHasBeenUpdated": "Se ha actualizado la reserva",
  "aboutPage.header": "O en",
  "aboutPage.metadata.description": "Las opciones",
  "aboutPage.metadata.keywords": "sobre nosotros palabras clave",
  "aboutPage.metadata.title": "O en",
  "aboutPage.subheader": "Lorem ipsum dolor...",
  "acceptance.required": "Aceptar los consentimientos requeridos",
  "account.changePassword": "Cambiar contraseña",
  "account.confirmRemoveAccount":
    "¿Está seguro de que desea eliminar su cuenta? Todas las reservas no realizadas se cancelarán automáticamente.",
  "account.removeAccount": "Eliminar cuenta",
  "account.removeRequest": "Para eliminar su cuenta, introduzca su contraseña",
  "accountPage.button.changePassword": "Cambiar contraseña",
  "accountPage.button.deleteAccount": "Eliminar cuenta",
  "accountPage.button.save": "Guardar",
  "accountPage.header": "Su cuenta",
  "accountPage.headerInColumn.yourPassword": "Tu contraseña",
  "accountPage.headerInColumn.yourPersonalData": "Sus datos",
  "accountPage.input.label.birthDate": "Fecha de nacimiento",
  "accountPage.input.label.city": "Ciudad",
  "accountPage.input.label.email": "Correo electrónico",
  "accountPage.input.label.firstName": "Nombre",
  "accountPage.input.label.lastName": "Nombre",
  "accountPage.input.label.newPassword": "Nueva contraseña",
  "accountPage.input.label.newPasswordRepeat": "Nueva contraseña (repetir)",
  "accountPage.input.label.oldPassword": "Antiguo eslogan",
  "accountPage.input.label.phoneNumber": "Número de teléfono",
  "accountPage.input.label.postalCode": "Código postal",
  "accountPage.input.label.streetAndLocalNumber": "Calle y número de piso",
  "accountPage.input.placeholder.birthDate": "Por ejemplo, 1976-10-25",
  "accountPage.input.placeholder.city": "Por ejemplo, Varsovia",
  "accountPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "accountPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "accountPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.placeholder.postalCode": "Por ejemplo, 03-123",
  "accountPage.input.placeholder.streetAndLocalNumber":
    "Por ejemplo, Grójecka 130/105",
  "accountPage.label.changePassowordNotPossible":
    "No es posible cambiar la contraseña porque te hayas conectado a través de Facebook o Google.",
  "accountPage.message.accountPermanentlyDeleted":
    "Tu cuenta ha sido eliminada permanentemente.",
  "accountPage.message.passwordChanged": "La contraseña ha sido modificada.",
  "accountPage.message.personalDataUpdated":
    "Se han actualizado los datos personales.",
  "accountPage.metadata.description": "Descripción de su cuenta",
  "accountPage.metadata.keywords": "Palabras clave de su cuenta",
  "accountPage.metadata.title": "Su cuenta",
  "accountPage.paragraph.optionalFields": "Campos opcionales",
  "accountPage.subheader":
    "Cambie sus datos personales para agilizar el proceso de tramitación.",
    "accountPage.toast.error.disableBothChannels":
    "¡Al menos un método de comunicación (SMS, CORREO ELECTRÓNICO) debe permanecer activo!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Habilitar el método de comunicación requiere la confirmación de la exactitud de los datos. ¡Guarda los cambios para recibir un enlace de verificación en la nueva dirección de correo electrónico!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Habilitar el método de comunicación requiere la confirmación de la exactitud de los datos. ¡Guarda los cambios para recibir un enlace de verificación por SMS en tu nuevo número de teléfono!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Para habilitar el nuevo método de comunicación, confirma tus datos haciendo clic en el enlace de activación enviado a tu dirección de correo electrónico.",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Para habilitar el nuevo método de comunicación, confirma tus datos haciendo clic en el enlace de activación enviado por SMS al número de teléfono proporcionado.",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Para verificar tus datos, haz clic en el enlace de verificación enviado a tu dirección de correo electrónico.",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Para verificar tus datos, haz clic en el enlace de verificación enviado por SMS a tu número de teléfono.",
  "agreement.canRevert":
    "mojstolik.co.uk le informa de que puede retirar su consentimiento para el tratamiento de sus datos personales.",
  "agreement.commercialInformationContact":
    "Acepto que Table4Rest sp. z o.o. envíe información comercial por medios de comunicación electrónica, en particular al número de teléfono y a la dirección de correo electrónico que he facilitado, incluidos SMS y MMS.",
  "agreement.marketingContact":
    "Consiento que Table4Rest sp. z o. o. se ponga en contacto conmigo, incluyendo SMS y MMS, así como el contacto mediante equipos terminales de telecomunicación y sistemas de llamada automática con fines de marketing directo, incluyendo el número de teléfono y la dirección de correo electrónico que he facilitado.",
  "agreement.marketingDataUsage":
    "Consiento el tratamiento de mis datos personales por Table4Rest sp. z o.o. para la comercialización de servicios y productos propios y de otras entidades ofrecidos a través de la aplicación MyStolik.pl.",
  "agreement.personalData":
    "Consiento el tratamiento de mis datos personales por Table4Rest sp. z o. o. con el fin de celebrar un contrato de prestación de servicios para el suministro y uso de la aplicación MyTable.",
  "agreement.readWithdrawal":
    "He leído los términos y condiciones de Mojstolik.es, en particular las instrucciones sobre el derecho de desistimiento.",
  "agreement.thirdPartDataUsage":
    "Doy mi consentimiento para que mis datos personales sean tratados por los restaurantes y otros establecimientos de restauración que he seleccionado con el fin de realizar una reserva con ellos.",
  "agreement.warningUncheck":
    "La retirada del consentimiento impide el uso de la aplicación. Para revocarlo, debe eliminar su cuenta.",
  "api.connectionLost":
    "La conexión a Internet se ha interrumpido. Se volverá a cargar la aplicación.",
  "app.readAgreement": "Condiciones del servicio MojStolik.pl",
  "cancellationReservationModal.component.button.cancel": "Anuncie",
  "cancellationReservationModal.component.button.confirm": "Aprobar",
  "cancellationReservationModal.component.header": "Anulación de la reserva",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "¿Está seguro de que desea cancelar su reserva en el restaurante {{restaurantName}} a las {{reservationHour}} del {{reservationDate}}?",
  "changePass.lowerLetter": "- contienen letras minúsculas",
  "changePass.newPass": "Nueva contraseña",
  "changePass.numeric": "- contienen dígitos",
  "changePass.oldPass": "Antiguo eslogan",
  "changePass.passHaveToBe": "La contraseña debe:",
  "changePass.passLength": "- tener al menos 6 caracteres",
  "changePass.specialChar":
    '- ¡contienen un carácter especial, por ejemplo "#@!',
  "changePass.upperLetter": "- contener al menos 1 mayúscula",
  "common.accept": "Aprobar",
  "common.add": "Añadir",
  "common.alertCloseWindow": "Cierra la ventana de selección",
  "common.back": "Volver",
  "common.bankAccountNumber": "Número de cuenta bancaria",
  "common.buildingNumber": "Número de edificio",
  "common.cancel": "Anuncie",
  "common.changeButton": "Cambia",
  "common.check": "Comprobación de",
  "common.city": "Ciudad",
  "common.clear": "Limpiar",
  "common.close": "Cerrar",
  "common.confirm": "Confirme",
  "common.confirmDecline": "Confirmar/rechazar",
  "common.couldNotDownloadData":
    "No se han podido descargar los datos. Actualice la aplicación.",
  "common.dayShort.monday": "Lu.",
  "common.dayShort.tuesday": "Ma.",
  "common.dayShort.wednesday": "Mi.",
  "common.dayShort.thursday": "Ju.",
  "common.dayShort.friday": "Vi.",
  "common.dayShort.saturday": "Sá.",
  "common.dayShort.sunday": "Do.",
  "common.delete": "Borrar",
  "common.detailsButton": "Detalles",
  "common.error": "Error",
  "common.gr": "gr",
  "common.help": "Ayuda",
  "common.hourShort": "hrs.",
  "common.localNumber": "Número local",
  "common.name": "Nombre",
  "common.new": "Nuevo",
  "common.next": "Más información en",
  "common.nextButton": "Más información en",
  "common.noData": "No hay datos que mostrar",
  "common.noValue": "Nie",
  "common.ok": "Ok",
  "common.password": "Contraseña",
  "common.preferences": "Preferencias",
  "common.requiredInputError": "Campo obligatorio",
  "common.save": "Guardar",
  "common.search": "Buscar en",
  "common.send": "Enviar",
  "common.shortPersons": "{{persons}} pers.",
  "common.street": "Calle",
  "common.successful": "Éxito",
  "common.undoButton": "Volver",
  "common.unit": "Unidad",
  "common.vatId": "PIN",
  "common.yesValue": "Tak",
  "common.zipCode": "Código postal",
  "common.zl": "zł",
  "company.addNewAction": "Nueva empresa añadida",
  "company.addNewCompany": "¿Añadir una nueva empresa?",
  "company.addressData": "Dirección",
  "company.buildingNumber": "Número de edificio",
  "company.city": "Ciudad",
  "company.companyExistsError": "La empresa ya existe",
  "company.country": "País",
  "company.localNumber": "Número local",
  "company.name": "Nombre de la empresa",
  "company.nipNumber": "PIN",
  "company.notFound":
    "No se ha encontrado la empresa en la base de datos del CDEIG",
  "company.postalCode": "Código postal",
  "company.questionUpdateModal": "¿Actualizar la empresa?",
  "company.street": "Calle",
  "company.updateAction": "La empresa se ha actualizado",
  "company.updateCompany": "¿Actualizar una empresa existente?",
  "company.vatId": "PIN",
  "component.cookiesPolicyLine.privacyPolicyText": "Política de privacidad",
  "component.cookiesPolicyLine.text":
    "Este sitio web utiliza cookies para ofrecerle el máximo nivel de servicio. Si continúa navegando, consideramos que acepta su uso.",
  "component.footer.menu.about": "O en",
  "component.footer.menu.contact": "Póngase en contacto con nosotros",
  "component.footer.menu.cookiesPolicy": "Política de cookies",
  "component.footer.menu.documents": "Documentos",
  "component.footer.menu.forGuest": "Para el huésped",
  "component.footer.menu.forRestaurants": "Para restaurantes",
  "component.footer.menu.header.applicationDownload": "Descargar la solicitud",
  "component.footer.menu.header.forClients": "Acerca de MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Información",
  "component.footer.menu.header.socialMedia": "Social media",
  "component.footer.menu.header.yourAccount": "Su cuenta",
  "component.footer.menu.howItWorks": "¿Cómo funciona?",
  "component.footer.menu.login": "Conectarse",
  "component.footer.menu.logout": "Cerrar sesión",
  "component.footer.menu.priceList": "Lista de precios",
  "component.footer.menu.privacyPolicy": "Política de privacidad",
  "component.footer.menu.registration": "Inscripción",
  "component.footer.menu.reservationHistory": "Sus reservas",
  "component.footer.menu.yourAccount": "Su cuenta",
  "component.header.menu.about": "O en",
  "component.header.menu.blog": "Noticias MOJSTOLIK.PL",
  "component.header.menu.contact": "Póngase en contacto con nosotros",
  "component.header.menu.documents": "Documentos",
  "component.header.menu.forGuest": "Para el huésped",
  "component.header.menu.forRestaurants": "Para restaurantes",
  "component.header.menu.forRestaurants.offer": "Nuestra oferta",
  "component.header.menu.howItWorks": "¿Cómo funciona?",
  "component.header.menu.login": "Conectarse",
  "component.header.menu.logout": "Cerrar sesión",
  "component.header.menu.mainPage": "Reserve",
  "component.header.menu.portal.restaurant": "Portal restauracji",
  "component.header.menu.priceList": "Lista de precios",
  "component.header.menu.reservationHistory": "Sus reservas",
  "component.header.menu.yourAccount": "Su cuenta",
  "config.communication.confirmationReservation": "Confirmación de reserva",
  "config.communication.modificationReservation": "Cambio de reserva",
  "config.communication.notifTitle": "Notificaciones PUSH",
  "config.communication.rejectionReservation": "Anulación de la reserva",
  "config.communication.remindNotification": "Recordatorios de reserva",
  "config.navbar.account": "Cuenta",
  "config.navbar.communication": "Comunicación",
  "config.navbar.configuration": "Configuración",
  "config.navbar.myaccount": "Cuenta",
  "config.navbar.mypreferences": "Preferencias",
  "configuration.changeLanguage": "Idioma",
  "configuration.customerProfiles": "Perfiles de clientes",
  "configuration.employeeGroups": "Grupos de empleados",
  "configuration.help": "Ayuda",
  "configuration.restaurant": "Restaurante",
  "contactPage.header": "Póngase en contacto con nosotros",
  "contactPage.metadata.description": "Contactar con opis",
  "contactPage.metadata.keywords": "Palabras clave de contacto",
  "contactPage.metadata.title": "Póngase en contacto con nosotros",
  "contactPage.subheader": "Escríbanos",
  "cookiesPolicyPage.header": "Política de cookies",
  "cookiesPolicyPage.metadata.title": "Política de cookies",
  "cookiesPolicyPage.subheader": "Información relevante.",
  "cuisine.american": "Cocina americana",
  "cuisine.arabic": "Cocina árabe",
  "cuisine.chinese": "Cocina china",
  "cuisine.polish": "Cocina polaca",
  "cuisine.romania": "Cocina rumana",
  "cusine.chinese": "Cocina china",
  "cusine.polish": "Cocina polaca",
  "customer.accountDetail": " cuentas",
  "customer.addEdit": "Añadir / Editar",
  "customer.blocked":
    "La cuenta ha sido bloqueada. Se ha bloqueado la posibilidad de reservar.",
  "customer.confirmText": "Reserve",
  "customer.createInvoice": "Crear una factura",
  "customer.createNewProfile": "Nuevo perfil añadido",
  "customer.createNewProfileModal": "¿Crear un nuevo perfil?",
  "customer.deleteProfile": "Borrar perfil",
  "customer.description": "Descripción",
  "customer.editCompany": "Editar empresa",
  "customer.editProfile": "Perfil actualizado",
  "customer.editReservingCustomerData": "Editar los datos del grupo de reserva",
  "customer.emailAddress": "Correo electrónico",
  "customer.errorProfileAction": "El perfil se asigna al cliente",
  "customer.existError": "El usuario ya existe",
  "customer.firstCreateCompany": "*primero elige una empresa",
  "customer.groupChooseLabel": "Seleccione",
  "customer.labels.blocked": "Bloqueado",
  "customer.labels.customerName": "Nombre",
  "customer.labels.emailLabel": "Correo electrónico",
  "customer.labels.firstNameLabel": "Nombre",
  "customer.labels.groupLabel": "Grupa",
  "customer.labels.lastNameLabel": "Nombre",
  "customer.labels.phoneNumber": "Número de teléfono",
  "customer.labels.statusLabel": "Estado",
  "customer.listOfProfiles": "Lista de perfiles",
  "customer.mail": "Correo electrónico",
  "customer.name": "Nombre",
  "customer.newPasswordWasSent": "Se ha enviado una nueva contraseña por SMS",
  "customer.noCustomer": "Ningún usuario",
  "customer.noCustomers": "No hay clientes",
  "customer.noReservations": "Sin reserva",
  "customer.onePersonFound": "1 persona encontrada",
  "customer.password": "Contraseña",
  "customer.password.mismatch": "El viejo eslogan no sirve",
  "customer.passwordHasChange": "La contraseña ha sido modificada",
  "customer.permissionForHoldingPersonalData":
    "Consentimiento para el tratamiento de datos personales",
  "customer.profile": "Perfil",
  "customer.profileDetails": "Detalles del perfil",
  "customer.profileExistsError": "El perfil ya existe",
  "customer.registration.code": "Tu código de activación es: _CODE_",
  "customer.registration.invalid": "Código de activación incorrecto",
  "customer.registration.sendByEmail":
    "Problema de envío de SMS - el código se envió a la dirección de correo electrónico proporcionada.",
  "customer.remarks": "Comentarios",
  "customer.remarksToClient": "Notas para el cliente",
  "customer.remarksToReservation": "Notas de reserva",
  "customer.removeProfileAction": "Perfil eliminado",
  "customer.removeProfileAction.error": "Perfil no eliminado",
  "customer.reservationHistory": "Historial de reservas",
  "customer.reservingCustomerData":
    "Datos de la persona que realiza la reserva",
  "customer.saveChanges": "¿Guardar los cambios?",
  "customer.surname": "Nombre",
  "customer.telephone": "Número de teléfono",
  "customer.telephoneNumber": "Número de teléfono",
  "customer.updateClient": "¿Actualizar los datos de los clientes?",
  "customer.updateProfileModal": "¿Actualizar perfil?",
  "defaultValues.restaurant": "Restaurante",
  "deleteAccountModal.component.button.cancel": "Anuncie",
  "deleteAccountModal.component.button.confirm": "Aprobar",
  "deleteAccountModal.component.header": "Eliminar cuenta",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "¿Está seguro de que desea eliminar su cuenta? También se eliminará su historial de reservas.",
  "documentsPage.header": "Documentos",
  "documentsPage.metadata.description": "Descripción de los documentos",
  "documentsPage.metadata.keywords": "Palabras clave",
  "documentsPage.metadata.title": "Documentos",
  "documentsPage.subheader": "Información pertinente",
  "editReservation.send": "Enviar",
  "editReservation.sendMessage":
    "Se ha enviado una solicitud para cambiar los parámetros de la reserva.",
  "email.already.exist": "El correo electrónico ya existe",
  "email.missing": "Correo electrónico obligatorio",
  "error": "Se ha producido un error. Vuelva a intentarlo más tarde.",
  "error.LocationNotFound": "Ubicación no encontrada o pocos caracteres",
  "error.cannotCreateReservation": "Error al enviar la solicitud",
  "error.connectionWithServerLost":
    "Se ha perdido la conexión con el servidor.",
  "error.connectionWithServerLostTryLater":
    "Se ha perdido la conexión con el servidor. Vuelva a intentarlo más tarde",
  "error.email.already.exist": "El correo electrónico ya existe",
  "error.facebook.account.already.exist":
    "Ya se ha creado una cuenta MojStolik para este número a través de Facebook. Utilice el inicio de sesión de Facebook",
  "error.google.account.already.exist":
    "Ya se ha creado una cuenta para este número en MojStolik a través de Google. Utilice Google Login",
  "error.notFoundAddress":
    "Ubicación no encontrada o muy pocos caracteres dados",
  "error.phone.already.exist":
    "Ya existe una cuenta para este número en MojStolik. Inicie sesión a través de su número de teléfono o utilice la opción de recordatorio de contraseña.",
  "filters.doFilter": "Filtro",
  "filters.fromToday": "A fecha de hoy",
  "filters.toToday": "Hasta hoy",
  "filters.today": "Hoy",
  "footer.Next": "Siguiente",
  "footer.Previous": "Anterior",
  "footer.alertOnBack": "¿Abandonar el cambio?",
  "forGuestPage.header": "Para el huésped",
  "forGuestPage.metadata.description": "Para el visitante, una descripción de",
  "forGuestPage.metadata.keywords": "Para el visitante, las palabras clave",
  "forGuestPage.metadata.title": "Para el huésped",
  "forRestaurantsPage.form":
    "Su solicitud ha sido enviada. En breve nos pondremos en contacto con usted.",
  "forRestaurantsPage.header": "Para restaurantes",
  "forRestaurantsPage.metadata.description": "Descripción de los restaurantes",
  "forRestaurantsPage.metadata.keywords":
    "Para los restaurantes, palabras clave",
  "forRestaurantsPage.metadata.title": "Para restaurantes",
  "forRestaurantsPage.subheader":
    "Descubra qué ventajas aportará el sistema MojStolik a su restaurante.",
  "global.form.message.atLeastNineCharacters":
    "Se requieren al menos nueve caracteres",
  "global.form.message.invalidBirthDateFormat":
    "Formato incorrecto de la fecha de nacimiento",
  "global.form.message.invalidEmailFormat":
    "Formato incorrecto de la dirección de correo electrónico",
  "global.form.message.invalidPhoneFormat": "Formato de teléfono incorrecto",
  "global.form.message.invalidPostCodeFormat":
    "Formato incorrecto del código postal",
  "global.form.message.newPasswordsDoNotMatch":
    "Los nuevos eslóganes son diferentes.",
  "global.form.message.requiredField": "Campo obligatorio",
  "global.messages.sessionTimeout": "Sesión expirada",
  "global.time.minutes": "minut",
  "group.removeErrorAction": "El grupo se asigna al usuario",
  "howItWorksPage.header": "¿Cómo funciona?",
  "howItWorksPage.metadata.description": "Descripción del funcionamiento",
  "howItWorksPage.metadata.keywords": "Cómo funcionan las palabras clave",
  "howItWorksPage.metadata.title": "¿Cómo funciona?",
  "howItWorksPage.subheader": "Paso a paso",
  "image.notFound": "Imagen no encontrada",
  "indexPage.background.header":
    "Encuentra los mejores restaurantes de tu ciudad,",
  "indexPage.background.headerExtend": "y reserve mesa:",
  "indexPage.button.bookNow": "Reserve",
  "indexPage.button.hideAdditionalFilters": "Ocultar filtros adicionales",
  "indexPage.button.readMore": "Leer",
  "indexPage.button.search": "Buscar en",
  "indexPage.button.showAdditionalFilters": "Filtros adicionales",
  "indexPage.button.use": "Utilice",
  "indexPage.header.blogs": "Artículos destacados",
  "indexPage.header.currentPromotions": "Ofertas especiales y eventos",
  "indexPage.header.currentPromotionsExtend": "en su ubicación",
  "indexPage.header.mostPopular": "Más populares",
  "indexPage.header.mostPopularExtend": "en su ubicación",
  "indexPage.input.label.date": "Datos",
  "indexPage.input.label.hour": "Hora",
  "indexPage.input.label.location": "Ubicación",
  "indexPage.input.label.persons": "Número de personas",
  "indexPage.input.label.persons.optionExtend": "os.",
  "indexPage.input.label.priceLevel": "Nivel de precios",
  "indexPage.input.label.rating": "Evaluación",
  "indexPage.input.label.rating.optionExtend": "estrellas.",
  "indexPage.input.label.restaurantType": "Tipo de cocina",
  "indexPage.input.label.searchByName": "Buscar por nombre",
  "indexPage.input.noData": "No hay resultados.",
  "indexPage.input.placeholder.select": "Seleccione",
  "indexPage.input.placeholder.type": "Entre en",
  "indexPage.input.placeholer.typeInOrSelect": "Escriba o seleccione",
  "invitation":
    "Hola, estás invitado al restaurante {{restaurant}} el {{date}} a las {{time}}. Descarga la aplicación MojStolik.es para obtener más información sobre el restaurante: https://www.mojstolik.pl/pobierz/apka.html",
  "invoice.vatId": "PIN: {{vatId}}",
  "login": "Inicio de sesión",
  "login.accountDoesNotExist": "La cuenta no existe",
  "login.accountName": "Nombre de usuario",
  "login.doLogin": "Inicio de sesión",
  "login.forgotPassword": "¿Ha olvidado su contraseña?",
  "login.forgottenPasswordLink": "¿Ha olvidado su contraseña?",
  "login.generalTitle": "Registro",
  "login.insertLoginData": "Introduzca sus datos de acceso",
  "login.insertManagerPassword":
    "Introduzca la dirección de correo electrónico del gestor",
  "login.loggingToSystem": "Inicio de sesión en el sistema",
  "login.loginOrPasswordWrong": "Login o contraseña inválidos",
  "login.loginText": "Inicio de sesión",
  "login.logout": "Wyloguj",
  "login.logout.message": "Ha cerrado la sesión",
  "login.newAccount": "Nueva cuenta",
  "login.newUpdateReady":
    "Ya está disponible una nueva versión de la aplicación. Actualización.",
  "login.noAppleAccount":
    "No hay ninguna cuenta asociada a esta cuenta de Apple. Prueba otro método de inicio de sesión o registra una cuenta nueva.",
  "login.noFacebookAccount":
    "No hay ninguna cuenta asociada a esta cuenta de Facebook. Prueba otro método de inicio de sesión o registra una cuenta nueva.",
  "login.noGoogleAccount":
    "No hay ninguna cuenta asociada a esta cuenta de Google. Prueba con otro método de inicio de sesión o registra una cuenta nueva.",
  "login.passwordRecovery": "Recuperación de contraseñas",
  "login.passwordSent.sms": "Su nueva contraseña es: _PASSWORD_.",
  "login.passwordSent.sms.too.often":
    "Intento demasiado frecuente de cambiar la contraseña, inténtelo de nuevo en un momento",
  "login.passwordSentToEmail":
    "Se ha enviado una nueva contraseña a la dirección de correo electrónico facilitada.",
  "login.phoneNumberOrMail":
    "Número de teléfono o dirección de correo electrónico",
  "login.recover": "Obtenido de",
  "login.resetPasswordLabel": "Correo electrónico",
  "login.restaurantChanged": "El restaurante se ha transformado",
  "login.thisAccountAlreadyExist": "El usuario ya existe",
  "login.updateRequired":
    "Necesita actualizar su aplicación. Puede que no funcione correctamente",
  "login.userUnauthorized": "Login o contraseña inválidos",
  "login.userUnauthorized.useApple":
    "Nombre de usuario o contraseña incorrectos. Intenta iniciar sesión a través de Apple o restablece tu contraseña.",
  "login.userUnauthorized.useFacebook":
    "Nombre de usuario o contraseña incorrectos. Intenta iniciar sesión a través de Facebook o restablece tu contraseña.",
  "login.userUnauthorized.useGoogle":
    "Usuario o contraseña incorrectos. Intenta iniciar sesión a través de Google o restablece tu contraseña.",
  "login.yourPassword": "Tu contraseña",
  "loginPage.anchor.passwordReset": "Restablecer contraseña",
  "loginPage.button.logIn": "Conectarse",
  "loginPage.button.register": "Regístrese en",
  "loginPage.columns.header.socialLogin": "Iniciar sesión con",
  "loginPage.columns.header.standardLogin": "Inicio de sesión estándar",
  "loginPage.header": "Registro",
  "loginPage.input.label.password": "Contraseña",
  "loginPage.input.label.phoneOrEmail": "Número de teléfono",
  "loginPage.message.logged": "Reblogueado.",
  "loginPage.message.loggedViaApple": "Reblogueado por Apple.",
  "loginPage.message.loggedViaFacebook": "Reblogueado vía Facebook.",
  "loginPage.message.loggedViaGoogle": "Reblogueado vía Google.",
  "loginPage.metadata.description": "Descripción del registro",
  "loginPage.metadata.keywords": "Palabras clave de registro",
  "loginPage.metadata.title": "Registro",
  "loginPage.paragraph.alreadyLoggedIn": "Ha iniciado sesión.",
  "loginPage.paragraph.dontHaveAccountYet": "¿Aún no tiene cuenta?",
  "loginPage.paragraph.registerNow": "Regístrese",
  "loginPage.subheader":
    "Como usuario registrado, puede reservar mesas más rápidamente y consultar su historial de reservas.",
  "logoutPage.metadata.description": "Descripción del cierre de sesión",
  "logoutPage.metadata.keywords": "Palabras clave de cierre de sesión",
  "logoutPage.metadata.title": "Cierre de sesión",
  "mail.confirm.message":
    "Confirme su dirección de correo electrónico haciendo clic en el enlace",
  "mail.confirm.title": "Mensaje de MyTable.co.uk",
  "map.searchArea": "Buscar en la zona",
  "menu.areaControl": "Gestión de habitaciones",
  "menu.config": "Configuración",
  "menu.configPage": "Ajustes",
  "menu.configuration": "Configuración",
  "menu.currentReservations": "Reservas actuales",
  "menu.customers": "Klienci",
  "menu.homePage": "Principal",
  "menu.invoice": "Facturas",
  "menu.login": "Registro",
  "menu.logout": "Wyloguj",
  "menu.myReserv": "Reservas",
  "menu.myReservations": "Mis reservas",
  "menu.newAccount": "Nueva cuenta",
  "menu.passwordRecovery": "Recuperación de contraseñas",
  "menu.reservationpage": "Nueva reserva",
  "menu.reservations": "Todas las reservas",
  "menu.resetPassword": "Recordatorio de contraseña",
  "menu.restaurant": "Restaurante",
  "menu.sale": "Promociones",
  "menu.search": "Buscar en",
  "myReservation.doRate": "Evalúe",
  "myReservation.doRateQuestion":
    "¿Te gustaría valorar esta reserva en {{rate}}/5?",
  "myReservation.doRateSuccess": "La evaluación se realizó",
  "myReservation.doRateTitle": "¿Cómo calificaría su reserva?",
  "myReservation.doSuccessRemove": "La reserva ha sido eliminada de la lista",
  "myReservation.yourRate": "Su evaluación",
  "navbar.allReserv": "Todos",
  "navbar.areaEdition": "Edición de habitaciones",
  "navbar.cancelChangesReservation": "¿Cancelar un cambio de reserva?",
  "navbar.cancelReservation": "¿Anular la reserva?",
  "navbar.changePass": "Cambio de contraseña",
  "navbar.clientPreview": "Vista del cliente",
  "navbar.companyEdition": "Edición para empresas",
  "navbar.configuration": "Configuración",
  "navbar.currentReserv": "Actual",
  "navbar.currentReservations": "Reservas actuales",
  "navbar.customers": "Klienci",
  "navbar.dataCustomer": "Datos de la persona que realiza la reserva",
  "navbar.defaultCancel": "¿Cancelar?",
  "navbar.employee": "Empleados",
  "navbar.historyReserv": "Histórico",
  "navbar.invoiceEdition": "Editar una factura",
  "navbar.invoicePreview": "Vista previa de la factura",
  "navbar.label.editReservation": "Cambio de reserva",
  "navbar.label.myProfile": "Mi perfil",
  "navbar.label.myReservation": "Mis reservas",
  "navbar.label.reservation": "Reservas",
  "navbar.label.reservationDetails": "Detalles de la reserva",
  "navbar.label.sale": "Promociones",
  "navbar.newReservation": "Nueva reserva",
  "navbar.reservationActualState": "Estado actual de la reserva",
  "navbar.reservationEdit": "Editar una reserva",
  "navbar.reservationPreview": "Avance de reservas",
  "navbar.reservations": "Reservas",
  "notFoundPage.button.goToHomePage": "Ir a la página de inicio",
  "notFoundPage.header": "Error 404",
  "notFoundPage.metadata.description":
    "404 - Descripción de la página no encontrada",
  "notFoundPage.metadata.title": "404 - ¡Página no encontrada!",
  "notFoundPage.subheader": "URL solicitada",
  "notFoundPage.subheaderExtend": "no se encontró en el servidor.",
  "notFoundPage.subheaderExtend2":
    "Compruebe que la dirección que ha introducido es correcta o vaya a",
  "notFoundPage.subheaderExtend3": "página de inicio",
  "notification.body.Canceled":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ ha sido cancelada.",
  "notification.body.Completed":
    "Gracias por tramitar su reserva en el _RESTAURANT_ en _DAY_ a las: _TIME_.",
  "notification.body.Confirmed":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ ha sido confirmada.",
  "notification.body.Rejected":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ ha sido cancelada. _MESSAGE_",
  "notification.body.WaitingForApproval":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ está pendiente de aprobación.",
  "notification.body.WaitingForConfirmation":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ está pendiente de confirmación.",
  "notification.invitation.accepted.body":
    "_NAME_ ha aceptado su invitación a _RESTAURANT_ el _DAY_ a las _TIME_.",
  "notification.invitation.accepted.title": "Invitación aceptada.",
  "notification.invitation.rejected.body":
    "_NAME_ ha declinado su invitación al _RESTAURANT_ el _DAY_ a las _TIME_.",
  "notification.invitation.rejected.title": "Anulación de la invitación.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Mensaje de _RESTAURANT_",
  "notification.parameters.updated.body":
    "Su reserva en _RESTAURANT_ el _DAY_ a las _TIME_ ha sido actualizada.",
  "notification.parameters.updated.title": "Actualización de reservas",
  "onboarding.promotion.MFR2023.Advanced.description":
    "La promoción MFR2023 incluye un periodo de prueba gratuito. Durante los tres primeros meses a partir de la firma del contrato, no se cobrarán las cuotas de suscripción detalladas en la descripción de los planes a continuación. La promoción es para nuevos clientes y se aplica únicamente a los planes LIGHT y ADVANCED y es válida del 25.04 al 20.05.2023.",
  "onboarding.promotion.MFR2023.AdvancedPro.description":
    "La promoción no se aplica al plan ADVANCED PRO.",
  "onboarding.promotion.MFR2023.Light.description":
    "La promoción MFR2023 incluye un periodo de prueba gratuito. Durante los tres primeros meses a partir de la firma del contrato, no se cobrarán las cuotas de suscripción detalladas en la descripción de los planes a continuación. La promoción es para nuevos clientes y se aplica únicamente a los planes LIGHT y ADVANCED y es válida del 25.04 al 20.05.2023.",
  "onboarding.promotion.MFR2023.description":
    "La promoción MFR2023 incluye un periodo de prueba gratuito. Durante los tres primeros meses a partir de la firma del contrato, no se cobrarán las cuotas de suscripción detalladas en la descripción de los planes a continuación. La promoción es para nuevos clientes y se aplica únicamente a los planes LIGHT y ADVANCED y es válida del 25.04 al 20.05.2023.",
  "onboarding.promotion.WAKACJE2023.Advanced.description":
    "La promoción no se aplica al plan AVANZADO.",
  "onboarding.promotion.WAKACJE2023.AdvancedPro.description":
    "La promoción no se aplica al plan ADVANCED PRO.",
  "onboarding.promotion.WAKACJE2023.Light.description":
    "HOLIDAY2023 es una oferta LIGHT de 0 PLN en los meses de junio, julio y agosto de 2023 para nuevos clientes. Después de estos meses, puede seguir trabajando con nosotros en las condiciones habituales.",
  "onboarding.promotion.WAKACJE2023.description":
    "HOLIDAY2023 es una oferta LIGHT de 0 PLN en los meses de junio, julio y agosto de 2023 para nuevos clientes. Después de estos meses, puede seguir trabajando con nosotros en las condiciones habituales.",
  "onboarding.promotion.promo-test.description":
    "Promoción promoción-prueba descripción. Normativa: _LINK_.",
  "onboarding.promotion.promo-test.terms.link":
    "https://tst-web.mojstolik.pl/pl",
  "password.errors.weak": "La contraseña es demasiado simple",
  "passwordFormModal.component.button.cancel": "Anuncie",
  "passwordFormModal.component.button.confirm": "Aprobar",
  "passwordFormModal.component.header": "Introduzca la contraseña de su cuenta",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Introduzca su contraseña para confirmar la acción.",
  "payment.card": "Tarjeta",
  "payment.cash": "efectivo",
  "payment.item.description.per.person": "_PRODUCT_NAME_",
  "payment.item.description.per.reservation": "_PRODUCT_NAME_",
  "payment.otherType": "otros",
  "payment.title.generic": "La reserva requiere un pago",
  "payment.title.with.event": "Cuota del evento: _EVENT_NAME_.",
  "payment.transfer": "transferencia",
  "payment.type": "Forma de pago",
  "preference.category.cuisine": "Tipo de cocina",
  "preference.category.cuisine.polish": "Cocina polaca",
  "preference.category.price": "Precio",
  "previewImageModal.component.anchor.close": "Cerrar",
  "previewRestaurantModal.component.anchor.close": "Cerrar",
  "priceListPage.header": "Lista de precios",
  "priceListPage.metadata.description": "Descripción de la lista de precios",
  "priceListPage.metadata.keywords": "Lista de precios por palabra clave",
  "priceListPage.metadata.title": "Lista de precios",
  "privacyPolicyPage.header": "Política de privacidad",
  "privacyPolicyPage.metadata.title": "Política de privacidad",
  "privacyPolicyPage.subheader": "Información relevante.",
  "promotions.emptyList": "Sin promoción",
  "registration.activeCode": "Código de activación",
  "registration.addressEmail": "Correo electrónico",
  "registration.codeInformation":
    "Se ha enviado un código de activación a {{phone}}, introdúzcalo a continuación y confírmelo con",
  "registration.createAccount": "Crear una cuenta",
  "registration.createSuccess": "La cuenta se creó",
  "registration.differentPasswords": "Las contraseñas difieren",
  "registration.finishButton": "Complete su inscripción",
  "registration.formIsInvalid":
    "Los datos no se han rellenado correctamente. Por favor, utilice la ayuda de la parte inferior.",
  "registration.generalTitle": "Inscripción",
  "registration.phoneNumber": "Número de teléfono",
  "registration.phoneToShort": "El número es demasiado corto",
  "registration.readAgreement": "Lea la normativa",
  "registration.repeatPassword": "Repetir contraseña",
  "registration.requiredFieldsFirstStep":
    "El número de teléfono, el correo electrónico y la contraseña son obligatorios.",
  "registration.weakPassword": "La contraseña es demasiado simple",
  "registrationPage.button.confirm": "Confirme",
  "registrationPage.button.register": "Regístrese en",
  "registrationPage.button.sendCodeAgain": "Reenviar código",
  "registrationPage.columns.header.registerStandard": "Registro estándar",
  "registrationPage.columns.header.registerWith": "Regístrese en",
  "registrationPage.header": "Inscripción",
  "registrationPage.header.SMSConfirmation": "Confirmación por SMS",
  "registrationPage.header.statements": "Declaraciones",
  "registrationPage.input.label.agreementEvent":
    " He leído las condiciones del evento y de la promoción, que se encuentran en el",
  "registrationPage.input.label.agreementEvent.extension": "en este enlace",
  "registrationPage.input.label.agreementReadWithdrawal":
    "He leído los términos y condiciones de Mojstolik.es, en particular las instrucciones sobre el derecho de desistimiento.",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Términos y condiciones de mojstolik.pl",
  "registrationPage.input.label.birthDate": "Fecha de nacimiento",
  "registrationPage.input.label.city": "Ciudad",
  "registrationPage.input.label.email": "Correo electrónico",
  "registrationPage.input.label.firstName": "Nombre",
  "registrationPage.input.label.lastName": "Nombre",
  "registrationPage.input.label.password": "Contraseña",
  "registrationPage.input.label.passwordRepeat": "Repetir contraseña",
  "registrationPage.input.label.phoneNumber": "Número de teléfono",
  "registrationPage.input.label.postalCode": "Código postal",
  "registrationPage.input.label.streetAndLocalNumber": "Calle y número de piso",
  "registrationPage.input.placeholder.birthDate": "Por ejemplo, 1976-10-25",
  "registrationPage.input.placeholder.city": "Por ejemplo, Varsovia",
  "registrationPage.input.placeholder.code": "Introduzca el código",
  "registrationPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "registrationPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "registrationPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.placeholder.postalCode": "Por ejemplo, 03-123",
  "registrationPage.input.placeholder.streetAndLocalNumber":
    "Por ejemplo, Grójecka 130/105",
  "registrationPage.message.accountCreated": "La cuenta ha sido creada.",
  "registrationPage.message.enterCodeFromSMS": "Transcribe el código del SMS.",
  "registrationPage.message.registrationTimeExpired":
    "Tiempo de registro expirado",
  "registrationPage.message.sendCodeAgain":
    "Se ha vuelto a enviar el código SMS.",
  "registrationPage.metadata.description": "Descripción de la inscripción",
  "registrationPage.metadata.keywords": "Registro de palabras clave",
  "registrationPage.metadata.title": "Inscripción",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Introduzca el código SMS que le enviamos a su número de teléfono para completar su registro.",
  "registrationPage.paragraph.enterSMSCodeFrom":
    "Introduzca el código SMS del día",
  "registrationPage.paragraph.optionalFields": "Campos opcionales",
  "registrationPage.paragraph.seconds": "segundo(-a)",
  "registrationPage.paragraph.sendCodeAgain":
    "Se ha vuelto a enviar el código SMS.",
  "registrationPage.paragraph.timeIsOver": "El tiempo se ha acabado.",
  "registrationPage.paragraph.timeLeft": "Resto",
  "registrationPage.subheader":
    "Como usuario registrado, puede reservar mesas más rápidamente y consultar su historial de reservas.",
  "request.blocked":
    "La solicitud ha sido bloqueada, inténtelo de nuevo más tarde",
  "reservation.addNewAction": "Se ha creado una nueva ráfaga",
  "reservation.addNote": "Añadir un comentario",
  "reservation.addRemarkQuestion": "¿Añadir una nota a la reserva?",
  "reservation.alreadyRated": "La reserva ya ha sido evaluada",
  "reservation.basicInformation": "Información básica",
  "reservation.cancelReservation": "Cancelar",
  "reservation.cancelReservationQuestion":
    "¿Está seguro de que desea cancelar su reserva?",
  "reservation.changeReservation": "Cambia",
  "reservation.changeReservationParameters":
    "Modificar los parámetros de la reserva",
  "reservation.changeStatusAction": "Estado cambiado a",
  "reservation.chooseStatus": "Seleccionar estado",
  "reservation.comunications": "Noticias",
  "reservation.createReservation": "Crear una reserva para",
  "reservation.defaultTime": "Hora de reserva preferida {{reservationTime}}.",
  "reservation.doReservation": "Reserve",
  "reservation.duration": "Duración",
  "reservation.durationTime": "Duración de la reserva {{durationTime}}",
  "reservation.editData": "Editar datos",
  "reservation.editReservation": "Editar reserva",
  "reservation.errorNewAction": "Error al crear una nueva reserva",
  "reservation.errorNotAvailableYet": "Las reservas aún no están disponibles.",
  "reservation.errorPast": "Las reservas no pueden crearse en el pasado",
  "reservation.errorToLate": "La reserva ya no está disponible.",
  "reservation.filter.fromDate": "Desde",
  "reservation.filter.lastName": "Nombre",
  "reservation.filter.toDate": "Hasta cuando",
  "reservation.forHowLong": "Duración",
  "reservation.generalTitle": "Reservas",
  "reservation.group": "Grupo",
  "reservation.individual": "Individual",
  "reservation.informationOnReservation": "Información sobre reservas",
  "reservation.invitation": "Invite a",
  "reservation.labels.client": "Cliente",
  "reservation.labels.date": "Termin",
  "reservation.labels.dateTo": "Visite",
  "reservation.labels.firstName": "Nombre",
  "reservation.labels.lastName": "Nombre",
  "reservation.labels.number": "Nr rez.",
  "reservation.labels.numberOfPeople": "Número de visitantes",
  "reservation.labels.phone": "Número de teléfono",
  "reservation.labels.profil": "Perfil",
  "reservation.labels.remarks": "Comentarios",
  "reservation.labels.status": "Estado",
  "reservation.labels.tableNumber": "Sala - mesa",
  "reservation.lessSpotThanPeople":
    "Se seleccionó un número menor de lugares. ¿Continuará?",
  "reservation.message": "Mensaje de MyTable.co.uk",
  "reservation.messages": "Escribir un mensaje",
  "reservation.morePeople": "Más gente:",
  "reservation.newPersonToReservation":
    "¿Añadir una nueva persona a la reserva?",
  "reservation.noFreeTables": "No hay mesas libres",
  "reservation.notExist": "La reserva no existe",
  "reservation.numberOfGuests": "Número de visitantes",
  "reservation.payment.pay": "Pagar",
  "reservation.payment.status": "Estado de los pagos",
  "reservation.payment.status.Cancelled": "Cancelado",
  "reservation.payment.status.Cancelled.text": "Se ha cancelado el pago",
  "reservation.payment.status.Completed": "Realizado",
  "reservation.payment.status.Completed.text": "Pago efectuado",
  "reservation.payment.status.Created": "Creado",
  "reservation.payment.status.Created.text": "Pago creado",
  "reservation.payment.status.Expired": "Caducado",
  "reservation.payment.status.Expired.text": "Pago caducado",
  "reservation.payment.status.ProcessingPayment": "Procesado",
  "reservation.payment.status.ProcessingPayment.text": "Pago en curso",
  "reservation.payment.status.Unknown": "Estado desconocido",
  "reservation.payment.status.Unknown.text": "Estado de pago desconocido",
  "reservation.payment.status.WaitingForPayment": "A la espera de",
  "reservation.payment.status.WaitingForPayment.text": "Pago pendiente",
  "reservation.paymentDetails": "Datos de pago",
  "reservation.paymentForReservation": "Pago de la reserva",
  "reservation.paymentRequired": "Las reservas son de pago",
  "reservation.payments": "Pagos",
  "reservation.personsError":
    "No se puede enviar la solicitud de reserva para 0 personas",
  "reservation.pickDurationTime": "Seleccione la duración",
  "reservation.preferences": "Preferencias",
  "reservation.prepayment": "Pago anticipado",
  "reservation.remarks": "Comentarios",
  "reservation.remarksFromRestaurant": "Comentario del restaurante",
  "reservation.remind.message":
    "El servicio MojStolik.es le recuerda que ha realizado una reserva en el restaurante _RESTAURANT_ el día _DATE_ para _PERSONS_. Para cancelar la reserva, haga clic en el enlace: _LINK_ El servicio de reservas es proporcionado por el servicio MojStolik.pl",
  "reservation.request.not.yet.available":
    "Las reservas aún no están disponibles.",
  "reservation.request.too.often": "Solicitud recién enviada",
  "reservation.reservationDate": "Día",
  "reservation.spotsChosen": "Lugares seleccionados",
  "reservation.status": "Estado",
  "reservation.status.Canceled": "Cancelado",
  "reservation.status.Completed": "Reserva efectuada",
  "reservation.status.Confirmed": "Reserva confirmada",
  "reservation.status.Delayed": "Retraso",
  "reservation.status.NotCompleted": "Reserva no realizada",
  "reservation.status.Rejected": "Reserva anulada",
  "reservation.status.WaitingForApproval": "Reserva pendiente de aprobación",
  "reservation.status.WaitingForConfirmation": "A la espera de",
  "reservation.successRequest": "Se ha enviado una solicitud de reserva",
  "reservation.summaryCost": "Importe total",
  "reservation.updateAction": "Se ha actualizado la reserva",
  "reservation.updateReservation": "¿Actualizar su reserva?",
  "reservation.updateReservationButton": "Actualice su reserva",
  "reservation.whichHour": "Hora",
  "reservation.wholeRoom": "Toda la sala",
  "reservationHistoryPage.button.revoke": "Cancelar",
  "reservationHistoryPage.header": "Sus reservas",
  "reservationHistoryPage.metadata.description": "Descripción de sus reservas",
  "reservationHistoryPage.metadata.keywords": "Sus palabras clave de reserva",
  "reservationHistoryPage.metadata.title": "Sus reservas",
  "reservationHistoryPage.paragraph.additionalDescription":
    "Comunicación con el restaurante",
  "reservationHistoryPage.paragraph.date": "Datos",
  "reservationHistoryPage.paragraph.duration": "Duración",
  "reservationHistoryPage.paragraph.hour": "Hora",
  "reservationHistoryPage.paragraph.persons": "Número de personas",
  "reservationHistoryPage.paragraph.personsShortcut": "os.",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "La reserva no existe",
  "reservationHistoryPage.paragraph.reservationListIsEmpty":
    "La lista de reservas está vacía.",
  "reservationHistoryPage.subheader":
    "Aquí puede consultar su historial de reservas y gestionar las reservas futuras.",
  "reservationMenu.allActive": "Todos los activos",
  "reservationMenu.fromApp": "Consultas sobre aplicaciones",
  "reservationMenu.toConfirmation": "Por confirmar",
  "reservationMenu.today": "Por hoy",
  "reservationParams.restaurantName": "Nombre del local",
  "reservationParams.yourLocalization": "Tu ubicación",
  "reservations.noReservations": "Sin reserva",
  "resetPassoword.generalTitle": "Recordatorio de contraseña",
  "resetPassoword.info":
    "Introduzca el número de teléfono al que se ha registrado su cuenta. La contraseña se enviará por SMS a este número.",
  "resetPassoword.sendNewPassword": "Enviar contraseña",
  "resetPasswordPage.button.confirm": "Confirme",
  "resetPasswordPage.header": "Restablecer contraseña",
  "resetPasswordPage.input.label.phoneOrEmail": "Número de teléfono",
  "resetPasswordPage.metadata.description":
    "Descripción del restablecimiento de contraseña",
  "resetPasswordPage.metadata.keywords":
    "Restablecer contraseña de palabra clave",
  "resetPasswordPage.metadata.title": "Restablecer contraseña",
  "resetPasswordPage.subheader":
    "Rellene el siguiente formulario y le enviaremos una nueva contraseña de cuenta por SMS",
  "resetPasswordPage.subheader2":
    "Te enviaremos una nueva contraseña a tu número de teléfono",
  "resetPasswordPage.toast.error.passwordChanged":
    "El número de teléfono indicado es incorrecto.",
  "resetPasswordPage.toast.passwordChanged":
    "Se envió una nueva contraseña por SMS al número de teléfono facilitado durante el registro.",
  "restaurant.aboutUs": "O en",
  "restaurant.bankAccountNumber": "Número de cuenta bancaria",
  "restaurant.buildingNumber": "Número de edificio",
  "restaurant.city": "Ciudad",
  "restaurant.closeHour": "Hora de cierre",
  "restaurant.companyName": "Nombre de la empresa",
  "restaurant.dayFormat": "dd - día del mes",
  "restaurant.defaultReservationLength":
    "Duración predeterminada de la reserva (h)",
  "restaurant.emailAddress": "Correo electrónico",
  "restaurant.emptyList":
    "No hay restaurantes que coincidan con los criterios de búsqueda indicados.",
  "restaurant.greaterThenZeroError": "Debe ser mayor que 0",
  "restaurant.insertRestaurant": "Entrar en restaurantes",
  "restaurant.legend": "Leyenda:",
  "restaurant.localNumber": "Número local",
  "restaurant.logo": "Logotipo",
  "restaurant.maximalReservationLength": "Longitud máxima de reserva (h)",
  "restaurant.menu.price": "{{price}} zł",
  "restaurant.minimalPeopleForGroup":
    "Número mínimo de personas por reserva de grupo",
  "restaurant.monthFormat": "MM - mes",
  "restaurant.navbar.aboutRestaurant": "Restaurante",
  "restaurant.navbar.menu": "Menú",
  "restaurant.navbar.promotion": "Promociones",
  "restaurant.navigate": "Navegar hasta el restaurante",
  "restaurant.navigationApp": "Aplicación de navegación",
  "restaurant.nipNumber": "PIN",
  "restaurant.openHour": "Hora de apertura",
  "restaurant.pickDay": "Elija un día",
  "restaurant.pickHour": "Seleccione una hora",
  "restaurant.pickPersons": "Seleccione el número de personas",
  "restaurant.postalCode": "Código postal",
  "restaurant.price.expensive": "Muy caro",
  "restaurant.price.extralow": "Muy barato",
  "restaurant.price.low": "Barato",
  "restaurant.price.medium": "No tan caro",
  "restaurant.productsServices": "Productos y servicios",
  "restaurant.promotion.label": "Promociones",
  "restaurant.questionUpdateRestaurant":
    "¿Actualizar los datos del restaurante?",
  "restaurant.rate": "Evaluación",
  "restaurant.restaurant": "Restaurante",
  "restaurant.saveNavigationPick": "Recuerda la elección",
  "restaurant.seriesNumberTemplate": "Plantilla de numeración de facturas",
  "restaurant.serverAddress": "Dirección del servidor",
  "restaurant.street": "Calle",
  "restaurant.web.confirm": "¿Quiere abrir un sitio web?",
  "restaurant.writeCity": "Ciudad",
  "restaurant.yearEndFormat": "uu - fin de año p.ej. 18",
  "restaurant.yearFullFormat": "uuu - un año completo p.ej. 2018",
  "restaurantPage.anchor.edit": "Editar",
  "restaurantPage.anchor.login": "Inicio de sesión",
  "restaurantPage.anchor.register": "regístrese en",
  "restaurantPage.anchor.sendCodeAgain": "Reenviar código",
  "restaurantPage.button.cancel": "Anuncie",
  "restaurantPage.button.confirm": "Confirme",
  "restaurantPage.button.goToHomePage": "Volver a la página de inicio",
  "restaurantPage.button.pageWWW": "Página web",
  "restaurantPage.button.seeMenu": "Ver el menú",
  "restaurantPage.button.showLess": "Mostrar menos",
  "restaurantPage.button.showMore": "Ver más",
  "restaurantPage.header.gallery": "Galería de fotos",
  "restaurantPage.header.promotions": "Ofertas especiales y eventos",
  "restaurantPage.header.reservationConfirmation": "Confirmación de reserva",
  "restaurantPage.header.restaurantDescription": "Descripción del restaurante",
  "restaurantPage.header.statements": "Declaraciones",
  "restaurantPage.header.use": "Utilice",
  "restaurantPage.header.yourPersonalData": "Sus datos",
  "restaurantPage.header.yourReservation": "Su reserva",
  "restaurantPage.input.label.agreementEvent":
    " He leído las condiciones del evento y de la promoción, que se encuentran en el",
  "restaurantPage.input.label.agreementEvent.extension": "en este enlace",
  "restaurantPage.input.label.agreementMarketing":
    "Deseo recibir promociones, ofertas especiales, ofertas de concursos u otros contenidos de marketing de MojStolik, incluida información personalizada sobre los servicios y productos de MojStolik disponibles en la Aplicación para clientes, mediante comunicación electrónica. Puede retirar este consentimiento en cualquier momento.",
  "restaurantPage.input.label.agreementMarketingThirdPart":
    " Deseo recibir promociones, ofertas especiales, ofertas de concursos u otros contenidos de marketing de MojStolik, incluida información personalizada para mí sobre los servicios y productos del Restaurante y las Entidades colaboradoras indicados en mojstolik.es, disponibles en la Solicitud del cliente, a través de comunicación electrónica. Puede retirar este consentimiento en cualquier momento.",
  "restaurantPage.input.label.code": "Introduce el código del día",
  "restaurantPage.input.label.date": "Datos",
  "restaurantPage.input.label.description": "Notas de reserva",
  "restaurantPage.input.label.email": "Correo electrónico",
  "restaurantPage.input.label.firstName": "Nombre",
  "restaurantPage.input.label.footer":
    "MojStolik utiliza diversos canales de comunicación electrónica y lleva a cabo su actividad mediante correo electrónico, teléfono, SMS y MMS. El administrador de sus datos personales es Table4Rest spółka z ograniczoną odpowiedzialnością con domicilio social en Varsovia, 02-656 Varsovia ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Sus datos personales se procesarán con el fin de dirigirle contenidos de marketing a través de canales de comunicación electrónica. Para más información sobre el tratamiento de sus datos personales, incluidos sus derechos, consulte la Política de privacidad.",
  "restaurantPage.input.label.hour": "Hora",
  "restaurantPage.input.label.lastName": "Nombre",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Deseo recibir promociones, ofertas especiales, ofertas de concursos u otros contenidos de marketing de MojStolik, incluida información personalizada para mí sobre los servicios y productos del Restaurante y las Entidades colaboradoras indicados en mojstolik.es, disponibles en la Solicitud del cliente, a través de comunicación electrónica. Puede retirar este consentimiento en cualquier momento.",
  "restaurantPage.input.label.personalData":
    "Declaro que conozco y acepto las disposiciones de los Términos y Condiciones para la prestación de servicios por parte de MojStolik a través de la Solicitud de Cliente.",
  "restaurantPage.input.label.personalData.extension":
    "Términos y condiciones de MojStolik",
  "restaurantPage.input.label.persons": "Número de personas",
  "restaurantPage.input.label.persons.optionExtend": "os.",
  "restaurantPage.input.label.phoneNumber": "Número de teléfono",
  "restaurantPage.input.label.readWithdrawal":
    "He leído los términos y condiciones de Mojstolik.es, en particular las instrucciones sobre el derecho de desistimiento.",
  "restaurantPage.input.label.readWithdrawal.extension":
    "Términos y condiciones de mojstolik.pl",
  "restaurantPage.input.placeholder.email": "Por ejemplo, adres@email.pl",
  "restaurantPage.input.placeholder.firstName": "Por ejemplo, Jan",
  "restaurantPage.input.placeholder.lastName": "Por ejemplo, Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.typeIn": "Entre en",
  "restaurantPage.messages.codeResent": "Se ha vuelto a enviar el código SMS.",
  "restaurantPage.paragraph.dataIsIncorrect": "¿Los datos son incorrectos?",
  "restaurantPage.paragraph.doYouHaveAnAccount":
    "¿Ya tiene una cuenta o desea registrarse?",
  "restaurantPage.paragraph.dontWontUsePromotion": "No quiero usar promociones",
  "restaurantPage.paragraph.noAvailableHours":
    "No hay horas disponibles en estos criterios.",
  "restaurantPage.paragraph.noAvailableHoursExtension":
    "Elige otra fecha o otro número de personas.",
  "restaurantPage.paragraph.or": " o",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Introduce el código del SMS que enviamos a tu número de teléfono para completar el proceso de reserva de mesa.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Introduce el código del mensaje que enviamos a tu dirección de correo electrónico para completar el proceso de reserva de mesa.",
  "restaurantPage.paragraph.sendCodeAgainText":
    "El código ha sido reenviado por SMS. ¿No recibiste el código?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Autorízate a través del correo electrónico:",
  "restaurantPage.paragraph.sendCodeViaEmail.link":
    "Envía el código a la dirección de correo electrónico proporcionada.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent":
    "El código ha sido enviado a la dirección de correo electrónico:",
  "restaurantPage.paragraph.tableReserved": "Se aceptan reservas",
  "restaurantPage.paragraph.tableReservedDescription":
    "Su reserva para hrs ha sido aceptada.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "en",
  "restaurantPage.paragraph.tableWaitingForApproval":
    "Reserva pendiente de aprobación",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription":
    "Su reserva por hrs.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "en",
  "restaurantPreview.doReservation": "Reserve",
  "restaurantPromotionInfoModal.button.use": "Utilice",
  "restaurantPromotionInfoModal.paragraph.promotionDetails":
    "Detalles de la oferta",
  "room.firstRoomVisualization":
    "En primer lugar, cree una visualización de la sala",
  "searchMapPage.button.list": "Lista",
  "searchMapPage.button.map": "Mapa",
  "searchMapPage.header": "Resultados de la búsqueda",
  "searchMapPage.map.button.refresh": "Actualizar el mapa",
  "searchMapPage.metadata.description":
    "Descripción de los resultados de la búsqueda",
  "searchMapPage.metadata.keywords":
    "Resultados de la búsqueda por palabra clave",
  "searchMapPage.metadata.title": "Resultados de la búsqueda",
  "searchPage.button.list": "Lista",
  "searchPage.button.map": "Mapa",
  "searchPage.header": "Resultados de la búsqueda",
  "searchPage.metadata.description":
    "Descripción de los resultados de la búsqueda",
  "searchPage.metadata.keywords": "Resultados de la búsqueda por palabra clave",
  "searchPage.metadata.title": "Resultados de la búsqueda",
  "searchPage.paragraph.noData":
    "No hay resultados para los criterios indicados.",
  "searchResultItem.component.button.book": "Reserve",
  "searchResultItem.component.button.cancel": "Anuncie",
  "searchResultItem.component.button.see": "Véase",
  "searchResultItem.component.label.availableHours": "Horas disponibles",
  "shortcut.isClosed": "cerrado",
  "shortcut.monday": "Lu.",
  "shortcut.tuesday": "Ma.",
  "shortcut.wednesday": "Mi.",
  "shortcut.thursday": "Ju.",
  "shortcut.friday": "Vi.",
  "shortcut.saturday": "Sá.",
  "shortcut.sunday": "Do.",
  "sms.send.problem":
    "Problema al enviar SMS, inténtalo a través de la aplicación",
  "social.appleloginBtn": " Iniciar sesión con Apple",
  "social.fbloginBtn": "Conectarse",
  "social.googleloginBtn": "Conectarse",
  "startPage.metadata.description":
    "Encuentra los mejores restaurantes de tu ciudad.",
  "startPage.metadata.keywords":
    "reserva de mesas, buscador de restaurantes, restaurantes",
  "startPage.metadata.title": "MojStolik.pl",
  "status.Canceled": "Cancelado",
  "status.Confirmed": "Confirmado",
  "status.Rejected": "Rechazado",
  "status.WaitingForConfirmation": "A la espera de confirmación",
  "token.expired": "Código caducado",
  "token.wrong": "El código no coincide o ha caducado",
  "user.acceptAgreement":
    "Acepto las condiciones del servicio www.mojstolik.pl",
  "user.addNewAction": "Cliente añadido",
  "user.cityAddress": "Ciudad",
  "user.dateOfBirth": "Fecha de nacimiento",
  "user.editAction": "Cliente actualizado",
  "user.email": "Correo electrónico",
  "user.firstName": "Nombre",
  "user.lastName": "Nombre",
  "user.name": "Nombre",
  "user.phone": "Número de teléfono",
  "user.postalCode": "Código postal",
  "user.sessionExpire": "Su sesión ha expirado",
  "user.streetAddress": "Dirección",
  "user.successUpdate": "Se han actualizado los datos personales",
  "user.surname": "Nombre",
  "user.updateQuestion": "¿Desea actualizar sus datos personales?",
  "validation.fieldRequired": "Este campo es obligatorio",
  "validation.passwordRequired": "Contraseña obligatoria",
  "validation.phoneRequired": "Se requiere un número de teléfono",
};

export default backendTranslationsES;
