const baseConstants = {
  APPLICATION_ALIAS: (process.env.REACT_APP__APPLICATION_ALIAS || 'ms-web'),
  BASE_URL: (process.env.REACT_APP__API_BASE_URL || ''),
  ONBOARDING_URL: (process.env.REACT_APP__API_ONBOARDING_URL || ''),
  'STRAPI_URL': (process.env.REACT_APP__API_STRAPI_URL || ''),
  'STRAPI_CONTEXT': (process.env.REACT_APP__API_STRAPI_CONTEXT || '/api'),
  NO_IMAGE: 'no-image.png',
  BASE_LOCATION_POLAND: {
    name: 'Polska',
    locationBounds: {
      northEast: {
        latitude: 54.75124,
        longitude: 24.03854
      },
      southWest: {
        latitude: 49.01048,
        longitude: 14.19479
      }
    }
  },
  BASE_LOCATION_WARSAW: {
    name: 'Warszawa',
    locationBounds: {
      northEast: {
        latitude: 52.495794783518804,
        longitude: 21.313079260000002
      },
      southWest: {
        latitude: 51.9700811164812,
        longitude: 20.80930894
      }
    }
  },
  FACEBOOK_ID: (process.env.REACT_APP__API_FACEBOOK_ID || ''),
  GOOGLE_ID: (process.env.REACT_APP__API_GOOGLE_ID || ''),
  GOOGLE_MAP_KEY: (process.env.REACT_APP__API_GOOGLE_MAP_KEY || ''),
  DEFAULT_RESERVATION_TIME_FROM: '00:00' /* VERY IMPORTANT: only accepts the format HH:MM (e.g. 00:00, 01:00) */,
  RESERVATION_STATUSES: [
    'WaitingForConfirmation',
    'Confirmed',
    'Canceled',
    'Rejected',
    'Completed',
    'NotCompleted',
    'WaitingForApproval'
  ]
};

export default baseConstants;