import React from "react";
import Modal from "react-modal";
import { restaurantUtils } from "../../utils";
import { routeService } from "../../services";
import { NavLink } from "react-router-dom";
import Img from "react-image";
import Ink from "react-ink";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import "./RestaurantPromotionInfoModal.scss";
// import Linkify from 'react-linkify';
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const RestaurantPromotionInfoModal = (props) => {
  const { t } = useTranslation();
  let restaurantData = props.data || null;
  const isOpen = props.isOpen || false;
  const onAfterOpen = props.onAfterOpen || null;
  const onAcceptOffer = props.onAccept || null;
  const onRequestClose = props.onRequestClose || null;
  const currentLanguage = props.currentLanguage || "pl";
  const page = props.page;
  const { search } = useLocation();
  const values = queryString.parse(search);
  const onResignation = props.onResignation;

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const handleOnUse = () => {
    restaurantUtils.pushEventActionGTM("rest_promo_use");
    onRequestClose();
    onAcceptOffer && onAcceptOffer(restaurantData);
  };

  const handleOnResignation = () => {
    restaurantUtils.pushEventActionGTM("rest_promo_cancel");
    onResignation();
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={isOpen}
        onAfterOpen={onAfterOpen}
        onRequestClose={onRequestClose}
        style={customStyles}
        contentLabel=""
        ariaHideApp={false}
      >
        <span
          className="closeModalButton"
          onClick={() => {
            onRequestClose();
            restaurantUtils.pushEventActionGTM("rest_promo_cancel");
          }}
        ></span>
        {restaurantData && restaurantData.restaurantName ? (
          <div className="modalInside restaurantPromotionInfoModal">
            <NavLink
              className="logoAnchor"
              to={restaurantUtils.getLink(
                routeService.getRoute("RESTAURANT_PROFILE", currentLanguage),
                restaurantData.restaurantName,
                restaurantData.alias.value
              )}
            >
              <Img
                src={restaurantUtils.getImageSource(
                  restaurantData.restaurantId.value,
                  restaurantData.image,
                  "logo"
                )}
                loader={
                  <div className="loaderMask loaderMask--restaurantLogo">
                    <span className="loaderMaskInside"></span>
                  </div>
                }
                alt={`${restaurantData.restaurantName} logo`}
                className="restaurantLogo"
              />
            </NavLink>
            {/* {props.configuration && props.configuration.cuisineOn && (
              <p className="categoryName">
                {restaurantUtils.getRestaurantType(restaurantData.preferences)}
              </p>
            )} */}
            <h2>
              <NavLink
                className="restaurantNameAnchor"
                to={restaurantUtils.getLink(
                  routeService.getRoute("RESTAURANT_PROFILE", currentLanguage),
                  restaurantData.restaurantName,
                  restaurantData.alias.value
                )}
              >
                {restaurantData.restaurantName}
              </NavLink>
            </h2>
            {restaurantData && restaurantData.address && (
              <React.Fragment>
                <p className="subheader">
                  <span className="locationValue">
                    <u>
                      {restaurantData.address.street}
                      {restaurantData.address.buildingNumber
                        ? ` ${restaurantData.address.buildingNumber}`
                        : ""}
                      {restaurantData.address.city
                        ? `, ${restaurantData.address.city}`
                        : ""}
                    </u>
                  </span>
                  <span className="hoursValue">
                    {restaurantData.effectiveDate !== ""
                      ? restaurantData.effectiveDate
                      : "-"}
                  </span>
                </p>
                <div className="promotionDescription">
                  <h3 style={{ margin: "2px 0" }}>
                    {t(
                      "restaurantPromotionInfoModal.paragraph.promotionDetails"
                    )}{" "}
                    — {restaurantData.name}:
                  </h3>
                  <ReactMarkdown
                    children={restaurantData.descriptionMarkdown}
                    remarkPlugins={[remarkGfm]}
                  />
                </div>
                <div className="button-container">
                  {page ? (
                    <button
                      onClick={() => {
                        onRequestClose();
                        restaurantUtils.pushEventActionGTM("rest_promo_cancel");
                      }}
                      className="MSButton MSButton--white MSButton--pointer"
                    >
                      {t("previewRestaurantModal.component.anchor.close")}
                    </button>
                  ) : (
                    <NavLink
                      to={
                        restaurantUtils.getLink(
                          routeService.getRoute(
                            "RESTAURANT_PROFILE",
                            currentLanguage
                          ),
                          restaurantData.restaurantName,
                          restaurantData.alias.value
                        ) + `${values.plugin ? "?plugin=true&" : ""}`
                      }
                      className="MSButton MSButton--white MSButton--pointer"
                      onClick={handleOnResignation}
                    >
                      {t("restaurantPage.header.cancel")}
                    </NavLink>
                  )}

                  <NavLink
                    className="MSButton MSButton--orange MSButton--pointer"
                    to={
                      restaurantUtils.getLink(
                        routeService.getRoute(
                          "RESTAURANT_PROFILE",
                          currentLanguage
                        ),
                        restaurantData.restaurantName,
                        restaurantData.alias.value
                      ) +
                      `${
                        values.plugin ? "?plugin=true&" : ""
                      }?selectedPromotion=${restaurantData.id.value}&eventId=${
                        restaurantData.eventId.value
                      }`
                    }
                    onClick={handleOnUse}
                  >
                    <span>{t("restaurantPromotionInfoModal.button.use")}</span>
                    <Ink />
                  </NavLink>
                </div>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div
            style={{
              width: "200px",
              height: "200px",
            }}
          >
            <div
              className="loaderMask"
              style={{
                backgroundColor: "rgba(255, 255, 255, .5)",
                width: "100%",
                height: "100%",
                position: "absolute",
                top: 0,
                left: 0,
              }}
            >
              <span className="loaderMaskInside" />
            </div>
          </div>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default RestaurantPromotionInfoModal;
