import React, { useState } from "react";
import { restaurantUtils } from "../../utils";
import { routeService } from "../../services";
import { useTranslation } from "react-i18next";
// import Img from "react-image";
import Rating from "react-rating";
import Ink from "react-ink";
import HorizontalScrollMenuAlternative from "../horizontalScrollMenuAlternative/HorizontalScrollMenuAlternative";
import classnames from 'classnames';
import "./SearchMapResultItem.scss";

const SearchMapResultItem = props => {
  const { t } = useTranslation();
  const { singleRestaurant, currentLanguage, size, queryStringParams } = props;
  const [reservationDate, setReservationDate] = useState(
    queryStringParams && queryStringParams.hour && queryStringParams.date
      ? `${queryStringParams.date}T${queryStringParams.hour}`
      : null
  );

  const availableHoursOnSelect = (selectedValue) => { 
    setReservationDate(selectedValue.value);
  };

  let additionalQueryStrings = Object.assign({}, queryStringParams, (
    {
      hour: queryStringParams.hour,
      selectedHour: reservationDate ? reservationDate : null
    }
  ));

  const openHoursData = restaurantUtils.getOpenHours(singleRestaurant.restaurant.info.openHours);

  const minPersons =  singleRestaurant?.restaurant?.info?.minReservationSize || props?.configuration?.minPersons || 1;
  const maxPersons =  singleRestaurant?.restaurant?.info?.maxReservationSize || props?.configuration?.maxPersons || 20;

  // const clearMarker = () => {
  //   if(typeof props.onClearMarker === 'function') {
  //     props.onClearMarker();
  //   }
  // }

  const isAvailableTimes = (
    singleRestaurant &&
    singleRestaurant.availableTimes &&
    Array.isArray(singleRestaurant.availableTimes) &&
    singleRestaurant.availableTimes.length > 0
  );

  const restaurantAvailableHoursInterval = (isAvailableTimes && singleRestaurant.availableTimes.length > 0 ? (
    restaurantUtils.getFullAvailableHours(currentLanguage, '00:00', '23:45', queryStringParams.date)
  ) : []);


  return (
    <div className="searchMapResultItem">
      {singleRestaurant && singleRestaurant.restaurant && (
        <div
          className={classnames({
            "restaurantLine": true,
            "restaurantLine--small": (size === 'small'),
          })}
        >
          {/* <div className="restaurantLine__image">
            
            <span
              // to={restaurantUtils.getLink(
              //   routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
              //   singleRestaurant.restaurant.info.name,
              //   singleRestaurant.restaurant.info.alias.value
              // )}
            >
              <Img
                src={restaurantUtils.getImageSource(
                  singleRestaurant.restaurant.info.id.value,
                  singleRestaurant.restaurant.logoExtension
                )}
                loader={
                  <div className="loaderMask">
                    <span className="loaderMaskInside"></span>
                  </div>
                }
                unloader={<div className="noImage"></div>}
                alt={`${singleRestaurant.restaurant.info.name} logo`}
                className="restaurantLogo"
              />
            </span>
          </div> */}
          <div className="restaurantLine__details">
            <span
              className="logoAnchor"
              onClick={() => {
                props.history.push(restaurantUtils.getLink(
                  routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                  singleRestaurant.restaurant.info.name,
                  singleRestaurant.restaurant.info.alias.value
                ));
              }}
            >
              <img
                src={restaurantUtils.getImageSource(
                  singleRestaurant.restaurant.info.alias.restaurantId.value,
                  singleRestaurant.restaurant.logoExtension,
                  "logo"
                )}
                alt={`${singleRestaurant.restaurant.info.name} logo`}
                className="restaurantLogo"
              />
            </span>
            {/* {props.configuration && props.configuration.cuisineOn && (
              <p className="categoryName">
                {restaurantUtils.getRestaurantType(
                  singleRestaurant.restaurant.info.preferences
                )}
              </p>
            )} */}
            <div className="restaurantName">
              <span
                onClick={() => {
                  props.history.push(restaurantUtils.getLink(
                    routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                    singleRestaurant.restaurant.info.name,
                    singleRestaurant.restaurant.info.alias.value
                  ));
                }}
              >
                {singleRestaurant.restaurant.info.name}
              </span>
              {props.configuration && props.configuration.ratingOn && (
                <span className="ratingStars">
                  <Rating
                    readonly={true}
                    placeholderRating={
                      singleRestaurant.restaurant.info.ranking || 0
                    }
                    emptySymbol={
                      <img
                        src="/images/icon-star-empty-symbol.svg"
                        className="icon"
                        alt="star"
                      />
                    }
                    placeholderSymbol={
                      <img
                        src="/images/icon-star-full-symbol.svg"
                        className="icon"
                        alt="star"
                      />
                    }
                    fullSymbol={
                      <img
                        src="/images/icon-star-full-symbol.svg"
                        className="icon"
                        alt="star"
                      />
                    }
                  />
                </span>
              )}
            </div>
            <span
              className="locationValue"
              onClick={() => {
                props.history.push(restaurantUtils.getLink(
                  routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                  singleRestaurant.restaurant.info.name,
                  singleRestaurant.restaurant.info.alias.value
                ));
              }}
            >
              {singleRestaurant.restaurant.info.address.street}
              {singleRestaurant.restaurant.info.address.buildingNumber
                ? ` ${singleRestaurant.restaurant.info.address.buildingNumber}`
                : ""}
              {singleRestaurant.restaurant.info.address.city
                ? `, ${singleRestaurant.restaurant.info.address.city}`
                : ""}
            </span>
            <span
              className="hoursValue"
              onClick={() => {
                props.history.push(restaurantUtils.getLink(
                  routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                  singleRestaurant.restaurant.info.name,
                  singleRestaurant.restaurant.info.alias.value
                ));
              }}
            >
              {openHoursData.map((dayData, dayIterator) => {
                const isClosed = !(dayData.openTime && dayData.closeTime);
                if (!isClosed && !dayData.openTime) {
                  dayData.openTime = '0:00';
                }
                if (!isClosed && !dayData.closeTime) {
                  dayData.closeTime = '24:00';
                }
                return (
                  <React.Fragment key={`restaurantProfilePage-${singleRestaurant.restaurant.info.alias.value}--openHours--${dayIterator}`}>
                    {dayIterator > 0 ? ', ' : null}
                    <span key={`restaurantProfile-${singleRestaurant.restaurant.info.alias.value}--${dayIterator}`}>
                      {dayData.day === dayData.dayTo ? t(`shortcut.${dayData.day.toLowerCase()}`) : `${t(`shortcut.${dayData.day.toLowerCase()}`)}—${t(`shortcut.${dayData.dayTo.toLowerCase()}`)}`}: {isClosed ? t(`shortcut.isClosed`) : `${dayData.openTime}—${dayData.closeTime}`} 
                    </span>
                  </React.Fragment>
                )
              })}
            </span>
            {singleRestaurant.restaurant.info.preferences &&
                <div className="preferencesDiv">
                    <span className="preferencesValue">
                        {singleRestaurant.restaurant.info.preferences.map((preference) => preference.description).join(', ')}
                    </span>
                </div>
            }
            { minPersons && maxPersons &&
              <div className="personsDiv">
                <span className="personsValue">
                    {t(`restaurantPage.header.reservationsPersons`, {'min' : minPersons, 'max': maxPersons})}
                </span>
              </div>
            }
            {/* <p className="aboutRestaurantText">
              {singleRestaurant.restaurant.info.shortDescription}
            </p> */}
            
            {Array.isArray(singleRestaurant.availableTimes) && singleRestaurant.availableTimes.length > 0 ? (
              <React.Fragment>
                <p className="availableHoursLabel">{t('searchResultItem.component.label.availableHours')}:</p>
                <div className="availableHoursContainer">
                  <div className="availableHoursContainer__body">
                    <HorizontalScrollMenuAlternative specificKey={singleRestaurant.restaurant.info.alias.value} data={restaurantAvailableHoursInterval.map(availableTimesItem => {
                      const isDisabled = singleRestaurant.availableTimes.indexOf(`${availableTimesItem.value}:00`) === -1;                        
                      let newItem = {...availableTimesItem};
                      if(isDisabled) {
                        newItem['disabled'] = true;
                      }
                      return newItem;
                    }
                    )} onSelect={availableHoursOnSelect} selected={reservationDate || null} 
                    autoSelect={true} />
                  </div>
                  
                  <div className="availableHoursContainer__submit">
                    <span
                      className="MSButton MSButton--orange MSButton--pointer"
                      onClick={() => {
                        props.history.push(restaurantUtils.getLink(
                          routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                          singleRestaurant.restaurant.info.name,
                          singleRestaurant.restaurant.info.alias.value
                        ));
                      }}
                    >
                      <React.Fragment>
                        <span>{t('searchResultItem.component.button.book')}</span>
                        <Ink />
                      </React.Fragment>
                    </span>
                  </div>
                </div>
              </React.Fragment>
            ): 
            <p className="noAvailableHours">
              <span className="redColor">
                {t(
                  "searchResultItem.component.label.noAvailableTables"
                )}
              </span>
            </p>
          }

            {(!singleRestaurant.availableTimes || (Array.isArray(singleRestaurant.availableTimes) && singleRestaurant.availableTimes.length === 0)) && (
              <p className={classnames({
                "seeRestaurantContainer": true
              })}>
                <span
                  className="MSButton MSButton--orange MSButton--pointer"
                  onClick={() => {
                    props.history.push(restaurantUtils.getLink(
                      routeService.getRoute("RESTAURANT_PROFILE", currentLanguage, additionalQueryStrings),
                      singleRestaurant.restaurant.info.name,
                      singleRestaurant.restaurant.info.alias.value
                    ));
                  }}
                >
                  <React.Fragment>
                    <span>{t('searchResultItem.component.button.see')}</span>
                    <Ink />
                  </React.Fragment>
                </span>
              </p>
            )}
            
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchMapResultItem;
