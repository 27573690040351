import React, { useState } from 'react'
import Modal from "react-modal";
import "./EventModal.scss"
import axios from "axios";
import { requestConstants } from "./../../constants"
import { EventImage } from "../../components/image/Image"
import { useTranslation } from 'react-i18next';


/**
 *  Wyświetlanie modalu reklamowego wydarzenia
 *  Musi przyjść obiekt z backendu by się wyświetlił
 */
const EventModal = () => {
    const [isOpen, setIsOpen] = useState(false)
    const [response, setResponse] = useState(true)
    const { t } = useTranslation()
    React.useEffect(() => {
        const loadData = async () => {
            try {
                const resp = await axios.get(`${requestConstants["API_BASE_URL"]}/api/event/banner`)
                // const images = await axios.get(`${resp.data.bannerUrl}`)
                // console.log("images", images)
                const { data } = resp
                if (data && data.bannerUrl && data.eventUrl  && data.active) {
                    setResponse(resp.data)
                    setIsOpen(true)
                } else {
                    setIsOpen(false)
                }
            } catch (e) {
                setIsOpen(false)
            }
        }
        loadData()
    }, [])

    if (!response || !response.bannerUrl) {
        return null
    }
    return <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="eventModal"
        ariaHideApp={false}
    >
        <span className="closeModalTextButton" onClick={() => setIsOpen(false)}>{t('previewRestaurantModal.component.anchor.close')}</span>

        {EventImage(response, () => setIsOpen(false))}
    </Modal>
}

export default EventModal
