import axios from "axios";
import baseConstants from "./../constants/baseConstants";
export const api = axios.create({
  baseURL: `${baseConstants.STRAPI_URL}${baseConstants.STRAPI_CONTEXT}/blogs`,
  withCredentials: true,
});

const baseFilters = `?sort=createdAt:DESC&filters[active]=true&populate[0]=author&populate[author][populate][0]=avatar&populate=image`

export const getNumberOfBlogs = () => {
  return getMethod("/count");
};

export const getBlogById = (id) => {
  return getMethod(`/${id}?populate=author`);
};

export const getAllBlogs = () => {
  return getMethod(baseFilters);
};

export const getMainBlogs = () => {
  return getMethod(`${baseFilters}&filters[mainPage]=true`);
};

export async function getMethod(url) {
  try {
    return await api.get(url).then((resp) => {
      return resp.data;
    });
  } catch (error) {
    return [];
  }
}

export function postMethod(url) {
  return api.post(url).then((resp) => {
    return resp.data;
  });
  //todo add error handle
}
