const frontendTranslationsDE = {
  "seo.subpage.main.title": "Online-Tischreservierung - MojStolik.pl",
  "seo.subpage.main.description":
    "Reservieren Sie online einen Tisch in den beliebtesten Restaurants in Polen. Laden Sie die App herunter, wählen Sie aus, wo Sie einen Tisch bestellen möchten und buchen Sie. Mit MojStolik.pl ist es ultra-einfach.",
  "seo.subpage.forRestaurant.title": "Buchungsplattform für Restaurants - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl ist eine Plattform für die Verwaltung von Restaurantreservierungen. Alle Reservierungen an 1 Ort. Eine zusätzliche Plattform für die Kommunikation mit Restaurantgästen.",
  "seo.subpage.aboutUs.title": "Eine ultra-einfache App für alle, die gerne auswärts essen.",
  "seo.subpage.aboutUs.description":
    "Hunderte der beliebtesten und angesehensten Restaurants des Landes und Tausende von Nutzern, die die Plattform MojStolik.de verwenden. Lassen Sie einen Tisch auf Sie warten und nicht umgekehrt.",
  "seo.subpage.howItWork.title":
    "Laden Sie die MojStolik.de App auf Ihr Handy und buchen Sie Tische",
  "seo.subpage.howItWork.description":
    "Mit nur wenigen Klicks steht ein Tisch in einem Restaurant für Sie bereit. Sie entscheiden, wann, wo und mit wem Sie zu Mittag oder zu Abend essen möchten. Laden Sie die App herunter und reservieren Sie Ihre Tische.",
  "seo.subpage.documents.title":
    "Finden Sie heraus, welche Vorteile Sie aus der Zusammenarbeit mit MojStolik.pl ziehen werden",
  "seo.subpage.documents.description":
    "Sie fragen sich, ob es sich lohnt? Schauen Sie sich die Highlights in den Dokumenten von MojStolik.de an. Verträge, Marketingangebote und Vorteile der Zusammenarbeit.",
  "seo.subpage.privacyPolicy.title":
    "MojStolik.pl als Verantwortlicher für die personenbezogenen Daten",
  "seo.subpage.privacyPolicy.description":
    "Lesen Sie wichtige Informationen und erfahren Sie mehr über die Plattform MojStolik.pl",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - Entdecken Sie Restaurants in Ihrer Nähe",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.de verwendet Cookies. Entdecken Sie mit uns die beliebtesten Restaurants in Ihrer Nähe.",
  "seo.subpage.registration.title":
    "Registrieren Sie sich und nutzen Sie die App noch heute. MojStolik.pl",
  "seo.subpage.registration.description":
    "Noch nie war es so einfach, einen Tisch zu reservieren. Bequeme Zahlungen, jede Zeit und Anzahl der Personen. Registrieren Sie sich und nutzen Sie MojStolik.pl noch heute.",
  "seo.subpage.login.title":
    "Loggen Sie sich in die App ein und wählen Sie Restaurants von MojStolik.de aus.",
  "seo.subpage.login.description":
    "In unserer App finden Sie die beliebtesten Restaurants in ganz Polen. Laden Sie die App herunter, loggen Sie sich ein und nutzen Sie das supereinfache Tischreservierungssystem.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - Verantwortliches Restaurant",
  "seo.subpage.covid.restaurant.description":
    "Lösungen für die Gastronomie, um Ihr Unternehmen in diesen schwierigen Zeiten am Laufen zu halten!",
  "seo.subpage.covid.client.title": "COVID-19 - Verantwortliche Besucher",
  "seo.subpage.covid.client.description":
    "Ein Leitfaden für verantwortungsbewusste Restaurantbesucher",
  "forRestaurantsPage.subheader":
    "Wenn die Gäste Ihr Restaurant in dieser Gruppe sehen, wissen sie, dass sie einen erstklassigen Service erwarten können.",
  "forRestaurantsPage.platform": "MOJSTOLIK.PL IST",
  "forRestaurantsPage.1.strong":
    "Das ist das Ende der Ära von Buch und Bleistift in Ihrem Restaurant!",
  "forRestaurantsPage.2.strong":
    "ist eine innovative Antwort auf die Bedürfnisse einer zunehmend ungeduldigen und digitalen Gesellschaft,",
  "forRestaurantsPage.2.text":
    "Genießen Sie die erweiterten Funktionen der Tablet-App und der Browser-Schnittstelle. Vergessen Sie das Reservierungsbuch und entscheiden Sie sich für eine einheitliche Lösung für jede Art von Restaurant.",
  "forRestaurantsPage.3.strong": "ist die intelligente Verwaltung aller Ihrer Buchungen,",
  "forRestaurantsPage.3.text":
    "Mit dem MOJSTOLIK.PL-System minimieren Sie das Risiko leerer Tische, an denen die Gäste sitzen sollten, dank:",
  "forRestaurantsPage.4.strong": "ist eine Sammlung von Informationen über Ihre Besucher,",
  "forRestaurantsPage.4.text":
    "die es Ihnen ermöglicht, in ständigem Kontakt mit ihnen zu stehen und sie mit Ihrer Aufmerksamkeit für ihre Bedürfnisse und Gewohnheiten zu überraschen,",
  "forRestaurantsPage.5.strong": "ist Ihre professionelle Datenbank,",
  "forRestaurantsPage.5.text": " und wir sorgen für ihre Sicherheit und die Einhaltung von RODO,",
  "forRestaurantsPage.6.strong":
    "ist ein modernes Instrument zur Kontaktaufnahme mit Ihren Besuchern,",
  "forRestaurantsPage.6.text":
    ' die Bestätigung des Besuchs und die Erinnerung an sich selbst auch während des "Lockdowns",',
  "forRestaurantsPage.7.strong": "ist die Manifestation Ihrer Verantwortung während COVID-19 -",
  "forRestaurantsPage.7.text":
    " Sie haben Kontakt zu Besuchern und können mit Einschränkungen umgehen,",
  "forRestaurantsPage.8.strong":
    "ist es, die Arbeit des Teams zu organisieren und zu rationalisieren,",
  "forRestaurantsPage.9.strong":
    "ist die Möglichkeit der automatischen telefonischen Tischreservierung",
  "forRestaurantsPage.9.text":
    " im Restaurant zu jeder Tages- und Nachtzeit, ohne das Team einzubeziehen. Mit dem IRV-Service geht niemand mit leeren Händen weg, und niemand muss auf eine Antwort warten,",
  "forRestaurantsPage.10.strong":
    "ist ein System, das in Zusammenarbeit mit einigen der am stärksten frequentierten Restaurants in Polen entwickelt wurde.",
  "forRestaurantsPage.10.text": " Wir hören zu und erfüllen Ihre Bedürfnisse,",
  "forRestaurantsPage.11.strong":
    "ist ein ständig verfügbarer Helpdesk in Polen und tägliche Marketing- und PR-Unterstützung,",
  "forRestaurantsPage.12.strong": "ist die technische Ausbildung,",
  "forRestaurantsPage.13.strong": "ist das Unternehmen der bewusstesten Gastronomen in Polen,",
  "forRestaurantsPage.13.text": "die wissen, wie sie ihr Unternehmen effizient führen können,",
  "forRestaurantsPage.14.text": "ist viel mehr...",
  "forRestaurantsPage.form.title":
    "Wenn Sie noch unentschlossen sind und mehr über die Plattform MojStolik.pl erfahren möchten, füllen Sie bitte dieses kurze Formular aus und wir werden Sie kontaktieren:",
  "covid.restaurant.1.strong": "PLAN B:.",
  "covid.restaurant.1.text":
    "Bereiten Sie jetzt IHREN Notfallplan für den Fall eines Anstiegs der Zahl der Erkrankungen vor - überlegen Sie, welche Manöver und Alternativen Sie im Falle einer Wiedereinführung von Einschränkungen unterschiedlichen Grades haben, wie Sie Ihre Arbeit neu organisieren, welche Verfahren Sie einführen, wie Sie den Kontakt zu den Gästen aufrechterhalten, Ihre finanziellen Mittel verwalten und die Arbeit Ihrer Mitarbeiter planen. Sie sind um Ihre Erfahrungen aus dem Frühjahr reicher. Lassen Sie sich nicht ein zweites Mal überraschen.",
  "covid.restaurant.2.strong": "SICHERHEIT UND HYGIENE sind heute Standard, aber...:",
  "covid.restaurant.2.text":
    "Lassen Sie das Thema nicht aus den Augen. Vergewissern Sie sich, dass alle empfohlenen Maßnahmen zur Sicherheit der Gäste und des Personals einwandfrei funktionieren: Gesichtsmasken für die Kellner, Desinfektion der Tische nach jedem Gast, elektronische Speisekarten als Option für die Gäste, Verfahren zum Händewaschen und -desinfizieren, Einhaltung ausreichender Abstände zwischen den Tischen und möglichst einfache bargeldlose Bezahlung. Scheuen Sie sich nicht, die Gäste höflich darauf hinzuweisen, wie sie sich in Ihrem Restaurant zu verhalten haben.",
  "covid.restaurant.3.strong": "BAUEN SIE IHRE GEMEINSCHAFT AUF:",
  "covid.restaurant.3.text":
    "Unsere Beobachtungen zeigen, dass die Restaurants, die es geschafft haben, eine Gemeinschaft um sich herum aufzubauen und während der Schließung mit ihren Gästen über die sozialen Medien oder über Textnachrichten, die von der App MojStolik.pl gesendet wurden, in Kontakt zu bleiben, in der neuen Realität viel besser abschnitten als diejenigen, die nicht angemessen kommunizierten. Stellen Sie also sicher, dass Sie bereits einen ständigen, interessanten und zuverlässigen Kontakt zu Ihren Gästen haben - kümmern Sie sich um die sozialen Medien, Ihre Website und überlegen Sie, wie Sie im Bedarfsfall sonst noch kommunizieren können. Glücklicherweise hat die Regierung im Mai die Verpflichtung für jedes Restaurant, eine Gästedatenbank zu führen, zurückgenommen, aber als verantwortungsbewusster Gastronom sollten Sie eine solche Datenbank aufbauen. Hier ist es sehr hilfreich, die Plattform MojStolik.pl zu nutzen - sie macht es so einfach wie möglich, die Daten (Name, E-Mail, Telefon) Ihrer Gäste in Übereinstimmung mit RODO zu sammeln und zu speichern. Die Daten gehören dem Restaurant.",
  "covid.restaurant.4.strong": "RESERVIERUNG ERFORDERLICH?",
  "covid.restaurant.4.text":
    "In vielen Ländern Europas und der Welt gibt es die Vorstellung, dass man nur in ein Restaurant gehen kann, wenn man im Voraus einen Tisch reserviert hat. Seien Sie also auf diese Eventualität vorbereitet und kümmern Sie sich um die Technik.",
  "covid.restaurant.5.strong": "VERSUCHEN SIE, IHRE GÄSTE DARAUF AUFMERKSAM ZU MACHEN -",
  "covid.restaurant.5.text":
    "Vermitteln Sie über die sozialen Medien, aber auch über andere Kanäle, warum es wichtig ist, die Sicherheitsvorschriften im Restaurant zu befolgen und welche Verfahren Sie in Ihrem Betrieb eingeführt haben, um das Infektionsrisiko zu minimieren. Machen Sie ihnen auch klar, dass Sie durch ihr Verhalten Verluste erleiden, die Sie sich in dieser schwierigen Zeit nicht leisten können. Dabei geht es vor allem um ungenutzte Buchungen, durch die Sie Zeit und Geld verlieren. Manche Menschen sind sich einfach nicht bewusst, wie groß das Problem für Gastronomen ist. Mit der Plattform Mojstolik.de wird der Prozess der Stornierung einer Buchung für die Gäste sehr einfach und läuft auf der Seite des Restaurants völlig automatisch ab.",
  "covid.restaurant.6.strong": "NACH EINSPARUNGEN SUCHEN:",
  "covid.restaurant.6.text":
    "Gerade in der heutigen Zeit ist jeder Gast ein Schatz. Lassen Sie ihn nicht auf einen vielbeschäftigten Kellner warten, um seine Reservierung entgegenzunehmen, der mit begrenztem Personal viele weitere Aufgaben hat und möglicherweise keine Zeit hat, zum Telefon zu laufen. Unsere Berechnungen zeigen, dass das Buchungssystem mehr als 20 Stunden Arbeit für Kellner oder Gastgeber pro Monat einspart, da etwa 50 % der Buchungen automatisch vom Telefon ins Internet gehen und Ihr Team zusätzlich entlasten. Einsparung pur!",
  "covid.restaurant.7.strong": "OPTIMIERUNG DER RAUMNUTZUNG:",
  "covid.restaurant.7.text":
    "Angesichts der geringeren Auslastung von Restaurants ist die Optimierung des Buchungsmanagements und der Tischauslastung entscheidend für das Geschäft. Informieren Sie sich über schlüsselfertige Technologielösungen, die die Kontrolle der über alle Kommunikationskanäle eingehenden Buchungen erleichtern: Website, App, soziale Medien, Telefon, persönliche Vorsprache. Die Plattform MojStolik.pl ist eine solche Lösung.",
  "covid.restaurant.8.strong": "MINIMIERUNG DES NICHT-SCHAU-EFFEKTS:",
  "covid.restaurant.8.text":
    "Eine Buchung, die nicht storniert wird, aber der Gast nicht erscheint, ist der Fluch der Gastronomen. In einer Zeit, in der nur eine Auslastung von 55-75% erlaubt ist, hat ein solches Verhalten der Gäste reale finanzielle Folgen. Versuchen Sie daher, das Risiko solcher Vorkommnisse zu minimieren. MojStolik.pl schickt den Gästen SMS-Erinnerungen über ihre Reservierung. Die Stornierung erfolgt einfach durch Klicken auf einen Link. Durch den Einsatz dieser Lösung erhöhen Sie die Möglichkeit, das Potenzial Ihres Restaurants voll auszuschöpfen und Verluste zu vermeiden. In London sind die Gastronomen in Aufruhr, weil sie sich ihre Verdienstmöglichkeiten jetzt nicht entgehen lassen wollen.",
  "covid.restaurant.9.strong": "SICHERES MENÜ:",
  "covid.restaurant.9.text":
    "Es wird empfohlen, dass Speisekarten in Restaurants Einwegkarten sind und jedes Mal weggeworfen werden, wenn sie jemandem in die Hände fallen. Solche Beschränkungen wurden beispielsweise in einigen US-Bundesstaaten als Pflicht eingeführt. Dies ist weder ökonomisch noch ökologisch sinnvoll. Warum also nicht die Möglichkeit in Betracht ziehen, die Speisekarte auf dem Smartphone des Gastes zu präsentieren? Die Nutzer von MojStolik.pl haben bereits eine solche Möglichkeit. Der Kellner hat die Möglichkeit, den Gästen, die die App heruntergeladen haben und damit vertraut sind, ein Menü vorzuschlagen. Es ist auch möglich, vor dem Besuch eine SMS mit einem Link aus der App zu versenden und die Gäste aufzufordern, sich bereits durch die Auswahl der Gerichte auf den Abend einzustimmen.☺ Die App kann auch am Tisch per QR-Code heruntergeladen werden. Eine schnelle, moderne und phantasievolle Möglichkeit, für einige Gäste. Es lohnt sich, sie anzubieten.",
  "covid.restaurant.10.strong": "DIE KÜCHE ANPASSEN:",
  "covid.restaurant.10.text":
    "Das Menü sollte relativ kurz sein und hauptsächlich auf saisonalen, lokalen oder einfach verfügbaren Produkten basieren. Andere Optionen sind ein Risiko, es sei denn, Sie richten sich auf ein zusätzliches Menü ein, das leicht austauschbar ist. Wenn es Probleme und neue Einschränkungen gibt, wie Fernreisen oder geschlossene Grenzen, werden Sie keine Probleme mit der Versorgung und der Beschaffung von Produkten für den Küchenchef haben. Es wird viel einfacher sein, die Küche von Tag zu Tag umzustellen. Auch die Kosten sind nicht unbedeutend.",
  "covid.restaurant.11.strong": "VERANTWORTUNGSVOLLE LIEFERANTEN HERUNTERLADEN:",
  "covid.restaurant.11.text":
    "Suchen Sie nach Partnern in der Wirtschaft. Unternehmen, die helfen, verstehen und neue Lösungen anbieten. Suchen Sie nach Anbietern hochwertiger Produkte in der Nähe Ihres Unternehmens - sich auf Lieferungen über weite Entfernungen und schwer zu findende Produkte zu verlassen, ist heutzutage zu riskant. Unter www.fortalks.pl können Sie herausfinden, was Unternehmen für ihre Restaurants/Partner tun.",
  "covid.restaurant.header": "HANDBUCH FÜR EIN VERANTWORTUNGSVOLLES RESTAURANT",
  "covid.restaurant.title": "HANDBUCH FÜR EIN VERANTWORTUNGSVOLLES RESTAURANT",
  "covid.restaurant.text1":
    "Wir alle wissen, dass die Restaurants in Polen ums Überleben kämpfen und daher meist hohe Standards in Bezug auf Arbeit, Hygiene und Kommunikation mit den Gästen haben. Lassen Sie uns einen Schritt weiter gehen. Die Plattform MojStolik.pl und das Team von FOR Solutions haben ein System und ein Paket mit vorgefertigten Lösungen für den Fall neuer Beschränkungen und einer möglichen zweiten Welle der Pandemie entwickelt. Das Ziel des Leitfadens ist es, den Restaurants zu helfen, im Geschäft zu überleben und auch die Gefahr einer Coronavirus-Infektion so weit wie möglich zu reduzieren.",
  "covid.restaurant.text2":
    "Die erste Welle des Coronavirus und die darauf folgende Sperrung waren ein echter Schock für den Catering-Markt. Es hat einige Zeit gedauert, bis sich alle davon erholt hatten und sich auf die neue Realität einstellen konnten. Inzwischen haben wir uns an die Situation gewöhnt und sind reicher an Wissen und Erfahrung im Umgang mit Krisensituationen. Dennoch müssen die Restaurants in ihrem eigenen Interesse und im Interesse ihrer Gäste alles tun, um die Ausbreitung des Virus zu verhindern. Wir haben eine Bestandsaufnahme gemacht, wie Sie Ihr Restaurant auf die wachsende Zahl von Infektionen vorbereiten können:",
  "covid.restaurant.welcome1": "Drogi Restauratorze",
  "covid.restaurant.welcome2":
    "Hier sind 11 Lösungen, die Ihnen helfen, Ihr Unternehmen in diesen schwierigen Zeiten am Laufen zu halten!",
  "covid.restaurant.summary":
    "Wir hoffen, dass diese Sammlung von Ratschlägen Ihnen dabei hilft, Ihr Restaurant an die neue Realität anzupassen und sich auf eventuelle Schwierigkeiten vorzubereiten. Sollten Sie weitere Fragen haben, stehen wir Ihnen gerne zur Verfügung. Wenn Sie weitere Informationen über die Zusammenarbeit mit uns wünschen, füllen Sie bitte das Formular aus. Wir werden Sie zurückrufen oder Ihnen schreiben.",
  "covid.restaurant.greetings": "Wir wollen helfen, deshalb tun wir uns zusammen!",
  "covid.restaurant.team": "Das Team von MojStolik.de und FOR Solutions.",
  "covid.client.page.title": "LEITFADEN FÜR VERANTWORTUNGSBEWUSSTE BESUCHER",
  "covid.client.dear.guest": "Lieber Gast",
  "covid.client.description":
    " Kein Restaurant kann ohne Sie existieren. Es ist alles für Sie. Wenn Sie das Gastro-Gewerbe nicht unterstützen, wird es von den Straßen unserer Städte verschwinden. Und geben Sie es zu, eine Welt ohne Bars und Restaurants wäre furchtbar traurig und leer, denn Zusammenkünfte am Tisch vereinen, unterhalten und entwickeln unser soziales Leben.",
  "covid.client.description2":
    "Deshalb unser Appell: Unterstützen Sie Ihre Lieblingsrestaurants, besuchen Sie sie, aber tun Sie es mit dem Kopf. Im Zeitalter des Coronavirus ist unsere kollektive soziale Verantwortung unglaublich wichtig. Achten Sie auch auf einige Ihrer Verhaltensweisen, die den Restaurants echten Schaden zufügen. Wir geben Beispiele dafür, um Restaurants zu helfen, effizienter zu arbeiten. Und schließlich sollten Sie alle Sicherheitsvorschriften kennen, damit Sie dazu beitragen können, die hohen Arbeits-, Hygiene- und Organisationsstandards der Restaurants aufrechtzuerhalten. Sie kümmern sich darum! Kümmern Sie sich auch um SIE!",
  "covid.client.description3":
    "Wie können Sie also Ihre Zeit in Ihren Lieblingsrestaurants verantwortungsvoll genießen? MojStolik.co.uk und FOR Solutions haben einige Tipps für Sie vorbereitet:",
  "covid.client.summary":
    "Wenn Sie diese einfachen Regeln befolgen, sorgen Sie für Ihre eigene Sicherheit und die des Personals Ihres Lieblingsrestaurants. Indem Sie dort sind und die köstlichen Gerichte genießen, unterstützen Sie sie und helfen ihnen, eine schwierige Zeit zu überstehen. Das Bild unserer Städte ohne Restaurants wäre tragisch....",
  "covid.client.1.strong": "GRUNDSÄTZE DER HYGIENE -",
  "covid.client.1.text":
    "d.h. beim Betreten des Lokals die Maske aufsetzen und abnehmen, erst wenn Sie sich an einen Tisch setzen, vor dem Betreten die Hände desinfizieren (Restaurants müssen am Eingang einen Flüssigkeitsspender haben). Wenn Sie auf die Toilette gehen, bedecken Sie ebenfalls Mund und Nase mit einer Maske. Diese Punkte sind wirklich wichtig, um das Risiko einer Ansteckung mit COVID-19 zu verringern.",
  "covid.client.2.strong": "SOZIALE DISTANZ -",
  "covid.client.2.text":
    "Wir wissen, dass es schwierig ist, den richtigen Abstand zu halten, und wir vergessen uns oft selbst, aber einfache Regeln genügen: Kommen Sie dem Team nicht in die Quere und verändern Sie die Position der Tische nicht ohne die Erlaubnis des Kellners oder des Managers. Sie sind zu Ihrer Sicherheit aufgestellt worden.",
  "covid.client.3.strong": "WAHL DES TISCHES -",
  "covid.client.3.text":
    "Wenn Sie ein Restaurant betreten (mit einer Gesichtsmaske!), warten Sie darauf, dass der Kellner Sie zum richtigen Tisch führt. Das erleichtert ihm die Arbeit und hilft Ihnen, Ihre Gäste sicher zu platzieren. Wenn Sie sich selbst einen Platz aussuchen können, setzen Sie sich nur an Tische, die als desinfiziert gekennzeichnet sind.",
  "covid.client.4.strong": "RESERVIERUNG -",
  "covid.client.4.text":
    "Wenn Sie zum Beispiel wissen, dass Sie heute Abend in ein Restaurant gehen, reservieren Sie einen Tisch im Voraus. Das macht es dem Restaurantpersonal leichter, die Bestuhlung für Ihre Gäste, aber auch die Versorgung und die Arbeit des Personals zu planen. Außerdem verringert sich dadurch Ihre Kontaktzeit mit dem Personal so weit wie möglich, was sowohl Ihnen als auch dem Personal zugute kommt. Mit der App MojStolik.de können Sie einfach und schnell einen Tisch im Restaurant Ihrer Wahl reservieren. Warum auf einen Tisch warten, wenn er auf Sie warten kann?",
  "covid.client.5.strong": "STORNIEREN SIE IHRE BUCHUNG, WENN SIE NICHT KOMMEN KÖNNEN!",
  "covid.client.5.text":
    "In einem Restaurant trotz Reservierung nicht zu erscheinen, ist nicht nur unhöflich, um es gelinde auszudrücken, sondern bedeutet auch für den Gastronomen einen echten Verlust. Er kann Ihren Tisch niemandem zuweisen, denn er ist ja reserviert. Er hat Sie und Ihre Mitgäste bei der Planung der Verpflegung berücksichtigt. Eine solche Situation ist für ihn ein echter finanzieller Verlust, der jetzt, da die Auslastung des Restaurants ohnehin zurückgeht, besonders akut ist. Die Plattform MojStolik.pl macht es Ihnen auch leichter - sie erinnert Sie nicht nur per SMS daran, wann und wo der Koch auf Sie wartet, sondern macht es Ihnen auch so einfach wie möglich, Ihre Reservierung zu stornieren, falls nötig. Es ist nur einen Klick entfernt!",
  "covid.client.6.strong": "BERÜHRUNGSLOSES MENÜ -",
  "covid.client.6.text":
    "Wenn Sie eine Tischreservierungs-App auf Ihrem Smartphone haben, können Sie damit auch die Speisekarte einsehen, ohne das Restaurant in die Hand nehmen zu müssen. Wenn Sie die App nicht haben, laden Sie sie mit den speziellen QR-Codes herunter, die Sie an den Tischen zur Verfügung haben.",
  "covid.client.7.strong": "KONTAKTLOS BEZAHLEN -",
  "covid.client.7.text":
    "vermeiden Sie direkten Kontakt und Barzahlungen. Zahlen Sie per Kreditkarte, Blister oder Telefon.",
  "covid.client.8.strong": "KRITISIERE NICHT -",
  "covid.client.8.text":
    "dies ist immer noch eine sehr schwierige Zeit für eine Branche, die praktisch jeden Tag ums Überleben kämpft. Unterlassen Sie es also, sich in der Öffentlichkeit kritisch zu äußern, und konzentrieren Sie sich stattdessen auf die positiven Gefühle des Schlemmens. Gute Emotionen werden derzeit von uns allen dringend benötigt, insbesondere von Restaurants, die doppelt so hart ums Überleben kämpfen.",
  "covid.client.9.strong": "UNTERSTÜTZUNG -.",
  "covid.client.9.text":
    "Wenn Sie mit Ihrem Besuch zufrieden waren, sollten Sie auf jeden Fall in Ihren sozialen Medien darüber berichten, INSTA STORIES erstellen und sich einen Spaß daraus machen, die Gerichte zu loben und andere Gäste in Ihr Lieblingsrestaurant zu locken!",
  "forRestaurantsPage.global.perMonth": "PLN/Monat",
  "forRestaurantsPage.global.perMonth.short": "zł/Monat",
  "forRestaurantsPage.global.button.choose": "CHOOSE",
  "forRestaurantsPage.global.button.next": "NÄCHSTES",
  "forRestaurantsPage.global.button.back": "RETURN",
  "forRestaurantsPage.global.button.accept": "ACCEPT",
  "forRestaurantsPage.global.button.discover": "SIEHE VERFÜGBARE PLÄNE",
  "forRestaurantsPage.global.button.send": "SENDEN",
  "forRestaurantsPage.global.button.learnMore": "Mehr lesen",
  "forRestaurantsPage.apiError": "Technische Probleme. Bitte versuchen Sie es später noch einmal.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.CO.UK IST MEHR ALS EIN SYSTEM",
  "forRestaurantsPage.1.subsection.1.header": "PORTAL UND BESUCHERANMELDUNG",
  "forRestaurantsPage.1.subsection.1.text":
    "Eine einfache und bequeme Plattform und App, mit der Sie sofort einen Tisch in Ihrem Restaurant reservieren können.",
  "forRestaurantsPage.1.subsection.2.header": "TELEFONANLAGE (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "Das Telefonsystem (IVR) nimmt Buchungen entgegen, anstatt dass sich Ihr Team der Bedienung Ihrer Gäste im Restaurant widmen kann.",
  "forRestaurantsPage.1.subsection.3.header": "PORTAL UND ANWENDUNG FÜR RESTAURANTS",
  "forRestaurantsPage.1.subsection.3.text":
    "Verwalten Sie Buchungen und Tische automatisch oder manuell über die Tablet-App oder das Portal des Restaurants.",
  "forRestaurantsPage.2.header": "ÜBER DIE APP ODER DEN BROWSER VERWALTEN",
  "forRestaurantsPage.3.header": "VERGESSEN SIE DAS NICHTERSCHEINEN",
  "forRestaurantsPage.3.listItem.1": "SMS-Bestätigung und Buchungserinnerungen",
  "forRestaurantsPage.3.listItem.2": "Schnelle und einfache Kündigungsmöglichkeiten",
  "forRestaurantsPage.3.listItem.3": "Analyse der historischen Besucheraktivitäten",
  "forRestaurantsPage.4.header": "DEN VERKEHR EFFEKTIV ZUM RESTAURANT LEITEN",
  "forRestaurantsPage.5.header": "WAS SAGEN DIE GASTRONOMEN ÜBER UNS?",
  "forRestaurantsPage.5.opinion.1.title":
    'Mariusz Diakowski, Inhaber des Restaurants "Altes Haus":',
  "forRestaurantsPage.5.opinion.1.text":
    'Die Zusammenarbeit mit MojStolik.de war für Stary Dom eine echte Revolution. Weder ich noch das Restaurantteam können sich vorstellen, in die Ära von "Buch und Bleistift" zurückzukehren. Tatsächlich hat MojStolik.pl seine Plattform in unserem Restaurant getestet. Wir hatten einige Bedenken, aber wir haben uns entschlossen, das Risiko einzugehen. Wir bereuen es absolut nicht! Alle unsere Kommentare und Vorschläge wurden sofort umgesetzt. Dank der Tests mit dem "lebenden Organismus" entspricht dieses System wirklich den Bedürfnissen von Restaurants mit hoher Auslastung, macht die Arbeit unglaublich einfach und verkürzt die Zeit. Keine Kugelschreiber, Bleistifte, Korrekturleser, die Seiten zusammenkleben, unleserliche Handschrift oder unpraktische Bücher mehr. Jetzt ist alles organisiert und einfach zu handhaben. Ich möchte noch hinzufügen, dass die Plattform praktisch fehlerfrei ist und der Helpdesk bei Bedarf einwandfrei funktioniert.',
  "forRestaurantsPage.5.opinion.2.title": "Marek Katrycz, Betriebsleiter bei STIXX Bar&Grill:",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl ist ein sehr modernes und innovatives System, dank dem wir als großes Restaurant die Reservierungen unserer Gäste und die Sitzordnung effektiv verwalten können, was die Arbeit unseres Teams erheblich vereinfacht. Wir empfehlen dieses System auf jeden Fall, wir verbessern ständig seine Funktionalität, es ist intuitiv und sehr einfach zu bedienen, es hat die Zeit, die wir brauchen, um Reservierungen anzunehmen und zu finden, reduziert, was besonders bei hoher Auslastung hilfreich ist.",
  "forRestaurantsPage.5.opinion.3.title": "Robert Goluba, Brand Manager Bombaj Masala:",
  "forRestaurantsPage.5.opinion.3.text":
    "Warum haben wir uns entschieden, das System von MojStolik.de in unseren Restaurants einzusetzen? Gutes Feedback in der Branche und ein wirklich netter und professioneller Umgang mit einem neuen Kunden. Das System ist sehr intuitiv, aber es hat so lange gedauert, das Personal zu schulen, bis jeder einzelne Angestellte im Restaurant wusste, wie man das Panel benutzt, also gab es keine Probleme bei der Implementierung von My Table im Restaurant. Der erste Tag mit vielen Gästen und Buchungen in unserem Restaurant reichte aus, um uns von den Vorteilen der Lösungen im MojStolik.pl-System zu überzeugen. Ich bin mir sicher, dass es uns ermöglicht hat (und weiterhin ermöglicht), die Gäste reibungsloser zu empfangen und folglich mehr Gäste zu bedienen. Das wirkt sich natürlich auch auf den Gewinn aus. Hätte mir jemand nach ein paar Wochen befohlen, wieder mit einem traditionellen Buchungsbuch zu arbeiten, hätte ich mich gefühlt wie... im 20.",
  "forRestaurantsPage.6.header": "LERNEN SIE DIE PLÄNE VON MOJSTOLIK.PL KENNEN",
  "forRestaurantsPage.6.subheader":
    "Wählen Sie einen Plan für Ihr Restaurant und beginnen Sie mit uns zu arbeiten.",
  "forRestaurantsPage.discountCodeInput": "Geben Sie den Aktionscode ein",
  "forRestaurantsPage.discountCodeButton": "Verwenden Sie",
  "forRestaurantsPage.discountCodeLoading": "Der Ladevorgang läuft...",
  "forRestaurantsPage.discountCodeSuccess": "Promo-Code geladen",
  "error.promotion.not.exists": "Der angegebene Aktionscode existiert nicht",
  "forRestaurantsPage.allOffers": "Für einen detaillierten Vergleich der MOJSTOLIK.PL-Pläne",
  "forRestaurantsPage.allOffers.orange": "Hier gehen",
  "forRestaurantsPage.offers.offerItem.1.header": "LICHT",
  "forRestaurantsPage.offers.offerItem.1.text":
    "Für Restaurants, die die Einfachheit der Automatisierung von Buchungsprozessen in Echtzeit schätzen.",
  "forRestaurantsPage.offers.offerItem.2.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.2.text":
    "Für Restaurants, die neben den Buchungen auch Räume und Tische verwalten möchten.",
  "forRestaurantsPage.offers.offerItem.3.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "Für große Restaurants, in denen eine vollständige Automatisierung aller Kommunikationskanäle mit den Gästen unerlässlich ist.",
  "forRestaurantsPage.offers.subscription": "Abonnement",
  "forRestaurantsPage.offers.commission": "Kommission",
  "forRestaurantsPage.offers.commission.currency": "zł",
  "forRestaurantsPage.offers.header": "Zusätzliche Optionen",
  "forRestaurantsPage.offers.additional-options.prepayments": "Vorauszahlungsmodul für Buchungen",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Automatischer telefonischer Reservierungsdienst (IVR) mit zusätzlich 300 SMS/Monat",
  "forRestaurantsPage.offers.additional-options.smsPackage":
    "Zusätzliches SMS-Paket - 1000 Stück/Monat",
  "forRestaurantsPage.offers.mostPopular": "Die häufigste Wahl",
  "forRestaurantsPage.offers.pricingSetting1":
    "Die oben genannten Preise gelten für einen jährlichen Abrechnungszeitraum. Wechseln Sie zur monatlichen Abrechnung:",
  "forRestaurantsPage.offers.pricingSetting2":
    "Die oben genannten Preise gelten für einen monatlichen Abrechnungszeitraum. Wechseln Sie zur jährlichen Abrechnung:",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1 Alle im Angebot genannten Preise sind Nettopreise in PLN, zu denen die gesetzliche Mehrwertsteuer in der am Tag der Rechnungsstellung geltenden Höhe hinzukommt.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2 Im Falle des jährlichen Zahlungsmodells wird die monatliche Gebühr dargestellt, während die Zahlung einmalig für 12 Monate im Voraus erfolgt.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3 Der Marketing-Rabatt wird in Höhe von 50 PLN abzüglich der monatlichen Gebühr gewährt, sofern das Restaurant den Buchungslink über MOJSTOLIK.PL mindestens 2 Mal pro Monat in den sozialen Medien des Restaurants teilt.",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4 Die Kosten für eine SMS außerhalb des Abonnements betragen 0,09 PLN netto.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5 Bei Verwendung des Vorauszahlungsmoduls wird vom Zahlungsdienstleister für jede Transaktion eine Provision von 1,59 % des Transaktionsbetrags erhoben.",
  "forRestaurantsPageAllOffers.header": "Erkunden Sie die Pläne von MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Wählen Sie einen Plan für Ihr Restaurant und beginnen Sie mit uns zu arbeiten.",
  "forRestaurantsPageAllOffers.offer.1": "LICHT",
  "forRestaurantsPageAllOffers.offer.2": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Dem Kunden zur Verfügung stehende Buchungskanäle",
  "forRestaurantsPageAllOffers.listHeader.2": "Reservierungsmanagement für Restaurants",
  "forRestaurantsPageAllOffers.listHeader.3": "Konfiguration des Restaurantprofils",
  "forRestaurantsPageAllOffers.listHeader.4":
    "Zugänglichkeit für Restaurants von den Einrichtungen aus",
  "forRestaurantsPageAllOffers.listHeader.5": "Zusätzliche Dienstleistungen",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1": "Reservierungen von www.mojstolik.pl",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Reservierungen über die spezielle mobile App MojStolik",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3":
    "Reservierungen über die Website des Restaurants",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Reservierungen mit Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Buchungen über Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Buchungsbestätigungen/Erinnerungen per SMS/Anwendungsbenachrichtigung**",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
    "Automatische Bearbeitung von Telefonbuchungen",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
    "Reservierungen auf der Instagram-Seite des Restaurants",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Buchungen aus einem E-Mail-Postfach (Autoresponder)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Links / QR-Codes in Artikeln und PR-Materialien von Restaurants",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Buchungsliste - tabellarische Ansicht",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Reservierungen auf dem Hallenplan",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Gantt-Ansicht (Buchungsgrafik)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4": "Buchungsdetails und Bearbeitung",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Erweiterte Buchungsverwaltung",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Täglicher Buchungsbericht",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7":
    "Abwicklung von Vorauszahlungen für Buchungen",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Begehbarer Dienst",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Buchungshistorie / Analyse von Gästepräferenzen und -verhalten",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": "Minimierung des No-Show-Effekts",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Live-Betrieb",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "Restaurant-Kundendatenbank / RODO-Konformität",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "DATENSICHERHEIT / Verschlüsseltes System und verschiedene Zugriffsebenen auf der Grundlage von Rollen",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Visitenkarte und individuelles Restaurantprofil auf dem Kundenportal und der mobilen App",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Deskriptive Restaurantdaten",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Grafische Materialien",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Verwaltung der Verfügbarkeitszeiten",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Pausen und besondere Tage",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6": "Parametrisierung der Buchungsannahme",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Konfiguration des Raums und der Tische",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Verwaltungsportal für Restaurants",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Restaurant-App für das Tablet",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1": "Helpdesk und technische Unterstützung",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
    "Einführungsschulung für das Restaurantpersonal",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Präsenz/Promotion der Restaurants auf den Social Media Kanälen MojStolik.pl",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Anzahl der im Abonnement verfügbaren SMS-Nachrichten",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/Monat",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/Monat",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/Monat",
  "forRestaurantsPageAllOffers.listItem.optional": "Option",
  "forRestaurantsPageForm.downloadButton": "DIE DATEN HERUNTERLADEN",
  "forRestaurantsPageForm.header": "Hier beginnen Sie Ihre Arbeit mit mojstolik.de",
  "forRestaurantPageForm.apiError":
    "Fehler beim Herunterladen der Gebotsdaten. Bitte versuchen Sie es später noch einmal und wir entschuldigen uns für die Unannehmlichkeiten",
  "forRestaurantPageForm.paramsError":
    "Leider konnten die Details des Angebots nicht angezeigt werden. Um unsere Angebote zu sehen, gehen Sie zu",
  "forRestaurantPageForm.paramsError.link": "Angebotsseiten",
  "forRestaurantsPageForm.chosenPlan": "Ausgewählter Plan MOJSTOLIK.PL",
  "forRestaurantsPageForm.discountCode": "Förderung:",
  "forRestaurantsPageForm.payment.yearly": "Der gewählte Plan wird auf Jahresbasis abgerechnet.",
  "forRestaurantsPageForm.payment.monthly": "Der gewählte Tarif wird monatlich abgerechnet.",
  "forRestaurantsPageForm.additionalOptions": "Ausgewählte zusätzliche Optionen:",
  "forRestaurantsPageForm.formHeader.1":
    "Geben Sie grundlegende Informationen über das Restaurant an",
  "forRestaurantsPageForm.formHeader.2": "Angaben zur Kontaktperson eingeben",
  "forRestaurantsPageForm.formHeader.3": "Geben Sie Einzelheiten zum Abkommen an",
  "forRestaurantsPageForm.formHeader.4": "Angabe der Person, die die Einrichtung vertritt",
  "forRestaurantsPageForm.formHeader.5": "MOJSTOLIK.PL Vereinbarung",
  "forRestaurantsPageForm.restaurantName": "Name des Restaurants",
  "forRestaurantsPageForm.street": "Straße",
  "forRestaurantsPageForm.apartment": "Lokales",
  "forRestaurantsPageForm.postalCode": "Postleitzahl",
  "forRestaurantsPageForm.city": "Stadt",
  "forRestaurantsPageForm.email": "E-Mail Adresse",
  "forRestaurantsPageForm.phoneNumber": "Rufnummer",
  "forRestaurantsPageForm.website": "Adresse der Website",
  "forRestaurantsPageForm.website.menu": "Website-Adresse mit Speisekarte",
  "forRestaurantsPageForm.name": "Name",
  "forRestaurantsPageForm.surname": "Name",
  "forRestaurantsPageForm.title": "Titel",
  "forRestaurantsPageForm.entity": "Name der Einrichtung",
  "forRestaurantsPageForm.email.invoice": "E-Mail Adresse für Rechnung",
  "forRestaurantsPageForm.email.contact": "Kontakt-E-Mail-Adresse",
  "forRestaurantsPageForm.proxy": "Art der Bevollmächtigung",
  "forRestaurantsPageForm.select.owner": "Eigentümer",
  "forRestaurantsPageForm.select.manager": "Manager",
  "forRestaurantsPageForm.select.boardMember": "Mitglied des Verwaltungsrats",
  "forRestaurantsPageForm.select.other": "Andere",
  "forRestaurantsPageForm.contract": "Inhalt der Vereinbarung",
  "forRestaurantsPageForm.pdfLink": "pdf herunterladen",
  "forRestaurantsPageForm.statement.1":
    "Ich stimme der Verarbeitung meiner persönlichen Daten durch Table4Rest sp. z o.o. zum Zwecke des Abschlusses eines Vertrages über die Erbringung von Dienstleistungen für die Bereitstellung und Nutzung der Anwendung MojStolik.pl zu.",
  "forRestaurantsPageForm.statement.2":
    "Ich akzeptiere die Bedingungen des oben genannten Vertrages und die Regeln und Vorschriften von MOJSTOLIK.PL und erkläre, dass ich berechtigt bin, einen Vertrag über die Erbringung von Dienstleistungen für die Bereitstellung und Nutzung der Anwendung MojStolik.pl im Namen des Restaurants abzuschließen.",
  "forRestaurantsPageForm.loading": "Ihr Formular wird gesendet...",
  "forRestaurantsPageForm.success": "Formularübermittlung erfolgreich",
  "forRestaurantsPageForm.nipError": "Eingabe der korrekten MwSt.-Nummer",
  "forRestaurantsPageForm.loadingData": "Daten-Download läuft...",
  "forRestaurantsPageForm.error": "Es ist ein Fehler aufgetreten.",
  "forRestaurantsPageForm.dataSuccess": "Daten korrekt heruntergeladen",
  "forRestaurantsPageForm.generatingContract": "Vertragserstellung im Gange...",
  "forRestaurantsPageSuccess.header": "Danke, dass Sie sich für Mojstolik.pl entschieden haben",
  "forRestaurantsPageSuccess.text.1":
    "IN KÜRZE WIRD EIN VERTRAGSDOKUMENT AN DIE E-MAIL-ADRESSE GESCHICKT, DIE SIE IM VORHERIGEN SCHRITT AKZEPTIERT HABEN.",
  "forRestaurantsPageSuccess.text.2":
    "Wir werden auch die notwendigen Anweisungen vorbereiten und Ihnen zur Verfügung stellen, damit Sie Ihr Restaurant im System konfigurieren und mit der Zusammenarbeit beginnen können.",
  "forRestaurantsPageSuccess.text.3": "Wir sehen uns dort!",
  "forRestaurantsPageSuccess.button": "Zurück zur Titelseite",
  "global.messages.sessionTimeout": "Sitzung abgelaufen",
  "global.form.message.requiredField": "Dieses Feld ist erforderlich",
  "global.form.message.vatIDNumber": "Die TIN sollte 10 Ziffern enthalten",
  "global.form.message.invalidEmailFormat": "Falsches E-Mail-Adressformat",
  "global.form.message.invalidPhoneFormat": "Falsches Telefonnummernformat",
  "global.form.message.invalidBirthDateFormat": "Falsches Format des Geburtsdatums",
  "global.form.message.invalidPostCodeFormat": "Falsches Postleitzahlenformat. Erwartet XX-XXX",
  "global.form.message.invalidNipFormat": "Falsches Format der Mehrwertsteuernummer",
  "global.form.message.invalidKrsFormat": "Falsches Format der KRS-Nummer",
  "global.form.message.invalidRegonFormat": "Falsches Format der REGON-Nummer",
  "global.form.message.invalidUrlFormat": "Falsches Format der Webadresse",
  "global.form.message.newPasswordsDoNotMatch": "Die neuen Slogans sind anders!",
  "global.form.message.atLeastNineCharacters": "Mindestens neun Zeichen erforderlich",
  "global.time.minutes": "minutiös",
  "aboutPage.metadata.title": "O in",
  "aboutPage.metadata.description": "Die Optionen",
  "aboutPage.metadata.keywords": "Über uns Schlüsselwörter",
  "accountPage.metadata.title": "Ihr Konto",
  "accountPage.metadata.description": "Beschreibung Ihres Kontos",
  "accountPage.metadata.keywords": "Schlüsselwörter für Ihr Konto",
  "contactPage.metadata.title": "Kontakt",
  "contactPage.metadata.description": "Kontakt zu opis",
  "contactPage.metadata.keywords": "Kontakt Schlüsselwörter",
  "documentsPage.metadata.title": "Dokumenty",
  "documentsPage.metadata.description": "Beschreibung der Dokumente",
  "documentsPage.metadata.keywords": "Schlüsselwörter Dokumente",
  "forGuestPage.metadata.title": "Für den Gast",
  "forGuestPage.metadata.description": "Für den Besucher eine Beschreibung von",
  "forGuestPage.metadata.keywords": "Für den Besucher, Schlüsselwörter",
  "howItWorksPage.metadata.title": "Wie funktioniert das?",
  "howItWorksPage.metadata.description": "Beschreibung der Funktionsweise",
  "howItWorksPage.metadata.keywords": "Wie Schlüsselwörter funktionieren",
  "loginPage.metadata.title": "Protokollierung",
  "loginPage.metadata.description": "Beschreibung der Protokollierung",
  "loginPage.metadata.keywords": "Schlüsselwörter für die Protokollierung",
  "resetPasswordPage.metadata.title": "Passwort zurücksetzen",
  "resetPasswordPage.metadata.description": "Passwort zurücksetzen Beschreibung",
  "resetPasswordPage.metadata.keywords": "Kennwort zurücksetzen",
  "logoutPage.metadata.title": "Abmeldung",
  "logoutPage.metadata.description": "Beschreibung der Abmeldung",
  "logoutPage.metadata.keywords": "Abmeldeschlüsselwörter",
  "notFoundPage.metadata.title": "404 - Seite nicht gefunden!",
  "notFoundPage.metadata.description": "404 - Seite nicht gefunden Beschreibung",
  "priceListPage.metadata.title": "Die Preisliste",
  "priceListPage.metadata.description": "Beschreibung der Preisliste",
  "priceListPage.metadata.keywords": "Schlüsselwort Preisliste",
  "registrationPage.metadata.title": "Anmeldung",
  "registrationPage.metadata.description": "Beschreibung der Registrierung",
  "registrationPage.metadata.keywords": "Schlüsselwort Registrierung",
  "reservationHistoryPage.metadata.title": "Ihre Buchungen",
  "reservationHistoryPage.metadata.description": "Beschreibung Ihrer Buchungen",
  "reservationHistoryPage.metadata.keywords": "Ihre Buchungsschlüsselwörter",
  "searchMapPage.metadata.title": "Suchergebnisse",
  "searchMapPage.metadata.description": "Beschreibung der Suchergebnisse",
  "searchMapPage.metadata.keywords": "Suchergebnisse für Schlüsselwörter",
  "searchPage.metadata.title": "Suchergebnisse",
  "searchPage.metadata.description": "Beschreibung der Suchergebnisse",
  "searchPage.metadata.keywords": "Suchergebnisse für Schlüsselwörter",
  "privacyPolicyPage.metadata.title": "Datenschutzbestimmungen",
  "cookiesPolicyPage.metadata.title": "Cookies-Politik",
  "shortcut.isClosed": "geschlossen",
  "shortcut.monday": "Mo.",
  "shortcut.tuesday": "Di.",
  "shortcut.wednesday": "Mi.",
  "shortcut.thursday": "Do.",
  "shortcut.friday": "Fr.",
  "shortcut.saturday": "Sa.",
  "shortcut.sunday": "So.",
  "defaultValues.restaurant": "Restaurant",
  "restaurantPromotionInfoModal.button.use": "Verwenden Sie",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Details zum Angebot",
  "previewImageModal.component.anchor.close": "Schließen Sie",
  "previewRestaurantModal.component.anchor.close": "Schließen Sie",
  "deleteAccountModal.component.header": "Konto löschen",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Ihr Buchungsverlauf wird ebenfalls gelöscht.",
  "deleteAccountModal.component.button.confirm": "Genehmigen Sie",
  "deleteAccountModal.component.button.cancel": "Ankündigung",
  "cancellationReservationModal.component.header": "Stornierung der Reservierung",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Sind Sie sicher, dass Sie Ihre Tischreservierung im Restaurant {{restaurantName}} um {{reservationHour}} am {{reservationDate}} stornieren möchten?",
  "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
    "Die mit der Buchung verbundene Gebühr wurde an das Restaurant überwiesen. Bitte kontaktieren Sie das Restaurant direkt für eine mögliche Rückerstattung.",
  "cancellationReservationModal.component.button.confirm": "Genehmigen Sie",
  "cancellationReservationModal.component.button.cancel": "Ankündigung",
  "searchResultItem.component.label.availableHours": "Verfügbare Stunden",
  "searchResultItem.component.label.futureReservationDaysError":
    "Fernbuchungen sind für {{days}} Tage im Voraus möglich",
  "searchResultItem.component.label.noAvailableTables":
    "Für die gewählten Suchparameter (Datum, Uhrzeit, Anzahl der Personen) bietet dieses Restaurant keine Tische zur Reservierung an.",
  "searchResultItem.component.button.book": "Buchen Sie",
  "searchResultItem.component.button.cancel": "Ankündigung",
  "searchResultItem.component.button.see": "Siehe",
  "passwordFormModal.component.header": "Passwort für Ihr Konto eingeben",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Geben Sie Ihr Passwort ein, um die Aktion zu bestätigen.",
  "passwordFormModal.component.button.confirm": "Genehmigen Sie",
  "passwordFormModal.component.button.cancel": "Ankündigung",
  "cuisine.romania": "rumänische Küche",
  "cuisine.american": "amerikanische Küche",
  "cuisine.arabic": "arabische Küche",
  "cuisine.chinese": "Chinesische Küche",
  "cuisine.polish": "Polnische Küche",
  "cusine.chinese": "Chinesische Küche",
  "cusine.polish": "Polnische Küche",
  "component.cookiesPolicyLine.text":
    "Diese Website verwendet Cookies, um Ihnen den bestmöglichen Service zu bieten. Durch die weitere Nutzung der Website erklären Sie sich mit der Verwendung von Cookies einverstanden.",
  "component.cookiesPolicyLine.privacyPolicyText": "Datenschutzbestimmungen",
  "component.header.menu.mainPage": "Buchen Sie",
  "component.header.menu.forRestaurants": "Für Restaurants",
  "component.header.menu.portal.restaurant": "Portal restauracji",
  "component.header.menu.forRestaurants.offer": "Unser Angebot",
  "component.header.menu.blog": "Nachrichten MOJSTOLIK.PL",
  "component.header.menu.christmas": "URLAUB ANGEBOTE",
  "component.header.menu.forGuest": "Für den Gast",
  "component.header.menu.howItWorks": "Wie funktioniert das?",
  "component.header.menu.priceList": "Die Preisliste",
  "component.header.menu.contact": "Kontakt",
  "component.header.menu.documents": "Dokumenty",
  "component.header.menu.about": "O in",
  "component.header.menu.login": "Einloggen",
  "component.header.menu.logout": "Abmelden",
  "component.header.menu.reservationHistory": "Ihre Buchungen",
  "component.header.menu.yourAccount": "Ihr Konto",
  "component.header.menu.covid": "COVID-19 Handbücher",
  "component.header.menu.covid.restaurant": "Restaurantführer",
  "component.header.menu.covid.client": "Leitfaden für Besucher",
  "component.header.menuMobile.covid.restaurant": "COVID-19-Leitfaden für Restaurants",
  "component.header.menuMobile.covid.client": "COVID-19-Führer für Besucher",
  "component.header.menu.events": "Veranstaltungen",
  "component.header.menu.event": "Veranstaltung -",
  "component.footer.menu.header.forClients": "Über MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Informationen",
  "component.footer.menu.header.yourAccount": "Ihr Konto",
  "component.footer.menu.header.socialMedia": "Soziale Medien",
  "component.footer.menu.header.applicationDownload": "Download der Bewerbung",
  "component.footer.menu.forRestaurants": "Für Restaurants",
  "component.footer.menu.forGuest": "Für den Gast",
  "component.footer.menu.howItWorks": "Wie funktioniert das?",
  "component.footer.menu.priceList": "Die Preisliste",
  "component.footer.menu.contact": "Kontakt",
  "component.footer.menu.documents": "Dokumenty",
  "component.footer.menu.about": "O in",
  "component.footer.menu.login": "Einloggen",
  "component.footer.menu.reservationHistory": "Ihre Buchungen",
  "component.footer.menu.yourAccount": "Ihr Konto",
  "component.footer.menu.logout": "Abmelden",
  "component.footer.menu.registration": "Anmeldung",
  "component.footer.menu.privacyPolicy": "Datenschutzbestimmungen",
  "component.footer.menu.cookiesPolicy": "Cookie-Politik",
  "indexPage.background.header": "Finden Sie die besten Restaurants in Ihrer Stadt,",
  "indexPage.header.currentPromotions": "Sonderangebote und Veranstaltungen",
  "indexPage.header.currentPromotionsExtend": "an Ihrem Standort",
  "indexPage.header.blogs": "Ausgewählte Artikel",
  "indexPage.header.mostPopular": "Am beliebtesten",
  "indexPage.header.mostPopularExtend": "an Ihrem Standort",
  "indexPage.background.headerExtend": "und dann einen Tisch reservieren:",
  "indexPage.input.label.location": "Standort",
  "indexPage.input.label.date": "Daten",
  "indexPage.input.label.hour": "Stunde",
  "indexPage.input.label.persons": "Anzahl der Personen",
  "indexPage.input.label.persons.optionExtend": "Pers.",
  "indexPage.input.label.restaurantType": "Art der Küche",
  "indexPage.input.label.rating": "Bewertung",
  "indexPage.input.label.rating.optionExtend": "Sterne.",
  "indexPage.input.label.priceLevel": "Preisniveau",
  "indexPage.input.label.searchByName": "Suche nach Namen",
  "indexPage.input.placeholder.select": "Wählen Sie",
  "indexPage.input.placeholder.type": "Eingabe",
  "indexPage.input.placeholer.typeInOrSelect": "Tippen oder wählen Sie",
  "indexPage.input.noData": "Keine Ergebnisse.",
  "indexPage.button.search": "Suche",
  "indexPage.button.showAdditionalFilters": "Zusätzliche Filter",
  "indexPage.button.hideAdditionalFilters": "Zusätzliche Filter ausblenden",
  "indexPage.button.use": "Verwenden Sie",
  "indexPage.button.promotion": "Förderung",
  "indexPage.button.bookNow": "Buchen Sie",
  "indexPage.button.readMore": "Lesen Sie",
  "accountPage.header": "Ihr Konto",
  "accountPage.subheader":
    "Ändern Sie Ihre persönlichen Daten, um den Erfüllungsprozess zu beschleunigen.",
  "accountPage.headerInColumn.yourPersonalData": "Ihre Daten",
  "accountPage.headerInColumn.yourPassword": "Ihr Passwort",
  "accountPage.input.label.firstName": "Name",
  "accountPage.input.placeholder.firstName": "Z.B. Jan",
  "accountPage.input.label.lastName": "Name",
  "accountPage.input.placeholder.lastName": "z.B. Kowalski",
  "accountPage.input.label.phoneNumber": "Rufnummer",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.label.email": "E-Mail Adresse",
  "accountPage.input.placeholder.email": "Z.B. adres@email.pl",
  "accountPage.paragraph.optionalFields": "Optionale Felder",
  "accountPage.input.label.birthDate": "Datum der Geburt",
  "accountPage.input.placeholder.birthDate": "z.B. 1976-10-25",
  "accountPage.input.label.postalCode": "Postleitzahl",
  "accountPage.input.placeholder.postalCode": "Z.B. 03-123",
  "accountPage.input.label.city": "Stadt",
  "accountPage.input.placeholder.city": "Z.B. Warschau",
  "accountPage.input.label.streetAndLocalNumber": "Straße und Wohnungsnummer",
  "accountPage.input.placeholder.streetAndLocalNumber": "z. B. Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Kommunikationsmethoden:",
  "accountPage.button.save": "Speichern Sie",
  "accountPage.button.deleteAccount": "Konto löschen",
  "accountPage.input.label.oldPassword": "Alter Slogan",
  "accountPage.input.label.newPassword": "Neues Passwort",
  "accountPage.input.label.newPasswordRepeat": "Neues Passwort (Wiederholung)",
  "accountPage.button.changePassword": "Passwort ändern",
  "accountPage.message.personalDataUpdated": "Die persönlichen Daten wurden aktualisiert.",
  "accountPage.message.accountPermanentlyDeleted": "Ihr Konto wurde endgültig gelöscht.",
  "accountPage.message.passwordChanged": "Das Passwort wurde geändert.",
  "accountPage.label.changePasswordNotPossible":
    "Es ist nicht möglich, Ihr Passwort zu ändern, weil Sie sich über Facebook oder Google angemeldet haben.",
  "accountPage.toast.error.disableBothChannels":
    "Mindestens eine Kommunikationsmethode (SMS, E-MAIL) muss aktiv bleiben!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Die Aktivierung der Kommunikationsmethode erfordert die Bestätigung der Datenkorrektheit. Speichere die Änderungen, um einen Bestätigungslink an die neue E-Mail-Adresse zu erhalten!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Die Aktivierung der Kommunikationsmethode erfordert die Bestätigung der Datenkorrektheit. Speichere die Änderungen, um einen Bestätigungs-SMS-Link an deine neue Telefonnummer zu erhalten!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Um die neue Kommunikationsmethode zu aktivieren, bestätige deine Daten, indem du auf den Aktivierungslink klickst, der an deine E-Mail-Adresse gesendet wurde!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Um die neue Kommunikationsmethode zu aktivieren, bestätige deine Daten, indem du auf den Aktivierungslink klickst, der per SMS an die angegebene Telefonnummer gesendet wurde!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Um deine Daten zu überprüfen, klicke auf den Bestätigungslink, der an deine E-Mail-Adresse gesendet wurde!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Um deine Daten zu überprüfen, klicke auf den Bestätigungslink, der per SMS an deine Telefonnummer gesendet wurde!",
  "accountPage.channels.disclaimer":
    "* Um die Telefonnummer oder E-Mail-Adresse zu bearbeiten, müssen Sie das zu bearbeitende Kommunikationsmodul deaktivieren.",
  "accountPage.confirmSms.header": "Bestätigung der Datenauthentizität",
  "accountPage.confirmSms.paragraph":
    "Um Ihre Daten zu bestätigen, geben Sie den per SMS an die angegebene Telefonnummer gesendeten Code ein!",
  "reservationHistoryPage.sendMessage": "Eine Nachricht senden",
  "reservationHistoryPage.send": "Senden Sie",
  "reservationHistoryPage.cancel": "Ankündigung",
  "reservationChatPage.send": "Senden Sie",
  "reservationChatPage.writeMessage": "Geben Sie Ihre Nachricht ein",
  "reservationHistoryPage.header": "Ihre Buchungen",
  "reservationHistoryPage.subheader":
    "Hier können Sie Ihre historischen Buchungen einsehen und zukünftige Buchungen verwalten.",
  "reservationHistoryPage.paragraph.personsShortcut": "Pers.",
  "reservationHistoryPage.button.revoke": "Abbrechen",
  "reservationHistoryPage.button.pay": "Bezahlen",
  "reservationHistoryPage.paragraph.additionalDescription": "Kommunikation mit dem Restaurant",
  "reservationHistoryPage.paragraph.date": "Daten",
  "reservationHistoryPage.paragraph.duration": "Dauer",
  "reservationHistoryPage.paragraph.hour": "Stunde",
  "reservationHistoryPage.paragraph.persons": "Anzahl der Personen",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "Die Reservierung existiert nicht",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "Die Buchungsliste ist leer.",
  "reservationHistoryPage.paragraph.status": "Status",
  "reservationPage.paragraph.lateInfo":
    "Bitte beachten Sie, dass bei Verspätung der Tisch 15 Minuten auf den Gast wartet!",
  "reservationHistoryPage.paragraph.payment.status": "Zahlungsstatus",
  "searchPage.header": "Suchergebnisse",
  "searchPage.button.map": "Karte",
  "searchPage.button.list": "Liste",
  "searchPage.paragraph.noData": "Keine Ergebnisse für die angegebenen Kriterien.",
  "searchMapPage.map.button.refresh": "Aktualisieren Sie die Karte",
  "searchMapPage.header": "Suchergebnisse",
  "searchMapPage.button.map": "Karte",
  "searchMapPage.button.list": "Liste",
  "loginPage.header": "Protokollierung",
  "loginPage.subheader":
    "Als eingeloggter Benutzer können Sie schneller Tische buchen und Ihre Buchungshistorie einsehen.",
  "loginPage.columns.header.standardLogin": "Standard-Anmeldung",
  "loginPage.columns.header.socialLogin": "Anmelden mit",
  "loginPage.input.label.phoneOrEmail": "Telefonnummer/E-Mail-Adresse",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.input.label.password": "Passwort",
  "loginPage.input.placeholder.password": "",
  "loginPage.anchor.passwordReset": "Passwort zurücksetzen",
  "loginPage.button.logIn": "Einloggen",
  "loginPage.paragraph.dontHaveAccountYet": "Sie haben noch kein Konto?",
  "modal.login.dontHaveAccountYet": "Sie haben noch kein Konto",
  "loginPage.paragraph.registerNow": "Registrieren!",
  "loginPage.button.register": "Register",
  "loginPage.message.logged": "Rebloggt.",
  "loginPage.message.loggedViaFacebook": "Rebloggt über Facebook.",
  "loginPage.message.loggedViaApple": "Rebloggt von Apple.",
  "loginPage.message.loggedViaGoogle": "Rebloggt über Google.",
  "loginPage.paragraph.alreadyLoggedIn": "Sie sind derzeit eingeloggt.",
  "resetPasswordPage.header": "Passwort zurücksetzen",
  "resetPasswordPage.subheader":
    "Füllen Sie das untenstehende Formular aus, und wir senden Ihnen ein neues Passwort für Ihr Konto per SMS oder E-Mail.",
  "resetPasswordPage.subheader2":
    "Wir werden Ihnen ein neues Passwort an Ihre Telefonnummer schicken",
  "resetPasswordPage.input.label.phoneOrEmail": "Geben Sie Ihre Telefonnummer oder E-Mail-Adresse ein",
  "resetPasswordPage.button.confirm": "Bestätigen Sie",
  "resetPasswordPage.toast.passwordChanged":
    "Das neue Passwort wurde an die angegebene Telefonnummer/E-Mail-Adresse gesendet.",
  "resetPasswordPage.toast.error.passwordChanged": "Die angegebene Telefonnummer/E-Mail-Adresse ist falsch.",
  "cookiesPolicyPage.header": "Cookies-Politik",
  "cookiesPolicyPage.subheader": "Relevante Informationen.",
  "cookiesPolicyPage.point.1":
    "Die Website sammelt keine Informationen automatisch, mit Ausnahme der in Cookies enthaltenen Informationen.",
  "cookiesPolicyPage.point.2":
    'Cookies (sog. "Kekse") sind IT-Daten, insbesondere Textdateien, die auf dem Endgerät des Dienstnutzers gespeichert werden und für die Nutzung der Website bestimmt sind. Cookies enthalten in der Regel den Namen der Website, von der sie stammen, die Zeit, in der sie auf dem Endgerät gespeichert werden, und eine eindeutige Nummer.',
  "cookiesPolicyPage.point.3":
    "Die Person, die Cookies auf dem Endgerät des Nutzers der Website platziert und auf diese zugreift, ist der Eigentümer von mojstolik.pl Table4Rest Sp. z o.o. mit Sitz in der ul. Ksawerów 21, 02-656 Warschau.",
  "cookiesPolicyPage.point.4": "Cookies werden verwendet für:",
  "cookiesPolicyPage.point.4a":
    "Anpassung des Inhalts der Seiten der Website an die Präferenzen der Benutzer und Optimierung der Nutzung der Websites; insbesondere ermöglichen diese Dateien die Erkennung des Geräts des Website-Benutzers und die angemessene Anzeige der Website, die an seine individuellen Bedürfnisse angepasst ist;",
  "cookiesPolicyPage.point.4b":
    "die Erstellung von Statistiken, die uns helfen zu verstehen, wie die Besucher der Website die Websites nutzen, was es uns ermöglicht, deren Struktur und Inhalt zu verbessern;",
  "cookiesPolicyPage.point.4c":
    "Aufrechterhaltung einer Sitzung des Nutzers des Dienstes (nach dem Einloggen), dank derer der Nutzer sein Login und sein Passwort nicht auf jeder Unterseite des Dienstes erneut eingeben muss;",
  "cookiesPolicyPage.point.5":
    'Die Website verwendet zwei Haupttypen von Cookies: "Session" (Sitzungscookies) und "permanente" (dauerhafte Cookies). "Sitzungs-Cookies" sind temporäre Dateien, die auf dem Endgerät des Nutzers gespeichert werden, bis dieser sich abmeldet, die Website verlässt oder die Software (Webbrowser) ausschaltet. "Dauerhafte" Cookies werden auf dem Endgerät des Nutzers für die in den Cookie-Parametern angegebene Zeit oder bis zu ihrer Löschung durch den Nutzer gespeichert.',
  "cookiesPolicyPage.point.6":
    "Die folgenden Arten von Cookies werden im Rahmen des Dienstes verwendet:",
  "cookiesPolicyPage.point.6a":
    '"notwendige" Cookies, um die Nutzung der auf der Website verfügbaren Dienste zu ermöglichen, z. B. Authentifizierungs-Cookies, die für Dienste verwendet werden, die eine Authentifizierung auf der Website erfordern;',
  "cookiesPolicyPage.point.6b":
    "Cookies, die für Sicherheitszwecke verwendet werden, z. B. um einen Missbrauch der Authentifizierung auf der Website zu erkennen;",
  "cookiesPolicyPage.point.6c":
    'Performance"-Cookies, die es ermöglichen, Informationen darüber zu sammeln, wie die Seiten der Website genutzt werden;',
  "cookiesPolicyPage.point.6d":
    '"funktionale" Cookies, die es ermöglichen, sich die vom Nutzer gewählten Einstellungen zu merken und die Benutzeroberfläche zu personalisieren, z. B. im Hinblick auf die vom Nutzer gewählte Sprache oder Herkunftsregion, die Schriftgröße, das Design der Website usw;',
  "cookiesPolicyPage.point.6e":
    '"Werbe"-Cookies, die es ermöglichen, den Nutzern Werbeinhalte zu liefern, die besser auf ihre Interessen zugeschnitten sind.',
  "cookiesPolicyPage.point.7":
    "In vielen Fällen erlaubt die Web-Browsing-Software (Webbrowser) standardmäßig die Speicherung von Cookies auf dem Endgerät des Nutzers. Die Nutzer der Website können ihre Einstellungen in Bezug auf Cookies jederzeit ändern. Diese Einstellungen können insbesondere dahingehend geändert werden, dass die automatische Handhabung von Cookies in den Einstellungen eines Webbrowsers blockiert wird, oder dass über ihre jeweilige Platzierung auf dem Gerät eines Nutzers des Dienstes informiert wird. Detaillierte Informationen über die Möglichkeiten und Methoden der Verwendung von Cookies sind in den Einstellungen Ihrer Software (Webbrowser) verfügbar.",
  "cookiesPolicyPage.point.8":
    "Der Betreiber der Website weist Sie darauf hin, dass Einschränkungen bei der Verwendung von Cookies einige der auf der Website verfügbaren Funktionen beeinträchtigen können.",
  "privacyPolicyPage.header": "Datenschutzbestimmungen",
  "privacyPolicyPage.subheader": "Relevante Informationen.",
  "privacyPolicyPage.point.one": "ALLGEMEINE BESTIMMUNGEN",
  "privacyPolicyPage.point.one.1":
    "Der Datenverwalter ist Table4Rest Sp. z o.o. mit Sitz in Warschau 02-656, Ksawerów Str. 21, eingetragen im Unternehmerregister unter der KRS-Nr.: 0000752110, für das die Registrierungsdateien vom REGISTRAR'S COURT FOR THE M.ST.WARSAW IN WARSAW, XIII ECONOMIC DEPARTMENT OF THE NATIONAL REGISTER NIP: 5213842139 REGON: 381529521 geführt werden.",
  "privacyPolicyPage.point.one.2":
    "Für die Auslegung von Begriffen gilt das Glossar der Allgemeinen Geschäftsbedingungen oder wie in der Datenschutzerklärung beschrieben (sofern sich diese direkt aus der Beschreibung ergibt).",
  "privacyPolicyPage.point.one.3":
    'Zum besseren Verständnis der Datenschutzrichtlinie wird der Begriff "Nutzer" durch "Sie" und "Administrator" durch "wir" ersetzt. Der Begriff "RODO" bezeichnet die Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten, zum freien Datenverkehr und zur Aufhebung der Richtlinie 95/46/EG.',
  "privacyPolicyPage.point.one.4":
    "Wir respektieren das Recht auf Privatsphäre und sorgen für die Datensicherheit. Zu diesem Zweck verwenden wir unter anderem ein sicheres Kommunikationsverschlüsselungsprotokoll (SSL).",
  "privacyPolicyPage.point.one.5":
    "Persönliche Daten, die in das Formular auf der Landing Page eingegeben werden, werden vertraulich behandelt und sind für Unbefugte nicht einsehbar.",
  "privacyPolicyPage.point.two": "CONTROLLER",
  "privacyPolicyPage.point.two.1":
    "Der Dienstanbieter ist der Verwalter von Personen, die das Kontaktformular auf der Website verwendet haben oder die eine andere Form der Kommunikation (E-Mail, Telefon, mobile App, Bestellanruf-Plugin, andere Formen der Kommunikation) verwendet haben.",
  "privacyPolicyPage.point.two.2": "Es werden personenbezogene Daten verarbeitet:",
  "privacyPolicyPage.point.two.2a": "a. in Übereinstimmung mit der Datenschutzgesetzgebung,",
  "privacyPolicyPage.point.two.2b":
    "b. in Übereinstimmung mit der implementierten Datenschutzrichtlinie,",
  "privacyPolicyPage.point.two.2c":
    "c. in dem Umfang und zu dem Zweck, der für die Errichtung, den Abschluss, die Änderung oder die Beendigung des Vertrags und die ordnungsgemäße Erbringung der Dienstleistungen erforderlich ist,",
  "privacyPolicyPage.point.two.2d":
    "d. in dem Umfang und für die Zwecke, die zur Wahrung berechtigter Interessen erforderlich sind (berechtigte Zwecke) und die Verarbeitung die Rechte und Freiheiten der betroffenen Person nicht beeinträchtigt.",
  "privacyPolicyPage.point.two.3":
    "Jede betroffene Person (wenn wir der für die Verarbeitung Verantwortliche sind) hat das Recht auf Auskunft, Berichtigung, Löschung oder Einschränkung der Verarbeitung, das Recht auf Widerspruch und das Recht auf Beschwerde bei einer Aufsichtsbehörde.",
  "privacyPolicyPage.point.two.4":
    "Die Kontaktaufnahme mit der Person, die für die Verarbeitung personenbezogener Daten in der Organisation des Dienstanbieters zuständig ist, ist per E-Mail möglich unter: kontakt@mojstolik.pl",
  "privacyPolicyPage.point.two.5":
    "Wir behalten uns vor, Ihre Daten nach Beendigung des Vertrages oder Widerruf der Einwilligung nur insoweit zu verarbeiten, als dies zur Durchsetzung etwaiger Ansprüche vor Gericht erforderlich ist oder nationales oder EU- bzw. internationales Recht uns zur Aufbewahrung der Daten verpflichtet.",
  "privacyPolicyPage.point.two.6":
    "Der Dienstanbieter hat das Recht, die personenbezogenen Daten des Nutzers und andere Daten des Nutzers den nach den einschlägigen Rechtsvorschriften befugten Stellen (z. B. Strafverfolgungsbehörden) zur Verfügung zu stellen.",
  "privacyPolicyPage.point.two.7":
    "Die Löschung personenbezogener Daten kann aufgrund des Widerrufs der Einwilligung oder eines gesetzlich zulässigen Widerspruchs gegen die Verarbeitung personenbezogener Daten erfolgen.",
  "privacyPolicyPage.point.two.8":
    "Der Diensteanbieter darf personenbezogene Daten nur an Stellen weitergeben, die nach den einschlägigen Rechtsvorschriften dazu befugt sind.",
  "privacyPolicyPage.point.two.9":
    "Personenbezogene Daten werden nur von Personen verarbeitet, die von uns dazu ermächtigt wurden oder von Auftragsverarbeitern, mit denen wir eng zusammenarbeiten.",
  "restaurantPage.button.pageWWW": "Website",
  "restaurantPage.button.seeMenu": "Siehe Menü",
  "restaurantPage.button.confirm": "Bestätigen Sie",
  "restaurantPage.button.cancel": "Ankündigung",
  "restaurantPage.button.choose": "Wählen Sie",
  "restaurantPage.button.back": "Rückkehr",
  "restaurantPage.button.showMore": "Mehr anzeigen",
  "restaurantPage.button.showMore.promotion": "Mehr lesen",
  "restaurantPage.button.showLess": "Weniger anzeigen",
  "restaurantPage.button.goToHomePage": "Zurück zur Hauptseite",
  "restaurantPage.button.reservationDetails": "Details zur Buchung",
  "restaurantPage.button.goToPaymentPage": "Gehen Sie zum Zahlungspanel",
  "restaurantPage.paymentPage.info":
    "Sie werden sofort an den Zahlungsanbieter Przelewy24.pl weitergeleitet.",
  "restaurantPage.header.restaurantDescription": "Beschreibung des Restaurants",
  "restaurantPage.header.bookingPolicy": "Reservierungspolitik",
  "restaurantPage.header.gallery": "Fotogalerie",
  "restaurantPage.header.yourReservation": "Ihre Buchung",
  "restaurantPage.header.yourPersonalData": "Ihre Daten",
  "restaurantPage.header.ctnWithoutLogin": "Fortfahren ohne Einloggen",
  "restaurantPage.header.login": "Protokollierung",
  "restaurantPage.button.login": "Einloggen",
  "restaurantPage.button.orLoginWith": "Oder melden Sie sich an mit",
  "restaurantPage.header.createAccount":
    "Erstellen Sie ein Konto bei MojStolik.de und profitieren Sie:",
  "restaurantPage.header.createAccount.benefit.1": "Schnell zu buchen",
  "restaurantPage.header.createAccount.benefit.2": "Vollständige Buchungshistorie",
  "restaurantPage.header.createAccount.benefit.3": "Zugang zu einzigartigen Werbeaktionen",
  "restaurantPage.button.createAccount": "Ein MojStolik.pl-Konto erstellen",
  "restaurantPage.header.statements": "Erklärungen",
  "restaurantPage.header.statement": "Stellungnahme",
  "restaurantPage.header.promotions": "Sonderangebote und Veranstaltungen",
  "restaurantPage.header.use": "Verwenden Sie",
  "restaurantPage.header.cancel": "Aufgeben",
  "restaurantPage.header.reservationConfirmation": "Buchungsbestätigung",
  "restaurantPage.header.reservationsPersons": "Buchungen von {{min}} bis {{max}} Personen",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Gib den Code aus der SMS ein, die wir an deine Telefonnummer gesendet haben, um den Tischreservierungsprozess abzuschließen.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Gib den Code aus der Nachricht ein, die wir an deine E-Mail-Adresse gesendet haben, um den Tischreservierungsprozess abzuschließen.",
  "restaurantPage.input.label.code": "Geben Sie den Code des Tages ein",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "Für die gewählten Kriterien (Datum, Anzahl der Personen, Aktion) sind keine Tabellen verfügbar. Bitte wählen Sie ein anderes Datum, eine andere Anzahl von Personen oder eine andere Aktion.",
  "restaurantPage.paragraph.noAvailableHours":
    "Keine freien Tabellen in den ausgewählten Kriterien.",
  "restaurantPage.paragraph.noAvailableHoursExt":
    "Wählen Sie ein anderes Datum oder eine andere Anzahl von Personen.",
  "restaurantPage.input.label.date": "Daten",
  "restaurantPage.input.label.hour": "Stunde",
  "restaurantPage.input.label.persons": "Anzahl der Personen",
  "restaurantPage.input.label.persons.optionExtend": "Pers.",
  "restaurantPage.input.label.firstName": "Name",
  "restaurantPage.input.label.vatIdNumber": "NIP",
  "restaurantPage.input.label.address": "Adres",
  "restaurantPage.input.label.companyName": "Name des Unternehmens",
  "restaurantPage.input.label.street": "Straße",
  "restaurantPage.input.label.buildingNumber": "Gebäude",
  "restaurantPage.input.label.localNumber": "Lokales",
  "restaurantPage.input.label.postalCode": "Postleitzahl",
  "restaurantPage.input.label.city": "Stadt",
  "restaurantPage.input.label.country": "Land",
  "restaurantPage.get.company.data": "Herunterladen",
  "restaurantPage.input.label.lastName": "Name",
  "restaurantPage.input.label.phoneNumber": "Rufnummer",
  "restaurantPage.input.label.email": "E-Mail Adresse",
  "restaurantPage.input.label.password": "Passwort eingeben",
  "restaurantPage.input.label.passwordRepeat": "Passwort wiederholen",
  "restaurantPage.input.label.description": "Buchungshinweise",
  "agreements.allAgreements": "Alle markieren",
  "agreements.invoice": "Geben Sie Ihre Rechnungsdaten ein",
  "restaurantPage.input.label.personalData":
    "Ich erkläre, dass ich die Bestimmungen der Allgemeinen Geschäftsbedingungen für die Erbringung von Dienstleistungen durch MojStolik über die Kundenanwendung kenne und akzeptiere.",
  "restaurantPage.input.label.personalData.extension": " MojStolik Bedingungen und Konditionen",
  "restaurantPage.input.label.readWithdrawal":
    "Ich habe die Allgemeinen Geschäftsbedingungen von MojStolik.de gelesen, insbesondere die Belehrung über das Widerrufsrecht.",
  "restaurantPage.input.label.readWithdrawal.extension":
    "Allgemeine Geschäftsbedingungen von mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "Ich möchte Werbeaktionen, Sonderangebote, Gewinnspiele oder andere Marketinginhalte von MojStolik, einschließlich maßgeschneiderter Informationen über die Dienstleistungen und Produkte von MojStolik, die in der Kundenanwendung verfügbar sind, über elektronische Kommunikation erhalten. Sie können diese Zustimmung jederzeit widerrufen.",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Ich möchte Werbeaktionen, Sonderangebote, Gewinnspiele oder andere Marketing-Inhalte von MojStolik erhalten, einschließlich auf mich zugeschnittener Informationen über die Dienstleistungen und Produkte des Restaurants und der kooperierenden Unternehmen, die auf mojstolik.de angegeben sind, die in der Kundenanwendung verfügbar sind, über elektronische Kommunikation. Sie können diese Einwilligung jederzeit widerrufen.",
  "agreement.eventAgreement":
    "Ich habe die Bedingungen und Konditionen des Sonderangebots gelesen und akzeptiere diese.",
  "registrationPage.input.label.eventAgreementUrl.extension": "unter diesem Link",
  "restaurantPage.input.placeholder.typeIn": "Eingabe",
  "restaurantPage.input.placeholder.firstName": "Z.B. Jan",
  "restaurantPage.input.placeholder.lastName": "z.B. Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.email": "Z.B. adres@email.pl",
  "restaurantPage.paragraph.fillOrLogin":
    "Füllen Sie die restlichen Daten aus oder laden Sie sie über Facebook, Google oder Apple herunter",
  "restaurantPage.paragraph.doYouWantToRegister":
    "Wenn Sie ein Konto auf MOJSTOLIK.PL erstellen möchten, geben Sie bitte ein Passwort ein",
  "restaurantPage.paragraph.dataIsIncorrect": "Sind die Daten falsch?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Der Code wurde erneut per SMS gesendet. Hast du den Code nicht erhalten?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Autorisieren Sie sich per E-Mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link":
    "Senden Sie den Code an die angegebene E-Mail-Adresse.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent":
    "Der Code wurde an die E-Mail-Adresse gesendet:",
  "restaurantPage.anchor.sendCodeAgain": "Code erneut senden",
  "restaurantPage.anchor.edit": "bearbeiten",
  "restaurantPage.anchor.login": "Anmeldung",
  "restaurantPage.paragraph.or": " oder",
  "restaurantPage.anchor.register": "registrieren",
  "restaurantPage.paragraph.dontWontUsePromotion": "Ich möchte keine Werbeaktionen durchführen",
  "restaurantPage.messages.codeResent": "Der SMS-Code wurde erneut gesendet.",
  "restaurantPage.header.reservationRecieved": "Annahme von Vorbehalten",
  "restaurantPage.summary.paragraph.firstLastName": "Vorname Nachname:",
  "restaurantPage.summary.paragraph.phoneNumber": "Rufnummer:",
  "restaurantPage.summary.paragraph.email": "E-Mail:",
  "restaurantPage.summary.paragraph.restName": "Name des Restaurants:",
  "restaurantPage.summary.paragraph.date": "Abgabetermin:",
  "restaurantPage.summary.paragraph.date.time": ", Std.",
  "restaurantPage.summary.paragraph.duration": "Dauer:",
  "restaurantPage.summary.paragraph.persons": "Anzahl der Besucher:",
  "restaurantPage.summary.paragraph.totalCost": "Vorauszahlungen insgesamt:",
  "restaurantPage.summary.special.offer": "Sonderangebot:",
  "restaurantPage.summary.paragraph.state": "Status:",
  "restaurantPage.paragraph.tableReserved": "Es wurde ein Tisch reserviert",
  "reservation.status.Canceled": "Abgesagt",
  "reservation.status.Completed": "Reservierung gemacht",
  "reservation.status.Confirmed": "Reservierung bestätigt",
  "reservation.status.Delayed": "Verspätet",
  "reservation.status.NotCompleted": "Buchung nicht erfüllt",
  "reservation.status.Rejected": "Buchung storniert",
  "reservation.status.WaitingForApproval": "Buchung noch nicht genehmigt",
  "reservation.status.WaitingForConfirmation": "Warten auf",
  "restaurantPage.paragraph.tableReservedDescription": "Ihr Tisch ist für eine Stunde reserviert.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "auf",
  "restaurantPage.paragraph.tableWaitingForApproval": "Buchung noch nicht genehmigt",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1": "Ihre Buchung für Stunden.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "Sollten Sie innerhalb weniger Stunden keine Bestätigung Ihrer Buchung erhalten, wenden Sie sich bitte direkt an das Restaurant",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "auf",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "Sobald die Buchung bestätigt ist, wird eine Nachricht mit einem Zahlungslink an die angegebene Telefonnummer gesendet. Die Reservierung ist nach Erhalt der Zahlungsbestätigung gültig",
  "restaurantPage.paragraph.tableReservedPaymentInformation":
    "Reservierungen müssen bezahlt werden",
  "restaurantPage.input.label.footer":
    "MojStolik nutzt eine Vielzahl elektronischer Kommunikationskanäle und wickelt seine Geschäfte per E-Mail, Telefon, SMS und MMS ab. Der Verwalter Ihrer personenbezogenen Daten ist Table4Rest spółka z ograniczoną odpowiedzialnością mit Sitz in Warschau, 02-656 Warschau, ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Ihre personenbezogenen Daten werden zu dem Zweck verarbeitet, Ihnen über elektronische Kommunikationskanäle Marketinginhalte zukommen zu lassen. Weitere Informationen über die Verarbeitung Ihrer personenbezogenen Daten, einschließlich Ihrer Rechte, finden Sie in der Datenschutzrichtlinie.",
  "forGuestPage.header": "Für den Gast",
  "howItWorksPage.header": "Wie funktioniert das?",
  "howItWorksPage.subheader": "Schritt für Schritt",
  "howItWorksPage.content.title": "Buchung über die mobile App",
  "howItWorksPage.content.step1": "Schritt 1",
  "howItWorksPage.content.step1.content": "Der Kunde sucht in der mobilen App nach Restaurants und",
  "howItWorksPage.content.step1.content.strong": "sieht die verfügbaren Tabellen in Echtzeit.",
  "howItWorksPage.content.step2": "Schritt 2",
  "howItWorksPage.content.step2.content.strong":
    "Der Manager kann die Buchungsanfrage in seinem Panel sehen",
  "howItWorksPage.content.step2.content":
    " Er weiß, ob die Anfrage von einem Neukunden oder einem Stammkunden stammt und ob es irgendwelche Tischpräferenzen gibt.",
  "howItWorksPage.content.step3": "Schritt 3",
  "howItWorksPage.content.step3.content": "Ein Kunde kommt in ein Restaurant, in dem",
  "howItWorksPage.content.step3.content.strong":
    "wird mit Namen begrüßt. Das Personal hat alle Kundendaten",
  "howItWorksPage.content.step3.contentb":
    " - weiß, ob er ihr regelmäßiger Besucher ist oder sie zum ersten Mal besucht.",
  "howItWorksPage.phone.title": "Telefonische Reservierung",
  "howItWorksPage.content.phone.step1": "Schritt 1",
  "howItWorksPage.content.phone.step1.content1": "Klient",
  "howItWorksPage.content.phone.step1.content.strong": "ruft das Restaurant Ihrer Wahl an",
  "howItWorksPage.content.phone.step1.content2": "mit einer Buchungsanfrage.",
  "howItWorksPage.content.phone.step2": "Schritt 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "Der Manager kann die Tischverfügbarkeit in Echtzeit in seinem Dashboard sehen",
  "howItWorksPage.content.phone.step2.content":
    ". Wenn ein Manager die Telefonnummer eines Kunden eingibt, sieht er sofort die Daten des Kunden oder er vervollständigt/erfasst die Daten eines neuen Kunden.",
  "howItWorksPage.content.phone.step3": "Schritt 3",
  "howItWorksPage.content.phone.step3.content": "Ein Kunde kommt in ein Restaurant, in dem",
  "howItWorksPage.content.phone.step3.content.strong":
    "Das Personal begrüßt ihn mit Namen, hat alle Kundendaten",
  "howItWorksPage.content.phone.step3.contentb":
    " - weiß, ob er ihr regelmäßiger Besucher ist oder sie zum ersten Mal besucht.",
  "priceListPage.header": "Die Preisliste",
  "priceListPage.content.link.1": "Für Preisinformationen, bitte",
  "priceListPage.content.link.2": "Kontakt",
  "contactPage.header": "Kontakt",
  "contactPage.subheader": "Schreiben Sie uns!",
  "contactPage.content.title": "Kontaktangaben",
  "documentsPage.header": "Dokumenty",
  "documentsPage.header.for.clients": "Für den Kunden",
  "documentsPage.header.for.restaurants": "Für Restaurants",
  "documentsPage.subheader": "Einschlägige Informationen",
  "documentsPage.for.clients.document.1":
    "Regelungen für die Erbringung von Dienstleistungen durch MojStolik über die Kundenanwendung",
  "documentsPage.for.clients.document.2":
    "Informationen über die Verarbeitung von personenbezogenen Daten",
  "documentsPage.header.for.restaurants.document.1":
    "Allgemeine Geschäftsbedingungen für die Bereitstellung der MojStolik-Anwendung für Restaurants",
  "documentsPage.header.for.restaurants.document.2":
    "Beauftragungsvereinbarung für die Verarbeitung personenbezogener Daten des Restaurants",
  "documentsPage.header.for.restaurants.document.3": "Persönliche Daten mojstolik.co.uk",
  "aboutPage.header": "O in",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " - ist ein polnisches System, das von einer Gruppe von Freunden entwickelt wurde - heimlichen Liebhabern von gutem Essen und der Kunst der Bewirtung in Restaurants. Es ist eine technologische Lösung, die dazu beiträgt, eine Gemeinschaft um Orte herum aufzubauen, die durch gute Küche und Respekt für die Zeit und die Gewohnheiten ihrer treuen Gäste attraktiv sind. MojStolik.pl ist eine wachsende Gruppe von Restaurants, die zusammenarbeiten, um sicherzustellen, dass die polnische Gastronomie mit ausgezeichneter Qualität, Liebe zum Detail und Respekt für die Fans, die Zeit am Tisch mit Freunden, Familie oder Geschäftspartnern verbringen, identifiziert wird. Die Gründer des Systems haben ihre Leidenschaft für die Gastronomie in die Sprache der Technik übersetzt und so ein gastronomisches Portal und eine Anwendung geschaffen, die Menschen an den Tischen der beliebtesten und am meisten geschätzten Restaurants in Polen miteinander verbindet.",
  "aboutPage.application.header1":
    " Sie möchten einen Tisch in einem Restaurant reservieren? Jetzt können Sie dies über eine App auf Ihrem Telefon oder über ein Multi-Restaurant-Portal tun.",
  "aboutPage.application.description1":
    "Mojstolik.pl ist eine neue umfassende Buchungsplattform, die seit kurzem für Warschauer Restaurants verfügbar ist. Sie ermöglicht Gastronomiebetrieben die bequeme Verwaltung von Tischreservierungen in Echtzeit und integriert alle Kommunikationskanäle mit den Gästen: Telefon, E-Mail, die MojStolik-Mobil-App und den sogenannten Walk-In. Die Restaurantleiter haben damit Zugriff auf eine dynamische Ansicht des Raumes, entscheiden selbst über die Raumaufteilung, die Anzahl der verfügbaren Plätze und wie lange jeder Tisch reserviert werden kann. Ein zusätzlicher Vorteil des Systems ist der SMS-Reservierungsbestätigungsmechanismus mit der Möglichkeit, über einen Link zu stornieren und SMS-Erinnerungen an die Gäste zu senden.",
  "aboutPage.application.header2": "MojStolik.de: ein Tablet statt eines Buches",
  "aboutPage.application.description2":
    "MojStolik.pl ist auch ein Instrument, das den Restaurants hilft, die Kundenbindung zu stärken. Das System ermöglicht den Aufbau einer Gästedatenbank, die Analyse der Buchungshistorie und der Besuchszähler zeigt an, wann eine bestimmte Person das Restaurant besucht hat. Bei der Reservierung haben die Gäste die Möglichkeit, Kommentare wie Tischpräferenzen, Lieblingsgerichte oder Weine hinzuzufügen. Die neue Plattform ermöglicht die sichere Speicherung von Kundendaten gemäß den RODO-Anforderungen, was bei dem von den Restaurants verwendeten Buchungsbuch in Papierform nicht der Fall ist.",
  "aboutPage.application.description2b":
    "Die App wird von mehr als 100 Restaurants in ganz Polen genutzt, darunter: Stary Dom, Baczewskie Restaurant, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE'S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, und weitere werden in Kürze hinzugefügt.",
  "aboutPage.application.header3": "MojStolik - neue Tischreservierungs-App",
  "aboutPage.application.description3":
    " MojStolik ist eine neue mobile Anwendung, mit der Sie schnell und bequem einen Tisch in einem Restaurant reservieren können. Mit der App können Sie sofort die Verfügbarkeit prüfen und einen Tisch für eine bestimmte Stunde reservieren, da sie in Echtzeit mit dem Restaurant kommuniziert. Sie können Ihrer Buchung einen besonderen Wunsch oder einen Kommentar zu Ihrem Lieblingstisch im Restaurant, Informationen über Allergien oder andere Vorlieben hinzufügen, und das Restaurant kann Ihnen über die Chatfunktion mitteilen, ob Ihr Wunsch möglich ist. In der MojStolik-Anwendung können wir auch die aktuelle Speisekarte des Restaurants einsehen und haben Zugang zu Sonderangeboten, die die Restaurants für uns vorbereitet haben.",
  "aboutPage.contact.data": "Kontaktangaben",
  "aboutPage.contact.street": "ul.",
  "registrationPage.header": "Anmeldung",
  "registrationPage.subheader":
    "Als eingeloggter Benutzer können Sie Tische schneller buchen und Ihre Buchungshistorie einsehen.",
  "registrationPage.columns.header.registerStandard": "Standard-Registrierung",
  "registrationPage.columns.header.registerWith": "Anmelden bei",
  "registrationPage.input.label.firstName": "Name",
  "registrationPage.input.placeholder.firstName": "Z.B. Jan",
  "registrationPage.input.label.lastName": "Name",
  "registrationPage.input.placeholder.lastName": "z.B. Kowalski",
  "registrationPage.input.label.phoneNumber": "Rufnummer",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.label.email": "E-Mail Adresse",
  "registrationPage.input.placeholder.email": "Z.B. adres@email.pl",
  "registrationPage.paragraph.optionalFields": "Optionale Felder",
  "registrationPage.input.label.birthDate": "Datum der Geburt",
  "registrationPage.input.placeholder.birthDate": "z.B. 1976-10-25",
  "registrationPage.input.label.postalCode": "Postleitzahl",
  "registrationPage.input.placeholder.postalCode": "Z.B. 03-123",
  "registrationPage.input.label.city": "Stadt",
  "registrationPage.input.placeholder.city": "Z.B. Warschau",
  "registrationPage.input.label.streetAndLocalNumber": "Straße und Wohnungsnummer",
  "registrationPage.input.placeholder.streetAndLocalNumber": "z. B. Grójecka 130/105",
  "registrationPage.input.label.password": "Passwort",
  "registrationPage.input.label.passwordRepeat": "Passwort wiederholen",
  "registrationPage.header.statements": "Erklärungen",
  "registrationPage.input.label.agreementPersonalData":
    "Ich erkläre mich mit der Verarbeitung meiner personenbezogenen Daten durch Table4Rest sp. z o.o. und die von mir ausgewählten Restaurants und Gastronomiebetriebe zum Zwecke der Erfüllung des Vertragsgegenstandes gemäß den in diesem Dokument enthaltenen Informationen einverstanden.",
  "registrationPage.input.label.agreementPersonalData.extension":
    "Informationen über die Verarbeitung der personenbezogenen Daten der Kunden",
  "registrationPage.input.label.agreementReadWithdrawal":
    "Ich habe die Allgemeinen Geschäftsbedingungen von MojStolik.de gelesen, insbesondere die Belehrung über das Widerrufsrecht.",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Allgemeine Geschäftsbedingungen von mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Zustimmung zur Vermarktung",
  "registrationPage.input.label.agreementMarketing.extension": "Zustimmung",
  "registrationPage.input.label.promotionReadWithdrawal.extension":
    "Bedingungen und Konditionen der Aktion",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "Ich bin damit einverstanden, dass die von mir ausgewählten Gaststätten und sonstigen Verpflegungseinrichtungen meine personenbezogenen Daten verarbeiten, um eine Reservierung vorzunehmen.",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "unter diesem Link",
  "registrationPage.button.register": "Register",
  "registrationPage.message.sendCodeAgain": "Der SMS-Code wurde erneut gesendet.",
  "registrationPage.message.accountCreated": "Das Konto wurde erstellt.",
  "registrationPage.message.registrationTimeExpired": "Die Registrierungszeit ist abgelaufen",
  "registrationPage.message.enterCodeFromSMS": "Transkribieren Sie den Code aus der SMS.",
  "registrationPage.paragraph.timeIsOver": "Die Zeit ist abgelaufen.",
  "registrationPage.paragraph.timeLeft": "Verbleibende",
  "registrationPage.paragraph.seconds": "Sekunde(-a)",
  "registrationPage.header.SMSConfirmation": "SMS-Bestätigung",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Geben Sie den SMS-Code ein, den wir an Ihre Telefonnummer geschickt haben, um Ihre Anmeldung abzuschließen.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Geben Sie den SMS-Code des Tages ein",
  "registrationPage.input.placeholder.code": "Geben Sie den Code ein",
  "registrationPage.paragraph.sendCodeAgain": "Der SMS-Code wurde erneut gesendet.",
  "registrationPage.button.sendCodeAgain": "Code erneut senden",
  "registrationPage.button.confirm": "Bestätigen Sie",
  "notFoundPage.header": "Fehler 404",
  "notFoundPage.subheader": "Gewünschte URL",
  "notFoundPage.subheaderExtend": "wurde auf dem Server nicht gefunden.",
  "notFoundPage.subheaderExtend2":
    "Überprüfen Sie, ob die eingegebene Adresse korrekt ist, oder gehen Sie zu",
  "notFoundPage.subheaderExtend3": "Homepage",
  "notFoundPage.button.goToHomePage": "Zur Hauptseite gehen",
  "global.toast.payment.count": "Erforderliche Produkte nicht ausgewählt",
  "paymentInfo.description.label": "Name",
  "paymentInfo.amount.label": "Preis pro Einheit.",
  "paymentInfo.quantity.label": "Nummer",
  "paymentInfo.total.label": "Insgesamt",
  "paymentInfo.nocalculation": "Zahlungen sind nicht erforderlich",
  "paymentInfo.summarycost.label": "Zu entrichten:",
  "paymentInfo.required.products": "Obligatorische Produkte",
  "paymentInfo.optional.products": "Optionale Produkte",
  "paymentInfo.recalculate": "Neuberechnen",
  "paymentInfo.amount.to.paid": "Zu zahlender Gesamtbetrag",
  "paymentInfo.info":
    "Die Gesamtzahl der obligatorischen Produkte muss der Anzahl der Personen auf der Buchung entsprechen.",
  "paymentInfo.info1": "Sie müssen noch wählen",
  "paymentInfo.info3": "produkt",
  "paymentInfo.info4": "Produkte",
  "paymentInfo.package.title": "Basis-Paket",
  "paymentInfo.select.product": "Produkt auswählen",
  "paymentInfo.select.basic.product": "Wählen Sie ein Basisprodukt",
  "paymentInfo.select.required.product": "Obligatorische Produkte auswählen",
  "paymentInfo.too.much.products": "Sie können nicht mehr Produkte auswählen",
  "restaurantPage.button.confirmAndPay": "Bestätigen und bezahlen",
  "restaurantPage.button.closestTime": "Bevorstehender Termin",
  "paymentInfo.title": "Entgelte",
  "reservation.payment.status.Created": "Erstellt",
  "reservation.payment.status.WaitingForPayment": "Warten auf",
  "reservation.payment.status.ProcessingPayment": "Während des Prozesses",
  "reservation.payment.status.Completed": "Realisiert",
  "reservation.payment.status.Cancelled": "Abgesagt",
  "reservation.payment.status.Expired": "Abgelaufen",
  "reservation.payment.status.Unknown": "Unbekannter Status",
  "custom.form.required": "Erforderliches Feld",
  "custom.button.send": "Senden Sie",
  "custom.button.hide": "Kollabieren",
  "custom.button.show": "Erweitern Sie",
  "reservation.confirmation.payment.count": "Neuberechnung der Produkte",
  "reservation.confirmation.selected.promotion": "Ausgewählte Förderung",
  "forRestaurantsPage.form.toast.success": "Ihre Kontaktdaten wurden gesendet.",
  "forRestaurantsPage.form.toast.error":
    "Es ist ein Fehler aufgetreten, bitte versuchen Sie es erneut.",
  "forRestaurantsPage.form.email.error": "Falsche E-Mail",
  "forRestaurantsPage.label.name": "NAME",
  "forRestaurantsPage.label.restaurant": "RESTAURANT:",
  "forRestaurantsPage.label.email": "E-MAIL:",
  "forRestaurantsPage.label.phone": "TELEFON:",
  "forRestaurantsPage.header.agreement": "ERKLÄRUNGEN",
  "forRestaurantsPage.label.agreement":
    " Ich stimme der Verarbeitung meiner persönlichen Daten durch Table4Rest sp. z o. o. zum Zwecke des Abschlusses eines Vertrages über die Erbringung von Dienstleistungen für die Bereitstellung und Nutzung der Anwendung MojStolik.pl und für die Zusendung eines Angebots auf elektronischem Wege zu.",
  "surveyPage.header.title": "Kundenfragebogen",
  "surveyPage.header.description":
    "Ihr Feedback ist das Wichtigste für uns, denn nur so können wir uns weiterentwickeln. Bitte teilen Sie uns Ihre Meinung mit:",
  "surveyPage.button.complete": "Bereit",
  "surveyPage.button.back": "Rückkehr",
  "surveyPage.button.next": "Weitere",
  "surveyPage.surveyCompleteMessage": "Vielen Dank, dass Sie an der Umfrage teilgenommen haben!",
  "surveyPage.emptySurveyMessage":
    "Beim Herunterladen der Umfragedaten ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  "surveyPage.error": "Es ist ein Fehler aufgetreten.",
  "surveyPage.results.sending": "Die Veröffentlichung Ihrer Antworten ist in Arbeit...",
  "surveyPage.results.success": "Ihr Fragebogen wurde abgeschickt",
  "surveyPage.filledAlert.header": "Dieser Fragebogen wurde bereits ausgefüllt"
};

export default frontendTranslationsDE;
