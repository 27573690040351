const backendTranslationsDE = {
  "ByWindow": "Am Fenster",
  "Failed": "Gescheitert",
  "ForHowManyPeopleTable": "Anzahl der Bezüge",
  "NewReservationCouldNotBeCreated": "Neue Buchung kann nicht erstellt werden",
  "NewReservationHasBeenCreated": "Eine neue Buchung wurde erstellt",
  "NonSmoking": "Für Nichtraucher",
  "Outdoor": "Außerhalb",
  "ReservationHasBeenUpdated": "Die Buchung wurde aktualisiert",
  "aboutPage.header": "O in",
  "aboutPage.metadata.description": "Die Optionen",
  "aboutPage.metadata.keywords": "Über uns Schlüsselwörter",
  "aboutPage.metadata.title": "O in",
  "aboutPage.subheader": "Lorem ipsum dolor...",
  "acceptance.required": "Akzeptieren Sie die erforderlichen Zustimmungen",
  "account.changePassword": "Passwort ändern",
  "account.confirmRemoveAccount":
    "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Alle nicht realisierten Buchungen werden automatisch gelöscht.",
  "account.removeAccount": "Konto löschen",
  "account.removeRequest":
    "Um Ihr Konto zu löschen, geben Sie Ihr Passwort ein",
  "accountPage.button.changePassword": "Passwort ändern",
  "accountPage.button.deleteAccount": "Konto löschen",
  "accountPage.button.save": "Speichern Sie",
  "accountPage.header": "Ihr Konto",
  "accountPage.headerInColumn.yourPassword": "Ihr Passwort",
  "accountPage.headerInColumn.yourPersonalData": "Ihre Daten",
  "accountPage.input.label.birthDate": "Datum der Geburt",
  "accountPage.input.label.city": "Stadt",
  "accountPage.input.label.email": "E-Mail Adresse",
  "accountPage.input.label.firstName": "Name",
  "accountPage.input.label.lastName": "Name",
  "accountPage.input.label.newPassword": "Neues Passwort",
  "accountPage.input.label.newPasswordRepeat": "Neues Passwort (Wiederholung)",
  "accountPage.input.label.oldPassword": "Alter Slogan",
  "accountPage.input.label.phoneNumber": "Rufnummer",
  "accountPage.input.label.postalCode": "Postleitzahl",
  "accountPage.input.label.streetAndLocalNumber": "Straße und Wohnungsnummer",
  "accountPage.input.placeholder.birthDate": "z.B. 1976-10-25",
  "accountPage.input.placeholder.city": "Z.B. Warschau",
  "accountPage.input.placeholder.email": "Z.B. adres@email.pl",
  "accountPage.input.placeholder.firstName": "Z.B. Jan",
  "accountPage.input.placeholder.lastName": "z.B. Kowalski",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.placeholder.postalCode": "Z.B. 03-123",
  "accountPage.input.placeholder.streetAndLocalNumber":
    "z. B. Grójecka 130/105",
  "accountPage.label.changePassowordNotPossible":
    "Es ist nicht möglich, Ihr Passwort zu ändern, weil Sie sich über Facebook oder Google angemeldet haben.",
  "accountPage.message.accountPermanentlyDeleted":
    "Ihr Konto wurde endgültig gelöscht.",
  "accountPage.message.passwordChanged": "Das Passwort wurde geändert.",
  "accountPage.message.personalDataUpdated":
    "Die persönlichen Daten wurden aktualisiert.",
  "accountPage.metadata.description": "Beschreibung Ihres Kontos",
  "accountPage.metadata.keywords": "Schlüsselwörter für Ihr Konto",
  "accountPage.metadata.title": "Ihr Konto",
  "accountPage.paragraph.optionalFields": "Optionale Felder",
  "accountPage.subheader":
    "Ändern Sie Ihre persönlichen Daten, um den Erfüllungsprozess zu beschleunigen.",
    "accountPage.toast.error.disableBothChannels":
    "Mindestens eine Kommunikationsmethode (SMS, E-MAIL) muss aktiv bleiben!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Die Aktivierung der Kommunikationsmethode erfordert die Bestätigung der Datenkorrektheit. Speichere die Änderungen, um einen Bestätigungslink an die neue E-Mail-Adresse zu erhalten!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Die Aktivierung der Kommunikationsmethode erfordert die Bestätigung der Datenkorrektheit. Speichere die Änderungen, um einen Bestätigungs-SMS-Link an deine neue Telefonnummer zu erhalten!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Um die neue Kommunikationsmethode zu aktivieren, bestätige deine Daten, indem du auf den Aktivierungslink klickst, der an deine E-Mail-Adresse gesendet wurde!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Um die neue Kommunikationsmethode zu aktivieren, bestätige deine Daten, indem du auf den Aktivierungslink klickst, der per SMS an die angegebene Telefonnummer gesendet wurde!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Um deine Daten zu überprüfen, klicke auf den Bestätigungslink, der an deine E-Mail-Adresse gesendet wurde!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Um deine Daten zu überprüfen, klicke auf den Bestätigungslink, der per SMS an deine Telefonnummer gesendet wurde!",
  "agreement.canRevert":
    "mojstolik.de informiert Sie darüber, dass Sie Ihre Zustimmung zur Verarbeitung Ihrer personenbezogenen Daten zurückziehen können.",
  "agreement.commercialInformationContact":
    "Ich stimme zu, dass Table4Rest sp. z o.o. kommerzielle Informationen mittels elektronischer Kommunikation, insbesondere an die von mir angegebene Telefonnummer und E-Mail-Adresse, einschließlich SMS und MMS, senden darf.",
  "agreement.marketingContact":
    "Ich erkläre mich damit einverstanden, dass Table4Rest sp. z o. o. mich zum Zwecke des Direktmarketings kontaktiert, einschließlich SMS und MMS, sowie Kontaktaufnahme über Telekommunikationsendgeräte und automatische Anrufsysteme, auch unter der von mir angegebenen Telefonnummer und E-Mail-Adresse.",
  "agreement.marketingDataUsage":
    "Ich stimme der Verarbeitung meiner persönlichen Daten durch Table4Rest sp. z o.o. zum Zwecke der Vermarktung der eigenen und der durch die Anwendung MyStolik.pl angebotenen Dienstleistungen und Produkte anderer Unternehmen zu.",
  "agreement.personalData":
    "Ich stimme der Verarbeitung meiner persönlichen Daten durch Table4Rest sp. z o. o. zum Zwecke des Abschlusses eines Vertrages über die Erbringung von Dienstleistungen für die Bereitstellung und Nutzung der MyTable-Anwendung zu.",
  "agreement.readWithdrawal":
    "Ich habe die Allgemeinen Geschäftsbedingungen von Mojstolik.de gelesen, insbesondere die Belehrung über das Widerrufsrecht",
  "agreement.thirdPartDataUsage":
    "Ich bin damit einverstanden, dass meine personenbezogenen Daten von den von mir ausgewählten Restaurants und sonstigen Verpflegungseinrichtungen zum Zwecke der Buchung bei ihnen verarbeitet werden.",
  "agreement.warningUncheck":
    "Der Widerruf der Zustimmung verhindert die Nutzung der Anwendung. Um sie zu widerrufen, müssen Sie Ihr Konto löschen.",
  "api.connectionLost":
    "Die Verbindung zum Internet ist unterbrochen worden. Die Anwendung wird neu geladen.",
  "app.readAgreement":
    "Bedingungen und Konditionen der Dienstleistung MojStolik.pl",
  "cancellationReservationModal.component.button.cancel": "Ankündigung",
  "cancellationReservationModal.component.button.confirm": "Genehmigen Sie",
  "cancellationReservationModal.component.header":
    "Stornierung der Reservierung",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Sind Sie sicher, dass Sie Ihre Reservierung im Restaurant {{restaurantName}} um {{reservationHour}} am {{reservationDate}} stornieren möchten?",
  "changePass.lowerLetter": "- Kleinbuchstaben enthalten",
  "changePass.newPass": "Neues Passwort",
  "changePass.numeric": "- Ziffern enthalten",
  "changePass.oldPass": "Alter Slogan",
  "changePass.passHaveToBe": "Das Passwort muss:",
  "changePass.passLength": "- mindestens 6 Zeichen lang sein",
  "changePass.specialChar": "- ein Sonderzeichen enthalten, z. B. '#@!",
  "changePass.upperLetter": "- mindestens 1 Großbuchstabe enthalten",
  "common.accept": "Genehmigen Sie",
  "common.add": "hinzufügen",
  "common.alertCloseWindow": "Sie schließen das Auswahlfenster",
  "common.back": "Zurück",
  "common.bankAccountNumber": "Bankkontonummer",
  "common.buildingNumber": "Nummer des Gebäudes",
  "common.cancel": "Ankündigung",
  "common.changeButton": "Ändern Sie",
  "common.check": "Überprüfen Sie",
  "common.city": "Stadt",
  "common.clear": "Sauber",
  "common.close": "Schließen Sie",
  "common.confirm": "Bestätigen Sie",
  "common.confirmDecline": "Bestätigen/Ablehnen",
  "common.couldNotDownloadData":
    "Daten konnten nicht heruntergeladen werden. Aktualisieren Sie die Anwendung.",
  "common.dayShort.monday": "Mo.",
  "common.dayShort.tuesday": "Di.",
  "common.dayShort.wednesday": "Mi.",
  "common.dayShort.thursday": "Do.",
  "common.dayShort.friday": "Fr.",
  "common.dayShort.saturday": "Sa.",
  "common.dayShort.sunday": "So.",
  "common.delete": "Löschen",
  "common.detailsButton": "Einzelheiten",
  "common.error": "Fehler",
  "common.gr": "gr",
  "common.help": "Hilfe",
  "common.hourShort": "Std.",
  "common.localNumber": "Lokale Nummer",
  "common.name": "Name",
  "common.new": "Neu",
  "common.next": "Weitere",
  "common.nextButton": "Weitere",
  "common.noData": "Keine Daten zum Anzeigen",
  "common.noValue": "Nie",
  "common.ok": "Ok",
  "common.password": "Passwort",
  "common.preferences": "Präferenzen",
  "common.requiredInputError": "Feld ist erforderlich",
  "common.save": "Speichern Sie",
  "common.search": "Suche",
  "common.send": "Senden Sie",
  "common.shortPersons": "{{persons}} Pers.",
  "common.street": "Straße",
  "common.successful": "Erfolg",
  "common.undoButton": "Zurück",
  "common.unit": "Einheit",
  "common.vatId": "NIP",
  "common.yesValue": "Tak",
  "common.zipCode": "Postleitzahl",
  "common.zl": "zł",
  "company.addNewAction": "Neues Unternehmen hinzugefügt",
  "company.addNewCompany": "Ein neues Unternehmen hinzufügen?",
  "company.addressData": "Angaben zur Adresse",
  "company.buildingNumber": "Nummer des Gebäudes",
  "company.city": "Stadt",
  "company.companyExistsError": "Das Unternehmen existiert bereits",
  "company.country": "Land",
  "company.localNumber": "Lokale Nummer",
  "company.name": "Name des Unternehmens",
  "company.nipNumber": "NIP",
  "company.notFound":
    "Das Unternehmen wurde nicht in der CDEIG-Datenbank gefunden",
  "company.postalCode": "Postleitzahl",
  "company.questionUpdateModal": "Das Unternehmen aktualisieren?",
  "company.street": "Straße",
  "company.updateAction": "Das Unternehmen ist aktualisiert worden",
  "company.updateCompany": "Ein bestehendes Unternehmen aktualisieren?",
  "company.vatId": "NIP",
  "component.cookiesPolicyLine.privacyPolicyText": "Datenschutzbestimmungen",
  "component.cookiesPolicyLine.text":
    "Diese Website verwendet Cookies, um Ihnen den bestmöglichen Service zu bieten. Durch die weitere Nutzung der Website erklären Sie sich mit der Verwendung von Cookies einverstanden.",
  "component.footer.menu.about": "O in",
  "component.footer.menu.contact": "Kontakt",
  "component.footer.menu.cookiesPolicy": "Cookie-Politik",
  "component.footer.menu.documents": "Dokumenty",
  "component.footer.menu.forGuest": "Für den Gast",
  "component.footer.menu.forRestaurants": "Für Restaurants",
  "component.footer.menu.header.applicationDownload": "Download der Bewerbung",
  "component.footer.menu.header.forClients": "Über MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Informationen",
  "component.footer.menu.header.socialMedia": "Soziale Medien",
  "component.footer.menu.header.yourAccount": "Ihr Konto",
  "component.footer.menu.howItWorks": "Wie funktioniert das?",
  "component.footer.menu.login": "Einloggen",
  "component.footer.menu.logout": "Abmelden",
  "component.footer.menu.priceList": "Die Preisliste",
  "component.footer.menu.privacyPolicy": "Datenschutzbestimmungen",
  "component.footer.menu.registration": "Anmeldung",
  "component.footer.menu.reservationHistory": "Ihre Buchungen",
  "component.footer.menu.yourAccount": "Ihr Konto",
  "component.header.menu.about": "O in",
  "component.header.menu.blog": "Nachrichten MOJSTOLIK.PL",
  "component.header.menu.contact": "Kontakt",
  "component.header.menu.documents": "Dokumenty",
  "component.header.menu.forGuest": "Für den Gast",
  "component.header.menu.forRestaurants": "Für Restaurants",
  "component.header.menu.forRestaurants.offer": "Unser Angebot",
  "component.header.menu.howItWorks": "Wie funktioniert das?",
  "component.header.menu.login": "Einloggen",
  "component.header.menu.logout": "Abmelden",
  "component.header.menu.mainPage": "Buchen Sie",
  "component.header.menu.portal.restaurant": "Portal restauracji",
  "component.header.menu.priceList": "Die Preisliste",
  "component.header.menu.reservationHistory": "Ihre Buchungen",
  "component.header.menu.yourAccount": "Ihr Konto",
  "config.communication.confirmationReservation": "Buchungsbestätigung",
  "config.communication.modificationReservation": "Änderung der Reservierung",
  "config.communication.notifTitle": "PUSH-Benachrichtigungen",
  "config.communication.rejectionReservation": "Stornierung der Reservierung",
  "config.communication.remindNotification": "Buchungserinnerungen",
  "config.navbar.account": "Konto",
  "config.navbar.communication": "Kommunikation",
  "config.navbar.configuration": "Konfiguration",
  "config.navbar.myaccount": "Konto",
  "config.navbar.mypreferences": "Präferenzen",
  "configuration.changeLanguage": "Sprache",
  "configuration.customerProfiles": "Kundenprofile",
  "configuration.employeeGroups": "Arbeitnehmergruppen",
  "configuration.help": "Hilfe",
  "configuration.restaurant": "Restaurant",
  "contactPage.header": "Kontakt",
  "contactPage.metadata.description": "Kontakt zu opis",
  "contactPage.metadata.keywords": "Kontakt Schlüsselwörter",
  "contactPage.metadata.title": "Kontakt",
  "contactPage.subheader": "Schreiben Sie uns!",
  "cookiesPolicyPage.header": "Cookies-Politik",
  "cookiesPolicyPage.metadata.title": "Cookies-Politik",
  "cookiesPolicyPage.subheader": "Relevante Informationen.",
  "cuisine.american": "amerikanische Küche",
  "cuisine.arabic": "arabische Küche",
  "cuisine.chinese": "Chinesische Küche",
  "cuisine.polish": "Polnische Küche",
  "cuisine.romania": "rumänische Küche",
  "cusine.chinese": "Chinesische Küche",
  "cusine.polish": "Polnische Küche",
  "customer.accountDetail": " Konten",
  "customer.addEdit": "Hinzufügen / Bearbeiten",
  "customer.blocked":
    "Das Konto wurde gesperrt. Die Möglichkeit der Buchung wurde gesperrt.",
  "customer.confirmText": "Buchen Sie",
  "customer.createInvoice": "Eine Rechnung erstellen",
  "customer.createNewProfile": "Neues Profil hinzugefügt",
  "customer.createNewProfileModal": "Ein neues Profil erstellen?",
  "customer.deleteProfile": "Profil löschen",
  "customer.description": "Beschreibung",
  "customer.editCompany": "Unternehmen bearbeiten",
  "customer.editProfile": "Profil aktualisiert",
  "customer.editReservingCustomerData": "Details zur Buchungsgruppe bearbeiten",
  "customer.emailAddress": "E-Mail",
  "customer.errorProfileAction": "Das Profil ist dem Kunden zugeordnet",
  "customer.existError": "Benutzer existiert bereits",
  "customer.firstCreateCompany": "*Wählen Sie zunächst ein Unternehmen",
  "customer.groupChooseLabel": "Wählen Sie",
  "customer.labels.blocked": "Blockiert",
  "customer.labels.customerName": "Name",
  "customer.labels.emailLabel": "E-Mail",
  "customer.labels.firstNameLabel": "Name",
  "customer.labels.groupLabel": "Grupa",
  "customer.labels.lastNameLabel": "Name",
  "customer.labels.phoneNumber": "Rufnummer",
  "customer.labels.statusLabel": "Status",
  "customer.listOfProfiles": "Profil-Liste",
  "customer.mail": "E-Mail",
  "customer.name": "Name",
  "customer.newPasswordWasSent": "Ein neues Passwort wurde per SMS verschickt",
  "customer.noCustomer": "Kein Benutzer",
  "customer.noCustomers": "Keine Kunden",
  "customer.noReservations": "Keine Reservierung",
  "customer.onePersonFound": "1 Person gefunden",
  "customer.password": "Passwort",
  "customer.password.mismatch": "Der alte Slogan passt nicht mehr",
  "customer.passwordHasChange": "Das Passwort wurde geändert",
  "customer.permissionForHoldingPersonalData":
    "Zustimmung zur Verarbeitung von personenbezogenen Daten",
  "customer.profile": "Profil",
  "customer.profileDetails": "Profil-Details",
  "customer.profileExistsError": "Profil existiert bereits",
  "customer.registration.code": "Ihr Aktivierungscode lautet: _CODE_",
  "customer.registration.invalid": "Falscher Aktivierungscode",
  "customer.registration.sendByEmail":
    "Problem beim SMS-Versand - der Code wurde an die angegebene E-Mail-Adresse gesendet.",
  "customer.remarks": "Kommentare",
  "customer.remarksToClient": "Hinweise für den Kunden",
  "customer.remarksToReservation": "Buchungshinweise",
  "customer.removeProfileAction": "Profil gelöscht",
  "customer.removeProfileAction.error": "Profil nicht gelöscht",
  "customer.reservationHistory": "Buchungshistorie",
  "customer.reservingCustomerData":
    "Angaben zur Person, die die Buchung vornimmt",
  "customer.saveChanges": "Die Änderungen speichern?",
  "customer.surname": "Name",
  "customer.telephone": "Rufnummer",
  "customer.telephoneNumber": "Rufnummer",
  "customer.updateClient": "Kundendaten aktualisieren?",
  "customer.updateProfileModal": "Profil aktualisieren?",
  "defaultValues.restaurant": "Restaurant",
  "deleteAccountModal.component.button.cancel": "Ankündigung",
  "deleteAccountModal.component.button.confirm": "Genehmigen Sie",
  "deleteAccountModal.component.header": "Konto löschen",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Sind Sie sicher, dass Sie Ihr Konto löschen möchten? Ihr Buchungsverlauf wird ebenfalls gelöscht.",
  "documentsPage.header": "Dokumenty",
  "documentsPage.metadata.description": "Beschreibung der Dokumente",
  "documentsPage.metadata.keywords": "Schlüsselwörter Dokumente",
  "documentsPage.metadata.title": "Dokumenty",
  "documentsPage.subheader": "Einschlägige Informationen",
  "editReservation.send": "Senden Sie",
  "editReservation.sendMessage":
    "Es wurde eine Anfrage zur Änderung der Buchungsparameter gesendet.",
  "email.already.exist": "E-Mail existiert bereits",
  "email.missing": "E-Mail ist erforderlich",
  "error":
    "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.",
  "error.LocationNotFound":
    "Ort nicht gefunden oder zu wenig Zeichen angegeben",
  "error.cannotCreateReservation": "Anfrage konnte nicht gesendet werden",
  "error.connectionWithServerLost":
    "Die Verbindung zum Server wurde unterbrochen.",
  "error.connectionWithServerLostTryLater":
    "Die Verbindung zum Server wurde unterbrochen. Bitte versuchen Sie es später erneut",
  "error.email.already.exist": "E-Mail existiert bereits",
  "error.facebook.account.already.exist":
    "Für diese Nummer wurde bereits ein MojStolik-Konto über Facebook eingerichtet. Verwenden Sie die Facebook-Anmeldung",
  "error.google.account.already.exist":
    "Für diese Nummer wurde bereits ein Konto auf MojStolik über Google eingerichtet. Google-Anmeldung verwenden",
  "error.notFoundAddress": "Ort nicht gefunden oder zu wenig Zeichen angegeben",
  "error.phone.already.exist":
    "Für diese Nummer ist bereits ein Konto auf MojStolik eingerichtet. Loggen Sie sich über Ihre Telefonnummer ein oder nutzen Sie die Passwort-Erinnerungsfunktion.",
  "filters.doFilter": "Filter",
  "filters.fromToday": "Ab heute",
  "filters.toToday": "Bis heute",
  "filters.today": "Heute",
  "footer.Next": "Weiter",
  "footer.Previous": "Vorherige",
  "footer.alertOnBack": "Den Wandel aufgeben?",
  "forGuestPage.header": "Für den Gast",
  "forGuestPage.metadata.description": "Für den Besucher eine Beschreibung von",
  "forGuestPage.metadata.keywords": "Für den Besucher, Schlüsselwörter",
  "forGuestPage.metadata.title": "Für den Gast",
  "forRestaurantsPage.form":
    "Ihre Bewerbung wurde abgeschickt. Wir werden uns in Kürze mit Ihnen in Verbindung setzen.",
  "forRestaurantsPage.header": "Für Restaurants",
  "forRestaurantsPage.metadata.description": "Für Restaurants Beschreibung",
  "forRestaurantsPage.metadata.keywords": "Für Restaurants, Schlüsselwörter",
  "forRestaurantsPage.metadata.title": "Für Restaurants",
  "forRestaurantsPage.subheader":
    "Finden Sie heraus, welche Vorteile das MojStolik-System für Ihr Restaurant bietet.",
  "global.form.message.atLeastNineCharacters":
    "Mindestens neun Zeichen erforderlich",
  "global.form.message.invalidBirthDateFormat":
    "Falsches Format des Geburtsdatums",
  "global.form.message.invalidEmailFormat": "Falsches E-Mail-Adressformat",
  "global.form.message.invalidPhoneFormat": "Falsches Telefonformat",
  "global.form.message.invalidPostCodeFormat": "Falsches Postleitzahlenformat",
  "global.form.message.newPasswordsDoNotMatch":
    "Die neuen Slogans sind anders!",
  "global.form.message.requiredField": "Erforderliches Feld",
  "global.messages.sessionTimeout": "Sitzung abgelaufen",
  "global.time.minutes": "minutiös",
  "group.removeErrorAction": "Die Gruppe wird dem Benutzer zugewiesen",
  "howItWorksPage.header": "Wie funktioniert das?",
  "howItWorksPage.metadata.description": "Beschreibung der Funktionsweise",
  "howItWorksPage.metadata.keywords": "Wie Schlüsselwörter funktionieren",
  "howItWorksPage.metadata.title": "Wie funktioniert das?",
  "howItWorksPage.subheader": "Schritt für Schritt",
  "image.notFound": "Bild nicht gefunden",
  "indexPage.background.header":
    "Finden Sie die besten Restaurants in Ihrer Stadt,",
  "indexPage.background.headerExtend": "und dann einen Tisch reservieren:",
  "indexPage.button.bookNow": "Buchen Sie",
  "indexPage.button.hideAdditionalFilters": "Zusätzliche Filter ausblenden",
  "indexPage.button.readMore": "Lesen Sie",
  "indexPage.button.search": "Suche",
  "indexPage.button.showAdditionalFilters": "Zusätzliche Filter",
  "indexPage.button.use": "Verwenden Sie",
  "indexPage.header.blogs": "Ausgewählte Artikel",
  "indexPage.header.currentPromotions": "Sonderangebote und Veranstaltungen",
  "indexPage.header.currentPromotionsExtend": "an Ihrem Standort",
  "indexPage.header.mostPopular": "Am beliebtesten",
  "indexPage.header.mostPopularExtend": "an Ihrem Standort",
  "indexPage.input.label.date": "Daten",
  "indexPage.input.label.hour": "Stunde",
  "indexPage.input.label.location": "Standort",
  "indexPage.input.label.persons": "Anzahl der Personen",
  "indexPage.input.label.persons.optionExtend": "os.",
  "indexPage.input.label.priceLevel": "Preisniveau",
  "indexPage.input.label.rating": "Bewertung",
  "indexPage.input.label.rating.optionExtend": "Sterne.",
  "indexPage.input.label.restaurantType": "Art der Küche",
  "indexPage.input.label.searchByName": "Suche nach Namen",
  "indexPage.input.noData": "Keine Ergebnisse.",
  "indexPage.input.placeholder.select": "Wählen Sie",
  "indexPage.input.placeholder.type": "Eingabe",
  "indexPage.input.placeholer.typeInOrSelect": "Tippen oder wählen Sie",
  "invitation":
    "Hallo, Sie sind in das Restaurant {{restaurant}} am {{date}} um {{time}} eingeladen. Laden Sie die MojStolik.de App herunter, um mehr über das Restaurant zu erfahren: https://www.mojstolik.pl/pobierz/apka.html",
  "invoice.vatId": "PIN: {{vatId}}",
  "login": "Anmeldung",
  "login.accountDoesNotExist": "Das Konto existiert nicht",
  "login.accountName": "Name des Benutzers",
  "login.doLogin": "Anmeldung",
  "login.forgotPassword": "Haben Sie Ihr Passwort vergessen?",
  "login.forgottenPasswordLink": "Haben Sie Ihr Passwort vergessen?",
  "login.generalTitle": "Protokollierung",
  "login.insertLoginData": "Geben Sie Ihre Anmeldedaten ein",
  "login.insertManagerPassword":
    "Geben Sie die E-Mail-Adresse des Managers ein",
  "login.loggingToSystem": "Anmeldung im System",
  "login.loginOrPasswordWrong": "Login oder Passwort ungültig",
  "login.loginText": "Anmeldung",
  "login.logout": "Wyloguj",
  "login.logout.message": "Sie sind abgemeldet worden",
  "login.newAccount": "Neues Konto",
  "login.newUpdateReady":
    "Eine neue Version der Anwendung ist verfügbar. Update.",
  "login.noAppleAccount":
    "Diesem Apple-Konto ist kein Konto zugeordnet. Versuchen Sie eine andere Anmeldemethode oder registrieren Sie ein neues Konto.",
  "login.noFacebookAccount":
    "Es gibt kein Konto, das mit diesem Facebook-Konto verbunden ist. Versuchen Sie eine andere Anmeldemethode oder registrieren Sie ein neues Konto.",
  "login.noGoogleAccount":
    "Diesem Google-Konto ist kein Konto zugeordnet. Versuchen Sie eine andere Anmeldemethode oder registrieren Sie ein neues Konto.",
  "login.passwordRecovery": "Passwort-Wiederherstellung",
  "login.passwordSent.sms": "Ihr neues Passwort lautet: _PASSWORD_.",
  "login.passwordSent.sms.too.often":
    "Zu häufiger Versuch, das Passwort zu ändern, versuchen Sie es in Kürze erneut",
  "login.passwordSentToEmail":
    "Ein neues Passwort wurde an die angegebene E-Mail-Adresse gesendet",
  "login.phoneNumberOrMail": "Telefonnummer oder E-Mail Adresse",
  "login.recover": "Abgerufen von",
  "login.resetPasswordLabel": "E-Mail Adresse",
  "login.restaurantChanged": "Das Restaurant wurde umgestaltet",
  "login.thisAccountAlreadyExist": "Benutzer existiert bereits",
  "login.updateRequired":
    "Sie müssen Ihre Anwendung aktualisieren. Sie funktioniert möglicherweise nicht richtig",
  "login.userUnauthorized": "Login oder Passwort ungültig",
  "login.userUnauthorized.useApple":
    "Falsches Login oder Passwort. Versuchen Sie, sich über Apple anzumelden oder setzen Sie Ihr Passwort zurück.",
  "login.userUnauthorized.useFacebook":
    "Falsches Login oder Passwort. Versuchen Sie, sich über Facebook anzumelden oder setzen Sie Ihr Passwort zurück.",
  "login.userUnauthorized.useGoogle":
    "Falsches Login oder Passwort. Versuchen Sie, sich über Google anzumelden oder setzen Sie Ihr Passwort zurück.",
  "login.yourPassword": "Ihr Passwort",
  "loginPage.anchor.passwordReset": "Passwort zurücksetzen",
  "loginPage.button.logIn": "Einloggen",
  "loginPage.button.register": "Register",
  "loginPage.columns.header.socialLogin": "Anmelden mit",
  "loginPage.columns.header.standardLogin": "Standard-Anmeldung",
  "loginPage.header": "Protokollierung",
  "loginPage.input.label.password": "Passwort",
  "loginPage.input.label.phoneOrEmail": "Rufnummer",
  "loginPage.message.logged": "Rebloggt.",
  "loginPage.message.loggedViaApple": "Rebloggt von Apple.",
  "loginPage.message.loggedViaFacebook": "Rebloggt über Facebook.",
  "loginPage.message.loggedViaGoogle": "Rebloggt über Google.",
  "loginPage.metadata.description": "Beschreibung der Protokollierung",
  "loginPage.metadata.keywords": "Schlüsselwörter für die Protokollierung",
  "loginPage.metadata.title": "Protokollierung",
  "loginPage.paragraph.alreadyLoggedIn": "Sie sind derzeit eingeloggt.",
  "loginPage.paragraph.dontHaveAccountYet": "Sie haben noch kein Konto?",
  "loginPage.paragraph.registerNow": "Registrieren!",
  "loginPage.subheader":
    "Als eingeloggter Benutzer können Sie schneller Tische buchen und Ihre Buchungshistorie einsehen.",
  "logoutPage.metadata.description": "Beschreibung der Abmeldung",
  "logoutPage.metadata.keywords": "Abmeldeschlüsselwörter",
  "logoutPage.metadata.title": "Abmeldung",
  "mail.confirm.message":
    "Bestätigen Sie Ihre E-Mail-Adresse, indem Sie auf den Link klicken",
  "mail.confirm.title": "Nachricht von MyTable.co.uk",
  "map.searchArea": "Durchsuchen Sie das Gebiet",
  "menu.areaControl": "Zimmerverwaltung",
  "menu.config": "Konfiguration",
  "menu.configPage": "Einstellungen",
  "menu.configuration": "Konfiguration",
  "menu.currentReservations": "Aktuelle Buchungen",
  "menu.customers": "Klienci",
  "menu.homePage": "Hauptseite",
  "menu.invoice": "Rechnungen",
  "menu.login": "Protokollierung",
  "menu.logout": "Wyloguj",
  "menu.myReserv": "Reservierungen",
  "menu.myReservations": "Meine Buchungen",
  "menu.newAccount": "Neues Konto",
  "menu.passwordRecovery": "Passwort-Wiederherstellung",
  "menu.reservationpage": "Neue Buchung",
  "menu.reservations": "Alle Buchungen",
  "menu.resetPassword": "Passwort-Erinnerung",
  "menu.restaurant": "Restaurant",
  "menu.sale": "Werbeaktionen",
  "menu.search": "Suche",
  "myReservation.doRate": "Bewerten Sie",
  "myReservation.doRateQuestion":
    "Möchten Sie diese Buchung mit {{rate}}/5 bewerten?",
  "myReservation.doRateSuccess": "Die Bewertung wurde abgegeben",
  "myReservation.doRateTitle": "Wie würden Sie Ihre Buchung bewerten?",
  "myReservation.doSuccessRemove":
    "Die Reservierung wurde aus der Liste entfernt",
  "myReservation.yourRate": "Ihre Bewertung",
  "navbar.allReserv": "Alle",
  "navbar.areaEdition": "Zimmer-Ausgabe",
  "navbar.cancelChangesReservation": "Eine Umbuchung stornieren?",
  "navbar.cancelReservation": "Die Buchung stornieren?",
  "navbar.changePass": "Passwort ändern",
  "navbar.clientPreview": "Ansicht des Kunden",
  "navbar.companyEdition": "Firmenausgabe",
  "navbar.configuration": "Konfiguration",
  "navbar.currentReserv": "Aktuell",
  "navbar.currentReservations": "Aktuelle Buchungen",
  "navbar.customers": "Klienci",
  "navbar.dataCustomer": "Angaben zur Person, die die Buchung vornimmt",
  "navbar.defaultCancel": "Abbrechen?",
  "navbar.employee": "Mitarbeiter",
  "navbar.historyReserv": "Historisch",
  "navbar.invoiceEdition": "Bearbeiten einer Rechnung",
  "navbar.invoicePreview": "Rechnungsvorschau",
  "navbar.label.editReservation": "Änderung der Reservierung",
  "navbar.label.myProfile": "Mein Profil",
  "navbar.label.myReservation": "Meine Buchungen",
  "navbar.label.reservation": "Buchung",
  "navbar.label.reservationDetails": "Details zur Buchung",
  "navbar.label.sale": "Werbeaktionen",
  "navbar.newReservation": "Neue Buchung",
  "navbar.reservationActualState": "Aktueller Buchungsstatus",
  "navbar.reservationEdit": "Bearbeitung einer Buchung",
  "navbar.reservationPreview": "Buchungsvorschau",
  "navbar.reservations": "Reservierungen",
  "notFoundPage.button.goToHomePage": "Zur Hauptseite gehen",
  "notFoundPage.header": "Fehler 404",
  "notFoundPage.metadata.description":
    "404 - Seite nicht gefunden Beschreibung",
  "notFoundPage.metadata.title": "404 - Seite nicht gefunden!",
  "notFoundPage.subheader": "Gewünschte URL",
  "notFoundPage.subheaderExtend": "wurde auf dem Server nicht gefunden.",
  "notFoundPage.subheaderExtend2":
    "Überprüfen Sie, ob die eingegebene Adresse korrekt ist, oder gehen Sie zu",
  "notFoundPage.subheaderExtend3": "Homepage",
  "notification.body.Canceled":
    "Ihre Reservierung im _RESTAURANT_ am _DAY_ zur _TIME_ wurde storniert.",
  "notification.body.Completed":
    "Vielen Dank für die Bearbeitung Ihrer Reservierung im _RESTAURANT_ am _DAY_ um: _TIME_.",
  "notification.body.Confirmed":
    "Ihre Buchung im _RESTAURANT_ am _DAY_ zur _TIME_ wurde bestätigt.",
  "notification.body.Rejected":
    "Ihre Reservierung im _RESTAURANT_ am _DAY_ um _TIME_ wurde storniert. _MESSAGE_",
  "notification.body.WaitingForApproval":
    "Ihre Reservierung im _RESTAURANT_ am _DAY_ zur _TIME_ muss noch bestätigt werden.",
  "notification.body.WaitingForConfirmation":
    "Ihre Reservierung im _RESTAURANT_ am _DAY_ zur _TIME_ ist noch nicht bestätigt.",
  "notification.invitation.accepted.body":
    "_NAME_ hat Ihre Einladung ins _RESTAURANT_ am _DAY_ um _TIME_ angenommen.",
  "notification.invitation.accepted.title": "Einladung angenommen.",
  "notification.invitation.rejected.body":
    "_NAME_ hat Ihre Einladung ins _RESTAURANT_ am _DAY_ zur _TIME_ abgelehnt.",
  "notification.invitation.rejected.title": "Stornierung der Einladung.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Nachricht von _RESTAURANT_",
  "notification.parameters.updated.body":
    "Ihre Buchung im _RESTAURANT_ am _DAY_ zur _TIME_ wurde aktualisiert.",
  "notification.parameters.updated.title": "Aktualisierung der Buchungen",
  "onboarding.promotion.MFR2023.Advanced.description":
    "Das MFR2023-Angebot umfasst einen kostenlosen Testzeitraum. In den ersten drei Monaten nach Vertragsunterzeichnung fallen keine Abonnementgebühren an, wie in der Beschreibung der Pläne unten angegeben. Die Aktion ist für Neukunden und gilt nur für die Tarife LIGHT und ADVANCED und ist gültig vom 25.04 - 20.05.2023.",
  "onboarding.promotion.MFR2023.AdvancedPro.description":
    "Das Angebot gilt nicht für den ADVANCED PRO-Tarif.",
  "onboarding.promotion.MFR2023.Light.description":
    "Das MFR2023-Angebot umfasst einen kostenlosen Testzeitraum. In den ersten drei Monaten nach Vertragsunterzeichnung fallen keine Abonnementgebühren an, wie in der Beschreibung der Pläne unten angegeben. Die Aktion ist für Neukunden und gilt nur für die Tarife LIGHT und ADVANCED und ist gültig vom 25.04 - 20.05.2023.",
  "onboarding.promotion.MFR2023.description":
    "Das MFR2023-Angebot umfasst einen kostenlosen Testzeitraum. In den ersten drei Monaten nach Vertragsunterzeichnung fallen keine Abonnementgebühren an, wie in der Beschreibung der Pläne unten angegeben. Die Aktion ist für Neukunden und gilt nur für die Tarife LIGHT und ADVANCED und ist gültig vom 25.04 - 20.05.2023.",
  "onboarding.promotion.WAKACJE2023.Advanced.description":
    "Das Angebot gilt nicht für den ADVANCED-Tarif.",
  "onboarding.promotion.WAKACJE2023.AdvancedPro.description":
    "Das Angebot gilt nicht für den ADVANCED PRO-Tarif.",
  "onboarding.promotion.WAKACJE2023.Light.description":
    "HOLIDAY2023 ist ein LIGHT-Angebot für 0 PLN in den Monaten Juni, Juli und August 2023 für Neukunden. Nach diesen Monaten können Sie gerne zu den üblichen Bedingungen weiter mit uns zusammenarbeiten.",
  "onboarding.promotion.WAKACJE2023.description":
    "HOLIDAY2023 ist ein LIGHT-Angebot für 0 PLN in den Monaten Juni, Juli und August 2023 für Neukunden. Nach diesen Monaten können Sie gerne zu den üblichen Bedingungen weiter mit uns zusammenarbeiten.",
  "onboarding.promotion.promo-test.description":
    "Promotion Promo-Test Beschreibung. Vorschriften: _LINK_.",
  "onboarding.promotion.promo-test.terms.link":
    "https://tst-web.mojstolik.pl/pl",
  "password.errors.weak": "Das Passwort ist zu einfach",
  "passwordFormModal.component.button.cancel": "Ankündigung",
  "passwordFormModal.component.button.confirm": "Genehmigen Sie",
  "passwordFormModal.component.header": "Passwort für Ihr Konto eingeben",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Geben Sie Ihr Passwort ein, um die Aktion zu bestätigen.",
  "payment.card": "Karte",
  "payment.cash": "Bargeld",
  "payment.item.description.per.person": "_PRODUCT_NAME_",
  "payment.item.description.per.reservation": "_PRODUCT_NAME_",
  "payment.otherType": "andere",
  "payment.title.generic": "Buchung erfordert Zahlung",
  "payment.title.with.event": "Gebühr für die Veranstaltung: _EVENT_NAME_.",
  "payment.transfer": "Übertragung",
  "payment.type": "Art der Zahlung",
  "preference.category.cuisine": "Art der Küche",
  "preference.category.cuisine.polish": "Polnisch kulinarisch",
  "preference.category.price": "Preis",
  "previewImageModal.component.anchor.close": "Schließen Sie",
  "previewRestaurantModal.component.anchor.close": "Schließen Sie",
  "priceListPage.header": "Die Preisliste",
  "priceListPage.metadata.description": "Beschreibung der Preisliste",
  "priceListPage.metadata.keywords": "Schlüsselwort Preisliste",
  "priceListPage.metadata.title": "Die Preisliste",
  "privacyPolicyPage.header": "Datenschutzbestimmungen",
  "privacyPolicyPage.metadata.title": "Datenschutzbestimmungen",
  "privacyPolicyPage.subheader": "Relevante Informationen.",
  "promotions.emptyList": "Keine Beförderung",
  "registration.activeCode": "Aktivierungscode",
  "registration.addressEmail": "E-Mail Adresse",
  "registration.codeInformation":
    "Ein Aktivierungscode wurde an {{phone}} gesendet. Geben Sie ihn unten ein und bestätigen Sie mit",
  "registration.createAccount": "Konto erstellen",
  "registration.createSuccess": "Das Konto wurde erstellt",
  "registration.differentPasswords": "Unterschiedliche Passwörter",
  "registration.finishButton": "Vervollständigen Sie Ihre Registrierung",
  "registration.formIsInvalid":
    "Die Daten sind nicht korrekt ausgefüllt worden. Bitte benutzen Sie die Hilfe am Ende der Seite.",
  "registration.generalTitle": "Anmeldung",
  "registration.phoneNumber": "Rufnummer",
  "registration.phoneToShort": "Die Nummer ist zu kurz",
  "registration.readAgreement": "Lesen Sie die Vorschriften",
  "registration.repeatPassword": "Passwort wiederholen",
  "registration.requiredFieldsFirstStep":
    "Telefonnummer, E-Mail und Passwort sind obligatorisch.",
  "registration.weakPassword": "Das Passwort ist zu einfach",
  "registrationPage.button.confirm": "Bestätigen Sie",
  "registrationPage.button.register": "Register",
  "registrationPage.button.sendCodeAgain": "Code erneut senden",
  "registrationPage.columns.header.registerStandard": "Standard-Registrierung",
  "registrationPage.columns.header.registerWith": "Anmelden bei",
  "registrationPage.header": "Anmeldung",
  "registrationPage.header.SMSConfirmation": "SMS-Bestätigung",
  "registrationPage.header.statements": "Erklärungen",
  "registrationPage.input.label.agreementEvent":
    " Ich habe die Bedingungen für die Veranstaltung und die Aktion gelesen, die Sie in der",
  "registrationPage.input.label.agreementEvent.extension": "unter diesem Link",
  "registrationPage.input.label.agreementReadWithdrawal":
    "Ich habe die Allgemeinen Geschäftsbedingungen von Mojstolik.de gelesen, insbesondere die Belehrung über das Widerrufsrecht",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Allgemeine Geschäftsbedingungen von mojstolik.pl",
  "registrationPage.input.label.birthDate": "Datum der Geburt",
  "registrationPage.input.label.city": "Stadt",
  "registrationPage.input.label.email": "E-Mail Adresse",
  "registrationPage.input.label.firstName": "Name",
  "registrationPage.input.label.lastName": "Name",
  "registrationPage.input.label.password": "Passwort",
  "registrationPage.input.label.passwordRepeat": "Passwort wiederholen",
  "registrationPage.input.label.phoneNumber": "Rufnummer",
  "registrationPage.input.label.postalCode": "Postleitzahl",
  "registrationPage.input.label.streetAndLocalNumber":
    "Straße und Wohnungsnummer",
  "registrationPage.input.placeholder.birthDate": "z.B. 1976-10-25",
  "registrationPage.input.placeholder.city": "Z.B. Warschau",
  "registrationPage.input.placeholder.code": "Geben Sie den Code ein",
  "registrationPage.input.placeholder.email": "Z.B. adres@email.pl",
  "registrationPage.input.placeholder.firstName": "Z.B. Jan",
  "registrationPage.input.placeholder.lastName": "z.B. Kowalski",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.placeholder.postalCode": "Z.B. 03-123",
  "registrationPage.input.placeholder.streetAndLocalNumber":
    "z. B. Grójecka 130/105",
  "registrationPage.message.accountCreated": "Das Konto wurde erstellt.",
  "registrationPage.message.enterCodeFromSMS":
    "Transkribieren Sie den Code aus der SMS.",
  "registrationPage.message.registrationTimeExpired":
    "Die Registrierungszeit ist abgelaufen",
  "registrationPage.message.sendCodeAgain":
    "Der SMS-Code wurde erneut gesendet.",
  "registrationPage.metadata.description": "Beschreibung der Registrierung",
  "registrationPage.metadata.keywords": "Schlüsselwort Registrierung",
  "registrationPage.metadata.title": "Anmeldung",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Geben Sie den SMS-Code ein, den wir an Ihre Telefonnummer geschickt haben, um Ihre Anmeldung abzuschließen.",
  "registrationPage.paragraph.enterSMSCodeFrom":
    "Geben Sie den SMS-Code des Tages ein",
  "registrationPage.paragraph.optionalFields": "Optionale Felder",
  "registrationPage.paragraph.seconds": "Sekunde(-a)",
  "registrationPage.paragraph.sendCodeAgain":
    "Der SMS-Code wurde erneut gesendet.",
  "registrationPage.paragraph.timeIsOver": "Die Zeit ist abgelaufen.",
  "registrationPage.paragraph.timeLeft": "Verbleibende",
  "registrationPage.subheader":
    "Als eingeloggter Benutzer können Sie schneller Tische buchen und Ihre Buchungshistorie einsehen.",
  "request.blocked":
    "Die Anfrage wurde blockiert, bitte versuchen Sie es später noch einmal",
  "reservation.addNewAction": "Es wurde ein neuer Burst erstellt",
  "reservation.addNote": "Kommentar hinzufügen",
  "reservation.addRemarkQuestion": "Eine Notiz zur Buchung hinzufügen?",
  "reservation.alreadyRated": "Die Buchung wurde bereits bewertet",
  "reservation.basicInformation": "Grundlegende Informationen",
  "reservation.cancelReservation": "Abbrechen",
  "reservation.cancelReservationQuestion":
    "Sind Sie sicher, dass Sie Ihre Buchung stornieren möchten?",
  "reservation.changeReservation": "Ändern Sie",
  "reservation.changeReservationParameters": "Buchungsparameter ändern",
  "reservation.changeStatusAction": "Status geändert in",
  "reservation.chooseStatus": "Status auswählen",
  "reservation.comunications": "Nachrichten",
  "reservation.createReservation": "Erstellen Sie eine Reservierung für",
  "reservation.defaultTime": "Bevorzugte Buchungszeit {{reservationTime}}.",
  "reservation.doReservation": "Buchen Sie",
  "reservation.duration": "Dauer",
  "reservation.durationTime": "Buchungsdauer {{durationTime}}",
  "reservation.editData": "Daten bearbeiten",
  "reservation.editReservation": "Buchung bearbeiten",
  "reservation.errorNewAction": "Neue Buchung kann nicht erstellt werden",
  "reservation.errorNotAvailableYet": "Eine Buchung ist noch nicht möglich.",
  "reservation.errorPast":
    "Reservierungen können nicht in der Vergangenheit erstellt werden",
  "reservation.errorToLate": "Eine Buchung ist nicht mehr möglich.",
  "reservation.filter.fromDate": "Seit",
  "reservation.filter.lastName": "Name",
  "reservation.filter.toDate": "Bis wann",
  "reservation.forHowLong": "Dauer",
  "reservation.generalTitle": "Buchung",
  "reservation.group": "Gruppe",
  "reservation.individual": "Einzelne",
  "reservation.informationOnReservation": "Informationen zur Buchung",
  "reservation.invitation": "Einladen",
  "reservation.labels.client": "Klient",
  "reservation.labels.date": "Termin",
  "reservation.labels.dateTo": "Do",
  "reservation.labels.firstName": "Name",
  "reservation.labels.lastName": "Name",
  "reservation.labels.number": "Nr. rez.",
  "reservation.labels.numberOfPeople": "Anzahl der Besucher",
  "reservation.labels.phone": "Rufnummer",
  "reservation.labels.profil": "Profil",
  "reservation.labels.remarks": "Kommentare",
  "reservation.labels.status": "Status",
  "reservation.labels.tableNumber": "Zimmer - Tisch",
  "reservation.lessSpotThanPeople":
    "Es wurde eine kleinere Anzahl von Standorten ausgewählt. Fortsetzung folgt?",
  "reservation.message": "Nachricht von MyTable.co.uk",
  "reservation.messages": "Schreiben Sie eine Nachricht",
  "reservation.morePeople": "Mehr Menschen:",
  "reservation.newPersonToReservation":
    "Eine neue Person zur Buchung hinzufügen?",
  "reservation.noFreeTables": "Keine freien Tische",
  "reservation.notExist": "Die Reservierung existiert nicht",
  "reservation.numberOfGuests": "Anzahl der Besucher",
  "reservation.payment.pay": "Bezahlen",
  "reservation.payment.status": "Zahlungsstatus",
  "reservation.payment.status.Cancelled": "Abgesagt",
  "reservation.payment.status.Cancelled.text": "Die Zahlung wurde storniert",
  "reservation.payment.status.Completed": "Realisiert",
  "reservation.payment.status.Completed.text": "Geleistete Zahlung",
  "reservation.payment.status.Created": "Erstellt",
  "reservation.payment.status.Created.text": "Zahlung erstellt",
  "reservation.payment.status.Expired": "Abgelaufen",
  "reservation.payment.status.Expired.text": "Zahlung abgelaufen",
  "reservation.payment.status.ProcessingPayment": "Verarbeitet",
  "reservation.payment.status.ProcessingPayment.text": "Zahlung in Bearbeitung",
  "reservation.payment.status.Unknown": "Unbekannter Status",
  "reservation.payment.status.Unknown.text": "Unbekannter Zahlungsstatus",
  "reservation.payment.status.WaitingForPayment": "Warten auf",
  "reservation.payment.status.WaitingForPayment.text": "Zahlung ausstehend",
  "reservation.paymentDetails": "Einzelheiten zur Zahlung",
  "reservation.paymentForReservation": "Zahlung buchen",
  "reservation.paymentRequired": "Reservierungen sind gebührenpflichtig",
  "reservation.payments": "Zahlungen",
  "reservation.personsError":
    "Reservierungsanfrage für 0 Personen kann nicht gesendet werden",
  "reservation.pickDurationTime": "Dauer auswählen",
  "reservation.preferences": "Präferenzen",
  "reservation.prepayment": "Vorauszahlung",
  "reservation.remarks": "Kommentare",
  "reservation.remarksFromRestaurant": "Kommentar zum Restaurant",
  "reservation.remind.message":
    "Der Dienst MojStolik.de erinnert Sie an eine Reservierung im Restaurant _RESTAURANT_ am _DATE_ für _PERSONS_. Um die Reservierung zu stornieren, klicken Sie auf den Link: _LINK_ Der Reservierungsdienst wird vom Dienst MojStolik.pl angeboten",
  "reservation.request.not.yet.available":
    "Reservierungen sind noch nicht möglich.",
  "reservation.request.too.often": "Anfrage gerade gesendet",
  "reservation.reservationDate": "Tag",
  "reservation.spotsChosen": "Ausgewählte Veranstaltungsorte",
  "reservation.status": "Status",
  "reservation.status.Canceled": "Abgesagt",
  "reservation.status.Completed": "Reservierung gemacht",
  "reservation.status.Confirmed": "Reservierung bestätigt",
  "reservation.status.Delayed": "Verspätet",
  "reservation.status.NotCompleted": "Buchung nicht erfüllt",
  "reservation.status.Rejected": "Buchung storniert",
  "reservation.status.WaitingForApproval": "Buchung noch nicht genehmigt",
  "reservation.status.WaitingForConfirmation": "Warten auf",
  "reservation.successRequest": "Eine Anfrage zur Reservierung wurde gesendet",
  "reservation.summaryCost": "Gesamtbetrag",
  "reservation.updateAction": "Die Buchung wurde aktualisiert",
  "reservation.updateReservation": "Ihre Buchung aktualisieren?",
  "reservation.updateReservationButton": "Aktualisieren Sie Ihre Buchung",
  "reservation.whichHour": "Stunde",
  "reservation.wholeRoom": "Der ganze Raum",
  "reservationHistoryPage.button.revoke": "Abbrechen",
  "reservationHistoryPage.header": "Ihre Buchungen",
  "reservationHistoryPage.metadata.description": "Beschreibung Ihrer Buchungen",
  "reservationHistoryPage.metadata.keywords": "Ihre Buchungsschlüsselwörter",
  "reservationHistoryPage.metadata.title": "Ihre Buchungen",
  "reservationHistoryPage.paragraph.additionalDescription":
    "Kommunikation mit dem Restaurant",
  "reservationHistoryPage.paragraph.date": "Daten",
  "reservationHistoryPage.paragraph.duration": "Dauer",
  "reservationHistoryPage.paragraph.hour": "Stunde",
  "reservationHistoryPage.paragraph.persons": "Anzahl der Personen",
  "reservationHistoryPage.paragraph.personsShortcut": "os.",
  "reservationHistoryPage.paragraph.reservationIsEmpty":
    "Die Reservierung existiert nicht",
  "reservationHistoryPage.paragraph.reservationListIsEmpty":
    "Die Buchungsliste ist leer.",
  "reservationHistoryPage.subheader":
    "Hier können Sie Ihre historischen Buchungen einsehen und zukünftige Buchungen verwalten.",
  "reservationMenu.allActive": "Alle aktiven",
  "reservationMenu.fromApp": "Fragen zur Anwendung",
  "reservationMenu.toConfirmation": "Noch zu bestätigen",
  "reservationMenu.today": "Für heute",
  "reservationParams.restaurantName": "Name des Betriebs",
  "reservationParams.yourLocalization": "Ihr Standort",
  "reservations.noReservations": "Keine Reservierung",
  "resetPassoword.generalTitle": "Passwort-Erinnerung",
  "resetPassoword.info":
    "Geben Sie die Telefonnummer ein, unter der Ihr Konto registriert wurde. Das Passwort wird per SMS an diese Nummer gesendet",
  "resetPassoword.sendNewPassword": "Passwort senden",
  "resetPasswordPage.button.confirm": "Bestätigen Sie",
  "resetPasswordPage.header": "Passwort zurücksetzen",
  "resetPasswordPage.input.label.phoneOrEmail": "Rufnummer",
  "resetPasswordPage.metadata.description":
    "Passwort zurücksetzen Beschreibung",
  "resetPasswordPage.metadata.keywords": "Kennwort zurücksetzen",
  "resetPasswordPage.metadata.title": "Passwort zurücksetzen",
  "resetPasswordPage.subheader":
    "Füllen Sie das untenstehende Formular aus und wir senden Ihnen ein neues Passwort für Ihr Konto per SMS",
  "resetPasswordPage.subheader2":
    "Wir werden Ihnen ein neues Passwort an Ihre Telefonnummer schicken",
  "resetPasswordPage.toast.error.passwordChanged":
    "Die angegebene Telefonnummer ist falsch.",
  "resetPasswordPage.toast.passwordChanged":
    "Ein neues Passwort wurde per SMS an die bei der Registrierung angegebene Telefonnummer geschickt.",
  "restaurant.aboutUs": "O in",
  "restaurant.bankAccountNumber": "Bankkontonummer",
  "restaurant.buildingNumber": "Nummer des Gebäudes",
  "restaurant.city": "Stadt",
  "restaurant.closeHour": "Feierabend",
  "restaurant.companyName": "Name des Unternehmens",
  "restaurant.dayFormat": "tt - Tag des Monats",
  "restaurant.defaultReservationLength": "Standard-Buchungslänge (h)",
  "restaurant.emailAddress": "E-Mail",
  "restaurant.emptyList":
    "Es gibt keine Restaurants, die den angegebenen Suchkriterien entsprechen.",
  "restaurant.greaterThenZeroError": "Muss größer als 0 sein",
  "restaurant.insertRestaurant": "Restaurants eingeben",
  "restaurant.legend": "Legende:",
  "restaurant.localNumber": "Lokale Nummer",
  "restaurant.logo": "Logo",
  "restaurant.maximalReservationLength": "Maximale Buchungslänge (h)",
  "restaurant.menu.price": "{{price}} zł",
  "restaurant.minimalPeopleForGroup":
    "Mindestanzahl von Personen pro Gruppenbuchung",
  "restaurant.monthFormat": "MM - Monat",
  "restaurant.navbar.aboutRestaurant": "Restaurant",
  "restaurant.navbar.menu": "Menü",
  "restaurant.navbar.promotion": "Werbeaktionen",
  "restaurant.navigate": "Zum Restaurant navigieren",
  "restaurant.navigationApp": "Anwendung Navigation",
  "restaurant.nipNumber": "NIP",
  "restaurant.openHour": "Öffnungszeit",
  "restaurant.pickDay": "Wählen Sie einen Tag",
  "restaurant.pickHour": "Wählen Sie eine Zeit",
  "restaurant.pickPersons": "Wählen Sie die Anzahl der Personen",
  "restaurant.postalCode": "Postleitzahl",
  "restaurant.price.expensive": "Sehr teuer",
  "restaurant.price.extralow": "Sehr günstig",
  "restaurant.price.low": "Günstige",
  "restaurant.price.medium": "Nicht so teuer",
  "restaurant.productsServices": "Produkte/Dienstleistungen",
  "restaurant.promotion.label": "Werbeaktionen",
  "restaurant.questionUpdateRestaurant":
    "Aktualisieren Sie die Restaurantdaten?",
  "restaurant.rate": "Bewertung",
  "restaurant.restaurant": "Restaurant",
  "restaurant.saveNavigationPick": "Erinnern Sie sich an die Wahl",
  "restaurant.seriesNumberTemplate": "Vorlage für Rechnungsnummerierung",
  "restaurant.serverAddress": "Server-Adresse",
  "restaurant.street": "Straße",
  "restaurant.web.confirm": "Möchten Sie eine Website eröffnen?",
  "restaurant.writeCity": "Stadt",
  "restaurant.yearEndFormat": "uu - Jahresende z.B. 18",
  "restaurant.yearFullFormat": "uuu - ein ganzes Jahr z.B. 2018",
  "restaurantPage.anchor.edit": "bearbeiten",
  "restaurantPage.anchor.login": "Anmeldung",
  "restaurantPage.anchor.register": "registrieren",
  "restaurantPage.anchor.sendCodeAgain": "Code erneut senden",
  "restaurantPage.button.cancel": "Ankündigung",
  "restaurantPage.button.confirm": "Bestätigen Sie",
  "restaurantPage.button.goToHomePage": "Zurück zur Hauptseite",
  "restaurantPage.button.pageWWW": "Website",
  "restaurantPage.button.seeMenu": "Siehe Menü",
  "restaurantPage.button.showLess": "Weniger anzeigen",
  "restaurantPage.button.showMore": "Mehr anzeigen",
  "restaurantPage.header.gallery": "Fotogalerie",
  "restaurantPage.header.promotions": "Sonderangebote und Veranstaltungen",
  "restaurantPage.header.reservationConfirmation": "Buchungsbestätigung",
  "restaurantPage.header.restaurantDescription": "Beschreibung des Restaurants",
  "restaurantPage.header.statements": "Erklärungen",
  "restaurantPage.header.use": "Verwenden Sie",
  "restaurantPage.header.yourPersonalData": "Ihre Daten",
  "restaurantPage.header.yourReservation": "Ihre Buchung",
  "restaurantPage.input.label.agreementEvent":
    " Ich habe die Bedingungen für die Veranstaltung und die Aktion gelesen, die Sie in der",
  "restaurantPage.input.label.agreementEvent.extension": "unter diesem Link",
  "restaurantPage.input.label.agreementMarketing":
    "Ich möchte Werbeaktionen, Sonderangebote, Gewinnspiele oder andere Marketinginhalte von MojStolik, einschließlich maßgeschneiderter Informationen über die Dienstleistungen und Produkte von MojStolik, die in der Kundenanwendung verfügbar sind, über elektronische Kommunikation erhalten. Sie können diese Zustimmung jederzeit widerrufen.",
  "restaurantPage.input.label.agreementMarketingThirdPart":
    " Ich möchte Werbeaktionen, Sonderangebote, Gewinnspiele oder andere Marketing-Inhalte von MojStolik erhalten, einschließlich auf mich zugeschnittener Informationen über die Dienstleistungen und Produkte des Restaurants und der kooperierenden Unternehmen, die auf mojstolik.de angegeben sind, die in der Kundenanwendung verfügbar sind, über elektronische Kommunikation. Sie können diese Einwilligung jederzeit widerrufen.",
  "restaurantPage.input.label.code": "Geben Sie den Code des Tages ein",
  "restaurantPage.input.label.date": "Daten",
  "restaurantPage.input.label.description": "Buchungshinweise",
  "restaurantPage.input.label.email": "E-Mail Adresse",
  "restaurantPage.input.label.firstName": "Name",
  "restaurantPage.input.label.footer":
    "MojStolik nutzt eine Vielzahl elektronischer Kommunikationskanäle und wickelt seine Geschäfte per E-Mail, Telefon, SMS und MMS ab. Der Verwalter Ihrer personenbezogenen Daten ist Table4Rest spółka z ograniczoną odpowiedzialnością mit Sitz in Warschau, 02-656 Warschau, ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Ihre personenbezogenen Daten werden zu dem Zweck verarbeitet, Ihnen über elektronische Kommunikationskanäle Marketinginhalte zukommen zu lassen. Weitere Informationen über die Verarbeitung Ihrer personenbezogenen Daten, einschließlich Ihrer Rechte, finden Sie in der Datenschutzrichtlinie.",
  "restaurantPage.input.label.hour": "Stunde",
  "restaurantPage.input.label.lastName": "Name",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Ich möchte Werbeaktionen, Sonderangebote, Gewinnspiele oder andere Marketing-Inhalte von MojStolik erhalten, einschließlich auf mich zugeschnittener Informationen über die Dienstleistungen und Produkte des Restaurants und der kooperierenden Unternehmen, die auf mojstolik.de angegeben sind, die in der Kundenanwendung verfügbar sind, über elektronische Kommunikation. Sie können diese Einwilligung jederzeit widerrufen.",
  "restaurantPage.input.label.personalData":
    "Ich erkläre, dass ich die Bestimmungen der Allgemeinen Geschäftsbedingungen für die Erbringung von Dienstleistungen durch MojStolik über die Kundenanwendung kenne und akzeptiere.",
  "restaurantPage.input.label.personalData.extension":
    "MojStolik Bedingungen und Konditionen",
  "restaurantPage.input.label.persons": "Anzahl der Personen",
  "restaurantPage.input.label.persons.optionExtend": "os.",
  "restaurantPage.input.label.phoneNumber": "Rufnummer",
  "restaurantPage.input.label.readWithdrawal":
    "Ich habe die Allgemeinen Geschäftsbedingungen von Mojstolik.de gelesen, insbesondere die Belehrung über das Widerrufsrecht",
  "restaurantPage.input.label.readWithdrawal.extension":
    "Allgemeine Geschäftsbedingungen von mojstolik.pl",
  "restaurantPage.input.placeholder.email": "Z.B. adres@email.pl",
  "restaurantPage.input.placeholder.firstName": "Z.B. Jan",
  "restaurantPage.input.placeholder.lastName": "z.B. Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.typeIn": "Eingabe",
  "restaurantPage.messages.codeResent": "Der SMS-Code wurde erneut gesendet.",
  "restaurantPage.paragraph.dataIsIncorrect": "Sind die Daten falsch?",
  "restaurantPage.paragraph.doYouHaveAnAccount":
    "Haben Sie bereits ein Konto oder möchten Sie sich registrieren?",
  "restaurantPage.paragraph.dontWontUsePromotion":
    "Ich möchte keine Werbeaktionen durchführen",
  "restaurantPage.paragraph.noAvailableHours":
    "Für diese Kriterien sind keine Stunden verfügbar.",
  "restaurantPage.paragraph.noAvailableHoursExtension":
    "Wählen Sie ein anderes Datum oder eine andere Anzahl von Personen.",
  "restaurantPage.paragraph.or": " oder",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Gib den Code aus der SMS ein, die wir an deine Telefonnummer gesendet haben, um den Tischreservierungsprozess abzuschließen.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Gib den Code aus der Nachricht ein, die wir an deine E-Mail-Adresse gesendet haben, um den Tischreservierungsprozess abzuschließen.",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Der Code wurde erneut per SMS gesendet. Hast du den Code nicht erhalten?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Autorisieren Sie sich per E-Mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link":
    "Senden Sie den Code an die angegebene E-Mail-Adresse.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent":
    "Der Code wurde an die E-Mail-Adresse gesendet:",
  "restaurantPage.paragraph.tableReserved": "Reservierung akzeptiert",
  "restaurantPage.paragraph.tableReservedDescription":
    "Ihre Buchung für Stunden wurde angenommen.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "auf",
  "restaurantPage.paragraph.tableWaitingForApproval":
    "Buchung noch nicht genehmigt",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription":
    "Ihre Buchung für Stunden.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "auf",
  "restaurantPreview.doReservation": "Buchen Sie",
  "restaurantPromotionInfoModal.button.use": "Verwenden Sie",
  "restaurantPromotionInfoModal.paragraph.promotionDetails":
    "Details zum Angebot",
  "room.firstRoomVisualization":
    "Erstellen Sie zunächst eine Visualisierung des Raumes",
  "searchMapPage.button.list": "Liste",
  "searchMapPage.button.map": "Karte",
  "searchMapPage.header": "Suchergebnisse",
  "searchMapPage.map.button.refresh": "Aktualisieren Sie die Karte",
  "searchMapPage.metadata.description": "Beschreibung der Suchergebnisse",
  "searchMapPage.metadata.keywords": "Suchergebnisse für Schlüsselwörter",
  "searchMapPage.metadata.title": "Suchergebnisse",
  "searchPage.button.list": "Liste",
  "searchPage.button.map": "Karte",
  "searchPage.header": "Suchergebnisse",
  "searchPage.metadata.description": "Beschreibung der Suchergebnisse",
  "searchPage.metadata.keywords": "Suchergebnisse für Schlüsselwörter",
  "searchPage.metadata.title": "Suchergebnisse",
  "searchPage.paragraph.noData":
    "Keine Ergebnisse für die angegebenen Kriterien.",
  "searchResultItem.component.button.book": "Buchen Sie",
  "searchResultItem.component.button.cancel": "Ankündigung",
  "searchResultItem.component.button.see": "Siehe",
  "searchResultItem.component.label.availableHours": "Verfügbare Stunden",
  "shortcut.isClosed": "geschlossen",
  "shortcut.monday": "Mo.",
  "shortcut.tuesday": "Di.",
  "shortcut.wednesday": "Mi.",
  "shortcut.thursday": "Do.",
  "shortcut.friday": "Fr.",
  "shortcut.saturday": "Sa.",
  "shortcut.sunday": "So.",
  "sms.send.problem":
    "Problem beim SMS-Versand, bitte versuchen Sie es über die App",
  "social.appleloginBtn": " Mit Apple anmelden",
  "social.fbloginBtn": "Einloggen",
  "social.googleloginBtn": "Einloggen",
  "startPage.metadata.description":
    "Finden Sie die besten Restaurants in Ihrer Stadt.",
  "startPage.metadata.keywords":
    "Tischreservierung, Restaurant-Suchmaschine, Restaurants",
  "startPage.metadata.title": "MojStolik.pl",
  "status.Canceled": "Abgesagt",
  "status.Confirmed": "Bestätigt",
  "status.Rejected": "Abgelehnt",
  "status.WaitingForConfirmation": "Bestätigung steht noch aus",
  "token.expired": "Code abgelaufen",
  "token.wrong": "Der Code stimmt nicht überein oder ist abgelaufen",
  "user.acceptAgreement":
    "Ich akzeptiere die Nutzungsbedingungen www.mojstolik.pl",
  "user.addNewAction": "Kunde hinzugefügt",
  "user.cityAddress": "Stadt",
  "user.dateOfBirth": "Datum der Geburt",
  "user.editAction": "Kunde aktualisiert",
  "user.email": "E-Mail",
  "user.firstName": "Name",
  "user.lastName": "Name",
  "user.name": "Name",
  "user.phone": "Rufnummer",
  "user.postalCode": "Postleitzahl",
  "user.sessionExpire": "Ihre Sitzung ist abgelaufen",
  "user.streetAddress": "Adres",
  "user.successUpdate": "Die persönlichen Daten wurden aktualisiert",
  "user.surname": "Name",
  "user.updateQuestion": "Möchten Sie Ihre persönlichen Daten aktualisieren?",
  "validation.fieldRequired": "Dieses Feld ist erforderlich",
  "validation.passwordRequired": "Passwort erforderlich",
  "validation.phoneRequired": "Eine Telefonnummer ist erforderlich",
};

export default backendTranslationsDE;
