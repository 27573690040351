const frontendTranslationsFR = {
  "seo.subpage.main.title": "Réservation de table en ligne - MojStolik.pl",
  "seo.subpage.main.description":
    "Réservez une table en ligne dans les restaurants les plus populaires de Pologne. Téléchargez l'application, choisissez l'endroit où vous souhaitez commander une table et réservez. Avec MojStolik.pl, c'est ultra-simple.",
  "seo.subpage.forRestaurant.title": "Plate-forme de réservation de restaurants - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl est une plateforme de gestion des réservations de restaurants. Toutes les réservations en un seul endroit. Une plateforme supplémentaire pour la communication avec les clients du restaurant.",
  "seo.subpage.aboutUs.title":
    "Une application ultra-simple pour ceux qui aiment manger au restaurant.",
  "seo.subpage.aboutUs.description":
    "Des centaines de restaurants parmi les plus populaires et les plus respectés du pays et des milliers d'utilisateurs de la plateforme MojStolik.co.uk. Laissez une table vous attendre et non l'inverse.",
  "seo.subpage.howItWork.title":
    "Téléchargez l'application MojStolik.co.uk sur votre téléphone et réservez des tables.",
  "seo.subpage.howItWork.description":
    "En quelques clics, c'est une table de restaurant qui vous attend. Vous décidez quand, où et avec qui vous voulez déjeuner ou dîner. Téléchargez l'application et réservez vos tables.",
  "seo.subpage.documents.title":
    "Découvrez les avantages que vous tirerez de votre collaboration avec MojStolik.pl",
  "seo.subpage.documents.description":
    "Vous vous demandez si cela en vaut la peine ? Découvrez les points forts des documents de MojStolik.co.uk. Contrats, offres commerciales et avantages de la coopération.",
  "seo.subpage.privacyPolicy.title":
    "MojStolik.pl en tant que responsable du traitement des données à caractère personnel",
  "seo.subpage.privacyPolicy.description":
    "Lire les informations essentielles et en savoir plus sur la plateforme MojStolik.pl",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - découvrez les restaurants de votre région",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.co.uk utilise des cookies. Découvrez avec nous les restaurants les plus appréciés de votre région.",
  "seo.subpage.registration.title":
    "Inscrivez-vous et utilisez l'application dès aujourd'hui. MojStolik.pl",
  "seo.subpage.registration.description":
    "Réserver une table n'a jamais été aussi facile. Paiements pratiques, à n'importe quel moment et pour n'importe quel nombre de personnes. Inscrivez-vous et utilisez MojStolik.pl dès aujourd'hui.",
  "seo.subpage.login.title":
    "Connectez-vous à l'application et choisissez les restaurants de MojStolik.co.uk",
  "seo.subpage.login.description":
    "Notre application vous permet de trouver les restaurants les plus populaires dans toute la Pologne. Téléchargez l'application, connectez-vous et utilisez le système de réservation de tables ultra-simple.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - Restaurant responsable",
  "seo.subpage.covid.restaurant.description":
    "Des solutions pour les restaurants afin d'assurer la pérennité de votre entreprise en ces temps difficiles !",
  "seo.subpage.covid.client.title": "COVID-19 - Visiteurs responsables",
  "seo.subpage.covid.client.description": "Un guide pour les clients responsables des restaurants",
  "covid.restaurant.1.strong": "PLAN B :.",
  "covid.restaurant.1.text":
    "Préparez dès maintenant VOTRE plan d'urgence en cas d'augmentation du nombre de maladies - réfléchissez aux manœuvres et aux alternatives dont vous disposez en cas de réintroduction de divers degrés de restrictions, à la manière dont vous réorganiserez votre travail, aux procédures que vous introduirez, à la manière dont vous maintiendrez le contact avec les hôtes, à la gestion de vos ressources financières et à la planification du travail de votre personnel. Vous vous enrichissez de cette expérience printanière. Ne vous laissez pas surprendre une deuxième fois.",
  "covid.restaurant.2.strong": "La SÉCURITÉ ET L'HYGIÈNE sont désormais la norme, mais... :",
  "covid.restaurant.2.text":
    "Ne laissez pas le sujet de côté. Vérifiez que toutes les procédures de sécurité recommandées pour les clients et le personnel fonctionnent parfaitement : masques sur le visage des serveurs, désinfection des tables après chaque client, menus électroniques en option pour les clients, procédures de lavage et de désinfection des mains, maintien d'une distance suffisante entre les tables et facilitation des paiements sans numéraire. N'hésitez pas à donner des instructions polies aux clients sur la manière de se comporter dans votre restaurant.",
  "covid.restaurant.3.strong": "CONSTRUIRE VOTRE COMMUNAUTÉ :",
  "covid.restaurant.3.text":
    "Nos observations montrent que les restaurants qui ont réussi à créer une communauté autour d'eux et qui, pendant le lockdown, sont restés en contact avec leurs clients via les médias sociaux ou les SMS envoyés depuis l'application MojStolik.pl ont mieux résisté à la nouvelle réalité que ceux qui n'ont pas communiqué de manière adéquate. Assurez-vous donc d'avoir déjà un contact constant, intéressant et fiable avec vos hôtes - occupez-vous des médias sociaux, de votre site web, et réfléchissez à la manière dont vous pourrez communiquer en cas de besoin. Heureusement, le gouvernement a retiré de ses travaux de mai l'obligation pour chaque restaurant de disposer d'une base de données de ses clients, mais en tant que restaurateur responsable, vous devez constituer une telle base de données. Il est très utile d'utiliser la plateforme MojStolik.pl, qui facilite au maximum la collecte et le stockage des données (nom, adresse électronique, téléphone) de vos clients, conformément à la loi RODO. Les données appartiennent au restaurant.",
  "covid.restaurant.4.strong": "UNE RÉSERVATION EST-ELLE NÉCESSAIRE ?",
  "covid.restaurant.4.text":
    "Dans de nombreux pays d'Europe et du monde, il existe des idées selon lesquelles on ne peut aller au restaurant que si l'on a réservé une table à l'avance. Préparez-vous donc à cette éventualité et prenez soin de la technologie.",
  "covid.restaurant.5.strong": "ESSAYEZ DE SENSIBILISER VOS INVITÉS -",
  "covid.restaurant.5.text":
    "Par le biais des médias sociaux, mais aussi d'autres canaux, communiquez les raisons pour lesquelles il est important de respecter les règles de sécurité dans les restaurants et les procédures que vous avez mises en place dans vos locaux pour minimiser le risque d'infection. Faites-leur également comprendre que certains de leurs comportements vous exposent à des pertes que vous ne pouvez pas vous permettre en cette période difficile. Il s'agit principalement des réservations non utilisées, qui vous font perdre du temps et de l'argent. Certaines personnes ne se rendent tout simplement pas compte de l'ampleur du problème pour les restaurateurs. Avec la plateforme Mojstolik.co.uk, le processus d'annulation d'une réservation devient très simple pour les clients et est complètement automatique du côté du restaurant.",
  "covid.restaurant.6.strong": "RECHERCHER DES ÉCONOMIES :",
  "covid.restaurant.6.text":
    "Surtout aujourd'hui, chaque client est un trésor et il ne faut pas le laisser attendre qu'un serveur occupé prenne sa réservation, qui, avec un personnel limité, a beaucoup plus de responsabilités et n'a peut-être pas le temps de courir vers le téléphone. Nos calculs montrent que le système de réservation permet d'économiser plus de 20 heures de travail de serveur ou d'hôte par mois, car environ 50 % des réservations passent automatiquement du téléphone à l'internet et viennent s'ajouter à la charge de travail de votre équipe. Des économies à l'état pur !",
  "covid.restaurant.7.strong": "L'OPTIMISATION DE L'UTILISATION DES SALLES :",
  "covid.restaurant.7.text":
    "Avec la baisse du taux d'occupation des restaurants, l'optimisation de la gestion des réservations et de l'utilisation des tables est cruciale pour l'activité. Consultez les solutions technologiques clés en main qui faciliteront le contrôle des réservations provenant de tous les canaux de communication : site web, application, médias sociaux, téléphone, sans rendez-vous. La plateforme MojStolik.pl est une solution de ce type.",
  "covid.restaurant.8.strong": "MINIMISER L'EFFET DE NON SPECTACLE :",
  "covid.restaurant.8.text":
    "Une réservation qui n'est pas annulée mais dont le client ne se présente pas est le fléau des restaurateurs. À une époque où le taux d'occupation n'est autorisé qu'à 55-75 %, le comportement des clients a de réelles conséquences financières. Il faut donc essayer de minimiser le risque de telles situations. MojStolik.pl envoie aux clients des SMS de rappel concernant leur réservation. Pour annuler, il suffit de cliquer sur le lien envoyé. En utilisant cette solution, vous augmentez la possibilité d'exploiter pleinement le potentiel de votre restaurant et d'éviter les pertes. À Londres, les traiteurs se sont révoltés car ce n'est pas le moment de perdre leur opportunité de gain.",
  "covid.restaurant.9.strong": "MENU SÉCURITÉ :",
  "covid.restaurant.9.text":
    "Dans les restaurants, il est recommandé que les menus soient jetables et jetés chaque fois qu'ils tombent entre les mains d'une personne. De telles restrictions ont été introduites comme une obligation, par exemple, dans certains États américains. Ce n'est ni économique ni écologique. Alors pourquoi ne pas envisager de présenter votre menu sur le smartphone de votre client ? Les utilisateurs de MojStolik.pl disposent déjà d'une telle option. Le serveur a la possibilité de proposer un menu aux clients qui ont téléchargé l'application et se sont donc familiarisés avec elle. Il est également possible d'envoyer un SMS avec un lien avant la visite depuis l'appli et de demander aux invités de se mettre déjà dans l'ambiance de la soirée en choisissant des plats.☺ L'appli peut également être téléchargée à table via un code QR. Une option rapide, moderne et imaginative, pour certains convives. Elle vaut la peine d'être proposée.",
  "covid.restaurant.10.strong": "ADAPTER LA CUISINE :",
  "covid.restaurant.10.text":
    "Le menu doit être relativement court et basé principalement sur des produits de saison, locaux ou tout simplement facilement disponibles. Les autres options constituent un risque, à moins que vous ne vous prépariez à un menu supplémentaire - facilement remplaçable. En cas de problèmes et de nouvelles contraintes, comme les voyages lointains ou les frontières fermées, vous n'aurez aucun problème d'approvisionnement et de produits pour le chef. Il sera beaucoup plus facile de réorganiser la cuisine au jour le jour. Les coûts ne sont pas négligeables non plus.",
  "covid.restaurant.11.strong": "TÉLÉCHARGER DES FOURNISSEURS RESPONSABLES :",
  "covid.restaurant.11.text":
    "Recherchez des partenaires commerciaux. Des entreprises qui vous aideront, vous comprendront et vous proposeront de nouvelles solutions. Recherchez des fournisseurs de produits de haute qualité à proximité de votre entreprise - il est trop risqué de nos jours de compter sur des approvisionnements à longue distance et des produits difficiles à trouver. Vous pouvez découvrir ce que les entreprises font pour leurs restaurants/partenaires sur le site www.fortalks.pl.",
  "covid.restaurant.header": "MANUEL POUR UN RESTAURANT RESPONSABLE",
  "covid.restaurant.title": "MANUEL POUR UN RESTAURANT RESPONSABLE",
  "covid.restaurant.text1":
    "Nous savons tous que les restaurants polonais se battent pour survivre, c'est pourquoi ils appliquent généralement des normes élevées en matière de travail, d'hygiène et de communication avec les clients. Allons plus loin. La plateforme MojStolik.pl et l'équipe FOR Solutions ont mis au point un système et un ensemble de solutions prêtes à l'emploi en cas de nouvelles restrictions et d'une éventuelle deuxième vague de pandémie. L'objectif de ce guide est d'aider les restaurants à survivre et à réduire autant que possible le risque d'infection par le coronavirus.",
  "covid.restaurant.text2":
    "La première vague de coronavirus et le confinement qui a suivi ont été un véritable choc pour le marché de la restauration. Il a fallu un certain temps pour que tout le monde s'en remette et passe à une nouvelle réalité. Aujourd'hui, nous sommes habitués à la situation, plus riches en connaissances et en expérience sur la manière de gérer les situations de crise. Cependant, dans leur propre intérêt et dans celui de leurs clients, les restaurants doivent faire tout ce qui est en leur pouvoir pour empêcher la propagation du virus. Nous avons fait le point sur la manière de préparer votre restaurant face à un nombre croissant d'infections :",
  "covid.restaurant.welcome1": "Drogi Restauratorze",
  "covid.restaurant.welcome2":
    "Voici 11 solutions pour aider votre entreprise à poursuivre ses activités en ces temps difficiles !",
  "covid.restaurant.summary":
    "Nous espérons que ce recueil de conseils vous aidera à adapter votre restaurant à la nouvelle réalité et à vous préparer à d'éventuelles difficultés. Si vous avez des questions supplémentaires, nous sommes à votre disposition. Si vous souhaitez obtenir plus d'informations sur notre collaboration, veuillez remplir le formulaire. Nous vous rappellerons ou vous écrirons.",
  "covid.restaurant.greetings": "Nous voulons aider, c'est pourquoi nous unissons nos forces !",
  "covid.restaurant.team": "L'équipe de MojStolik.co.uk et FOR Solutions.",
  "covid.client.page.title": "GUIDE DU VISITEUR RESPONSABLE",
  "covid.client.dear.guest": "Chère invitée",
  "covid.client.description":
    " Aucun restaurant ne peut exister sans vous. Tout dépend de vous. Si vous ne soutenez pas le secteur de la restauration, il disparaîtra des rues de nos villes. Et avouez-le, un monde sans bars ni restaurants serait terriblement triste et vide, car les réunions autour d'une table unissent, divertissent et développent notre vie sociale.",
  "covid.client.description2":
    "Nous lançons donc un appel : soutenez vos restaurants préférés, visitez-les, mais faites-le avec votre tête. À l'ère des coronavirus, notre responsabilité sociale collective est extrêmement importante. Prenez également note de certains de vos comportements qui causent un réel préjudice aux restaurants. Nous en donnons des exemples pour aider les restaurants à fonctionner plus efficacement. Enfin, prenez connaissance de toutes les règles de sécurité afin de contribuer au maintien des normes élevées de travail, d'hygiène et d'organisation des restaurants. Ils s'en occupent ! Prenez soin de VOUS aussi !",
  "covid.client.description3":
    "Alors, comment profiter de façon responsable du temps passé dans vos restaurants préférés ? MojStolik.co.uk et FOR Solutions ont préparé quelques conseils pour vous :",
  "covid.client.summary":
    "En suivant ces règles simples, vous assurerez votre sécurité et celle de l'équipe de votre restaurant préféré. En étant présent et en dégustant leurs délicieux plats, vous les soutenez de manière réaliste et les aidez à traverser une période difficile. L'image de nos villes sans restaurants serait tragique....",
  "covid.client.1.strong": "LES PRINCIPES D'HYGIÈNE DE BASE -",
  "covid.client.1.text":
    "Par exemple, lorsque vous entrez dans l'établissement, mettez votre masque et retirez-le, seulement lorsque vous vous asseyez à une table, désinfectez-vous les mains avant d'entrer (les restaurants sont tenus d'avoir un distributeur de liquide à l'entrée). Lorsque vous allez aux toilettes, couvrez à nouveau votre bouche et votre nez avec un masque. Ces points sont essentiels pour réduire le risque de contracter le COVID-19.",
  "covid.client.2.strong": "DISTANCE SOCIALE -",
  "covid.client.2.text":
    "Nous savons qu'il est difficile de garder la bonne distance et nous nous oublions souvent, mais des règles simples suffisent : ne pas gêner l'équipe et ne pas changer la position des tables sans l'autorisation du serveur ou du gérant. Elles ont été mises en place pour assurer votre sécurité.",
  "covid.client.3.strong": "CHOIX DE LA TABLE -",
  "covid.client.3.text":
    "lorsque vous entrez dans un restaurant (en portant un masque !), attendez que le serveur vous indique la bonne table. Cela lui facilitera la tâche et vous permettra de faire asseoir vos invités en toute sécurité. Si vous pouvez choisir vous-même votre place, ne vous asseyez qu'aux tables marquées comme désinfectées.",
  "covid.client.4.strong": "RÉSERVATION -",
  "covid.client.4.text":
    "si vous savez que vous allez au restaurant ce soir, par exemple, réservez une table à l'avance. Il est ainsi plus facile pour le personnel du restaurant de planifier l'installation de vos invités, mais aussi de planifier l'approvisionnement et le travail du personnel. En outre, cela réduit autant que possible votre temps de contact avec le personnel, ce qui est bénéfique à la fois pour vous et pour lui. L'application MojStolik.co.uk vous permet de réserver facilement et rapidement une table dans le restaurant de votre choix. Pourquoi attendre une table quand elle peut vous attendre ?",
  "covid.client.5.strong": "ANNULEZ VOTRE RÉSERVATION SI VOUS NE POUVEZ PAS VENIR !",
  "covid.client.5.text":
    "Ne pas se présenter dans un restaurant alors que l'on a réservé n'est pas seulement impoli, c'est le moins que l'on puisse dire, mais expose le restaurateur à des pertes réelles. Il ne peut attribuer votre table à personne car, après tout, elle est réservée. Il a tenu compte de vous et des autres convives lors de l'approvisionnement. Une telle situation représente pour lui une véritable perte financière, d'autant plus importante que le taux d'occupation du restaurant est de toute façon réduit. La plateforme MojStolik.pl vous facilitera également la tâche : non seulement elle vous rappellera par SMS à quelle heure et à quel endroit le chef vous attend, mais elle vous permettra également d'annuler votre réservation le plus facilement possible si nécessaire. Il suffit d'un clic !",
  "covid.client.6.strong": "MENU SANS CONTACT -",
  "covid.client.6.text":
    "si vous disposez d'une application de réservation de table sur votre smartphone, elle vous permettra également de consulter le menu sans avoir à prendre celui du restaurant en main. Si vous n'avez pas l'application, téléchargez-la à l'aide des codes QR spéciaux que vous avez mis à disposition aux tables.",
  "covid.client.7.strong": "PAYER PAR CONTACTLESS -",
  "covid.client.7.text":
    "éviter le contact direct et les paiements en espèces. Payez par carte de crédit, blister ou téléphone.",
  "covid.client.8.strong": "NE PAS CRITIQUER -",
  "covid.client.8.text":
    "il s'agit toujours d'une période très difficile pour un secteur qui lutte pratiquement chaque jour pour sa survie. Abstenez-vous donc de critiquer en public et concentrez-vous plutôt sur les émotions positives de la fête. Nous avons tous besoin d'émotions positives en ce moment, en particulier dans les restaurants qui redoublent d'efforts pour survivre.",
  "covid.client.9.strong": "SOUTIEN -.",
  "covid.client.9.text":
    "si vous avez été satisfait de votre visite, n'oubliez pas d'en parler sur les médias sociaux, de faire des INSTA STORIES et de vous amuser à vanter les mérites des plats et à attirer d'autres clients dans votre restaurant préféré !",
  "forRestaurantsPage.global.perMonth": "PLN/mois",
  "forRestaurantsPage.global.perMonth.short": "zł/mies.",
  "forRestaurantsPage.global.button.choose": "CHOISIR",
  "forRestaurantsPage.global.button.next": "SUIVANT",
  "forRestaurantsPage.global.button.back": "RETOUR",
  "forRestaurantsPage.global.button.accept": "ACCEPTER",
  "forRestaurantsPage.global.button.discover": "VOIR LES PLANS DISPONIBLES",
  "forRestaurantsPage.global.button.send": "ENVOYER",
  "forRestaurantsPage.global.button.learnMore": "En savoir plus",
  "forRestaurantsPage.apiError": "Problèmes techniques. Veuillez réessayer plus tard.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.CO.UK EST PLUS QU'UN SYSTÈME",
  "forRestaurantsPage.1.subsection.1.header": "PORTAIL ET APPLICATION VISITEURS",
  "forRestaurantsPage.1.subsection.1.text":
    "Une plateforme et une application simples et pratiques qui vous permettent de réserver instantanément une table dans votre restaurant.",
  "forRestaurantsPage.1.subsection.2.header": "SYSTÈME TÉLÉPHONIQUE (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "Le système téléphonique (IVR) prend les réservations au lieu de permettre à votre équipe de se consacrer au service de vos clients dans le restaurant.",
  "forRestaurantsPage.1.subsection.3.header": "PORTAIL ET APPLICATION POUR LES RESTAURANTS",
  "forRestaurantsPage.1.subsection.3.text":
    "Gérer les réservations et les tables automatiquement ou manuellement à partir de l'application tablette ou du portail du restaurant.",
  "forRestaurantsPage.2.header": "GÉRER À PARTIR DE L'APPLICATION OU DU NAVIGATEUR",
  "forRestaurantsPage.2.text":
    "Profitez des fonctionnalités avancées de l'application pour tablette et de l'interface navigateur. Oubliez le carnet de réservation et optez pour une solution unique pour chaque type de restaurant.",
  "forRestaurantsPage.3.header": "OUBLIER LE NO-SHOW",
  "forRestaurantsPage.3.text":
    "Avec le système MOJSTOLIK.PL, vous minimisez le risque de tables vides où les invités devraient être assis grâce à :",
  "forRestaurantsPage.3.listItem.1": "SMS de confirmation et de rappel des réservations",
  "forRestaurantsPage.3.listItem.2": "Des possibilités d'annulation rapides et faciles",
  "forRestaurantsPage.3.listItem.3": "Analyse de l'activité historique des visiteurs",
  "forRestaurantsPage.4.header": "DIRIGER EFFICACEMENT LE TRAFIC VERS LE RESTAURANT",
  "forRestaurantsPage.5.header": "QUE DISENT LES RESTAURATEURS DE NOUS ?",
  "forRestaurantsPage.5.opinion.1.title":
    "Mariusz Diakowski, propriétaire du restaurant Old House :",
  "forRestaurantsPage.5.opinion.1.text":
    "Travailler avec MojStolik.co.uk a été une véritable révolution pour Stary Dom. Ni moi ni l'équipe du restaurant ne pouvons imaginer revenir à l'époque du \"livre et du crayon\". En fait, MojStolik.pl a testé sa plateforme dans notre restaurant. Nous avions quelques doutes, mais nous avons décidé de prendre le risque. Nous ne le regrettons absolument pas ! Toutes nos remarques et suggestions ont été mises en œuvre immédiatement. Grâce aux tests effectués sur des organismes vivants, ce système répond réellement aux besoins des restaurants à forte fréquentation, facilite considérablement le travail et raccourcit le temps de travail. Finis les stylos, les crayons, les correcteurs qui collent les pages, l'écriture illisible et les registres peu pratiques. Tout est désormais organisé et facile à utiliser. J'ajouterai que la plate-forme est pratiquement sans faille et que le service d'assistance fonctionne parfaitement en cas de besoin.",
  "forRestaurantsPage.5.opinion.2.title":
    "Marek Katrycz, directeur des opérations au STIXX Bar&Grill :",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl est un système très moderne et innovant, grâce auquel nous, en tant que grand restaurant, pouvons gérer efficacement les réservations de nos clients et l'attribution des places, ce qui simplifie grandement le travail de notre équipe. Nous recommandons vivement ce système, nous améliorons constamment ses fonctionnalités, il est intuitif et très facile à utiliser, il a réduit le temps qu'il nous faut pour prendre les réservations et les trouver, ce qui est particulièrement utile en période de forte affluence.",
  "forRestaurantsPage.5.opinion.3.title": "Robert Goluba, directeur de la marque Bombaj Masala :",
  "forRestaurantsPage.5.opinion.3.text":
    "Pourquoi avons-nous choisi d'utiliser le système MojStolik.co.uk dans nos restaurants ? Nous avons eu de bons échos dans le secteur et nous avons adopté une approche vraiment sympathique et professionnelle à l'égard d'un nouveau client. Le système est très intuitif, mais il a fallu beaucoup de temps pour former le personnel jusqu'à ce que chaque employé du restaurant sache comment utiliser le panneau. Le premier jour, avec un grand nombre de clients et de réservations dans notre restaurant, a suffi à nous convaincre des avantages des solutions du système MojStolik.pl. Je suis certain qu'il nous a permis (et continue de le faire) d'accueillir les clients plus facilement et, par conséquent, de servir plus de clients. Cela se traduit naturellement dans les résultats. Si, après quelques semaines, quelqu'un m'avait ordonné de reprendre le travail avec un carnet de réservation traditionnel, je me serais senti comme... au XXe siècle.",
  "forRestaurantsPage.6.header": "CONNAÎTRE LES PROJETS DE MOJSTOLIK.PL",
  "forRestaurantsPage.6.subheader":
    "Choisissez un plan pour votre restaurant et commencez à travailler avec nous.",
  "forRestaurantsPage.discountCodeInput": "Saisir le code promotionnel",
  "forRestaurantsPage.discountCodeButton": "Utilisation",
  "forRestaurantsPage.discountCodeLoading": "Chargement en cours...",
  "forRestaurantsPage.discountCodeSuccess": "Code promo chargé",
  "error.promotion.not.exists": "Le code promotionnel spécifié n'existe pas",
  "forRestaurantsPage.allOffers": "Pour une comparaison détaillée des plans MOJSTOLIK.PL",
  "forRestaurantsPage.allOffers.orange": "Cliquez ici",
  "forRestaurantsPage.form.title":
    "Si vous êtes indécis et que vous souhaitez en savoir plus sur la plateforme MojStolik.pl, veuillez remplir ce court formulaire et nous vous contacterons :",
  "forRestaurantsPage.offers.offerItem.1.header": "LIGHT",
  "forRestaurantsPage.offers.offerItem.1.text":
    "Pour les restaurants qui apprécient la simplicité de l'automatisation des processus d'encaissement des réservations en temps réel.",
  "forRestaurantsPage.offers.offerItem.2.header": "AVANCÉ",
  "forRestaurantsPage.offers.offerItem.2.text":
    "Pour les restaurants qui souhaitent gérer les salles et les tables en plus des réservations elles-mêmes.",
  "forRestaurantsPage.offers.offerItem.3.header": "AVANCÉ",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "Pour les grands restaurants, où l'automatisation complète de tous les canaux de communication avec les clients est essentielle.",
  "forRestaurantsPage.offers.subscription": "Abonament",
  "forRestaurantsPage.offers.commission": "La Commission",
  "forRestaurantsPage.offers.commission.currency": "zł",
  "forRestaurantsPage.offers.header": "Options supplémentaires",
  "forRestaurantsPage.offers.additional-options.prepayments":
    "Module de prépaiement pour les réservations",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Service de réservation téléphonique automatique (IVR) avec forfait supplémentaire de 300 SMS/mois",
  "forRestaurantsPage.offers.additional-options.smsPackage":
    "Pack SMS supplémentaire - 1000 pcs/mois",
  "forRestaurantsPage.offers.mostPopular": "Le choix le plus courant",
  "forRestaurantsPage.offers.pricingSetting1":
    "Les prix ci-dessus s'appliquent à une période de facturation annuelle. Passez à la facturation mensuelle :",
  "forRestaurantsPage.offers.pricingSetting2":
    "Les prix ci-dessus s'appliquent à une période de facturation mensuelle. Passez à la facturation annuelle :",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1 Tous les prix indiqués dans l'offre sont des prix nets en PLN et la TVA légale sera ajoutée au taux applicable le jour de la facturation.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2 Dans le cas du modèle de paiement annuel, la redevance mensuelle est présentée, tandis que le paiement est unique pour 12 mois à l'avance.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3 La réduction marketing est accordée pour un montant de 50 PLN net de la redevance mensuelle, à condition que le restaurant partage le lien de réservation via MOJSTOLIK.PL sur les médias sociaux du restaurant au moins 2 fois par mois.",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4 Le coût d'un SMS hors abonnement est de 0,09 PLN net.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5 Lors de l'utilisation du module de prépaiement, une commission de 1,59% du montant de la transaction est prélevée par l'opérateur de paiement sur chaque transaction.",
  "forRestaurantsPageAllOffers.header": "Explorer les plans de MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Choisissez un plan pour votre restaurant et commencez à travailler avec nous.",
  "forRestaurantsPageAllOffers.offer.1": "LIGHT",
  "forRestaurantsPageAllOffers.offer.2": "AVANCÉ",
  "forRestaurantsPageAllOffers.offer.3": "AVANCÉ",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Canaux de réservation disponibles pour le client",
  "forRestaurantsPageAllOffers.listHeader.2": "Gestion des réservations de restaurants",
  "forRestaurantsPageAllOffers.listHeader.3": "Configuration du profil du restaurant",
  "forRestaurantsPageAllOffers.listHeader.4": "Accessibilité des restaurants aux installations",
  "forRestaurantsPageAllOffers.listHeader.5": "Services complémentaires",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1":
    "Réservations à partir de www.mojstolik.pl",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Réservations à partir de l'application mobile dédiée MojStolik",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3": "Réservations sur le site du restaurant",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Réservations avec Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Réservations à partir de Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Confirmations/rappels de réservation par SMS/notification d'application**",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
    "Traitement automatique des réservations par téléphone",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8":
    "Réservations sur l'Instagram du restaurant",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Réservations à partir d'une boîte aux lettres électronique (autorépondeur)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Liens / codes QR dans les articles et documents de relations publiques des restaurants",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Liste des réservations - vue tabulaire",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Réservations sur le plan du hall",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Vue de Gantt (graphique de réservation)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4":
    "Détails de la réservation et de l'édition",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Gestion avancée des réservations",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Rapport quotidien de réservation",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7":
    "Traitement des paiements anticipés pour les réservations",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Service sans rendez-vous",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Historique des réservations / analyse des préférences et du comportement des clients",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": "Minimiser l'effet no-show",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Fonctionnement en direct",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "Base de données des clients des restaurants / conformité RODO",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "SÉCURITÉ DES DONNÉES / Système crypté et différents niveaux d'accès en fonction des rôles",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Carte de visite et profil individuel du restaurant sur le portail client et l'application mobile.",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Données descriptives sur les restaurants",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Matériel graphique",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Gestion des heures de disponibilité",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Pauses et journées spéciales",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6":
    "Paramétrage de l'acceptation des réservations",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Configuration de la salle et des tables",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Portail de gestion pour les restaurants",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Application restaurant pour tablette",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1":
    "Service d'assistance et soutien technique",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
    "Formation à la mise en œuvre pour le personnel des restaurants",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Présence / promotion des restaurants sur les réseaux de médias sociaux MojStolik.pl",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Nombre de SMS disponibles dans l'abonnement",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/mc",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/mc",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/mc",
  "forRestaurantsPageAllOffers.listItem.optional": "Option",
  "forRestaurantsPageForm.downloadButton": "TÉLÉCHARGER LES DONNÉES",
  "forRestaurantsPageForm.header": "C'est ici que vous commencez à travailler avec mojstolik.co.uk",
  "forRestaurantPageForm.apiError":
    "Erreur de téléchargement des données de l'offre. Veuillez réessayer plus tard et nous vous prions de nous excuser pour ce désagrément.",
  "forRestaurantPageForm.paramsError":
    "Désolé, les détails de l'offre n'ont pas pu être affichés. Pour consulter nos offres, rendez-vous sur",
  "forRestaurantPageForm.paramsError.link": "pages d'offres",
  "forRestaurantsPageForm.chosenPlan": "Plan sélectionné MOJSTOLIK.PL",
  "forRestaurantsPageForm.discountCode": "Promotion :",
  "forRestaurantsPageForm.payment.yearly": "Le plan choisi est facturé sur une base annuelle.",
  "forRestaurantsPageForm.payment.monthly": "Le plan choisi est facturé sur une base mensuelle.",
  "forRestaurantsPageForm.additionalOptions": "Options supplémentaires sélectionnées :",
  "forRestaurantsPageForm.formHeader.1": "Fournir des informations de base sur le restaurant",
  "forRestaurantsPageForm.formHeader.2": "Saisir les coordonnées de la personne de contact",
  "forRestaurantsPageForm.formHeader.3": "Fournir les détails de l'accord",
  "forRestaurantsPageForm.formHeader.4":
    "Indiquer les coordonnées de la personne représentant l'entité",
  "forRestaurantsPageForm.formHeader.5": "MOJSTOLIK.PL Accord",
  "forRestaurantsPageForm.restaurantName": "Nom du restaurant",
  "forRestaurantsPageForm.street": "Rue",
  "forRestaurantsPageForm.apartment": "Locale",
  "forRestaurantsPageForm.postalCode": "Code postal",
  "forRestaurantsPageForm.city": "Ville",
  "forRestaurantsPageForm.email": "Adresse électronique",
  "forRestaurantsPageForm.phoneNumber": "Numéro de téléphone",
  "forRestaurantsPageForm.website": "Adresse du site web",
  "forRestaurantsPageForm.website.menu": "Adresse du site web avec le menu du restaurant",
  "forRestaurantsPageForm.name": "Nom",
  "forRestaurantsPageForm.surname": "Nom",
  "forRestaurantsPageForm.title": "Titre",
  "forRestaurantsPageForm.entity": "Nom de l'entité",
  "forRestaurantsPageForm.email.invoice": "Adresse e-mail pour la facture",
  "forRestaurantsPageForm.email.contact": "Adresse électronique du contact",
  "forRestaurantsPageForm.proxy": "Type de procuration",
  "forRestaurantsPageForm.select.owner": "Propriétaire",
  "forRestaurantsPageForm.select.manager": "Gestionnaire",
  "forRestaurantsPageForm.select.boardMember": "Membre du conseil d'administration",
  "forRestaurantsPageForm.select.other": "Autres",
  "forRestaurantsPageForm.contract": "Contenu de l'accord",
  "forRestaurantsPageForm.pdfLink": "Télécharger le pdf",
  "forRestaurantsPageForm.statement.1":
    "Je consens au traitement de mes données personnelles par Table4Rest sp. z o.o. dans le but de conclure un contrat de prestation de services pour la fourniture et l'utilisation de l'application MojStolik.pl.",
  "forRestaurantsPageForm.statement.2":
    "J'accepte les termes et conditions de l'accord ci-dessus et les règles et règlements de MOJSTOLIK.PL et déclare que je suis autorisé à conclure un contrat de prestation de services pour la fourniture et l'utilisation de l'application MojStolik.pl au nom du restaurant.",
  "forRestaurantsPageForm.loading": "Votre formulaire est envoyé...",
  "forRestaurantsPageForm.success": "Soumission du formulaire réussie",
  "forRestaurantsPageForm.nipError": "Indiquer le numéro de TVA correct",
  "forRestaurantsPageForm.loadingData": "Téléchargement des données en cours...",
  "forRestaurantsPageForm.error": "Une erreur s'est produite.",
  "forRestaurantsPageForm.dataSuccess": "Les données ont été téléchargées correctement",
  "forRestaurantsPageForm.generatingContract": "Génération de contrats en cours...",
  "forRestaurantsPageSuccess.header": "Merci d'avoir choisi Mojstolik.pl",
  "forRestaurantsPageSuccess.text.1":
    "DANS UN BREF DÉLAI, UN DOCUMENT CONTRACTUEL SERA ENVOYÉ À L'ADRESSE ÉLECTRONIQUE QUE VOUS AVEZ ACCEPTÉE À L'ÉTAPE PRÉCÉDENTE.",
  "forRestaurantsPageSuccess.text.2":
    "Nous préparerons également et vous fournirons les instructions nécessaires pour vous permettre de configurer votre restaurant dans le système et de commencer à travailler ensemble.",
  "forRestaurantsPageSuccess.text.3": "à bientôt !",
  "forRestaurantsPageSuccess.button": "Retour à la page d'accueil",
  "global.messages.sessionTimeout": "Session expirée",
  "global.form.message.requiredField": "Ce champ est obligatoire",
  "global.form.message.vatIDNumber": "Le NIF doit contenir 10 chiffres",
  "global.form.message.invalidEmailFormat": "Format d'adresse électronique incorrect",
  "global.form.message.invalidPhoneFormat": "Format incorrect du numéro de téléphone",
  "global.form.message.invalidBirthDateFormat": "Format incorrect de la date de naissance",
  "global.form.message.invalidPostCodeFormat": "Format de code postal incorrect. Attendu XX-XXX",
  "global.form.message.invalidNipFormat": "Format incorrect du numéro de TVA",
  "global.form.message.invalidKrsFormat": "Format incorrect du numéro KRS",
  "global.form.message.invalidRegonFormat": "Format incorrect du numéro REGON",
  "global.form.message.invalidUrlFormat": "Format d'adresse web incorrect",
  "global.form.message.newPasswordsDoNotMatch": "Les nouveaux slogans sont différents !",
  "global.form.message.atLeastNineCharacters": "Au moins neuf caractères requis",
  "global.time.minutes": "minut",
  "aboutPage.metadata.title": "O en",
  "aboutPage.metadata.description": "Les options",
  "aboutPage.metadata.keywords": "à propos de nous mots clés",
  "accountPage.metadata.title": "Votre compte",
  "accountPage.metadata.description": "Description de votre compte",
  "accountPage.metadata.keywords": "Les mots-clés de votre compte",
  "contactPage.metadata.title": "Contact",
  "contactPage.metadata.description": "Contacter opis",
  "contactPage.metadata.keywords": "Mots-clés du contact",
  "documentsPage.metadata.title": "Dokumenty",
  "documentsPage.metadata.description": "Description des documents",
  "documentsPage.metadata.keywords": "Documents clés",
  "forGuestPage.metadata.title": "Pour l'invité",
  "forGuestPage.metadata.description": "Pour le visiteur, une description de",
  "forGuestPage.metadata.keywords": "Pour le visiteur, les mots-clés",
  "howItWorksPage.metadata.title": "Comment cela fonctionne-t-il ?",
  "howItWorksPage.metadata.description": "Description du fonctionnement",
  "howItWorksPage.metadata.keywords": "Comment fonctionnent les mots-clés",
  "loginPage.metadata.title": "Enregistrement",
  "loginPage.metadata.description": "Description de l'enregistrement",
  "loginPage.metadata.keywords": "Mots clés de l'enregistrement",
  "resetPasswordPage.metadata.title": "Réinitialiser le mot de passe",
  "resetPasswordPage.metadata.description": "Description de la réinitialisation du mot de passe",
  "resetPasswordPage.metadata.keywords": "Réinitialiser le mot de passe du mot-clé",
  "logoutPage.metadata.title": "Déconnexion",
  "logoutPage.metadata.description": "Description de la déconnexion",
  "logoutPage.metadata.keywords": "Déconnexion des mots-clés",
  "notFoundPage.metadata.title": "404 - Page non trouvée !",
  "notFoundPage.metadata.description": "404 - Description de la page non trouvée",
  "priceListPage.metadata.title": "Liste de prix",
  "priceListPage.metadata.description": "Description de la liste de prix",
  "priceListPage.metadata.keywords": "Liste de prix des mots-clés",
  "registrationPage.metadata.title": "Inscription",
  "registrationPage.metadata.description": "Description de l'enregistrement",
  "registrationPage.metadata.keywords": "Enregistrement des mots-clés",
  "reservationHistoryPage.metadata.title": "Vos réservations",
  "reservationHistoryPage.metadata.description": "Description de votre réservation",
  "reservationHistoryPage.metadata.keywords": "Vos mots-clés de réservation",
  "searchMapPage.metadata.title": "Résultats de la recherche",
  "searchMapPage.metadata.description": "Description des résultats de la recherche",
  "searchMapPage.metadata.keywords": "Résultats de la recherche par mot-clé",
  "searchPage.metadata.title": "Résultats de la recherche",
  "searchPage.metadata.description": "Description des résultats de la recherche",
  "searchPage.metadata.keywords": "Résultats de la recherche par mot-clé",
  "privacyPolicyPage.metadata.title": "Politique de confidentialité",
  "cookiesPolicyPage.metadata.title": "Politique en matière de cookies",
  "shortcut.isClosed": "fermé",
  "shortcut.monday": "Pon.",
  "shortcut.tuesday": "Poids",
  "shortcut.wednesday": "Śr.",
  "shortcut.thursday": "Czw.",
  "shortcut.friday": "Pt.",
  "shortcut.saturday": "Sob.",
  "shortcut.sunday": "Soleil.",
  "defaultValues.restaurant": "Restaurant",
  "restaurantPromotionInfoModal.button.use": "Utilisation",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Détails de l'offre",
  "previewImageModal.component.anchor.close": "Fermer",
  "previewRestaurantModal.component.anchor.close": "Fermer",
  "deleteAccountModal.component.header": "Supprimer le compte",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Êtes-vous sûr de vouloir supprimer votre compte ? L'historique de vos réservations sera également supprimé.",
  "deleteAccountModal.component.button.confirm": "Approuver",
  "deleteAccountModal.component.button.cancel": "Annonce",
  "cancellationReservationModal.component.header": "Annulation de la réservation",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Êtes-vous sûr de vouloir annuler votre réservation de table au restaurant {{nomdurestaurant}} à {{heure de réservation}} le {{date de réservation}} ?",
  "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
    "Les frais associés à la réservation ont été transférés au restaurant. Veuillez contacter le restaurant directement pour un éventuel recouvrement.",
  "cancellationReservationModal.component.button.confirm": "Approuver",
  "cancellationReservationModal.component.button.cancel": "Annonce",
  "searchResultItem.component.label.availableHours": "Heures disponibles",
  "searchResultItem.component.label.futureReservationDaysError":
    "Les réservations à distance sont possibles pour {{days}} jours à l'avance.",
  "searchResultItem.component.label.noAvailableTables":
    "Pour les paramètres de recherche sélectionnés (date, heure, nombre de personnes), ce restaurant ne propose pas de tables à réserver.",
  "searchResultItem.component.button.book": "Livre",
  "searchResultItem.component.button.cancel": "Annonce",
  "searchResultItem.component.button.see": "Voir",
  "passwordFormModal.component.header": "Entrez le mot de passe de votre compte",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Saisissez votre mot de passe pour confirmer l'action.",
  "passwordFormModal.component.button.confirm": "Approuver",
  "passwordFormModal.component.button.cancel": "Annonce",
  "cuisine.romania": "Cuisine roumaine",
  "cuisine.american": "Cuisine américaine",
  "cuisine.arabic": "Cuisine arabe",
  "cuisine.chinese": "Cuisine chinoise",
  "cuisine.polish": "Cuisine polonaise",
  "cusine.chinese": "Cuisine chinoise",
  "cusine.polish": "Cuisine polonaise",
  "component.cookiesPolicyLine.text":
    "Ce site web utilise des cookies pour vous fournir le plus haut niveau de service. En continuant à utiliser le site, vous acceptez leur utilisation.",
  "component.cookiesPolicyLine.privacyPolicyText": "Politique de confidentialité",
  "component.header.menu.mainPage": "Livre",
  "component.header.menu.forRestaurants": "Pour les restaurants",
  "component.header.menu.portal.restaurant": "Portail des restaurants",
  "component.header.menu.forRestaurants.offer": "Notre offre",
  "component.header.menu.blog": "Actualités MOJSTOLIK.PL",
  "component.header.menu.christmas": "OFFRES DE VACANCES",
  "component.header.menu.forGuest": "Pour l'invité",
  "component.header.menu.howItWorks": "Comment cela fonctionne-t-il ?",
  "component.header.menu.priceList": "Liste de prix",
  "component.header.menu.contact": "Contact",
  "component.header.menu.documents": "Dokumenty",
  "component.header.menu.about": "O en",
  "component.header.menu.login": "Se connecter",
  "component.header.menu.logout": "Déconnexion",
  "component.header.menu.reservationHistory": "Vos réservations",
  "component.header.menu.yourAccount": "Votre compte",
  "component.header.menu.covid": "Manuels COVID-19",
  "component.header.menu.covid.restaurant": "Guide des restaurants",
  "component.header.menu.covid.client": "Guide du visiteur",
  "component.header.menuMobile.covid.restaurant": "Guide COVID-19 pour les restaurants",
  "component.header.menuMobile.covid.client": "Guide du visiteur COVID-19",
  "component.header.menu.events": "Evénements",
  "component.header.menu.event": "Événement -",
  "component.footer.menu.header.forClients": "À propos de MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Informations",
  "component.footer.menu.header.yourAccount": "Votre compte",
  "component.footer.menu.header.socialMedia": "Médias sociaux",
  "component.footer.menu.header.applicationDownload": "Télécharger le dossier de candidature",
  "component.footer.menu.forRestaurants": "Pour les restaurants",
  "component.footer.menu.forGuest": "Pour l'invité",
  "component.footer.menu.howItWorks": "Comment cela fonctionne-t-il ?",
  "component.footer.menu.priceList": "Liste de prix",
  "component.footer.menu.contact": "Contact",
  "component.footer.menu.documents": "Dokumenty",
  "component.footer.menu.about": "O en",
  "component.footer.menu.login": "Se connecter",
  "component.footer.menu.reservationHistory": "Vos réservations",
  "component.footer.menu.yourAccount": "Votre compte",
  "component.footer.menu.logout": "Déconnexion",
  "component.footer.menu.registration": "Inscription",
  "component.footer.menu.privacyPolicy": "Politique de confidentialité",
  "component.footer.menu.cookiesPolicy": "Politique en matière de cookies",
  "indexPage.background.header": "Trouvez les meilleurs restaurants de votre ville,",
  "indexPage.header.currentPromotions": "Offres spéciales et événements",
  "indexPage.header.currentPromotionsExtend": "sur votre lieu de travail",
  "indexPage.header.blogs": "Articles en vedette",
  "indexPage.header.mostPopular": "Les plus populaires",
  "indexPage.header.mostPopularExtend": "sur votre lieu de travail",
  "indexPage.background.headerExtend": "et réservez une table :",
  "indexPage.input.label.location": "Localisation",
  "indexPage.input.label.date": "Données",
  "indexPage.input.label.hour": "Heure",
  "indexPage.input.label.persons": "Nombre de personnes",
  "indexPage.input.label.persons.optionExtend": "os.",
  "indexPage.input.label.restaurantType": "Type de cuisine",
  "indexPage.input.label.rating": "L'évaluation",
  "indexPage.input.label.rating.optionExtend": "étoiles.",
  "indexPage.input.label.priceLevel": "Niveau de prix",
  "indexPage.input.label.searchByName": "Recherche par nom",
  "indexPage.input.placeholder.select": "Sélectionner",
  "indexPage.input.placeholder.type": "Entrer",
  "indexPage.input.placeholer.typeInOrSelect": "Tapez ou sélectionnez",
  "indexPage.input.noData": "Aucun résultat.",
  "indexPage.button.search": "Recherche",
  "indexPage.button.showAdditionalFilters": "Filtres supplémentaires",
  "indexPage.button.hideAdditionalFilters": "Masquer les filtres supplémentaires",
  "indexPage.button.use": "Utilisation",
  "indexPage.button.promotion": "promotion",
  "indexPage.button.bookNow": "Livre",
  "indexPage.button.readMore": "Lire",
  "accountPage.header": "Votre compte",
  "accountPage.subheader":
    "Modifiez vos données personnelles pour accélérer le processus d'exécution.",
  "accountPage.headerInColumn.yourPersonalData": "Vos données",
  "accountPage.headerInColumn.yourPassword": "Votre mot de passe",
  "accountPage.input.label.firstName": "Nom",
  "accountPage.input.placeholder.firstName": "Par exemple, Jan",
  "accountPage.input.label.lastName": "Nom",
  "accountPage.input.placeholder.lastName": "Par exemple, Kowalski",
  "accountPage.input.label.phoneNumber": "Numéro de téléphone",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.label.email": "Adresse électronique",
  "accountPage.input.placeholder.email": "Par exemple : adres@email.pl",
  "accountPage.paragraph.optionalFields": "Champs facultatifs",
  "accountPage.input.label.birthDate": "Date de naissance",
  "accountPage.input.placeholder.birthDate": "Par exemple : 1976-10-25",
  "accountPage.input.label.postalCode": "Code postal",
  "accountPage.input.placeholder.postalCode": "Par exemple, 03-123",
  "accountPage.input.label.city": "Ville",
  "accountPage.input.placeholder.city": "Par exemple, Varsovie",
  "accountPage.input.label.streetAndLocalNumber": "Numéro de rue et d'appartement",
  "accountPage.input.placeholder.streetAndLocalNumber": "Par exemple : Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Méthodes de communication :",
  "accountPage.button.save": "Économiser",
  "accountPage.button.deleteAccount": "Supprimer le compte",
  "accountPage.input.label.oldPassword": "Ancien slogan",
  "accountPage.input.label.newPassword": "Nouveau mot de passe",
  "accountPage.input.label.newPasswordRepeat": "Nouveau mot de passe (répétition)",
  "accountPage.button.changePassword": "Modifier le mot de passe",
  "accountPage.message.personalDataUpdated": "Les données personnelles ont été mises à jour.",
  "accountPage.message.accountPermanentlyDeleted": "Votre compte a été définitivement supprimé.",
  "accountPage.message.passwordChanged": "Le mot de passe a été modifié.",
  "accountPage.label.changePasswordNotPossible":
    "Il n'est pas possible de modifier votre mot de passe parce que vous vous êtes connecté via Facebook ou Google.",
  "accountPage.toast.error.disableBothChannels":
    "Au moins une des méthodes de communication (SMS, E-MAIL) doit rester active !",
  "accountPage.toast.error.enableAfterChange.mail":
    "L'activation de la méthode de communication nécessite la confirmation de l'exactitude des données. Enregistrez les modifications pour recevoir un lien de vérification à votre nouvelle adresse électronique !",
  "accountPage.toast.error.enableAfterChange.phone":
    "L'activation de la méthode de communication nécessite la confirmation de l'exactitude des données. Enregistrez les modifications pour recevoir un lien de vérification par SMS à votre nouveau numéro de téléphone !",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Pour activer la nouvelle méthode de communication, confirmez vos coordonnées en accédant au lien d'activation qui vous a été envoyé par courrier électronique !",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Pour activer la nouvelle méthode de communication, confirmez vos coordonnées en accédant au lien d'activation qui vous a été envoyé par SMS au numéro de téléphone que vous avez fourni !",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Pour vérifier vos coordonnées, suivez le lien de vérification qui vous a été envoyé à votre adresse électronique !",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Pour vérifier vos données, suivez le lien de vérification envoyé par SMS à votre numéro de téléphone !",
  "reservationHistoryPage.sendMessage": "Envoyer un message",
  "reservationHistoryPage.send": "Envoyer",
  "reservationHistoryPage.cancel": "Annonce",
  "reservationChatPage.send": "Envoyer",
  "reservationChatPage.writeMessage": "Tapez votre message",
  "reservationHistoryPage.header": "Vos réservations",
  "reservationHistoryPage.subheader":
    "Vous pouvez y consulter l'historique de vos réservations et gérer les réservations futures.",
  "reservationHistoryPage.paragraph.personsShortcut": "os.",
  "reservationHistoryPage.button.revoke": "Annuler",
  "reservationHistoryPage.button.pay": "Payer",
  "reservationHistoryPage.paragraph.additionalDescription": "Communication avec le restaurant",
  "reservationHistoryPage.paragraph.date": "Données",
  "reservationHistoryPage.paragraph.duration": "Durée de l'accord",
  "reservationHistoryPage.paragraph.hour": "Heure",
  "reservationHistoryPage.paragraph.persons": "Nombre de personnes",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "La réservation n'existe pas",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "La liste des réservations est vide.",
  "reservationHistoryPage.paragraph.status": "Statut",
  "reservationPage.paragraph.lateInfo":
    "Attention, en cas de retard, la table attend l'invité 15 minutes !",
  "reservationHistoryPage.paragraph.payment.status": "État des paiements",
  "searchPage.header": "Résultats de la recherche",
  "searchPage.button.map": "Carte",
  "searchPage.button.list": "Lista",
  "searchPage.paragraph.noData": "Aucun résultat pour les critères donnés.",
  "searchMapPage.map.button.refresh": "Actualiser la carte",
  "searchMapPage.header": "Résultats de la recherche",
  "searchMapPage.button.map": "Carte",
  "searchMapPage.button.list": "Lista",
  "loginPage.header": "Enregistrement",
  "loginPage.subheader":
    "En tant qu'utilisateur connecté, vous pouvez réserver des tables plus rapidement et consulter l'historique de vos réservations.",
  "loginPage.columns.header.standardLogin": "Connexion standard",
  "loginPage.columns.header.socialLogin": "Se connecter avec",
  "loginPage.input.label.phoneOrEmail": "Numéro de téléphone",
  "loginPage.input.label.password": "Mot de passe",
  "loginPage.anchor.passwordReset": "Réinitialiser le mot de passe",
  "loginPage.button.logIn": "Se connecter",
  "loginPage.paragraph.dontHaveAccountYet": "Vous n'avez pas encore de compte ?",
  "modal.login.dontHaveAccountYet": "Vous n'avez pas encore de compte",
  "loginPage.paragraph.registerNow": "S'inscrire !",
  "loginPage.button.register": "Registre",
  "loginPage.message.logged": "Reblogé.",
  "loginPage.message.loggedViaFacebook": "Rebloggée via Facebook.",
  "loginPage.message.loggedViaApple": "Reblogé par Apple.",
  "loginPage.message.loggedViaGoogle": "Reblogé via Google.",
  "loginPage.paragraph.alreadyLoggedIn": "Vous êtes actuellement connecté.",
  "resetPasswordPage.header": "Réinitialiser le mot de passe",
  "resetPasswordPage.subheader":
    "Remplissez le formulaire ci-dessous et nous vous enverrons un nouveau mot de passe par SMS.",
  "resetPasswordPage.subheader2":
    "Nous vous enverrons un nouveau mot de passe sur votre numéro de téléphone.",
  "resetPasswordPage.input.label.phoneOrEmail": "Numéro de téléphone",
  "resetPasswordPage.button.confirm": "Confirmer",
  "resetPasswordPage.toast.passwordChanged":
    "Un nouveau mot de passe a été envoyé par SMS au numéro de téléphone fourni lors de l'inscription.",
  "resetPasswordPage.toast.error.passwordChanged": "Le numéro de téléphone indiqué est incorrect.",
  "cookiesPolicyPage.header": "Politique en matière de cookies",
  "cookiesPolicyPage.subheader": "Informations pertinentes.",
  "cookiesPolicyPage.point.1":
    "Le site ne collecte aucune information de manière automatique, à l'exception des informations contenues dans les cookies.",
  "cookiesPolicyPage.point.2":
    "Les cookies (appelés \"témoins de connexion\") sont des données informatiques, en particulier des fichiers texte, qui sont stockés dans l'équipement terminal de l'utilisateur du service et qui sont destinés à être utilisés sur le site web. Les cookies contiennent généralement le nom du site web d'où ils proviennent, la durée de leur stockage sur l'équipement terminal et un numéro unique.",
  "cookiesPolicyPage.point.3":
    "L'entité qui place les cookies sur l'équipement terminal de l'utilisateur du site Web et qui y accède est le propriétaire de mojstolik.pl Table4Rest Sp. z o.o., dont le siège social est situé ul. Ksawerów 21, 02-656 Varsovie.",
  "cookiesPolicyPage.point.4": "Les cookies sont utilisés pour :",
  "cookiesPolicyPage.point.4a":
    "adapter le contenu des pages du site web aux préférences de l'utilisateur et optimiser l'utilisation des sites web ; en particulier, ces fichiers permettent de reconnaître l'appareil de l'utilisateur du site web et d'afficher le site web de manière appropriée, en fonction de ses besoins individuels ;",
  "cookiesPolicyPage.point.4b":
    "la création de statistiques qui nous aident à comprendre comment les visiteurs du site Web utilisent les sites Web, ce qui nous permet d'améliorer leur structure et leur contenu ;",
  "cookiesPolicyPage.point.4c":
    "maintenir une session de l'utilisateur du service (après s'être connecté), grâce à laquelle l'utilisateur n'a pas besoin de réintroduire son login et son mot de passe sur chaque sous-page du service ;",
  "cookiesPolicyPage.point.5":
    "Le site Web utilise deux principaux types de cookies : \"session\" (cookies de session) et \"permanent\" (cookies persistants). Les cookies \"de session\" sont des fichiers temporaires qui sont stockés sur l'équipement terminal de l'utilisateur jusqu'à ce qu'il se déconnecte, quitte le site web ou éteigne le logiciel (navigateur web). Les cookies \"persistants\" sont stockés sur l'équipement terminal de l'utilisateur pendant la durée spécifiée dans les paramètres des cookies ou jusqu'à ce que l'utilisateur les supprime.",
  "cookiesPolicyPage.point.6":
    "Les types de cookies suivants sont utilisés dans le cadre du service :",
  "cookiesPolicyPage.point.6a":
    "les cookies \"nécessaires\" pour permettre l'utilisation des services disponibles sur le site web, par exemple les cookies d'authentification utilisés pour les services nécessitant une authentification sur le site web ;",
  "cookiesPolicyPage.point.6b":
    "les cookies utilisés à des fins de sécurité, par exemple pour détecter les abus d'authentification sur le site web ;",
  "cookiesPolicyPage.point.6c":
    'les cookies de "performance", qui permettent de collecter des informations sur la façon dont les pages du site sont utilisées ;',
  "cookiesPolicyPage.point.6d":
    "les cookies \"fonctionnels\", qui permettent de \"mémoriser\" les paramètres choisis par l'utilisateur et de personnaliser l'interface de l'utilisateur, par exemple en ce qui concerne la langue ou la région d'origine choisie par l'utilisateur, la taille de la police, la conception du site web, etc ;",
  "cookiesPolicyPage.point.6e":
    'les cookies "publicitaires", qui permettent de fournir aux utilisateurs un contenu publicitaire mieux adapté à leurs intérêts.',
  "cookiesPolicyPage.point.7":
    "Dans de nombreux cas, le logiciel de navigation sur le web (navigateur web) permet par défaut de stocker des cookies sur le terminal de l'utilisateur. Les utilisateurs du site web peuvent à tout moment modifier leurs paramètres concernant les cookies. Ces paramètres peuvent notamment être modifiés de manière à bloquer la gestion automatique des cookies dans les paramètres d'un navigateur web ou à informer de leur placement à chaque fois dans l'équipement d'un utilisateur du service. Des informations détaillées sur la possibilité et les méthodes d'utilisation des cookies sont disponibles dans les paramètres de votre logiciel (navigateur web).",
  "cookiesPolicyPage.point.8":
    "L'opérateur du site Internet vous informe que des restrictions sur l'utilisation des cookies peuvent affecter certaines des fonctionnalités disponibles sur le site Internet.",
  "privacyPolicyPage.header": "Politique de confidentialité",
  "privacyPolicyPage.subheader": "Informations pertinentes.",
  "privacyPolicyPage.point.one": "DISPOSITIONS GÉNÉRALES",
  "privacyPolicyPage.point.one.1":
    "L'administrateur des données est Table4Rest Sp. z o.o., dont le siège social est situé à Varsovie 02-656, Ksawerów St. 21, inscrit au registre des entrepreneurs sous le numéro KRS : 0000752110, dont les dossiers d'enregistrement sont conservés par le TRIBUNAL DU REGISTRE DE LA VILLE DE VARSOVIE, XIIIe DÉPARTEMENT ÉCONOMIQUE DU REGISTRE NATIONAL NIP : 5213842139 REGON : 381529521.",
  "privacyPolicyPage.point.one.2":
    "Pour l'interprétation des termes, le glossaire des conditions générales s'applique ou la description de la politique de protection de la vie privée (lorsque celle-ci découle directement de la description).",
  "privacyPolicyPage.point.one.3":
    'Pour une meilleure perception de la politique de confidentialité, le terme " Utilisateur " est remplacé par " vous ", " Administrateur " par " nous ". Le terme " RODO " désigne le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l\'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE.',
  "privacyPolicyPage.point.one.4":
    "Nous respectons le droit à la vie privée et veillons à la sécurité des données. À cette fin, nous utilisons, entre autres, un protocole de cryptage des communications sécurisées (SSL).",
  "privacyPolicyPage.point.one.5":
    "Les données personnelles fournies dans le formulaire de la page d'accueil sont traitées de manière confidentielle et ne sont pas visibles par des personnes non autorisées.",
  "privacyPolicyPage.point.two": "CONTRÔLEUR",
  "privacyPolicyPage.point.two.1":
    "Le prestataire de services est l'administrateur des personnes qui ont utilisé le formulaire de contact sur le site web ou qui ont utilisé une autre forme de communication (courriel, téléphone, application mobile, plug-in d'appel à la commande, autres formes de communication).",
  "privacyPolicyPage.point.two.2": "Les données personnelles sont traitées :",
  "privacyPolicyPage.point.two.2a":
    "a. conformément à la législation sur la protection des données,",
  "privacyPolicyPage.point.two.2b":
    "b. conformément à la politique de confidentialité mise en œuvre,",
  "privacyPolicyPage.point.two.2c":
    "c. dans la mesure et aux fins nécessaires à l'établissement, à la formation, à la modification ou à la résiliation de l'accord et à la bonne exécution des services,",
  "privacyPolicyPage.point.two.2d":
    "d. dans la mesure et pour la finalité nécessaires à la réalisation d'intérêts légitimes (finalités légitimes) et que le traitement ne porte pas atteinte aux droits et libertés de la personne concernée.",
  "privacyPolicyPage.point.two.3":
    "Chaque personne concernée (si nous sommes le responsable du traitement) dispose d'un droit d'accès, de rectification, d'effacement ou de limitation du traitement, d'un droit d'opposition et d'un droit d'introduire une réclamation auprès d'une autorité de contrôle.",
  "privacyPolicyPage.point.two.4":
    "Il est possible de contacter la personne qui supervise le traitement des données personnelles au sein de l'organisation du prestataire de services par courrier électronique à l'adresse suivante : kontakt@mojstolik.pl.",
  "privacyPolicyPage.point.two.5":
    "Nous nous réservons le droit de traiter vos données après la résiliation de l'accord ou le retrait du consentement uniquement dans la mesure où cela est nécessaire pour faire valoir d'éventuels droits en justice ou si le droit national, européen ou international nous oblige à conserver les données.",
  "privacyPolicyPage.point.two.6":
    "Le prestataire de services a le droit de mettre les données personnelles de l'utilisateur et d'autres données de l'utilisateur à la disposition d'entités autorisées en vertu de la législation pertinente (par exemple, les autorités chargées de l'application de la loi).",
  "privacyPolicyPage.point.two.7":
    "La suppression des données à caractère personnel peut résulter du retrait du consentement ou de l'introduction d'une objection légalement admissible au traitement des données à caractère personnel.",
  "privacyPolicyPage.point.two.8":
    "Le prestataire de services ne met pas les données à caractère personnel à la disposition d'entités autres que celles autorisées par la législation pertinente.",
  "privacyPolicyPage.point.two.9":
    "Les données personnelles ne sont traitées que par des personnes autorisées par nous ou par des sous-traitants avec lesquels nous travaillons en étroite collaboration.",
  "restaurantPage.button.pageWWW": "Site web",
  "restaurantPage.button.seeMenu": "Voir le menu",
  "restaurantPage.button.confirm": "Confirmer",
  "restaurantPage.button.cancel": "Annonce",
  "restaurantPage.button.choose": "Sélectionner",
  "restaurantPage.button.back": "Retour",
  "restaurantPage.button.showMore": "Afficher plus",
  "restaurantPage.button.showMore.promotion": "En savoir plus",
  "restaurantPage.button.showLess": "Montrer moins",
  "restaurantPage.button.goToHomePage": "Retour à la page d'accueil",
  "restaurantPage.button.reservationDetails": "Détails de la réservation",
  "restaurantPage.button.goToPaymentPage": "Accéder au panneau de paiement",
  "restaurantPage.paymentPage.info":
    "Vous serez immédiatement redirigé vers l'opérateur de paiement Przelewy24.pl.",
  "restaurantPage.header.restaurantDescription": "Description du restaurant",
  "restaurantPage.header.bookingPolicy": "Politique de réservation",
  "restaurantPage.header.gallery": "Galerie de photos",
  "restaurantPage.header.yourReservation": "Votre réservation",
  "restaurantPage.header.yourPersonalData": "Vos données",
  "restaurantPage.header.ctnWithoutLogin": "Continuer sans se connecter",
  "restaurantPage.header.login": "Enregistrement",
  "restaurantPage.button.login": "Se connecter",
  "restaurantPage.button.orLoginWith": "Ou se connecter en utilisant",
  "restaurantPage.header.createAccount": "Créez un compte sur MojStolik.co.uk et profitez-en :",
  "restaurantPage.header.createAccount.benefit.1": "Réservation rapide",
  "restaurantPage.header.createAccount.benefit.2": "Historique complet des réservations",
  "restaurantPage.header.createAccount.benefit.3": "Accès à des promotions uniques",
  "restaurantPage.button.createAccount": "Créer un compte MojStolik.pl",
  "restaurantPage.header.statements": "Déclarations",
  "restaurantPage.header.statement": "Déclaration",
  "restaurantPage.header.promotions": "Offres spéciales et événements",
  "restaurantPage.header.use": "Utilisation",
  "restaurantPage.header.cancel": "Abandonner",
  "restaurantPage.header.reservationConfirmation": "Confirmation de réservation",
  "restaurantPage.header.reservationsPersons": "Réservations de {{min}} à {{max}} personnes",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Saisissez le code figurant dans le SMS que nous vous avons envoyé pour terminer la procédure de réservation de la table.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Saisissez le code figurant dans le message que nous vous avons envoyé à votre adresse électronique pour terminer la procédure de réservation de la table.",
  "restaurantPage.input.label.code": "Saisir le code du jour",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "Aucun tableau n'est disponible pour les critères sélectionnés (date, nombre de personnes, promotion). Veuillez sélectionner une autre date, un autre nombre de personnes ou une autre promotion.",
  "restaurantPage.paragraph.noAvailableHours":
    "Pas de tables libres dans les critères sélectionnés.",
  "restaurantPage.paragraph.noAvailableHoursExt":
    "Choisissez une autre date ou un autre nombre de personnes.",
  "restaurantPage.input.label.date": "Données",
  "restaurantPage.input.label.hour": "Heure",
  "restaurantPage.input.label.persons": "Nombre de personnes",
  "restaurantPage.input.label.persons.optionExtend": "os.",
  "restaurantPage.input.label.firstName": "Nom",
  "restaurantPage.input.label.vatIdNumber": "PNM",
  "restaurantPage.input.label.address": "Adres",
  "restaurantPage.input.label.companyName": "Nom de l'entreprise",
  "restaurantPage.input.label.street": "Rue",
  "restaurantPage.input.label.buildingNumber": "Bâtiment",
  "restaurantPage.input.label.localNumber": "Locale",
  "restaurantPage.input.label.postalCode": "Code postal",
  "restaurantPage.input.label.city": "Ville",
  "restaurantPage.input.label.country": "Pays",
  "restaurantPage.get.company.data": "Télécharger",
  "restaurantPage.input.label.lastName": "Nom",
  "restaurantPage.input.label.phoneNumber": "Numéro de téléphone",
  "restaurantPage.input.label.email": "Adresse électronique",
  "restaurantPage.input.label.password": "Saisir le mot de passe",
  "restaurantPage.input.label.passwordRepeat": "Répéter le mot de passe",
  "restaurantPage.input.label.description": "Notes de réservation",
  "agreements.allAgreements": "Marquer tous",
  "agreements.invoice": "Saisissez les détails de votre facture",
  "restaurantPage.input.label.personalData":
    "Je déclare connaître et accepter les dispositions des conditions générales relatives à la fourniture de services par MojStolik via l'application client.",
  "restaurantPage.input.label.personalData.extension":
    " Conditions générales d'utilisation de MojStolik",
  "restaurantPage.input.label.readWithdrawal":
    "J'ai lu les conditions générales de MojStolik.co.uk, en particulier les instructions relatives au droit de rétractation.",
  "restaurantPage.input.label.readWithdrawal.extension":
    "Conditions d'utilisation du site mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "Je souhaite recevoir des promotions, des offres spéciales, des offres de concours ou d'autres contenus marketing de MojStolik, y compris des informations personnalisées sur les services et produits de MojStolik disponibles sur l'application client, par le biais d'une communication électronique. Vous pouvez retirer ce consentement à tout moment.",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Je souhaite recevoir des promotions, des offres spéciales, des offres de concours ou d'autres contenus marketing de MojStolik, y compris des informations personnalisées sur les services et les produits du restaurant et des entités coopérantes indiqués sur mojstolik.co.uk, disponibles dans l'application client, par le biais de la communication électronique. Vous pouvez retirer ce consentement à tout moment.",
  "agreement.eventAgreement":
    "J'ai lu et j'accepte les termes et conditions de l'offre spéciale figurant dans le site web de l'Union européenne.",
  "registrationPage.input.label.eventAgreementUrl.extension": "sous ce lien",
  "restaurantPage.input.placeholder.typeIn": "Entrer",
  "restaurantPage.input.placeholder.firstName": "Par exemple, Jan",
  "restaurantPage.input.placeholder.lastName": "Par exemple, Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.email": "Par exemple : adres@email.pl",
  "restaurantPage.paragraph.fillOrLogin":
    "Remplir les données restantes ou télécharger via Facebook, Google ou Apple",
  "restaurantPage.paragraph.doYouWantToRegister":
    "Si vous souhaitez créer un compte sur MOJSTOLIK.PL, veuillez donner votre mot de passe à",
  "restaurantPage.paragraph.dataIsIncorrect": "Les données sont-elles incorrectes ?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Le code a été renvoyé par SMS. Vous n'avez pas reçu le code ?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Authentifiez-vous par courrier électronique :",
  "restaurantPage.paragraph.sendCodeViaEmail.link":
    "Envoyez le code à l'adresse électronique fournie.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent":
    "Le code a été envoyé à l'adresse électronique :",
  "restaurantPage.anchor.sendCodeAgain": "Renvoyer le code",
  "restaurantPage.anchor.edit": "Editer",
  "restaurantPage.anchor.login": "Connexion",
  "restaurantPage.paragraph.or": " ou",
  "restaurantPage.anchor.register": "registre",
  "restaurantPage.paragraph.dontWontUsePromotion": "Je ne veux pas utiliser de promotions",
  "restaurantPage.messages.codeResent": "Le code SMS a été envoyé à nouveau.",
  "restaurantPage.header.reservationRecieved": "Acceptation des réservations",
  "restaurantPage.summary.paragraph.firstLastName": "Prénom Nom :",
  "restaurantPage.summary.paragraph.phoneNumber": "Numéro de téléphone :",
  "restaurantPage.summary.paragraph.email": "E-mail :",
  "restaurantPage.summary.paragraph.restName": "Nom du restaurant :",
  "restaurantPage.summary.paragraph.date": "Date limite :",
  "restaurantPage.summary.paragraph.date.time": ", hrs.",
  "restaurantPage.summary.paragraph.duration": "Durée de l'enquête :",
  "restaurantPage.summary.paragraph.persons": "Nombre de visiteurs :",
  "restaurantPage.summary.paragraph.totalCost": "Total des paiements anticipés :",
  "restaurantPage.summary.special.offer": "Offre spéciale :",
  "restaurantPage.summary.paragraph.state": "Statut :",
  "restaurantPage.paragraph.tableReserved": "Une table a été réservée",
  "reservation.status.Canceled": "Annulé",
  "reservation.status.Completed": "Réservation effectuée",
  "reservation.status.Confirmed": "Réservation confirmée",
  "reservation.status.Delayed": "Retardé",
  "reservation.status.NotCompleted": "Réservation non effectuée",
  "reservation.status.Rejected": "Réservation annulée",
  "reservation.status.WaitingForApproval": "Réservation en attente d'approbation",
  "reservation.status.WaitingForConfirmation": "En attente",
  "restaurantPage.paragraph.tableReservedDescription": "Votre table a été réservée pour une heure.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "sur",
  "restaurantPage.paragraph.tableWaitingForApproval": "Réservation en attente d'approbation",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1":
    "Votre réservation pour les heures.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "Si vous ne recevez pas de confirmation de votre réservation dans les heures qui suivent, veuillez contacter directement le restaurant.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "sur",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "Une fois la réservation confirmée, un message contenant un lien de paiement sera envoyé au numéro de téléphone indiqué. La réservation est valable dès réception de la confirmation du paiement",
  "restaurantPage.paragraph.tableReservedPaymentInformation":
    "Les réservations doivent être payées",
  "restaurantPage.input.label.footer":
    "MojStolik utilise une variété de canaux de communication électronique et mène ses activités en utilisant le courrier électronique, le téléphone, les SMS et les MMS. L'administrateur de vos données personnelles est Table4Rest spółka z ograniczoną odpowiedzialnością, dont le siège social est situé à Varsovie, 02-656 Varsovie, ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Vos données personnelles seront traitées dans le but de vous envoyer du contenu marketing par le biais de canaux de communication électroniques. Pour plus d'informations sur le traitement de vos données personnelles, y compris vos droits, veuillez consulter la politique de confidentialité.",
  "forGuestPage.header": "Pour l'invité",
  "howItWorksPage.header": "Comment cela fonctionne-t-il ?",
  "howItWorksPage.subheader": "Pas à pas",
  "howItWorksPage.content.title": "Réservation à partir de l'application mobile",
  "howItWorksPage.content.step1": "Étape 1",
  "howItWorksPage.content.step1.content":
    "Le client recherche des restaurants dans l'application mobile et",
  "howItWorksPage.content.step1.content.strong": "voit les tables disponibles en temps réel.",
  "howItWorksPage.content.step2": "Étape 2",
  "howItWorksPage.content.step2.content.strong":
    "Le gestionnaire peut voir la demande de réservation dans son tableau de bord.",
  "howItWorksPage.content.step2.content":
    " Il sait si la demande émane d'un nouveau client ou d'un client régulier et s'il y a des préférences de table.",
  "howItWorksPage.content.step3": "Étape 3",
  "howItWorksPage.content.step3.content": "Un client entre dans un restaurant où",
  "howItWorksPage.content.step3.content.strong":
    "est accueilli par son nom. Le personnel dispose des coordonnées complètes du client",
  "howItWorksPage.content.step3.contentb":
    " - sait s'il s'agit d'un visiteur régulier ou d'une première visite.",
  "howItWorksPage.phone.title": "Réservation par téléphone",
  "howItWorksPage.content.phone.step1": "Étape 1",
  "howItWorksPage.content.phone.step1.content1": "Klient",
  "howItWorksPage.content.phone.step1.content.strong": "appelle le restaurant de votre choix",
  "howItWorksPage.content.phone.step1.content2": "avec une demande de réservation.",
  "howItWorksPage.content.phone.step2": "Étape 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "Le gestionnaire peut voir la disponibilité des tables en temps réel dans son tableau de bord.",
  "howItWorksPage.content.phone.step2.content":
    ". Lorsqu'un gestionnaire saisit le numéro de téléphone d'un client, il voit immédiatement les coordonnées de ce dernier ou complète/saisit les coordonnées d'un nouveau client.",
  "howItWorksPage.content.phone.step3": "Étape 3",
  "howItWorksPage.content.phone.step3.content": "Un client entre dans un restaurant où",
  "howItWorksPage.content.phone.step3.content.strong":
    "le personnel l'accueille par son nom et dispose des coordonnées complètes du client",
  "howItWorksPage.content.phone.step3.contentb":
    " - sait s'il s'agit d'un visiteur régulier ou d'une première visite.",
  "priceListPage.header": "Liste de prix",
  "priceListPage.content.link.1": "Pour obtenir des informations sur les prix, veuillez",
  "priceListPage.content.link.2": "Contact",
  "contactPage.header": "Contact",
  "contactPage.subheader": "Écrivez-nous !",
  "contactPage.content.title": "Coordonnées de la personne à contacter",
  "documentsPage.header": "Dokumenty",
  "documentsPage.header.for.clients": "Pour le client",
  "documentsPage.header.for.restaurants": "Pour les restaurants",
  "documentsPage.subheader": "Informations pertinentes",
  "documentsPage.for.clients.document.1":
    "Règlement relatif à la fourniture de services par MojStolik via l'application client",
  "documentsPage.for.clients.document.2": "Informations sur le traitement des données personnelles",
  "documentsPage.header.for.restaurants.document.1":
    "Conditions de mise à disposition de l'application MojStolik pour les restaurants",
  "documentsPage.header.for.restaurants.document.2":
    "Accord de mandat pour le traitement des données à caractère personnel du restaurant",
  "documentsPage.header.for.restaurants.document.3": "Données personnelles mojstolik.co.uk",
  "aboutPage.header": "O en",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " - est un système polonais créé par un groupe d'amis - des amoureux secrets de la bonne cuisine et de l'art de recevoir dans les restaurants. Il s'agit d'une solution technologique qui permet de créer une communauté autour de lieux attrayants grâce à la bonne cuisine et au respect du temps et des habitudes de leurs clients fidèles. MojStolik.pl est un groupe croissant de restaurants qui travaillent ensemble pour s'assurer que la gastronomie polonaise est identifiée à l'excellente qualité, à l'attention portée aux détails et au respect des fans qui passent du temps à table avec leurs amis, leur famille ou leurs partenaires d'affaires. Les fondateurs du système ont traduit leur passion pour les restaurants en langage technologique et ont ainsi créé un portail et une application gastronomiques qui mettent en relation les personnes attablées dans les restaurants les plus appréciés et les plus populaires de Pologne.",
  "aboutPage.application.header1":
    " Vous voulez réserver une table dans un restaurant ? Désormais, vous pouvez le faire via une application sur votre téléphone ou à partir d'un portail multi-restaurants.",
  "aboutPage.application.description1":
    "Mojstolik.pl est une nouvelle plateforme de réservation complète récemment mise à la disposition des restaurants de Varsovie. Elle permet aux établissements de restauration de gérer facilement les réservations de tables en temps réel et intègre tous les canaux de communication avec les clients : téléphone, courriel, application mobile MojStolik et \"walk-in\". Grâce à lui, les gérants de restaurants ont accès à une vue dynamique de la salle, décident eux-mêmes de l'agencement de la salle, du nombre de places disponibles et de la durée de réservation de chaque table. Un autre avantage du système est le mécanisme de confirmation des réservations par SMS, avec la possibilité d'annuler via un lien et d'envoyer des rappels par SMS aux clients.",
  "aboutPage.application.header2":
    "MojStolik.co.uk : une tablette au lieu d'un carnet de réservation",
  "aboutPage.application.description2":
    "MojStolik.pl est également un outil destiné à aider les restaurants à fidéliser leur clientèle. Le système permet de créer une base de données de clients, d'analyser l'historique des réservations, et le compteur de visites indique à quelle heure une personne donnée a visité le restaurant. Lors de la réservation, les clients ont la possibilité d'ajouter des commentaires tels que leurs préférences de table, leurs plats ou vins préférés. La nouvelle plateforme permet de stocker les données des clients en toute sécurité, conformément aux exigences RODO, ce que ne permet pas le carnet de réservation papier utilisé par les restaurants.",
  "aboutPage.application.description2b":
    "L'application est utilisée par plus de 100 restaurants dans toute la Pologne, notamment : Stary Dom, Baczewskie Restaurant, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE'S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, et d'autres viendront s'ajouter prochainement.",
  "aboutPage.application.header3": "MojStolik - nouvelle application de réservation de tables",
  "aboutPage.application.description3":
    " MojStolik est une nouvelle application mobile qui vous permet de réserver une table dans un restaurant de manière rapide et pratique. L'application vous permet de vérifier instantanément la disponibilité et de réserver une table pour une heure donnée, car elle communique avec le restaurant en temps réel. Vous pouvez ajouter à votre réservation une demande spéciale ou un commentaire sur votre table préférée dans le restaurant, des informations sur vos allergies ou d'autres préférences, et le restaurant peut utiliser la fonction de chat pour vous faire savoir si votre demande est possible. L'application MojStolik permet également de consulter le menu actuel du restaurant et d'accéder aux promotions spéciales que les restaurants ont préparées pour nous.",
  "aboutPage.contact.data": "Coordonnées de la personne à contacter",
  "aboutPage.contact.street": "ul.",
  "registrationPage.header": "Inscription",
  "registrationPage.subheader":
    "En tant qu'utilisateur connecté, vous pouvez réserver des tables plus rapidement et consulter l'historique de vos réservations.",
  "registrationPage.columns.header.registerStandard": "Enregistrement standard",
  "registrationPage.columns.header.registerWith": "S'inscrire auprès de",
  "registrationPage.input.label.firstName": "Nom",
  "registrationPage.input.placeholder.firstName": "Par exemple, Jan",
  "registrationPage.input.label.lastName": "Nom",
  "registrationPage.input.placeholder.lastName": "Par exemple, Kowalski",
  "registrationPage.input.label.phoneNumber": "Numéro de téléphone",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.label.email": "Adresse électronique",
  "registrationPage.input.placeholder.email": "Par exemple : adres@email.pl",
  "registrationPage.paragraph.optionalFields": "Champs facultatifs",
  "registrationPage.input.label.birthDate": "Date de naissance",
  "registrationPage.input.placeholder.birthDate": "Par exemple : 1976-10-25",
  "registrationPage.input.label.postalCode": "Code postal",
  "registrationPage.input.placeholder.postalCode": "Par exemple, 03-123",
  "registrationPage.input.label.city": "Ville",
  "registrationPage.input.placeholder.city": "Par exemple, Varsovie",
  "registrationPage.input.label.streetAndLocalNumber": "Numéro de rue et d'appartement",
  "registrationPage.input.placeholder.streetAndLocalNumber": "Par exemple : Grójecka 130/105",
  "registrationPage.input.label.password": "Mot de passe",
  "registrationPage.input.label.passwordRepeat": "Répéter le mot de passe",
  "registrationPage.header.statements": "Déclarations",
  "registrationPage.input.label.agreementPersonalData":
    "Je consens au traitement de mes données personnelles par Table4Rest sp. z o.o. et les restaurants et établissements de restauration sélectionnés par moi dans le but d'exécuter l'objet du contrat conformément aux informations contenues dans le document.",
  "registrationPage.input.label.agreementPersonalData.extension":
    "Informations sur le traitement des données personnelles des clients",
  "registrationPage.input.label.agreementReadWithdrawal":
    "J'ai lu les conditions générales de MojStolik.co.uk, en particulier les instructions relatives au droit de rétractation.",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Conditions d'utilisation du site mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Consentement à la commercialisation",
  "registrationPage.input.label.agreementMarketing.extension": "Consentement",
  "registrationPage.input.label.promotionReadWithdrawal.extension": "Conditions de la promotion",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "Je consens au traitement de mes données personnelles par les restaurants et autres établissements de restauration que j'ai sélectionnés afin d'y effectuer une réservation.",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "sous ce lien",
  "registrationPage.button.register": "Registre",
  "registrationPage.message.sendCodeAgain": "Le code SMS a été envoyé à nouveau.",
  "registrationPage.message.accountCreated": "Le compte a été créé.",
  "registrationPage.message.registrationTimeExpired": "La durée d'enregistrement a expiré",
  "registrationPage.message.enterCodeFromSMS": "Transcrire le code du SMS.",
  "registrationPage.paragraph.timeIsOver": "Le temps est écoulé.",
  "registrationPage.paragraph.timeLeft": "Restant",
  "registrationPage.paragraph.seconds": "deuxième(-a)",
  "registrationPage.header.SMSConfirmation": "Confirmation par SMS",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Saisissez le code SMS que nous avons envoyé à votre numéro de téléphone pour terminer votre inscription.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Saisir le code SMS du jour",
  "registrationPage.input.placeholder.code": "Saisir le code",
  "registrationPage.paragraph.sendCodeAgain": "Le code SMS a été envoyé à nouveau.",
  "registrationPage.button.sendCodeAgain": "Renvoyer le code",
  "registrationPage.button.confirm": "Confirmer",
  "notFoundPage.header": "Erreur 404",
  "notFoundPage.subheader": "URL demandé",
  "notFoundPage.subheaderExtend": "n'a pas été trouvé sur le serveur.",
  "notFoundPage.subheaderExtend2":
    "Essayez de vérifier que l'adresse que vous avez saisie est correcte ou rendez-vous à l'adresse suivante",
  "notFoundPage.subheaderExtend3": "page d'accueil",
  "notFoundPage.button.goToHomePage": "Aller à la page d'accueil",
  "global.toast.payment.count": "Produits requis non sélectionnés",
  "paymentInfo.description.label": "Nom",
  "paymentInfo.amount.label": "Prix unitaire.",
  "paymentInfo.quantity.label": "Nombre",
  "paymentInfo.total.label": "Total",
  "paymentInfo.nocalculation": "Les paiements ne sont pas obligatoires",
  "paymentInfo.summarycost.label": "A payer :",
  "paymentInfo.required.products": "Produits obligatoires",
  "paymentInfo.optional.products": "Produits optionnels",
  "paymentInfo.recalculate": "Recalculer",
  "paymentInfo.amount.to.paid": "Montant total à payer",
  "paymentInfo.info":
    "Le nombre total de produits obligatoires doit être égal au nombre de personnes dans la réservation.",
  "paymentInfo.info1": "Vous devez encore choisir",
  "paymentInfo.info3": "produit",
  "paymentInfo.info4": "produits",
  "paymentInfo.package.title": "Paquet de base",
  "paymentInfo.select.product": "Sélectionner un produit",
  "paymentInfo.select.basic.product": "Choisir un produit de base",
  "paymentInfo.select.required.product": "Sélectionner les produits obligatoires",
  "paymentInfo.too.much.products": "Vous ne pouvez pas sélectionner plus de produits",
  "restaurantPage.button.confirmAndPay": "Confirmer et payer",
  "restaurantPage.button.closestTime": "Prochaine date",
  "paymentInfo.title": "Charges",
  "reservation.payment.status.Created": "Créée",
  "reservation.payment.status.WaitingForPayment": "En attente",
  "reservation.payment.status.ProcessingPayment": "Au cours du processus",
  "reservation.payment.status.Completed": "Réalisé",
  "reservation.payment.status.Cancelled": "Annulé",
  "reservation.payment.status.Expired": "Expiré",
  "reservation.payment.status.Unknown": "Statut inconnu",
  "custom.form.required": "Champ obligatoire",
  "custom.button.send": "Envoyer",
  "custom.button.hide": "Effondrement",
  "custom.button.show": "Élargir",
  "reservation.confirmation.payment.count": "Recalculer les produits",
  "reservation.confirmation.selected.promotion": "Promotion sélectionnée",
  "forRestaurantsPage.form.toast.success": "Vos coordonnées ont été envoyées.",
  "forRestaurantsPage.form.toast.error": "Une erreur s'est produite, veuillez réessayer.",
  "forRestaurantsPage.form.email.error": "Courriel incorrect",
  "forRestaurantsPage.label.name": "NOM",
  "forRestaurantsPage.label.restaurant": "RESTAURANT :",
  "forRestaurantsPage.label.email": "E-MAIL :",
  "forRestaurantsPage.label.phone": "TÉLÉPHONE :",
  "forRestaurantsPage.header.agreement": "DÉCLARATIONS",
  "forRestaurantsPage.label.agreement":
    " Je consens au traitement de mes données personnelles par Table4Rest sp. z o. o. dans le but de conclure un contrat de prestation de services pour la fourniture et l'utilisation de l'application MojStolik.pl et pour l'envoi d'une offre par voie électronique.",
  "surveyPage.header.title": "Questionnaire client",
  "surveyPage.header.description":
    "Votre avis est la chose la plus importante pour nous, c'est ce qui nous permet de nous développer. N'hésitez pas à nous faire part de votre opinion :",
  "surveyPage.button.complete": "Prêt",
  "surveyPage.button.back": "Retour",
  "surveyPage.button.next": "Plus loin",
  "surveyPage.surveyCompleteMessage": "Merci d'avoir répondu à l'enquête !",
  "surveyPage.emptySurveyMessage":
    "Une erreur s'est produite lors du téléchargement des données de l'enquête. Veuillez réessayer plus tard.",
  "surveyPage.error": "Une erreur s'est produite.",
  "surveyPage.results.sending": "L'affichage de vos réponses est en cours...",
  "surveyPage.results.success": "Votre questionnaire a été envoyé",
  "surveyPage.filledAlert.header": "Ce questionnaire a déjà été rempli"
};

export default frontendTranslationsFR;
