import { createSlice } from "@reduxjs/toolkit";

/* const initialState = {
  light: {
    type: 'Light',
    name: 'forRestaurantsPage.offers.offerItem.1.header',
    desc: 'forRestaurantsPage.offers.offerItem.1.text',
    price: {
      monthly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 89,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 89,
        },
      },
      yearly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 75,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 75,
        },
      },
    },
  },
  advanced: {
    type: 'Advanced',
    name: 'forRestaurantsPage.offers.offerItem.2.header',
    desc: 'forRestaurantsPage.offers.offerItem.2.text',
    price: {
      monthly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 199,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 249,
        },
      },
      yearly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 175,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 225,
        },
      },
    },
  },
  advancedPro: {
    type: 'AdvancedPro',
    name: 'forRestaurantsPage.offers.offerItem.3.header',
    subName: 'forRestaurantsPage.offers.offerItem.3.header.orange',
    desc: 'forRestaurantsPage.offers.offerItem.3.text',
    price: {
      monthly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 399,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 449,
        },
      },
      yearly: {
        Discounted: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 355,
        },
        Regular: {
          commissionPrice: 0,
          optionPrices: {
            Ivr: 250,
            Prepayments: 50,
            SmsPackage: 80,
          },
          planPrice: 405,
        },
      },
    },
  },
  offerChosen: {
    type: '',
    name: '',
    desc: '',
    subName: '',
    price: 0,
    billingPeriod: '',
    additionalOptions: {
      ivr: false,
      prepayments: false,
      smsPackage: false,
    },
  },
}; */

const initialState = {
  plansData: {
    Light: {
      type: "Light",
      name: "forRestaurantsPage.offers.offerItem.1.header",
      desc: "forRestaurantsPage.offers.offerItem.1.text",
    },
    Advanced: {
      type: "Advanced",
      name: "forRestaurantsPage.offers.offerItem.2.header",
      desc: "forRestaurantsPage.offers.offerItem.2.text",
    },
    AdvancedPro: {
      type: "AdvancedPro",
      name: "forRestaurantsPage.offers.offerItem.3.header",
      subName: "forRestaurantsPage.offers.offerItem.3.header.orange",
      desc: "forRestaurantsPage.offers.offerItem.3.text",
    },
  },
  perMonthPrices: {
    Yearly: {
      Light: {
        Regular: {
          planPrice: {
            price: 75,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 75,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
      Advanced: {
        Regular: {
          planPrice: {
            price: 225,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 175,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
      AdvancedPro: {
        Regular: {
          planPrice: {
            price: 405,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 355,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
    },
    Monthly: {
      Light: {
        Regular: {
          planPrice: {
            price: 89,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 89,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
      Advanced: {
        Regular: {
          planPrice: {
            price: 249,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 199,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
      AdvancedPro: {
        Regular: {
          planPrice: {
            price: 449,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
        Discounted: {
          planPrice: {
            price: 399,
            comment: "" ?? null,
          },
          commissionPrice: {
            price: 0,
            comment: "" ?? null,
          },
          optionPrices: {
            Prepayments: {
              price: 50,
              comment: "" ?? null,
            },
            Ivr: {
              price: 250,
              comment: "" ?? null,
            },
            SmsPackage: {
              price: 80,
              comment: "" ?? null,
            },
          },
        },
      },
    },
  },
  offerChosen: {
    type: "",
    name: "",
    desc: "",
    subName: "",
    price: 0,
    billingPeriod: "",
    additionalOptions: {
      ivr: false,
      prepayments: false,
      smsPackage: false,
    },
    isFirstRender: true,
    discountCode: "",
  },
};

const offerSlice = createSlice({
  name: "offers",
  initialState,
  reducers: {
    getConfigData(state, action) {
      state.perMonthPrices = action.payload;
    },
    setInitialOfferChosenData(state, action) {
      state.offerChosen.type = action.payload.type;
      state.offerChosen.name = action.payload.name;
      state.offerChosen.subName = action.payload.subName;
      state.offerChosen.desc = action.payload.desc;
      state.offerChosen.price = action.payload.price;
      state.offerChosen.billingPeriod = "Monthly";
    },
    light(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.Light.type,
        name: state.plansData.Light.name,
        desc: state.plansData.Light.desc,
        subName: "",
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: {
          ivr: state.offerChosen.additionalOptions.ivr,
          prepayments: state.offerChosen.additionalOptions.prepayments,
          smsPackage: state.offerChosen.additionalOptions.smsPackage,
        },
      };
    },
    advanced(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.Advanced.type,
        name: state.plansData.Advanced.name,
        desc: state.plansData.Advanced.desc,
        subName: "",
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: {
          ivr: state.offerChosen.additionalOptions.ivr,
          prepayments: state.offerChosen.additionalOptions.prepayments,
          smsPackage: state.offerChosen.additionalOptions.smsPackage,
        },
      };
    },
    advancedPro(state, action) {
      state.offerChosen = {
        ...state.offerChosen,
        type: state.plansData.AdvancedPro.type,
        name: state.plansData.AdvancedPro.name,
        desc: state.plansData.AdvancedPro.desc,
        subName: state.plansData.AdvancedPro.subName,
        price: action.payload,
        billingPeriod: state.offerChosen.billingPeriod,
        additionalOptions: {
          ivr: state.offerChosen.additionalOptions.ivr,
          prepayments: state.offerChosen.additionalOptions.prepayments,
          smsPackage: state.offerChosen.additionalOptions.smsPackage,
        },
      };
    },
    prepaymentsOptionChange(state) {
      state.offerChosen.additionalOptions.prepayments =
        !state.offerChosen.additionalOptions.prepayments;
    },
    ivrOptionChange(state) {
      state.offerChosen.additionalOptions.ivr =
        !state.offerChosen.additionalOptions.ivr;
    },
    smsPackageOptionChange(state) {
      state.offerChosen.additionalOptions.smsPackage =
        !state.offerChosen.additionalOptions.smsPackage;
    },
    prepaymentsAddPrice(state, action) {
      state.offerChosen.price = state.offerChosen.price + action.payload;
    },
    ivrAddPrice(state, action) {
      state.offerChosen.price = state.offerChosen.price + action.payload;
    },
    smsPackageAddPrice(state, action) {
      state.offerChosen.price = state.offerChosen.price + action.payload;
    },
    billingPeriod(state, action) {
      state.offerChosen.billingPeriod = action.payload;
    },
    setIsFirstRender(state, action) {
      state.offerChosen.isFirstRender = action.payload;
    },
    updateCode(state, action) {
      state.offerChosen.discountCode = action.payload;
    },
  },
});

export const offersActions = offerSlice.actions;
export default offerSlice.reducer;
