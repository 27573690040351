const frontendTranslationsPL = {
  // SEO
  "seo.subpage.main.title": "Rezerwacja stolika online – MojStolik.pl",
  "seo.subpage.main.description":
    "Zarezerwuj stolik online w najpopularniejszych restauracjach w Polsce. Pobierz aplikację, wybierz, gdzie chcesz zamówić stolik i rezerwuj. Z MojStolik.pl to ultraproste.",
  "seo.subpage.forRestaurant.title": "Platforma rezerwacyjna dla restauracji - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl to platforma do zarządzania rezerwacjami w restauracjach. Wszystkie rezerwacje w 1 miejscu. Dodatkowa platforma komunikacji z gośćmi restauracji.",
  "seo.subpage.aboutUs.title": "Ultraprosta aplikacja dla lubiących jadać na mieście.",
  "seo.subpage.aboutUs.description":
    "Setki najpopularniejszych i najbardziej cenionych restauracji w kraju i tysiące użytkowników korzystających z platformy MojStolik.pl. Niech stolik czeka na Ciebie i nigdy odwrotnie.",
  "seo.subpage.howItWork.title": "Pobierz aplikację MojStolik.pl na telefon i rezerwuj stoliki",
  "seo.subpage.howItWork.description":
    "Wystarczy tylko kilka kliknięć, aby to stolik w restauracji czekał na Ciebie. To Ty decydujesz, kiedy, gdzie i z kim chcesz zjeść obiad czy kolację. Pobierz aplikację i rezerwuj stoliki.",
  "seo.subpage.documents.title": "Sprawdź, jakie korzyści uzyskasz ze współpracy z MojStolik.pl",
  "seo.subpage.documents.description":
    "Zastanawiasz się czy warto? Sprawdź najważniejsze informacje w dokumentach MojStolik.pl. Umowy, oferty marketingowe i korzyści ze współpracy.",
  "seo.subpage.privacyPolicy.title": "MojStolik.pl jako administrator danych osobowych",
  "seo.subpage.privacyPolicy.description":
    "Przeczytaj istotne informacje i poznaj platformę MojStolik.pl",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - poznaj restauracje w Twojej okolicy",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.pl używa plików cookies. Poznaj z nami najbardziej cenione restauracje w Twojej okolicy.",
  "seo.subpage.registration.title":
    "Zarejestruj się i korzystaj z aplikacji już dziś. MojStolik.pl",
  "seo.subpage.registration.description":
    "Rezerwacja stolików jeszcze nigdy nie była tak prosta. Wygodne płatności, dowolny czas i liczba osób. Zarejestruj się i już dziś korzystaj z MojStolik.pl",
  "seo.subpage.login.title": "Zaloguj się do aplikacji i wybieraj restauracje z MojStolik.pl",
  "seo.subpage.login.description":
    "W naszej ofercie znajdziesz najpopularniejsze restauracje w całej Polsce. Pobierz aplikację, zaloguj się i korzystaj z ultraprostego systemu do rezerwacji stolików.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - Odpowiedzialna restauracja",
  "seo.subpage.covid.restaurant.description":
    "Rozwiązania dla restauracji, które pomogą utrzymać biznes w tych trudnych czasach!",
  "seo.subpage.covid.client.title": "COVID-19 - Odpowiedzialni goście",
  "seo.subpage.covid.client.description":
    "Poradnik dla odpowiedzialnych gości odpowiedzialnej restauracji",
  "covid.restaurant.1.strong": "PLAN B: ",
  "covid.restaurant.1.text":
    "Już teraz przygotuj SWÓJ plan awaryjny na wypadek wzrostu liczby zachorowań – pomyśl jakie masz możliwości manewru i alternatywy w razie ponownego wprowadzenia obostrzeń różnego stopnia, jak przeorganizujesz pracę, jakie wprowadzisz procedury, jak utrzymasz kontakt z gośćmi, zagospodarujesz środki finansowe i zaplanujesz pracę ludzi. Jesteś bogatszy o wiosenne doświadczenia. Nie daj się zaskoczyć drugi raz.",
  "covid.restaurant.2.strong": "BEZPIECZEŃSTWO I HIGIENA to już standard, ale…: ",
  "covid.restaurant.2.text":
    "Nie odpuszczaj tematu. Sprawdź, czy wszystkie zalecane procedury związane z bezpieczeństwem gości i pracowników działają bez zarzutu – maseczki na twarzach kelnerów, dezynfekcja stołów po każdym z gości, elektroniczne menu jako opcja dla gości, procedury mycia i dezynfekcji rąk, zachowanie odpowiedniego dystansu pomiędzy stolikami i maksymalne ułatwienie płatności bezgotówkowych. Nie bój się grzecznie instruować gości, jak mają się zachowywać u Ciebie w restauracji.",
  "covid.restaurant.3.strong": "BUDUJ SWOJĄ SPOŁECZNOŚĆ: ",
  "covid.restaurant.3.text":
    "Z naszych obserwacji wynika, że te restauracje, które zdołały zbudować wokół siebie społeczność, a podczas lockdown utrzymywały kontakt ze swoimi gośćmi poprzez media społecznościowe lub SMSy wysyłane z aplikacji MojStolik.pl poradziły sobie zdecydowanie lepiej w nowej rzeczywistości, niż te, które nie komunikowały się w odpowiedni sposób. Zadbaj zatem już teraz o stały, ciekawy i rzetelny kontakt ze swoimi gośćmi – zadbaj o social media, stronę, a także pomyśl, jak jeszcze będziesz mógł się komunikować w razie takiej potrzeby. Na szczęście rząd podczas swoich majowych prac wycofał się z obowiązku posiadania przez każdą restaurację bazy danych swoich gości, ale jako odpowiedzialny restaurator powinieneś taką bazę budować. Tu bardzo pomocne jest wykorzystanie platformy MojStolik.pl – maksymalnie ułatwia ona zbieranie i przechowywanie danych (imię i nazwisko, mail, telefon) gości zgodnie z RODO. Dane należą do restauracji.",
  "covid.restaurant.4.strong": "KONIECZNA REZERWACJA? ",
  "covid.restaurant.4.text":
    "W wielu krajach Europy i Świata pojawiały się pomysły, aby do restauracji można było pójść tylko po uprzednim zarezerwowaniu stolika. Bądź zatem przygotowany na taką ewentualność i zadbaj o technologię.",
  "covid.restaurant.5.strong": "STARAJ SIĘ UŚWIADAMIAĆ SWOICH GOŚCI – ",
  "covid.restaurant.5.text":
    "za pomocą mediów społecznościowych, ale też innych kanałów komunikuj, dlaczego przestrzeganie zasad bezpieczeństwa w restauracji jest tak ważne i jakie procedury wprowadziłeś u siebie, aby zminimalizować ryzyko zarażenia. Uświadamiaj im też, że niektóre ich zachowania narażają Cię na straty, na które w tym trudnym momencie nie możesz sobie pozwolić. Chodzi tu głównie o niewykorzystane rezerwacje, przez które tracisz czas i pieniądze. Niektórzy po prostu nie zdają sobie sprawy z tego, jak duży jest to problem dla restauratorów. Dzięki platformie Mojstolik.pl proces odwołania rezerwacji staje się bardzo prosty dla gości i jest całkowicie automatyczny po stronie restauracji.",
  "covid.restaurant.6.strong": "SZUKAJ OSZCZĘDNOŚCI: ",
  "covid.restaurant.6.text":
    "Szczególnie dzisiaj każdy gość to skarb i nie pozwólmy mu czekać na odebranie rezerwacji przez zabieganego kelnera, który przy ograniczonym składzie osobowym ma znacznie więcej obowiązków i może nie zdążyć dobiec do telefonu. Z naszych wyliczeń wynika, że system rezerwacyjny pozwala zaoszczędzić miesięcznie ponad 20 godzin pracy kelnera lub hosta, ponieważ około 50% rezerwacji automatycznie przechodzi z telefonu do internetu i dociąża Twój zespół. Czysta oszczędność!",
  "covid.restaurant.7.strong": "OPTYMALIZACJA WYKORZYSTANIA SALI: ",
  "covid.restaurant.7.text":
    "W przypadku zmniejszonego obłożenia restauracji, optymalizacja zarządzania rezerwacjami i wykorzystaniem stolików jest sprawą kluczową dla biznesu. Sprawdź technologiczne gotowe rozwiązania, które ułatwią kontrolę nad rezerwacjami wpływającymi ze wszystkich kanałów komunikacji: strona, aplikacja, social media, telefon, walk-in. Takim rozwiązaniem jest platforma MojStolik.pl.",
  "covid.restaurant.8.strong": "MINIMALIZACJA EFEKTU NON-SHOW: ",
  "covid.restaurant.8.text":
    "Rezerwacja, która nie jest odwołana, ale gość się nie pojawia to zmora restauratorów. W momencie, kiedy obłożenie lokalu dozwolone jest tylko na poziomie 55-75% takie zachowania gości mają realne konsekwencje finansowe. Dlatego postaraj się zminimalizować ryzyko takich zdarzeń. MojStolik.pl wysyła gościom przypomnienia SMS o rezerwacji. Jej ewentualne odwołanie to kwestia kliknięcia w wysłany link. Korzystając z tego rozwiązania zwiększasz możliwość pełnego wykorzystania potencjału swojej restauracji i unikasz strat. W Londynie gastronomowie zbuntowali się, bo to nie jest czas na stratę ich szansy zarabiania.",
  "covid.restaurant.9.strong": "BEZPIECZNE MENU: ",
  "covid.restaurant.9.text":
    "Zalecane jest, aby menu w restauracjach były jednorazowe i wyrzucane za każdym razem, kiedy trafi do czyiś rąk. Takie obostrzenia wprowadzone zostały jako obowiązek np. w niektórych stanach USA. Nie jest to ani ekonomiczne ani ekologiczne. Może zatem warto zastanowić się nad opcją prezentacji swojego menu w smartfonie gościa? Użytkownicy MojStolik.pl już mają taką możliwość. Kelner zyskuje możliwość zaproponowania gościom, którzy ściągnęli aplikację i w ten sposób zapoznali się z menu. Można też przed wizytą z aplikacji wysłać SMS z linkiem i poprosić gości, aby już wcześniej wprowadzili się w klimat wieczoru wybierając dania.☺ Aplikację można też pobrać przy stoliku przez QR kod. Szybka, nowoczesna i pomysłowa opcja, dla niektórych gości. Warto ją proponować.",
  "covid.restaurant.10.strong": "DOSTOSUJ KUCHNIĘ: ",
  "covid.restaurant.10.text":
    "Menu powinno być dosyć krótkie i w większości oparte na produktach sezonowych, lokalnych lub po prostu łatwo dostępnych. Inne warianty to ryzyko, chyba że nastawiasz się na dodatkową kartę dań – łatwą do wymiany. W razie problemów i nowych obostrzeń, np. z przemieszczaniem się na długich dystansach lub zamknięciem granic, nie będziesz miał problemów z dostawami i zdobyciem produktów dla szefa kuchni. Przeorganizowanie pracy kuchni z dnia na dzień będzie znacznie prostsze. Nie bez znaczenia są też koszty.",
  "covid.restaurant.11.strong": "POBIERAJ ODPOWIEDZIALNYCH DOSTAWCÓW: ",
  "covid.restaurant.11.text":
    "Szukaj partnerów w biznesie. Firmy, które pomogą, zrozumieją i dadzą nowe rozwiązania. Poszukaj dostawców wysokojakościowych produktów w sąsiedztwie swojego biznesu – poleganie na długodystansowych dostawach i trudno dostępnych produktach jest w obecnych czasach zbyt ryzykowne. Informacje co robią firmy dla swoich restauracji/partnerów znajdziesz na portalu www.fortalks.pl.",
  "covid.restaurant.header": "PORADNIK DLA ODPOWIEDZIALNEJ RESTAURACJI",
  "covid.restaurant.title": "PORADNIK DLA ODPOWIEDZIALNEJ RESTAURACJI",
  "covid.restaurant.text1":
    "Wszyscy wiemy, że restauracje w Polsce walczą o przetrwanie, dlatego mają w większości wysokie standardy pracy, higieny i komunikacji z gośćmi. Pójdźmy krok dalej. Platforma MojStolik.pl i zespół FOR Solutions wypracowały system i pakiet gotowych rozwiązań na wypadek nowych obostrzeń i ewentualną drugą falę pandemii. Celem poradnika jest pomoc restauracjom w biznesowym przetrwaniu i też maksymalne ograniczenie niebezpieczeństwa zakażeń koronawirusem.",
  "covid.restaurant.text2":
    "Pierwsza fala koronawirusa i związany z nią lockdown był dla rynku gastronomicznego prawdziwym szokiem. Otrząśnięcie się z niego i przejście do działania w nowej rzeczywistości zajęło wszystkim trochę czasu. Teraz jesteśmy już oswojeni z sytuacją, bogatsi w wiedzę i doświadczenia, jak radzić sobie w sytuacjach kryzysowych. Jednak we własnym i dobrze pojętym interesie, wspólnie z gośćmi, restauracje muszą robić wszystko, aby zapobiec rozprzestrzenianiu się wirusa. Zrobiliśmy podsumowanie, jak przygotować swoją restaurację do pracy w obliczu rosnącej ilości zakażeń:",
  "covid.restaurant.welcome1": "Drogi Restauratorze",
  "covid.restaurant.welcome2":
    "Oto 11 rozwiązań, które pomogą utrzymać biznes w tych trudnych czasach!",
  "covid.restaurant.summary":
    "Mamy nadzieję, że ten zbiór porad będzie pomocny w adaptacji Twojej restauracji do nowej rzeczywistości i w przygotowaniu się do ewentualnych utrudnień. W razie dodatkowych pytań, służymy pomocą. Jeśli chcesz uzyskać więcej informacji co do współpracy z nami, wypełnił formularz. Oddzwonimy lub odpiszemy.",
  "covid.restaurant.greetings": "Chcemy pomóc, dlatego łączymy siły!",
  "covid.restaurant.team": "Zespół MojStolik.pl i FOR Solutions.",
  "covid.client.page.title": "PORADNIK DLA ODPOWIEDZIALNYCH GOŚCI",
  "covid.client.dear.guest": "Drogi Gościu",
  "covid.client.description":
    " Bez Ciebie żadna restauracja nie ma racji bytu. TO wszystko dla Ciebie. Jeśli nie będziesz wspierał gastro-biznesu, to zniknie on z ulic naszych miast. A przyznaj, że świat bez barów i restauracji byłby strasznie smutny i pusty, bo spotkania przy stole łączą, bawią i rozwijają nasze życie towarzyskie.",
  "covid.client.description2":
    "Zatem apelujemy – wspieraj swoje ulubione restauracje, odwiedzaj je, ale rób to z głową. W dobie koronawirusa nasza wspólna społeczna odpowiedzialność jest niesamowicie ważna. Zwróć też uwagę na niektóre swoje zachowania, które powodują realne straty w restauracjach. Dajemy ich przykłady, aby pomóc restauracjom działać sprawniej. A na koniec, pamiętaj o wszystkich zasadach bezpieczeństwa tak, aby pomóc utrzymywać restauracjom wysokie standardy pracy, higieny i organizacji. Oni o to dbają! Dbaj i TY!",
  "covid.client.description3":
    "Jak zatem odpowiedzialnie cieszyć się czasem w ulubionych restauracjach? MojStolik.pl i FOR Solutions przygotowało dla Ciebie kilka podpowiedzi:",
  "covid.client.summary":
    "Stosując się do tych prostych zasad zadbasz o bezpieczeństwo swoje i załogi Twojej ulubionej restauracji, a będąc tam i rozkoszując się ich pysznymi daniami, realnie ją wspierasz i pomagasz przetrwać trudny czas. Obraz naszych miast bez restauracji byłby tragiczny…",
  "covid.client.1.strong": "PODSTAWOWE ZASADY HIGIENY – ",
  "covid.client.1.text":
    "czyli jak wchodzisz do lokalu, załóż maseczkę i zdejmij ją, dopiero jak usiądziesz przy stole, zdezynfekuj ręce przed wejściem (restauracje mają obowiązek posiadania dozownika z płynem na wejściu). Jak udajesz się do toalety, ponownie zakryj usta i nos maseczką. Te kwestie są naprawdę kluczowe dla ograniczenia ryzyka zarażenia się COVID-19. ",
  "covid.client.2.strong": "DYSTANS SPOŁECZNY – ",
  "covid.client.2.text":
    "wiemy, że utrzymywanie odpowiedniego dystansu jest kłopotliwe i często się zapominamy, ale wystarczą proste zasady: nie utrudniaj życia zespołowi i nie zmieniaj położenia stolików bez zgody kelnera lub managera. Zostały rozstawione tak, aby zapewnić Tobie bezpieczeństwo.",
  "covid.client.3.strong": "WYBÓR STOLIKA – ",
  "covid.client.3.text":
    "kiedy wchodzisz do restauracji (w maseczce na twarzy!), poczekaj aż kelner wskaże Ci odpowiedni stolik. W ten sposób ułatwisz mu pracę i bezpieczne rozsadzenie gości. Jeśli możesz wybrać miejsce sam to siadaj tylko przy stolikach, które są oznaczone jako zdezynfekowane.",
  "covid.client.4.strong": "REZERWACJA – ",
  "covid.client.4.text":
    "jeśli wiesz, że wybierasz się do restauracji np. dziś wieczorem, zarezerwuj wcześniej stolik. To ułatwia pracownikom restauracji rozplanowanie usadzenia gości, ale również zaplanowanie zaopatrzenia i pracy personelu. Poza tym skraca to maksymalnie czas Twojego kontaktu z personelem, a to jest korzystne i dla Ciebie i dla nich. Łatwo i szybko zarezerwujesz stolik w wybranej restauracji za pomocą aplikacji MojStolik.pl. Po co czekać na stolik, jeśli może on czekać na Ciebie?",
  "covid.client.5.strong": "ODWOŁUJ REZERWACJĘ, JEŚLI NIE MOŻESZ PRZYJŚĆ! ",
  "covid.client.5.text":
    "Nie pojawienie się w restauracji pomimo zrobionej rezerwacji jest nie tylko, delikatnie mówiąc, niegrzeczne, ale też naraża restauratora na realne straty. Nie może nikomu przydzielić Twojego stolika, bo przecież jest zarezerwowany. Wziął pod uwagę Ciebie i Twoich współbiesiadników podczas planowania zaopatrzenia. Taka sytuacja to realna strata finansowa dla niego, teraz kiedy i tak obłożenie restauracji jest zmniejszone, jest to szczególnie dotkliwe. Ułatwieniem dla Ciebie będzie też platforma MojStolik.pl – nie tylko przypomni Ci smsem, o której i gdzie szef kuchni na Ciebie czeka, ale też maksymalnie ułatwi Ci odwołanie rezerwacji w razie potrzeby. To tylko kliknięcie w jeden link!",
  "covid.client.6.strong": "BEZDOTYKOWE MENU – ",
  "covid.client.6.text":
    "jeśli masz w swoim smartfonie aplikację do rezerwacji stolików, umożliwi Ci ona też zapoznanie się z menu bez konieczności brania do rąk tego restauracyjnego. Jeśli nie masz aplikacji, ściągnij ją za pomocą specjalnych QR kodów, które masz dostępne przy stolikach.",
  "covid.client.7.strong": "PŁAĆ ZBLIŻENIOWO – ",
  "covid.client.7.text":
    "unikaj bezpośrednich kontaktów i płatności gotówkowych. Płać kartą kredytową, blikiem lub telefonem.",
  "covid.client.8.strong": "NIE KRYTYKUJ – ",
  "covid.client.8.text":
    "to i tak jest bardzo trudny czas dla branży, która praktycznie codziennie walczy o przetrwanie. Dlatego też powstrzymaj się od publicznego wyrażania krytycznych uwag, a skup się na pozytywnych emocjach związanych z biesiadowaniem. Dobrych emocji wszyscy teraz bardzo potrzebujemy, a szczególnie restauracje, które starają podwójnie, aby przetrwać.",
  "covid.client.9.strong": "WSPIERAJ – ",
  "covid.client.9.text":
    "jeśli byłeś zadowolony z wizyty, koniecznie opowiedz o tym w swoich social mediach, zrób INSTA STORIES i baw się dobrze zachwalając dania i przyciągając innych gości do Twojej ulubionej restauracji!",

  /* --forRestaurants page 2.0 - global-- */
  "forRestaurantsPage.global.perMonth": "zł/miesięcznie",
  "forRestaurantsPage.global.perMonth.short": "zł/mies.",
  "forRestaurantsPage.global.button.choose": "WYBIERZ",
  "forRestaurantsPage.global.button.next": "DALEJ",
  "forRestaurantsPage.global.button.back": "WRÓĆ",
  "forRestaurantsPage.global.button.accept": "AKCEPTUJ",
  "forRestaurantsPage.global.button.discover": "ZOBACZ DOSTĘPNE PLANY",
  "forRestaurantsPage.global.button.send": "WYŚLIJ",
  "forRestaurantsPage.global.button.learnMore": "Czytaj więcej",
  /* --forRestaurant page 2.0-- */
  "forRestaurantsPage.apiError": "Problemy techniczne. Proszę spróbować później.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.PL TO WIĘCEJ NIŻ SYSTEM",
  "forRestaurantsPage.1.subsection.1.header": "PORTAL I APLIKACJA DLA GOŚCI",
  "forRestaurantsPage.1.subsection.1.text":
    "Prosta i wygodna platforma i aplikacja umożliwiająca błyskawiczną rezerwację stolika w Twojej restauracji.",
  "forRestaurantsPage.1.subsection.2.header": "SYSTEM TELEFONICZNY (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "System telefoniczny (IVR) przyjmie rezerwacje zamiast Twojego zespołu, który może poświęcić się obsłudze Twoich gości w restauracji.",
  "forRestaurantsPage.1.subsection.3.header": "PORTAL I APLIKACJA DLA RESTAURACJI",
  "forRestaurantsPage.1.subsection.3.text":
    "Zarządzanie rezerwacjami i stolikami w sposób automatyczny lub manualnie z aplikacji na tablet lub portalu restauracji.",
  "forRestaurantsPage.2.header": "ZARZĄDZAJ Z POZIOMU APLIKACJI LUB PRZEGLĄDARKI",
  "forRestaurantsPage.2.text":
    "Korzystaj z zaawansowanych funkcji aplikacji na tablet oraz interfejsu na przeglądarkę. Zapomnij o książce rezerwacji i przejdź na rozwiązanie uniwersalne dla każdego typu restauracji.",
  "forRestaurantsPage.3.header": "ZAPOMNIJ O NO-SHOW",
  "forRestaurantsPage.3.text":
    "Z systemem MOJSTOLIK.PL minimalizujesz ryzyko pustych stolików przy których powinni siedzieć goście dzięki:",
  "forRestaurantsPage.3.listItem.1":
    "SMS-owym potwierdzeniom i przypomnieniom o dokonanej rezerwacji",
  "forRestaurantsPage.3.listItem.2": "Możliwości szybkiego i łatwego anulowania rezerwacji",
  "forRestaurantsPage.3.listItem.3": "Analizie historycznej aktywności gości",
  "forRestaurantsPage.4.header": "SKUTECZNIE KIERUJEMY RUCHEM DO RESTAURACJI",
  "forRestaurantsPage.5.header": "CO MÓWIĄ O NAS RESTAURATORZY?",
  "forRestaurantsPage.5.opinion.1.title": "Mariusz Diakowski, właściciel restauracji Stary Dom:",
  "forRestaurantsPage.5.opinion.1.text":
    "Praca z MojStolik.pl to była dla Stary Dom prawdziwa rewolucja. Ani ja ani zespół restauracji nie wyobrażamy już sobie powrotu do ery „księgi i ołówka”. Faktycznie MojStolik.pl prowadził na naszej restauracji testy swojej platformy. Mieliśmy trochę obaw, ale zdecydowaliśmy się zaryzykować. Absolutnie nie żałujemy! Wszystkie nasze uwagi i sugestie były od razu wdrażane. Dzięki testowaniu „na żywym organizmie” ten system naprawdę odpowiada potrzebom restauracji z dużym obłożeniem, niesamowicie ułatwia pracę i skraca jej czas. Koniec z długopisami, ołówkami, korektorami sklejającymi strony, nieczytelnym pismem i nie praktycznymi księgami. Teraz wszystko jest uporządkowane i proste w obsłudze. Dodam też, że platforma jest praktycznie bezawaryjna, a jeśli jest taka potrzeba to helpdesk działa bez zarzutu.",
  "forRestaurantsPage.5.opinion.2.title": "Marek Katrycz, Dyrektor Operacyjny w STIXX Bar&Grill:",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl jest to bardzo nowoczesny i nowatorski system, dzięki któremu możemy jako duża restauracja skutecznie zarządzać rezerwacjami Gości oraz rozplanowaniem ich usadzenia, co niesamowicie upraszcza pracę naszego Zespołu. Zdecydowanie polecamy ten system, stale polepszają jego funkcjonalność, jest on intuicyjny oraz bardzo łatwy w obsłudze.Skrócił nam czas przyjmowania rezerwacji oraz odszukiwania ich!, co szczególnie pomaga przy dużym obłożeniu.",
  "forRestaurantsPage.5.opinion.3.title": "Robert Goluba, Brand Manager Bombaj Masala:",
  "forRestaurantsPage.5.opinion.3.text":
    "Dlaczego zdecydowaliśmy się na system MojStolik.pl w naszych restauracjach? Dobre opinie w branży plus naprawdę miłe i fachowe podejście do nowego klienta. System jest bardzo intuicyjny, ale szkolenia dla załogi trwały tyle czasu, aż każdy pracownik restauracji umiał obsługiwać panel, dzięki temu nie było problemów z wdrożeniem Mojego Stolika w restauracji. Obyło się bez żadnych problemów, co przy nowych oprogramowaniach jest chyba niespotykane.Wystarczył pierwszy dzień z dużą ilością gości i rezerwacji w naszej restauracji, by przekonać się o zaletach rozwiązań w systemie MojStolik.pl. Jestem pewny, że pozwolił nam on (i nadal to robi) przyjmować płynniej gości i co za tym idzie - więcej gości obsługiwać. To naturalnie przekłada się na wynik finansowy. Gdyby po kilku tygodniach ktoś nakazałby mi powrót do pracy z tradycyjną księgą rezerwacji, czułbym się jak… w XX wieku.",
  "forRestaurantsPage.6.header": "POZNAJ PLANY MOJSTOLIK.PL",
  "forRestaurantsPage.6.subheader":
    "Wybierz plan dla Twojej restauracji i rozpocznij z nami współpracę.",
  "forRestaurantsPage.discountCodeInput": "Wpisz kod promocyjny",
  "forRestaurantsPage.discountCodeButton": "Użyj",
  "forRestaurantsPage.discountCodeLoading": "Trwa ładowanie...",
  "forRestaurantsPage.discountCodeSuccess": "Kod promocyjny wczytany",
  "error.promotion.not.exists": "Podany kod promocyjny nie istnieje",
  "forRestaurantsPage.allOffers": "Aby poznać szczegółowe porównanie planów MOJSTOLIK.PL",
  "forRestaurantsPage.allOffers.orange": "przejdź tutaj",
  "forRestaurantsPage.form.title":
    "Jeśli nie jesteś zdecydowana(-y) i chciałabyś/chciałbyś dowiedzieć się więcej o platformie MojStolik.pl proszę wypełnij ten krótki formularz a skontaktujemy się z Tobą:",
  /* --forRestaurant page 2.0 - komponent ofert-- */
  "forRestaurantsPage.offers.offerItem.1.header": "LIGHT",
  "forRestaurantsPage.offers.offerItem.1.text":
    "Dla restauracji, które cenią prostotę automatyzacji procesów kolekcji rezerwacji w czasie rzeczywistym.",
  "forRestaurantsPage.offers.offerItem.2.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.2.text":
    "Dla restauracji, które oprócz samych rezerwacji dodatkowo chcą zarządzać salami i stolikami.",
  "forRestaurantsPage.offers.offerItem.3.header": "ADVANCED",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "Dla dużych restauracji, w których pełna automatyzacja wszystkich kanałów komunikacji z gośćmi jest nieodzowna.",
  "forRestaurantsPage.offers.subscription": "Abonament",
  "forRestaurantsPage.offers.commission": "Prowizja",
  "forRestaurantsPage.offers.commission.currency": "zł",
  "forRestaurantsPage.offers.header": "Opcje dodatkowe",
  "forRestaurantsPage.offers.additional-options.prepayments": "Moduł przedpłat do rezerwacji",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Automatyczna obsługa rezerwacji telefonicznych (IVR) z dodatkowym pakietem 300 SMS/mc",
  "forRestaurantsPage.offers.additional-options.smsPackage":
    "Pakiet dodatkowych wiadomości SMS - 1000 szt./mc",
  "forRestaurantsPage.offers.mostPopular": "Najczęstszy wybór",
  "forRestaurantsPage.offers.pricingSetting1":
    "Powyższe ceny obowiązują w przypadku rocznego okresu rozliczeniowego. Przełącz na rozliczenie miesięczne: ",
  "forRestaurantsPage.offers.pricingSetting2":
    "Powyższe ceny obowiązują w przypadku miesięcznego okresu rozliczeniowego. Przełącz na rozliczenie roczne: ",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1. Wszystkie ceny w ofercie są cenami netto w zł i w momencie wystawienia faktury zostanie doliczony ustawowy podatek VAT według stawki obowiązującej w dniu wystawienia faktury.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2. W przypadku rocznego modelu płatności prezentowana jest wysokość miesięcznej opłaty, natomiast płatność jest jednorazowa za 12 miesięcy z góry.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3. Rabat marketingowy jest przyznawany w wysokość 50 zł netto od miesięcznej opłaty, pod warunkiem udostępnianie przez restaurację linku do rezerwacji poprzez MOJSTOLIK.PL w Social Media restauracji co najmniej 2 razy w każdym miesiącu. ",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4. Koszt SMS-a poza abonamentem wynosi 0,09 zł netto.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5. W przypadku korzystania z Modułu Przedpłat od każdej transakcji pobierana jest przez operatora płatności prowizja w wysokości 1,59% od kwoty transakcji.",

  /* --forRestaurant page 2.0 - porównanie ofert-- */
  "forRestaurantsPageAllOffers.header": "Poznaj Plany MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Wybierz plan dla Twojej restauracji i rozpocznij z nami współpracę.",
  "forRestaurantsPageAllOffers.offer.1": "LIGHT",
  "forRestaurantsPageAllOffers.offer.2": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3": "ADVANCED",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Kanały rezerwacji dostępne dla klienta",
  "forRestaurantsPageAllOffers.listHeader.2": "Zarządzanie rezerwacjami w restauracji",
  "forRestaurantsPageAllOffers.listHeader.3": "Konfiguracja profilu restauracji",
  "forRestaurantsPageAllOffers.listHeader.4": "Dostępność dla restauracji z urządzeń",
  "forRestaurantsPageAllOffers.listHeader.5": "Dodatkowe usługi",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1": "Rezerwacje ze strony www.mojstolik.pl",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Rezerwacje z dedykowanej aplikacji mobilnej MojStolik",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3": "Rezerwacje ze strony www restauracji",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Rezerwacje z Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Rezerwacje z Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Potwierdzenia / przypomnienia o rezerwacij poprzez SMS/Notyfukację aplikacji**",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7":
    "Automatyczna obsługa rezerwacji telefonicznych",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8": "Rezerwacje z Instagrama restauracji",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Rezerwacje ze skrzynki mailowej (autoresponder)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Linki / QR kody w artykułach i materiałach PR restauracji",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Lista rezerwacji - widok tabelaryczny",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Rezerwacje na mapie sali",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Widok Gantt'a (grafik rezerwacji)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4": "Szczegóły i edycja rezerwacji",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Zaawansowane zarządzanie rezerwacjami",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Codzienny raport z rezerwacji",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7": "Obsługa przedpłat do rezerwacji",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Obsługa walk-in",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Historia rezerwacji / analiza upodobań i zachowań gości",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": "Minimalizacja efektu no-show",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Praca w trybie live",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "Baza klientów restauracji / zgodność z RODO",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "BEZPIECZEŃSTWO DANYCH / Szyfrowany system i różne poziomy dostępu oparte na rolach",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Wizytówka i indywidualny profil restauracji na portalu klienta i w aplikacji mobilnej",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Dane opisowe restauracji",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Materiały graficzne",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Zarządzanie godzinami dostępności",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Przerwy i dni wyjątkowe",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6": "Parametryzacja przyjmowania rezerwacji",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Konfiguracja sali i stolików",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Portal zarządzania dla restauracji",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Aplikacja dla restauracji na tablet",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1": "Help desk i wsparcie techniczne",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
    "Szkolenie wdrożeniowe dla załogi restauracji",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Obecność / promocja restauracji na kanałach social media MojStolik.pl",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Liczba wiadomości SMS dostępnych w abonamencie",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/mc",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/mc",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/mc",
  "forRestaurantsPageAllOffers.listItem.optional": "Opcja",
  /* --forRestaurant page 2.0 - formularz-- */
  "forRestaurantsPageForm.downloadButton": "POBIERZ DANE",
  "forRestaurantsPageForm.header": "Tutaj rozpoczynasz współpracę z mojstolik.pl",
  "forRestaurantPageForm.apiError":
    "Błąd pobierania danych oferty. Prosimy spróbować później i przepraszamy za utrudnienia",
  "forRestaurantPageForm.paramsError":
    "Przepraszamy, nie udało wyświetlić się szczegółów oferty. Aby zapoznać się z naszymi ofertami przejdź do",
  "forRestaurantPageForm.paramsError.link": "strony ofert",
  "forRestaurantsPageForm.chosenPlan": "Wybrany plan MOJSTOLIK.PL",
  "forRestaurantsPageForm.discountCode": "Promocja: ",
  "forRestaurantsPageForm.payment.yearly": "Wybrany plan jest rozliczany z rocznym okresem.",
  "forRestaurantsPageForm.payment.monthly": "Wybrany plan jest rozliczany z miesięcznym okresem.",
  "forRestaurantsPageForm.additionalOptions": "Wybrane opcje dodatkowe:",
  "forRestaurantsPageForm.formHeader.1": "Podaj podstawowe dane restauracji",
  "forRestaurantsPageForm.formHeader.2": "Podaj dane osoby kontaktowej",
  "forRestaurantsPageForm.formHeader.3": "Podaj dane do Umowy",
  "forRestaurantsPageForm.formHeader.4": "Podaj dane osoby reprezentującej podmiot",
  "forRestaurantsPageForm.formHeader.5": "Umowa MOJSTOLIK.PL",
  "forRestaurantsPageForm.restaurantName": "Nazwa restauracji",
  "forRestaurantsPageForm.street": "Ulica",
  "forRestaurantsPageForm.apartment": "Lokal",
  "forRestaurantsPageForm.postalCode": "Kod pocztowy",
  "forRestaurantsPageForm.city": "Miejscowość",
  "forRestaurantsPageForm.email": "Adres e-mail",
  "forRestaurantsPageForm.phoneNumber": "Numer telefonu",
  "forRestaurantsPageForm.website": "Adres strony www",
  "forRestaurantsPageForm.website.menu": "Adres strony www z menu restauracji",
  "forRestaurantsPageForm.name": "Imię",
  "forRestaurantsPageForm.surname": "Nazwisko",
  "forRestaurantsPageForm.title": "Tytuł",
  "forRestaurantsPageForm.entity": "Nazwa podmiotu",
  "forRestaurantsPageForm.email.invoice": "Adres e-mail do faktur",
  "forRestaurantsPageForm.email.contact": "Adres e-mail kontaktowy",
  "forRestaurantsPageForm.proxy": "Typ pełnomocnictwa",
  "forRestaurantsPageForm.select.owner": "Właściciel",
  "forRestaurantsPageForm.select.manager": "Menedżer",
  "forRestaurantsPageForm.select.boardMember": "Członek zarządu",
  "forRestaurantsPageForm.select.other": "Inny",
  "forRestaurantsPageForm.contract": "Treść umowy",
  "forRestaurantsPageForm.pdfLink": "Pobierz pdf",
  "forRestaurantsPageForm.statement.1":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl",
  "forRestaurantsPageForm.statement.2":
    "Akceptuję warunki powyższej umowy oraz regulaminu MOJSTOLIK.PL i oświadczam, że jestem upoważniony do zawaria umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl w imieniu restauracji.",
  "forRestaurantsPageForm.loading": "Twój formularz jest wysyłany...",
  "forRestaurantsPageForm.success": "Wysyłanie formularza się powiodło",
  "forRestaurantsPageForm.nipError": "Wpisz poprawny numer NIP",
  "forRestaurantsPageForm.loadingData": "Trwa pobieranie danych...",
  "forRestaurantsPageForm.error": "Wystąpił błąd.",
  "forRestaurantsPageForm.dataSuccess": "Dane pobrane prawidłowo",
  "forRestaurantsPageForm.generatingContract": "Trwa generowanie umowy...",
  /* --forRestaurant page 2.0 - sukces-- */
  "forRestaurantsPageSuccess.header": "Dziękujemy, że wybrałeś ofertę Mojstolik.pl",
  "forRestaurantsPageSuccess.text.1":
    "ZA CHWILĘ NA PODANY ADRES E-MAIL ZOSTANIE WYSŁANY DOKUMENT UMOWY, KTÓRA ZOSTAŁA ZAAKCEPTOWANA W POPRZEDNIM KROKU.",
  "forRestaurantsPageSuccess.text.2":
    "RÓWNOLEGLE PRZYGOTUJEMY I PRZEKAŻEMY CI NIEZBĘDNE INSTRUKCJE, KTÓRE UMOŻLIWIĄ CI SKONFIGUROWANIE TWOJEJ RESTAURACJI W SYSTEMIE i ROZPOCZĘCIE WSPÓŁPRACY.",
  "forRestaurantsPageSuccess.text.3": "do zobaczenia!",
  "forRestaurantsPageSuccess.button": "Wróć na stronę główną",

  /* -- global -- */
  "global.messages.sessionTimeout": "Sesja wygasła",
  "global.form.message.requiredField": "To pole jest wymagane",
  "global.form.message.vatIDNumber": "NIP powinien zawerać 10 cyfr",
  "global.form.message.invalidEmailFormat": "Nieprawidłowy format adresu e-mail",
  "global.form.message.invalidPhoneFormat": "Nieprawidłowy format numeru telefonu",
  "global.form.message.invalidBirthDateFormat": "Nieprawidłowy format daty urodzenia",
  "global.form.message.invalidPostCodeFormat":
    "Nieprawidłowy format kodu pocztowego. Oczekiwany XX-XXX",
  "global.form.message.invalidNipFormat": "Nieprawidłowy format numeru NIP",
  "global.form.message.invalidKrsFormat": "Nieprawidłowy format numeru KRS",
  "global.form.message.invalidRegonFormat": "Nieprawidłowy format numeru REGON",
  "global.form.message.invalidUrlFormat": "Nieprawidłowy format adresu WWW",
  "global.form.message.newPasswordsDoNotMatch": "Nowe hasła różnią się!",
  "global.form.message.atLeastNineCharacters": "Wymagane co najmniej dziewięć znaków",
  "global.time.minutes": "minut",
  /* -- metadata -- */
  "aboutPage.metadata.title": "O nas",
  "aboutPage.metadata.description": "o nas opis",
  "aboutPage.metadata.keywords": "o nas slowa kluczowe",
  "accountPage.metadata.title": "Twoje konto",
  "accountPage.metadata.description": "Twoje konto opis",
  "accountPage.metadata.keywords": "Twoje konto słowa kluczowe",
  "contactPage.metadata.title": "Kontakt",
  "contactPage.metadata.description": "Kontakt opis",
  "contactPage.metadata.keywords": "Kontakt słowa kluczowe",
  "documentsPage.metadata.title": "Dokumenty",
  "documentsPage.metadata.description": "Dokumenty opis",
  "documentsPage.metadata.keywords": "Dokumenty słowa kluczowe",
  "forGuestPage.metadata.title": "Dla gościa",
  "forGuestPage.metadata.description": "Dla gościa opis",
  "forGuestPage.metadata.keywords": "Dla gościa słowa kluczowe",
  "howItWorksPage.metadata.title": "Jak to działa?",
  "howItWorksPage.metadata.description": "Jak to działa opis",
  "howItWorksPage.metadata.keywords": "Jak to działa słowa kluczowe",
  "loginPage.metadata.title": "Logowanie",
  "loginPage.metadata.description": "Logowanie opis",
  "loginPage.metadata.keywords": "Logowanie słowa kluczowe",
  "resetPasswordPage.metadata.title": "Zresetuj hasło",
  "resetPasswordPage.metadata.description": "Zresetuj hasło opis",
  "resetPasswordPage.metadata.keywords": "Zresetuj hasło słowa kluczowe",
  "logoutPage.metadata.title": "Wylogowanie",
  "logoutPage.metadata.description": "Wylogowanie opis",
  "logoutPage.metadata.keywords": "Wylogowanie słowa kluczowe",
  "notFoundPage.metadata.title": "404 - Strona nieznaleziona!",
  "notFoundPage.metadata.description": "404 - Strona nieznaleziona opis",
  "notFoundPage.metadata.keywords": "",
  "priceListPage.metadata.title": "Cennik",
  "priceListPage.metadata.description": "Cennik opis",
  "priceListPage.metadata.keywords": "Cennik słowa kluczowe",
  "registrationPage.metadata.title": "Rejestracja",
  "registrationPage.metadata.description": "Rejestracja opis",
  "registrationPage.metadata.keywords": "Rejestracja słowa kluczowe",
  "reservationHistoryPage.metadata.title": "Twoje rezerwacje",
  "reservationHistoryPage.metadata.description": "Twoje rezerwacje opis",
  "reservationHistoryPage.metadata.keywords": "Twoje rezerwacje słowa kluczowe",
  "searchMapPage.metadata.title": "Wyniki wyszukiwania",
  "searchMapPage.metadata.description": "Wyniki wyszukiwania opis",
  "searchMapPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
  "searchPage.metadata.title": "Wyniki wyszukiwania",
  "searchPage.metadata.description": "Wyniki wyszukiwania opis",
  "searchPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
  "privacyPolicyPage.metadata.title": "Polityka prywatności",
  "privacyPolicyPage.metadata.description": "",
  "privacyPolicyPage.metadata.keywords": "",
  "cookiesPolicyPage.metadata.title": "Polityka Cookies",
  "cookiesPolicyPage.metadata.description": "",
  "cookiesPolicyPage.metadata.keywords": "",
  /* -- shortcuts -- */
  "shortcut.isClosed": "nieczynne",
  "shortcut.monday": "Pon.",
  "shortcut.tuesday": "Wt.",
  "shortcut.wednesday": "Śr.",
  "shortcut.thursday": "Czw.",
  "shortcut.friday": "Pt.",
  "shortcut.saturday": "Sob.",
  "shortcut.sunday": "Niedz.",
  /* -- default values -- */
  "defaultValues.restaurant": "Restauracja",
  /* -- restaurantPromotionInfoModal component -- */
  "restaurantPromotionInfoModal.button.use": "Skorzystaj",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Szczegóły oferty",
  /* -- previewImageModal component -- */
  "previewImageModal.component.anchor.close": "Zamknij",
  /* -- previewRestaurantModal component -- */
  "previewRestaurantModal.component.anchor.close": "Zamknij",
  /* -- deleteAccountModal component -- */
  "deleteAccountModal.component.header": "Usuń konto",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Czy na pewno chcesz skasować swoje konto? Historia Twoich rezerwacji również zostanie usunięta.",
  "deleteAccountModal.component.button.confirm": "Zatwierdź",
  "deleteAccountModal.component.button.cancel": "Anuluj",
  /* -- cancellationReservationModal component -- */
  "cancellationReservationModal.component.header": "Odwołanie rezerwacji",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Czy na pewno chcesz odwołać rezerwację stolika w restauracji {{restaurantName}} o godz. {{reservationHour}} w dniu {{reservationDate}}?",
  "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
    "Opłata związana rezerwacją została przekazana restauracji. W celu ewentualnego odzyskania środków prosimy o skontaktowanie się bezpośrednio z restauracją.",
  "cancellationReservationModal.component.button.confirm": "Zatwierdź",
  "cancellationReservationModal.component.button.cancel": "Anuluj",
  /* -- searchResultItem component -- */
  "searchResultItem.component.label.availableHours": "Dostępne godziny",
  "searchResultItem.component.label.futureReservationDaysError":
    "Rezerwacje zdalne są możliwe na {{days}} dni do przodu",
  "searchResultItem.component.label.noAvailableTables":
    "Dla wybranych parametrów wyszukiwania (data, godzina, liczba osób) ta restauracja nie oferuje stolików do rezerwacji.",
  "searchResultItem.component.button.book": "Zarezerwuj",
  "searchResultItem.component.button.cancel": "Anuluj",
  "searchResultItem.component.button.see": "Zobacz",
  /* -- passwordFormModal component -- */
  "passwordFormModal.component.header": "Wprowadź hasło do konta",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Wprowadź swoje hasło, aby potwierdzić wykonanie akcji.",
  "passwordFormModal.component.button.confirm": "Zatwierdź",
  "passwordFormModal.component.button.cancel": "Anuluj",
  /* -- cuisine types -- */
  "cuisine.romania": "Kuchnia rumuńska",
  "cuisine.american": "Kuchnia amerykańska",
  "cuisine.arabic": "Kuchnia arabska",
  "cuisine.chinese": "Kuchnia chińska",
  "cuisine.polish": "Kuchnia polska",
  "cusine.chinese": "Kuchnia chińska",
  "cusine.polish": "Kuchnia polska",
  /* -- cookies policy line -- */
  "component.cookiesPolicyLine.text":
    "Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzsz się na ich użycie.",
  "component.cookiesPolicyLine.privacyPolicyText": "Polityka prywatności",
  /* -- header -- */
  "component.header.menu.mainPage": "Rezerwuj",
  "component.header.menu.forRestaurants": "Dla restauracji",
  "component.header.menu.portal.restaurant": "Portal restauracji",
  "component.header.menu.forRestaurants.offer": "Nasza oferta",
  "component.header.menu.blog": "Nowości MOJSTOLIK.PL",
  "component.header.menu.christmas": "OFERTY ŚWIĄTECZNE",
  "component.header.menu.forGuest": "Dla gościa",
  "component.header.menu.howItWorks": "Jak to działa?",
  "component.header.menu.priceList": "Cennik",
  "component.header.menu.contact": "Kontakt",
  "component.header.menu.documents": "Dokumenty",
  "component.header.menu.about": "O nas",
  "component.header.menu.login": "Zaloguj się",
  "component.header.menu.logout": "Wyloguj się",
  "component.header.menu.reservationHistory": "Twoje rezerwacje",
  "component.header.menu.yourAccount": "Twoje konto",
  "component.header.menu.covid": "Poradniki COVID-19",
  "component.header.menu.covid.restaurant": "Poradnik dla restauracji",
  "component.header.menu.covid.client": "Poradnik dla gości",
  "component.header.menuMobile.covid.restaurant": "Poradnik COVID-19 dla restauracji",
  "component.header.menuMobile.covid.client": "Poradnik COVID-19 dla gości",
  "component.header.menu.events": "Wydarzenia",
  "component.header.menu.event": "Wydarzenie - ",
  /* -- footer -- */
  "component.footer.menu.header.forClients": "O MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Informacje",
  "component.footer.menu.header.yourAccount": "Twoje konto",
  "component.footer.menu.header.socialMedia": "Social media",
  "component.footer.menu.header.applicationDownload": "Pobierz aplikację",
  "component.footer.menu.forRestaurants": "Dla restauracji",
  "component.footer.menu.forGuest": "Dla gościa",
  "component.footer.menu.howItWorks": "Jak to działa?",
  "component.footer.menu.priceList": "Cennik",
  "component.footer.menu.contact": "Kontakt",
  "component.footer.menu.documents": "Dokumenty",
  "component.footer.menu.about": "O nas",
  "component.footer.menu.login": "Zaloguj się",
  "component.footer.menu.reservationHistory": "Twoje rezerwacje",
  "component.footer.menu.yourAccount": "Twoje konto",
  "component.footer.menu.logout": "Wyloguj się",
  "component.footer.menu.registration": "Rejestracja",
  "component.footer.menu.privacyPolicy": "Polityka prywatności",
  "component.footer.menu.cookiesPolicy": "Polityka cookies",
  /* -- index page -- */
  "indexPage.background.header": "Znajdź najlepsze restauracje w Twoim mieście,",
  "indexPage.header.currentPromotions": "Oferty specjalne i wydarzenia",
  "indexPage.header.currentPromotionsExtend": "w Twojej lokalizacji",
  "indexPage.header.blogs": "Wyróżnione artykuły",
  "indexPage.header.mostPopular": "Najbardziej popularne",
  "indexPage.header.mostPopularExtend": "w Twojej lokalizacji",
  "indexPage.background.headerExtend": "a następnie zarezerwuj stolik:",
  "indexPage.input.label.location": "Lokalizacja",
  "indexPage.input.label.date": "Data",
  "indexPage.input.label.hour": "Godzina",
  "indexPage.input.label.persons": "Liczba osób",
  "indexPage.input.label.persons.optionExtend": "os.",
  "indexPage.input.label.restaurantType": "Rodzaj kuchni",
  "indexPage.input.label.rating": "Ocena",
  "indexPage.input.label.rating.optionExtend": "gwiazd.",
  "indexPage.input.label.priceLevel": "Poziom cenowy",
  "indexPage.input.label.searchByName": "Wyszukaj po nazwie",
  "indexPage.input.placeholder.select": "Wybierz",
  "indexPage.input.placeholder.type": "Wpisz",
  "indexPage.input.placeholer.typeInOrSelect": "Wpisz lub wybierz",
  "indexPage.input.noData": "Brak wyników.",
  "indexPage.button.search": "Szukaj",
  "indexPage.button.showAdditionalFilters": "Dodatkowe filtry",
  "indexPage.button.hideAdditionalFilters": "Ukryj dodatkowe filtry",
  "indexPage.button.use": "Skorzystaj",
  "indexPage.button.promotion": "promocja",
  "indexPage.button.bookNow": "Zarezerwuj",
  "indexPage.button.readMore": "Czytaj",
  /* -- account page -- */
  "accountPage.header": "Twoje konto",
  "accountPage.subheader":
    "Zmień swoje dane osobiste, aby proces Twojej realizacji przebiegał szybciej.",
  "accountPage.headerInColumn.yourPersonalData": "Twoje dane",
  "accountPage.headerInColumn.yourPassword": "Twoje hasło",
  "accountPage.input.label.firstName": "Imię",
  "accountPage.input.placeholder.firstName": "Np. Jan",
  "accountPage.input.label.lastName": "Nazwisko",
  "accountPage.input.placeholder.lastName": "Np. Kowalski",
  "accountPage.input.label.phoneNumber": "Numer telefonu",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.label.email": "Adres e-mail",
  "accountPage.input.placeholder.email": "Np. adres@email.pl",
  "accountPage.paragraph.optionalFields": "Pola opcjonalne",
  "accountPage.input.label.birthDate": "Data urodzenia",
  "accountPage.input.placeholder.birthDate": "Np. 1976-10-25",
  "accountPage.input.label.postalCode": "Kod pocztowy",
  "accountPage.input.placeholder.postalCode": "Np. 03-123",
  "accountPage.input.label.city": "Miasto",
  "accountPage.input.placeholder.city": "Np. Warszawa",
  "accountPage.input.label.streetAndLocalNumber": "Ulica i numer mieszkania",
  "accountPage.input.placeholder.streetAndLocalNumber": "Np. Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Metody komunikacji:",
  "accountPage.button.save": "Zapisz",
  "accountPage.button.deleteAccount": "Usuń konto",
  "accountPage.input.label.oldPassword": "Stare hasło",
  "accountPage.input.label.newPassword": "Nowe hasło",
  "accountPage.input.label.newPasswordRepeat": "Nowe hasło (powtórz)",
  "accountPage.button.changePassword": "Zmień hasło",
  "accountPage.message.personalDataUpdated": "Dane osobowe zostały zaktualizowane.",
  "accountPage.message.accountPermanentlyDeleted": "Twoje konto zostało permanentnie skasowane.",
  "accountPage.message.passwordChanged": "Hasło zostało zmienione.",
  "accountPage.label.changePasswordNotPossible":
    "Zmiana hasła jest niemożliwa, gdyż zalogowałeś się przez Facebook'a lub Google'a.",
  "accountPage.toast.error.disableBothChannels":
    "Co najmniej jedna z metod komunikacji (SMS, E-MAIL) musi pozostać aktywna!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Włączenie metody komunikacji wymaga potwierdzenia poprawności danych. Zapisz zmiany, aby dostać link weryfikacyjny na nowy adres email!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Włączenie metody komunikacji wymaga potwierdzenia poprawności danych. Zapisz zmiany, aby dostać link weryfikacyjny SMSem na Twój nowy numer telefonu!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "W celu włączenia nowej metody komunikacji, potwierdź swoje dane, wchodząc na link aktywacyjny, który został wysłany na Twój adres email!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "W celu włączenia nowej metody komunikacji, potwierdź swoje dane, wchodząc na link aktywacyjny, który został wysłany SMSem na podany numer telefonu!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "W celu weryfikacji Twoich danych, wejdź na link weryfikacyjny, który został wysłany na Twój adres email!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "W celu weryfikacji Twoich danych, wejdź na link weryfikacyjny, który został wysłany SMSem na Twój numer telefonu!",
  "accountPage.channels.disclaimer":
    "* W celu edycji numeru telefonu lub adresu e-mail należy wyłączyć edytowany moduł komunikacji.",
  "accountPage.confirmSms.header" : "Potwierdzenie wiarygodności danych",
  "accountPage.confirmSms.paragraph" : "W celu potwierdzenia swoich danych, wpisz kod wysłany SMSem na podany numer telefonu!",
    /* -- reservation history page -- */
  "reservationHistoryPage.sendMessage": "Wyślij wiadomość",
  "reservationHistoryPage.send": "Wyślij",
  "reservationHistoryPage.cancel": "Anuluj",
  "reservationChatPage.send": "Wyślij",
  "reservationChatPage.writeMessage": "Wpisz wiadomość",
  "reservationHistoryPage.header": "Twoje rezerwacje",
  "reservationHistoryPage.subheader":
    "W tym miejscu możesz przejrzeć swoje historyczne rezerwacje oraz zarządzać przyszłymi rezerwacjami.",
  "reservationHistoryPage.paragraph.personsShortcut": "os.",
  "reservationHistoryPage.button.revoke": "Odwołaj",
  "reservationHistoryPage.button.pay": "Zapłać",
  "reservationHistoryPage.paragraph.additionalDescription": "Komunikacja z restauracją",
  "reservationHistoryPage.paragraph.date": "Data",
  "reservationHistoryPage.paragraph.duration": "Czas trwania",
  "reservationHistoryPage.paragraph.hour": "Godzina",
  "reservationHistoryPage.paragraph.persons": "Liczba osób",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "Rezerwacja nie istnieje",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "Lista rezerwacji jest pusta.",
  "reservationHistoryPage.paragraph.status": "Status",
  "reservationPage.paragraph.lateInfo":
    "Uwaga, w przypadku spóźnienia stolik czeka na Gościa 15 minut!",
  "reservationHistoryPage.paragraph.payment.status": "Status płatności",
  /* -- search page -- */
  "searchPage.header": "Wyniki wyszukiwania",
  "searchPage.button.map": "Mapa",
  "searchPage.button.list": "Lista",
  "searchPage.paragraph.noData": "Brak wyników dla podanych kryteriów.",
  /* -- search map page -- */
  "searchMapPage.map.button.refresh": "Odśwież mapę",
  "searchMapPage.header": "Wyniki wyszukiwania",
  "searchMapPage.button.map": "Mapa",
  "searchMapPage.button.list": "Lista",
  /* -- login page -- */
  "loginPage.header": "Logowanie",
  "loginPage.subheader":
    "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
  "loginPage.columns.header.standardLogin": "Logowanie standardowe",
  "loginPage.columns.header.socialLogin": "Zaloguj się za pomocą",
  "loginPage.input.label.phoneOrEmail": "Numer telefonu/adres e-mail",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.input.label.password": "Hasło",
  "loginPage.input.placeholder.password": "",
  "loginPage.anchor.passwordReset": "Zresetuj hasło",
  "loginPage.button.logIn": "Zaloguj się",
  "loginPage.paragraph.dontHaveAccountYet": "Nie masz jeszcze konta?",
  "modal.login.dontHaveAccountYet": "Nie masz jeszcze konta",
  "loginPage.paragraph.registerNow": "Zarejestruj się!",
  "loginPage.button.register": "Zarejestruj się",
  "loginPage.message.logged": "Zalogowano.",
  "loginPage.message.loggedViaFacebook": "Zalogowano przez Facebook'a.",
  "loginPage.message.loggedViaApple": "Zalogowano przez Apple.",
  "loginPage.message.loggedViaGoogle": "Zalogowano przez Google'a.",
  "loginPage.paragraph.alreadyLoggedIn": "Jesteś aktualnie zalogowany.",
  /* -- Reset password page -- */
  "resetPasswordPage.header": "Zresetuj hasło",
  "resetPasswordPage.subheader":
    "Wypełnij poniższy formularz, a my wyślemy Ci SMS-em lub mailem nowe hasło do konta",
  "resetPasswordPage.subheader2": "Wyślemy ci nowe hasło na Twój numer telefonu",
  "resetPasswordPage.input.label.phoneOrEmail": "Wpisz numer telefonu lub adres e-mail",
  "resetPasswordPage.input.placeholder.phoneOrEmail": "",
  "resetPasswordPage.button.confirm": "Potwierdź",
  "resetPasswordPage.toast.passwordChanged":
    "Nowe hasło zostało wysłane na podany numer telefonu/adres e-mail.",
  "resetPasswordPage.toast.error.passwordChanged": "Podany nr telefonu/adres e-mail jest błędny.",
  /* -- cookies policy page -- */
  "cookiesPolicyPage.header": "Polityka Cookies",
  "cookiesPolicyPage.subheader": "Istotne informacje.",
  "cookiesPolicyPage.point.1":
    "Serwis nie zbiera w sposób automatyczny żadnych informacji, z wyjątkiem informacji zawartych w plikach cookies.",
  "cookiesPolicyPage.point.2":
    "Pliki cookies (tzw. „ciasteczka”) stanowią dane informatyczne, w szczególności pliki tekstowe, które przechowywane są w urządzeniu końcowym Użytkownika Serwisu i przeznaczone są do korzystania ze stron internetowych Serwisu. Cookies zazwyczaj zawierają nazwę strony internetowej, z której pochodzą, czas przechowywania ich na urządzeniu końcowym oraz unikalny numer.",
  "cookiesPolicyPage.point.3":
    "Podmiotem zamieszczającym na urządzeniu końcowym Użytkownika Serwisu pliki cookies oraz uzyskującym do nich dostęp jest właściciel mojstolik.pl spółka Table4Rest Sp. z o.o. z siedzibą pod adresem ul. Ksawerów 21, 02-656 Warszawa.",
  "cookiesPolicyPage.point.4": "Pliki cookies wykorzystywane są w celu:",
  "cookiesPolicyPage.point.4a":
    "dostosowania zawartości stron internetowych Serwisu do preferencji Użytkownika oraz optymalizacji korzystania ze stron internetowych; w szczególności pliki te pozwalają rozpoznać urządzenie Użytkownika Serwisu i odpowiednio wyświetlić stronę internetową, dostosowaną do jego indywidualnych potrzeb;",
  "cookiesPolicyPage.point.4b":
    "tworzenia statystyk, które pomagają zrozumieć, w jaki sposób Użytkownicy Serwisu korzystają ze stron internetowych, co umożliwia ulepszanie ich struktury i zawartości;",
  "cookiesPolicyPage.point.4c":
    "utrzymanie sesji Użytkownika Serwisu (po zalogowaniu), dzięki której Użytkownik nie musi na każdej podstronie Serwisu ponownie wpisywać loginu i hasła;",
  "cookiesPolicyPage.point.5":
    "W ramach Serwisu stosowane są dwa zasadnicze rodzaje plików cookies: „sesyjne” (session cookies) oraz „stałe” (persistent cookies). Cookies „sesyjne” są plikami tymczasowymi, które przechowywane są w urządzeniu końcowym Użytkownika do czasu wylogowania, opuszczenia strony internetowej lub wyłączenia oprogramowania (przeglądarki internetowej). „Stałe” pliki cookies przechowywane są w urządzeniu końcowym Użytkownika przez czas określony w parametrach plików cookies lub do czasu ich usunięcia przez Użytkownika.",
  "cookiesPolicyPage.point.6": "W ramach Serwisu stosowane są następujące rodzaje plików cookies:",
  "cookiesPolicyPage.point.6a":
    "„niezbędne” pliki cookies, umożliwiające korzystanie z usług dostępnych w ramach Serwisu, np. uwierzytelniające pliki cookies wykorzystywane do usług wymagających uwierzytelniania w ramach Serwisu;",
  "cookiesPolicyPage.point.6b":
    "pliki cookies służące do zapewnienia bezpieczeństwa, np. wykorzystywane do wykrywania nadużyć w zakresie uwierzytelniania w ramach Serwisu;",
  "cookiesPolicyPage.point.6c":
    "wydajnościowe” pliki cookies, umożliwiające zbieranie informacji o sposobie korzystania ze stron internetowych Serwisu;",
  "cookiesPolicyPage.point.6d":
    "„funkcjonalne” pliki cookies, umożliwiające „zapamiętanie” wybranych przez Użytkownika ustawień i personalizację interfejsu Użytkownika, np. w zakresie wybranego języka lub regionu, z którego pochodzi Użytkownik, rozmiaru czcionki, wyglądu strony internetowej itp.;",
  "cookiesPolicyPage.point.6e":
    "„reklamowe” pliki cookies, umożliwiające dostarczanie Użytkownikom treści reklamowych bardziej dostosowanych do ich zainteresowań.",
  "cookiesPolicyPage.point.7":
    "W wielu przypadkach oprogramowanie służące do przeglądania stron internetowych (przeglądarka internetowa) domyślnie dopuszcza przechowywanie plików cookies w urządzeniu końcowym Użytkownika. Użytkownicy Serwisu mogą dokonać w każdym czasie zmiany ustawień dotyczących plików cookies. Ustawienia te mogą zostać zmienione w szczególności w taki sposób, aby blokować automatyczną obsługę plików cookies w ustawieniach przeglądarki internetowej bądź informować o ich każdorazowym zamieszczeniu w urządzeniu Użytkownika Serwisu. Szczegółowe informacje o możliwości i sposobach obsługi plików cookies dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).",
  "cookiesPolicyPage.point.8":
    "Operator Serwisu informuje, że ograniczenia stosowania plików cookies mogą wpłynąć na niektóre funkcjonalności dostępne na stronach internetowych Serwisu.",
  /* -- privacy policy page -- */
  "privacyPolicyPage.header": "Polityka prywatności",
  "privacyPolicyPage.subheader": "Istotne informacje.",
  "privacyPolicyPage.point.one": "POSTANOWIENIA OGÓLNE",
  "privacyPolicyPage.point.one.1":
    "Administratorem danych jest Table4Rest Sp. z o.o. z siedzibą w Warszawa 02-656, ul. Ksawerów 21, wpisanej do Rejestru Przedsiębiorców pod numerem KRS: 0000752110 dla której akta rejestrowe prowadzi SĄD REJONOWY DLA M.ST.WARSZAWY W WARSZAWIE, XIII WYDZIAŁ GOSPODARCZY KRAJOWEGO REJESTRU SĄDOWEGO NIP: 5213842139 REGON: 381529521",
  "privacyPolicyPage.point.one.2":
    "Dla interpretacji terminów stosuje się słowniczek Regulaminu lub tak jak zostało to opisane w Polityce Prywatności (jeżeli wynika to bezpośrednio z opisu).",
  "privacyPolicyPage.point.one.3":
    "Na potrzeby lepszego odbioru Polityki Prywatności termin „Użytkownik” zastąpiony został określeniem „Ty”, „Administrator” – „My”. Termin „RODO” oznacza Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE.",
  "privacyPolicyPage.point.one.4":
    "Szanujemy prawo do prywatności i dbamy o bezpieczeństwo danych. W tym celu używany jest m.in. bezpieczny protokół szyfrowania komunikacji (SSL).",
  "privacyPolicyPage.point.one.5":
    "Dane osobowe podawane w formularzu na landing page’u są traktowane jako poufne i nie są widoczne dla osób nieuprawnionych.",
  "privacyPolicyPage.point.two": "ADMINISTRATOR DANYCH",
  "privacyPolicyPage.point.two.1":
    "Usługodawca jest administratorem osób które skorzystały z formularza kontaktowego na stronie internetowej lub skorzystały z innej formy komunikacji (poczta elektroniczna, telefon, aplikacja mobilna, wtyczka zamów rozmowę, inne formy komunikacji).",
  "privacyPolicyPage.point.two.2": "Dane osobowe przetwarzane są:",
  "privacyPolicyPage.point.two.2a": "a. zgodnie z przepisami dotyczącymi ochrony danych osobowych,",
  "privacyPolicyPage.point.two.2b": "b. zgodnie z wdrożoną Polityką Prywatności,",
  "privacyPolicyPage.point.two.2c":
    "c. w zakresie i celu niezbędnym do nawiązania, ukształtowania treści Umowy, zmiany bądź jej rozwiązania oraz prawidłowej realizacji świadczonych Usług,",
  "privacyPolicyPage.point.two.2d":
    "d. w zakresie i celu niezbędnym do wypełnienia uzasadnionych interesów (prawnie usprawiedliwionych celów), a przetwarzanie nie narusza praw i wolności osoby, której dane dotyczą.",
  "privacyPolicyPage.point.two.3":
    "Każda osoba, której dane dotyczą (jeżeli jesteśmy ich administratorem) ma prawo dostępu do danych, sprostowania, usunięcia lub ograniczenia przetwarzania, prawo sprzeciwu, prawo wniesienia skargi do organu nadzorczego.",
  "privacyPolicyPage.point.two.4":
    "Kontakt z osobą nadzorującą przetwarzanie danych osobowych w organizacji Usługodawcy jest możliwy drogą elektroniczną pod adresem e-mail: kontakt@mojstolik.pl",
  "privacyPolicyPage.point.two.5":
    "Zastrzegamy sobie prawo do przetwarzania Twoich danych po rozwiązaniu Umowy lub cofnięciu zgody tylko w zakresie na potrzeby dochodzenia ewentualnych roszczeń przed sądem lub jeżeli przepisy krajowe albo unijne bądź prawa międzynarodowego obligują nas do retencji danych.",
  "privacyPolicyPage.point.two.6":
    "Usługodawca ma prawo udostępniać dane osobowe Użytkownika oraz innych jego danych podmiotom upoważnionym na podstawie właściwych przepisów prawa (np. organom ścigania).",
  "privacyPolicyPage.point.two.7":
    "Usunięcie danych osobowych może nastąpić na skutek cofnięcia zgody bądź wniesienia prawnie dopuszczalnego sprzeciwu na przetwarzanie danych osobowych.",
  "privacyPolicyPage.point.two.8":
    "Usługodawca nie udostępniania danych osobowych innym podmiotom aniżeli upoważnionym na podstawie właściwych przepisów prawa.",
  "privacyPolicyPage.point.two.9":
    "Dane osobowe przetwarzają osoby wyłącznie upoważnione przez nas albo przetwarzający, z którymi ściśle współpracujemy.",
  /* -- restaurant profile page -- */
  "restaurantPage.button.pageWWW": "Strona WWW",
  "restaurantPage.button.seeMenu": "Zobacz menu",
  "restaurantPage.button.confirm": "Potwierdź",
  "restaurantPage.button.cancel": "Anuluj",
  "restaurantPage.button.choose": "Wybierz",
  "restaurantPage.button.back": "Wróć",
  "restaurantPage.button.showMore": "Pokaż więcej",
  "restaurantPage.button.showMore.promotion": "Więcej",
  "restaurantPage.button.showLess": "Pokaż mniej",
  "restaurantPage.button.goToHomePage": "Powrót do strony głównej",
  "restaurantPage.button.reservationDetails": "Szczegóły rezerwacji",
  "restaurantPage.button.goToPaymentPage": "Przejdź do panelu płatności",
  "restaurantPage.paymentPage.info":
    "Zaraz zostaniesz przekierowany do operatora płatności Przelewy24.pl",
  "restaurantPage.header.restaurantDescription": "Opis restauracji",
  "restaurantPage.header.bookingPolicy": "Polityka rezerwacji",
  "restaurantPage.header.gallery": "Galeria zdjęć",
  "restaurantPage.header.yourReservation": "Twoja rezerwacja",
  "restaurantPage.header.yourPersonalData": "Twoje dane",
  "restaurantPage.header.ctnWithoutLogin": "Kontynuuj bez logowania",
  "restaurantPage.header.login": "Logowanie",
  "restaurantPage.button.login": "Zaloguj się",
  "restaurantPage.button.orLoginWith": "Lub zaloguj się przy użyciu",
  "restaurantPage.header.createAccount": "Załóż konto w MojStolik.pl i zyskaj:",
  "restaurantPage.header.createAccount.benefit.1": "Szybki sposób rezerwacji",
  "restaurantPage.header.createAccount.benefit.2": "Pełną historię rezerwacji",
  "restaurantPage.header.createAccount.benefit.3": "Dostęp do unikalnych promocji",
  "restaurantPage.button.createAccount": "Załóż konto MojStolik.pl",
  "restaurantPage.header.statements": "Oświadczenia",
  "restaurantPage.header.statement": "Oświadczenie",
  "restaurantPage.header.promotions": "Oferty specjalne i wydarzenia",
  "restaurantPage.header.use": "Skorzystaj",
  "restaurantPage.header.cancel": "Zrezygnuj",
  "restaurantPage.header.reservationConfirmation": "Potwierdzenie rezerwacji",
  "restaurantPage.header.reservationsPersons": "Rezerwacje od {{min}} do {{max}} osób",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Wpisz kod z wiadomości SMS, którą wysłaliśmy na Twój numer telefonu, aby dokończyć proces rezerwacji stolika",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Wpisz kod z wiadomości, którą wysłaliśmy na Twój adres e-mail, aby dokończyć proces rezerwacji stolika",
  "restaurantPage.input.label.code": "Wpisz kod z dnia",
  "restaurantPage.input.placeholder.code": "",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "Brak dostępnych stolików przy wybranych kryteriach (data, liczba osób, promocja). Wybierz inną datę, liczbę osób lub promocję.",
  "restaurantPage.paragraph.noAvailableHours": "Brak wolnych stolików w wybranych kryteriach.",
  "restaurantPage.paragraph.noAvailableHoursExt": "Wybierz inną datę lub liczbę osób.",
  "restaurantPage.input.label.date": "Data",
  "restaurantPage.input.label.hour": "Godzina",
  "restaurantPage.input.label.persons": "Liczba osób",
  "restaurantPage.input.label.persons.optionExtend": "os.",
  "restaurantPage.input.label.firstName": "Imię",
  "restaurantPage.input.label.vatIdNumber": "NIP",
  "restaurantPage.input.label.address": "Adres",
  "restaurantPage.input.label.companyName": "Nazwa firmy",
  "restaurantPage.input.label.street": "Ulica",
  "restaurantPage.input.label.buildingNumber": "Budynek",
  "restaurantPage.input.label.localNumber": "Lokal",
  "restaurantPage.input.label.postalCode": "Kod pocztowy",
  "restaurantPage.input.label.city": "Miasto",
  "restaurantPage.input.label.country": "Kraj",
  "restaurantPage.get.company.data": "Pobierz",
  "restaurantPage.input.label.lastName": "Nazwisko",
  "restaurantPage.input.label.phoneNumber": "Numer telefonu",
  "restaurantPage.input.label.email": "Adres e-mail",
  "restaurantPage.input.label.password": "Wpisz hasło",
  "restaurantPage.input.label.passwordRepeat": "Powtórz hasło",
  "restaurantPage.input.label.description": "Uwagi dot. rezerwacji",
  "agreements.allAgreements": "Zaznacz wszystko",
  "agreements.invoice": "Podaj dane do faktury",
  "restaurantPage.input.label.personalData":
    "Oświadczam, że znam i akceptuję postanowienia Regulaminu świadczenia usług przez MojStolik za pośrednictwem Aplikacji dla Klienta. ",
  "restaurantPage.input.label.personalData.extension": " Regulamin MojStolik",
  "restaurantPage.input.label.readWithdrawal":
    "Zapoznałem się z regulaminem MojStolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy. ",
  "restaurantPage.input.label.readWithdrawal.extension": "Regulamin mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach MojStolik dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
  "restaurantPage.input.label.agreementMarketing.extension": "",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach Restauracji i Podmiotów Współpracujących wskazanych na stronie mojstolik.pl, dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
  "restaurantPage.input.label.marketingAgreement3thPart.extension": "",
  "agreement.eventAgreement":
    "Zapoznałem się i akceptuję regulamin oferty specjalne znajdujący się",
  "registrationPage.input.label.eventAgreementUrl.extension": "pod tym linkiem",
  "restaurantPage.input.placeholder.typeIn": "Wpisz",
  "restaurantPage.input.placeholder.firstName": "Np. Jan",
  "restaurantPage.input.placeholder.lastName": "Np. Kowalski",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.email": "Np. adres@email.pl",
  "restaurantPage.paragraph.fillOrLogin":
    "Wpisz pozostałe dane lub pobierz za pomocą Facebook, Google lub Apple",
  "restaurantPage.paragraph.doYouWantToRegister":
    "Jeśli chcesz założyć konto w portalu MOJSTOLIK.PL nadaj hasło",
  "restaurantPage.paragraph.dataIsIncorrect": "Dane są niepoprawne?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Kod został ponownie wysłany SMS-em. Nie dostałeś kodu?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Zautoryzuj się poprzez e-mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Wyślij kod na podany adres e-mail.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "Kod został wysłany na adres e-mail:",
  "restaurantPage.anchor.sendCodeAgain": "Wyślij kod ponownie",
  "restaurantPage.anchor.edit": "Edytuj",
  "restaurantPage.anchor.login": "Zaloguj",
  "restaurantPage.paragraph.or": " lub ",
  "restaurantPage.anchor.register": "zarejestruj się",
  "restaurantPage.paragraph.dontWontUsePromotion": "Nie chcę korzystać z promocji",
  "restaurantPage.messages.codeResent": "Kod SMS został ponownie wysłany.",
  "restaurantPage.header.reservationRecieved": "Przyjęcie rezerwacji",
  "restaurantPage.summary.paragraph.firstLastName": "Imię Nazwisko:",
  "restaurantPage.summary.paragraph.phoneNumber": "Numer telefonu:",
  "restaurantPage.summary.paragraph.email": "E-mail:",
  "restaurantPage.summary.paragraph.restName": "Nazwa restauracji:",
  "restaurantPage.summary.paragraph.date": "Termin:",
  "restaurantPage.summary.paragraph.date.time": ", godz.",
  "restaurantPage.summary.paragraph.duration": "Czas trwania:",
  "restaurantPage.summary.paragraph.persons": "Liczba gości:",
  "restaurantPage.summary.paragraph.totalCost": "Suma przedpłat:",
  "restaurantPage.summary.special.offer": "Oferta specjalna:",
  "restaurantPage.summary.paragraph.state": "Status:",
  "restaurantPage.paragraph.tableReserved": "Zarezerwowano stolik",
  "reservation.status.Canceled": "Odwołana",
  "reservation.status.Completed": "Rezerwacja zrealizowana",
  "reservation.status.Confirmed": "Rezerwacja została potwierdzona",
  "reservation.status.Delayed": "Opóźniona",
  "reservation.status.NotCompleted": "Rezerwacja niezrealizowana",
  "reservation.status.Rejected": "Rezerwacja została anulowana",
  "reservation.status.WaitingForApproval": "Rezerwacja oczekuje na zatwierdzenie",
  "reservation.status.WaitingForConfirmation": "Oczekująca",
  "restaurantPage.paragraph.tableReservedDescription": "Twój stolik został zarezerwowany na godz.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "w dniu",
  "restaurantPage.paragraph.tableWaitingForApproval": "Rezerwacja oczekuje na zatwierdzenie",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1": "Twoja rezerwacja na godz.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "Jeśli nie otrzymasz potwierdzenia rezerwacji w ciągu kilku godzin, skontaktuj się bezpośrednio z Restauracją",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "w dniu",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "Po zatwierdzeniu rezerwacji na wskazany numer telefonu zostanie przesłana wiadomość z linkiem do płatności. Rezerwacja jest ważna po otrzymaniu potwierdzenia płatności",
  "restaurantPage.paragraph.tableReservedPaymentInformation": "Rezerwacja musi zostać opłacona",
  "restaurantPage.input.label.footer":
    "MojStolik korzysta z różnych kanałów komunikacji elektronicznej oraz prowadzi działalności z wykorzystaniem poczty elektronicznej, telefonów, SMS-ów i MMS-ów. Administratorem Twoich danych osobowych jest Table4Rest spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, 02-656 Warszawa ulica Ksawerów 21, KRS 0000752110, NIP 5213842139. Twoje dane osobowe będą przetwarzane w celu kierowania do Ciebie treści marketingowych w kanałach komunikacji elektronicznej. Więcej informacji na temat przetwarzania danych osobowych, w tym przysługujących Ci praw, znajduje się w Polityce Prywatności.",
  /* -- for guests page -- */
  "forGuestPage.header": "Dla gościa",
  "forGuestPage.subheader": "",
  /* -- how it works page -- */
  "howItWorksPage.header": "Jak to działa?",
  "howItWorksPage.subheader": "Krok po kroku",
  "howItWorksPage.content.title": "Rezerwacja z aplikacji mobilnej",
  "howItWorksPage.content.step1": "Krok 1",
  "howItWorksPage.content.step1.content": "Klient w aplikacji mobilnej wyszukuje restauracje i ",
  "howItWorksPage.content.step1.content.strong": "w czasie rzeczywistym widzi dostępne stoliki.",
  "howItWorksPage.content.step2": "Krok 2",
  "howItWorksPage.content.step2.content.strong":
    "Manager w swoim panelu widzi zgłoszenie rezerwacji",
  "howItWorksPage.content.step2.content":
    " Wie, czy zgłoszenie przyszło od nowego klienta, czy stałego i czy są jakieś preferencje co do stolika.",
  "howItWorksPage.content.step3": "Krok 3",
  "howItWorksPage.content.step3.content": "Klient przychodzi do restauracji gdzie ",
  "howItWorksPage.content.step3.content.strong":
    "jest witany po imieniu. Obsługa ma pełne dane klienta",
  "howItWorksPage.content.step3.contentb":
    " — wie, czy jest ich stałym gościem, czy odwiedza ich po raz pierwszy.",
  "howItWorksPage.phone.title": "Rezerwacja telefoniczna",
  "howItWorksPage.content.phone.step1": "Krok 1",
  "howItWorksPage.content.phone.step1.content1": "Klient ",
  "howItWorksPage.content.phone.step1.content.strong": "dzwoni do wybranej restauracji ",
  "howItWorksPage.content.phone.step1.content2": "z zapytaniem o rezerwację.",
  "howItWorksPage.content.phone.step2": "Krok 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "Manager w swoim panelu widzi dostępność stolików w czasie rzeczywistym",
  "howItWorksPage.content.phone.step2.content":
    ". Manager wprowadzając numer telefonu klienta, od razu widzi jego dane, bądź uzupełnia/wprowadza dane nowego klienta.",
  "howItWorksPage.content.phone.step3": "Krok 3",
  "howItWorksPage.content.phone.step3.content": "Klient przychodzi do restauracji gdzie ",
  "howItWorksPage.content.phone.step3.content.strong":
    "obsługa wita go po imieniu, ma pełne dane Klienta",
  "howItWorksPage.content.phone.step3.contentb":
    " — wie, czy jest ich stałym gościem, czy odwiedza ich po raz pierwszy.",
  /* -- price list page -- */
  "priceListPage.header": "Cennik",
  "priceListPage.subheader": "",
  "priceListPage.content.link.1": "W celu otrzymania informacji cenowej prosimy o",
  "priceListPage.content.link.2": "kontakt",
  /* -- contact page -- */
  "contactPage.header": "Kontakt",
  "contactPage.subheader": "Napisz do nas!",
  "contactPage.content.title": "Dane kontaktowe",
  /* -- documents page -- */
  "documentsPage.header": "Dokumenty",
  "documentsPage.header.for.clients": "Dla klienta",
  "documentsPage.header.for.restaurants": "Dla Restauracji",
  "documentsPage.subheader": "Istotne informacje",
  "documentsPage.for.clients.document.1":
    "Regulamin świadczenia usług przez MojStolik za pośrednictwem Aplikacji dla Klienta",
  "documentsPage.for.clients.document.2": "Informacja dotycząca przetwarzania danych osobowych",
  "documentsPage.header.for.restaurants.document.1":
    "Regulamin świadczenia usługi udostępnienia Aplikacji MojStolik dla Restauracji",
  "documentsPage.header.for.restaurants.document.2":
    "Umowa powierzenia przetwarzania danych osobowych Restauracji",
  "documentsPage.header.for.restaurants.document.3": "Dane osobowe mojstolik.pl",

  /* -- about page -- */
  "aboutPage.header": "O nas",
  "aboutPage.subheader": "",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " – to polski system stworzony przez grupę przyjaciół — tajemniczych miłośników dobrego jedzenia i sztuki goszczenia w restauracjach. To technologiczne rozwiązanie, które pomaga budować społeczność wokół miejsc atrakcyjnych przez dobrą kuchnię oraz szacunek dla czasu i przyzwyczajeń swoich lojalnych gości. MojStolik.pl to coraz większa grupa restauracji, które wspólnymi siłami robią wszystko, aby polska gastronomia była utożsamiana z doskonałą jakością, dbałością o detal i szacunkiem dla fanów spędzania czasu przy stole z przyjaciółmi, rodziną, czy partnerami w biznesie. Założyciele systemu przełożyli swoją restauracyjną pasję na język technologiczny i dzięki temu powstał gastronomiczny portal i aplikacja, które łączą ludzi przy stołach najbardziej cenionych i popularnych restauracji w Polsce.",
  "aboutPage.application.header1":
    " Chcesz zarezerwować stolik w restauracji? Teraz zrobisz to za pomocą aplikacji w telefonie bądź z portalu skupiającego wiele restauracji",
  "aboutPage.application.description1":
    "Mojstolik.pl to nowa kompleksowa platforma rezerwacyjna od niedawna dostępna dla warszawskich restauracji. Pozwala lokalom gastronomicznym na wygodne zarządzanie rezerwacjami stolików w czasie rzeczywistym i integruje wszystkie kanały komunikacji z gośćmi: telefon, email, aplikację mobilną MojStolik i tzw. walk-in. Dzięki niej menedżerowie restauracji mają dostęp do dynamicznego widoku sali, sami decydują o układzie sal, liczbie dostępnych miejsc i na jak długo każdy stolik może być zarezerwowany. Dodatkowym atutem systemu jest mechanizm potwierdzeń SMS o rezerwacji z opcją odwołania poprzez link oraz wysyłania SMS do gości z przypomnieniem o wizycie.",
  "aboutPage.application.header2": "MojStolik.pl: tablet zamiast książki rezerwacji",
  "aboutPage.application.description2":
    "MojStolik.pl to także narzędzie wspomagające restauracje w budowaniu lojalności klientów. System umożliwia budowanie bazy danych gości, analizowanie historii rezerwacji a licznik wizyt pokazuje, który raz dana osoba odwiedza lokal. Goście podczas dokonywania rezerwacji mają możliwość dopisania uwag takich jak preferencje dotyczące stolika, ulubionych dań czy win. Nowa platforma pozwala na bezpieczne przechowywanie danych klienta zgodnie z wymaganiami RODO, czego nie spełnia papierowa książka rezerwacji używana przez restauracje.",
  "aboutPage.application.description2b":
    "Z aplikacji korzysta ponad 100 restauracji w całej Polsce jak: Stary Dom, Restauracja Baczewskich, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE’S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, a wkrótce dołączą do nich kolejne.",
  "aboutPage.application.header3": "MojStolik – nowa aplikacja do rezerwacji stolików",
  "aboutPage.application.description3":
    " MojStolik to nowa aplikacja mobilna, dzięki której szybko i wygodnie zarezerwujemy stolik w restauracji. Aplikacja umożliwia natychmiastowe sprawdzenie dostępności i zarezerwowanie stolika na daną godzinę, ponieważ komunikuje się z restauracją w czasie rzeczywistym. Do rezerwacji możemy dodać specjalną prośbę czy komentarz odnośnie do ulubionego stolika w lokalu, informacje o alergiach czy innych preferencjach, a restauracja za pomocą chatu może nam odpowiedzieć czy dana prośba jest możliwa do spełnienia. W aplikacji MojStolik sprawdzimy także aktualne menu lokalu, mamy też dostęp do specjalnych promocji, które przygotowały dla nas restauracje.",
  "aboutPage.contact.data": "Dane kontaktowe",
  "aboutPage.contact.street": "ul.",
  /* -- registration page -- */
  "registrationPage.header": "Rejestracja",
  "registrationPage.subheader":
    "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
  "registrationPage.columns.header.registerStandard": "Standardowa rejestracja",
  "registrationPage.columns.header.registerWith": "Zarejestruj się za pomocą",
  "registrationPage.input.label.firstName": "Imię",
  "registrationPage.input.placeholder.firstName": "Np. Jan",
  "registrationPage.input.label.lastName": "Nazwisko",
  "registrationPage.input.placeholder.lastName": "Np. Kowalski",
  "registrationPage.input.label.phoneNumber": "Numer telefonu",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.label.email": "Adres e-mail",
  "registrationPage.input.placeholder.email": "Np. adres@email.pl",
  "registrationPage.paragraph.optionalFields": "Pola opcjonalne",
  "registrationPage.input.label.birthDate": "Data urodzenia",
  "registrationPage.input.placeholder.birthDate": "Np. 1976-10-25",
  "registrationPage.input.label.postalCode": "Kod pocztowy",
  "registrationPage.input.placeholder.postalCode": "Np. 03-123",
  "registrationPage.input.label.city": "Miasto",
  "registrationPage.input.placeholder.city": "Np. Warszawa",
  "registrationPage.input.label.streetAndLocalNumber": "Ulica i numer mieszkania",
  "registrationPage.input.placeholder.streetAndLocalNumber": "Np. Grójecka 130/105",
  "registrationPage.input.label.password": "Hasło",
  "registrationPage.input.placeholder.password": "",
  "registrationPage.input.label.passwordRepeat": "Powtórz hasło",
  "registrationPage.input.placeholder.passwordRepeat": "",
  "registrationPage.header.statements": "Oświadczenia",
  "registrationPage.input.label.agreementPersonalData":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. oraz wybrane przeze mnie restauracje i lokale gastronomiczne w celu wykonania przedmiotu umowy zgodnie z informacjami zawartymi w dokumencie. ",
  "registrationPage.input.label.agreementPersonalData.extension":
    "Informacje dotyczące przetwarzanie danych osobowych Klientów",
  "registrationPage.input.label.agreementReadWithdrawal":
    "Zapoznałem się z regulaminem MojStolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy. ",
  "registrationPage.input.label.agreementReadWithdrawal.extension": "Regulamin mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Zgoda marketingowa",
  "registrationPage.input.label.agreementMarketing.extension": "Zgoda",
  "registrationPage.input.label.promotionReadWithdrawal.extension": "Regulamin Promocji",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "pod tym linkiem",
  "registrationPage.button.register": "Zarejestruj się",
  "registrationPage.message.sendCodeAgain": "Kod SMS został ponownie wysłany.",
  "registrationPage.message.accountCreated": "Konto zostało utworzone.",
  "registrationPage.message.registrationTimeExpired": "Czas rejestracji upłynął",
  "registrationPage.message.enterCodeFromSMS": "Przepisz kod z SMS-a.",
  "registrationPage.paragraph.timeIsOver": "Czas się skończył.",
  "registrationPage.paragraph.timeLeft": "Pozostało",
  "registrationPage.paragraph.seconds": "sekund(-a)",
  "registrationPage.header.SMSConfirmation": "Potwierdzenie SMS",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Wpisz kod SMS, który wysłaliśmy na Twój numer telefonu, aby dokończyć rejestrację.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Wpisz kod SMS z dnia",
  "registrationPage.input.placeholder.code": "Wpisz kod",
  "registrationPage.paragraph.sendCodeAgain": "Kod SMS został ponownie wysłany.",
  "registrationPage.button.sendCodeAgain": "Wyślij kod ponownie",
  "registrationPage.button.confirm": "Potwierdź",

  /* -- not found (404) page -- */
  "notFoundPage.header": "Błąd 404",
  "notFoundPage.subheader": "Żądany URL",
  "notFoundPage.subheaderExtend": "nie został znaleziony na serwerze.",
  "notFoundPage.subheaderExtend2":
    "Spróbuj sprawdzić, czy podany adres jest poprawny lub przejdź do",
  "notFoundPage.subheaderExtend3": "strony głównej",
  "notFoundPage.button.goToHomePage": "Przejdź do strony głównej",

  /* -- PaymentInfo Component -- */
  "global.toast.payment.count": "Nie wybrano wymaganych produktów",
  "paymentInfo.description.label": "Nazwa",
  "paymentInfo.amount.label": "Cena jedn.",
  "paymentInfo.quantity.label": "Liczba",
  "paymentInfo.total.label": "Razem",
  "paymentInfo.nocalculation": "Płatności nie są wymagane",
  "paymentInfo.summarycost.label": "Do zapłaty:",
  "paymentInfo.required.products": "Produkty obowiązkowe",
  "paymentInfo.optional.products": "Produkty opcjonalne",
  "paymentInfo.recalculate": "Przelicz",
  "paymentInfo.amount.to.paid": "Sumaryczna kwota do zapłaty ",
  "paymentInfo.info":
    "Sumaryczna liczba produktów obowiązkowych musi być równa liczbie osób w rezerwacji.",
  "paymentInfo.info1": "Musisz wybrać jeszcze",
  "paymentInfo.info3": "produkt",
  "paymentInfo.info4": "produkty",
  "paymentInfo.package.title": "Pakiet podstawowy",
  "paymentInfo.select.product": "Wybierz produkt",
  "paymentInfo.select.basic.product": "Wybierz produkt podstawowy",
  "paymentInfo.select.required.product": "Wybierz produkty obowiązkowe",
  "paymentInfo.too.much.products": "Nie możesz wybrać więcej produktów",
  "restaurantPage.button.confirmAndPay": "Potwierdź i zapłać",
  "restaurantPage.button.closestTime": "Najbliższy termin",
  "paymentInfo.title": "Opłaty",
  "reservation.payment.status.Created": "Utworzona",
  "reservation.payment.status.WaitingForPayment": "Oczekująca",
  "reservation.payment.status.ProcessingPayment": "W trakcie procesowania",
  "reservation.payment.status.Completed": "Zrealizowana",
  "reservation.payment.status.Cancelled": "Anulowana",
  "reservation.payment.status.Expired": "Wygasła",
  "reservation.payment.status.Unknown": "Nieznany status",

  /* -- custom form -- */
  "custom.form.required": "Pole wymagane",
  "custom.button.send": "Wyślij",
  "custom.button.hide": "Zwiń",
  "custom.button.show": "Rozwiń",

  /* -- reservation confirmation -- */
  "reservation.confirmation.payment.count": "Przelicz produkty",
  "reservation.confirmation.selected.promotion": "Wybrana promocja",

  /* -- forRestaurantsPage form -- */
  "forRestaurantsPage.form.toast.success": "Twoje dane kontaktowe zostały wysłane.",
  "forRestaurantsPage.form.toast.error": "Wystąpił błąd, spróbuj ponownie.",
  "forRestaurantsPage.form.email.error": "Niepoprawny email",
  "forRestaurantsPage.label.name": "IMIĘ I NAZWISKO",
  "forRestaurantsPage.label.restaurant": "RESTAURACJA:",
  "forRestaurantsPage.label.email": "E-MAIL:",
  "forRestaurantsPage.label.phone": "TELEFON:",
  "forRestaurantsPage.header.agreement": "OŚWIADCZENIA",
  "forRestaurantsPage.label.agreement":
    " Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MojStolik.pl oraz na przesłanie oferty drogą elektroniczną.",

  /* -- Survey Page -- */
  "surveyPage.header.title": "Ankieta klienta",
  "surveyPage.header.description":
    "Wasza opinia jest dla nas najważniejsza, dzięki niej możemy się rozwijać. Prosimy podziel się z nami Swoją opinią:",
  "surveyPage.button.complete": "Gotowe",
  "surveyPage.button.back": "Wróć",
  "surveyPage.button.next": "Dalej",
  "surveyPage.surveyCompleteMessage": "Dziękujemy za wypełnienie ankiety!",
  "surveyPage.emptySurveyMessage":
    "Wystąpił błąd podczas pobierania danych ankiety. Prosimy spróbować później.",
  "surveyPage.error": "Wystąpił błąd.",
  "surveyPage.results.sending": "Trwa wysyłanie twoich odpowiedzi...",
  "surveyPage.results.success": "Twoja ankieta została wysłana",
  "surveyPage.filledAlert.header": "Ta ankieta została już wypełniona"
};

export default frontendTranslationsPL;
