const backendTranslationsUK = {
  "ByWindow": "Біля вікна",
  "Failed": "Не вдалося.",
  "ForHowManyPeopleTable": "Кількість обкладинок",
  "NewReservationCouldNotBeCreated": "Не вдалося створити нове бронювання",
  "NewReservationHasBeenCreated": "Створено нове бронювання",
  "NonSmoking": "Для некурців",
  "Outdoor": "Назовні.",
  "ReservationHasBeenUpdated": "Бронювання оновлено",
  "aboutPage.header": "O в",
  "aboutPage.metadata.description": "Варіанти",
  "aboutPage.metadata.keywords": "про нас ключові слова",
  "aboutPage.metadata.title": "O в",
  "aboutPage.subheader": "Lorem ipsum dolor...",
  "acceptance.required": "Прийміть необхідні згоди",
  "account.changePassword": "Змінити пароль",
  "account.confirmRemoveAccount":
    "Ви впевнені, що хочете видалити свій обліковий запис? Всі нереалізовані бронювання будуть автоматично скасовані.",
  "account.removeAccount": "Видалити обліковий запис",
  "account.removeRequest": "Щоб видалити обліковий запис, введіть пароль",
  "accountPage.button.changePassword": "Змінити пароль",
  "accountPage.button.deleteAccount": "Видалити обліковий запис",
  "accountPage.button.save": "Зберегти",
  "accountPage.header": "Ваш рахунок",
  "accountPage.headerInColumn.yourPassword": "Ваш пароль",
  "accountPage.headerInColumn.yourPersonalData": "Ваші дані",
  "accountPage.input.label.birthDate": "Дата народження",
  "accountPage.input.label.city": "Місто",
  "accountPage.input.label.email": "Адреса електронної пошти",
  "accountPage.input.label.firstName": "Ім'я",
  "accountPage.input.label.lastName": "Ім'я",
  "accountPage.input.label.newPassword": "Новий пароль",
  "accountPage.input.label.newPasswordRepeat": "Новий пароль (повторити)",
  "accountPage.input.label.oldPassword": "Старе гасло.",
  "accountPage.input.label.phoneNumber": "Номер телефону",
  "accountPage.input.label.postalCode": "Поштовий індекс",
  "accountPage.input.label.streetAndLocalNumber": "Вулиця та номер квартири",
  "accountPage.input.placeholder.birthDate": "Наприклад, 1976-10-25",
  "accountPage.input.placeholder.city": "Наприклад, Варшава",
  "accountPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "accountPage.input.placeholder.firstName": "Наприклад, Ян",
  "accountPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.placeholder.postalCode": "Наприклад, 03-123",
  "accountPage.input.placeholder.streetAndLocalNumber": "Наприклад, Grójecka 130/105",
  "accountPage.label.changePassowordNotPossible":
    "Змінити пароль неможливо, оскільки ви увійшли через Facebook або Google.",
  "accountPage.message.accountPermanentlyDeleted": "Ваш обліковий запис видалено назавжди.",
  "accountPage.message.passwordChanged": "Пароль було змінено.",
  "accountPage.message.personalDataUpdated": "Особисті дані були оновлені.",
  "accountPage.metadata.description": "Опис вашого облікового запису",
  "accountPage.metadata.keywords": "Ключові слова вашого облікового запису",
  "accountPage.metadata.title": "Ваш рахунок",
  "accountPage.paragraph.optionalFields": "Необов'язкові поля",
  "accountPage.subheader":
    "Змініть свої особисті дані, щоб пришвидшити процес виконання замовлення.",
    "accountPage.toast.error.disableBothChannels":
    "Щонайменше один спосіб зв'язку (SMS, E-MAIL) повинен залишатися активним!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Увімкнення способу зв'язку вимагає підтвердження коректності даних. Збережіть зміни, щоб отримати посилання для підтвердження на нову електронну адресу!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Увімкнення способу зв'язку вимагає підтвердження коректності даних. Збережіть зміни, щоб отримати посилання для підтвердження через SMS на ваш новий номер телефону!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Щоб увімкнути новий спосіб зв'язку, підтвердьте свої дані, перейшовши за активаційним посиланням, надісланим на вашу електронну адресу!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Щоб увімкнути новий спосіб зв'язку, підтвердьте свої дані, перейшовши за активаційним посиланням, надісланим через SMS на вказаний номер телефону!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Щоб підтвердити свої дані, перейдіть за посиланням для верифікації, яке надіслано на вашу електронну адресу!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Щоб підтвердити свої дані, перейдіть за посиланням для верифікації, яке надіслано через SMS на ваш номер телефону!",
  "agreement.canRevert":
    "mojstolik.co.uk інформує вас про те, що ви можете відкликати свою згоду на обробку ваших персональних даних.",
  "agreement.commercialInformationContact":
    "Я погоджуюся, що Table4Rest sp. z o.o. може надсилати комерційну інформацію засобами електронного зв'язку, зокрема на вказаний мною номер телефону та адресу електронної пошти, включаючи SMS та MMS.",
  "agreement.marketingContact":
    "Я даю згоду на те, щоб Table4Rest sp. z o. o. зв'язувався зі мною, в тому числі за допомогою SMS і MMS, а також за допомогою телекомунікаційного кінцевого обладнання та систем автоматичного обдзвону з метою прямого маркетингу, в тому числі на вказаний мною номер телефону та адресу електронної пошти.",
  "agreement.marketingDataUsage":
    "Я даю згоду на обробку моїх персональних даних компанією Table4Rest sp. z o.o. з метою маркетингу власних послуг і продуктів, пропонованих через додаток MyStolik.pl, а також послуг і продуктів інших суб'єктів.",
  "agreement.personalData":
    "Я даю згоду на обробку моїх персональних даних компанією Table4Rest sp. z o. o. з метою укладення договору про надання послуг з надання та використання додатку MyTable.",
  "agreement.readWithdrawal":
    "Я ознайомився з умовами та положеннями Mojstolik.co.uk, зокрема з інструкціями щодо права на відкликання",
  "agreement.thirdPartDataUsage":
    "Я даю згоду на обробку моїх персональних даних обраними мною ресторанами та іншими закладами громадського харчування з метою здійснення в них бронювання.",
  "agreement.warningUncheck":
    "Відкликання згоди унеможливлює використання додатку. Щоб відкликати її, ви повинні видалити свій обліковий запис.",
  "api.connectionLost": "З'єднання з Інтернетом було перервано. Додаток буде перезавантажено.",
  "app.readAgreement": "Умови використання сервісу MojStolik.pl",
  "cancellationReservationModal.component.button.cancel": "Оголосити",
  "cancellationReservationModal.component.button.confirm": "Схвалити",
  "cancellationReservationModal.component.header": "Скасування бронювання",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Ви дійсно хочете скасувати бронювання в ресторані {{restaurantName}} о {{reservationHour}} на {{reservationDate}}?",
  "changePass.lowerLetter": "- містять маленькі літери",
  "changePass.newPass": "Новий пароль",
  "changePass.numeric": "- містять цифри",
  "changePass.oldPass": "Старе гасло.",
  "changePass.passHaveToBe": "Потрібен пароль:",
  "changePass.passLength": "- мати довжину не менше 6 символів",
  "changePass.specialChar": "- містити спеціальний символ, наприклад, '#@!",
  "changePass.upperLetter": "- містити принаймні 1 велику літеру",
  "common.accept": "Схвалити",
  "common.add": "Додати",
  "common.alertCloseWindow": "Ви закриваєте вікно вибору",
  "common.back": "Назад",
  "common.bankAccountNumber": "Номер банківського рахунку",
  "common.buildingNumber": "Номер будинку",
  "common.cancel": "Оголосити",
  "common.changeButton": "Зміна",
  "common.check": "Перевіряю.",
  "common.city": "Місто",
  "common.clear": "Чистий",
  "common.close": "Закрити",
  "common.confirm": "Підтвердити",
  "common.confirmDecline": "Підтвердити/відхилити",
  "common.couldNotDownloadData": "Не вдалося завантажити дані. Оновіть додаток.",
  "common.dayShort.monday": "Пн.",
  "common.dayShort.tuesday": "Вт.",
  "common.dayShort.wednesday": "Ср.",
  "common.dayShort.thursday": "Чт.",
  "common.dayShort.friday": "Пт.",
  "common.dayShort.saturday": "Сб.",
  "common.dayShort.sunday": "Нд.",
  "common.delete": "Видалити",
  "common.detailsButton": "Деталі",
  "common.error": "Помилка.",
  "common.gr": "гр",
  "common.help": "Допоможіть!",
  "common.hourShort": "годин.",
  "common.localNumber": "Місцевий номер",
  "common.name": "Ім'я",
  "common.new": "Новий",
  "common.next": "Далі",
  "common.nextButton": "Далі",
  "common.noData": "Немає даних для відображення",
  "common.noValue": "Ні.",
  "common.ok": "Гаразд.",
  "common.password": "Пароль",
  "common.preferences": "Уподобання",
  "common.requiredInputError": "Поле обов'язкове для заповнення",
  "common.save": "Зберегти",
  "common.search": "Пошук",
  "common.send": "Надіслати",
  "common.shortPersons": "{{persons}} oc.",
  "common.street": "Вулиця",
  "common.successful": "Успіх",
  "common.undoButton": "Назад",
  "common.unit": "Одиниця",
  "common.vatId": "НІП",
  "common.yesValue": "Так.",
  "common.zipCode": "Поштовий індекс",
  "common.zl": "zł",
  "company.addNewAction": "Додано нову компанію",
  "company.addNewCompany": "Додати нову компанію?",
  "company.addressData": "Адресні дані",
  "company.buildingNumber": "Номер будинку",
  "company.city": "Місто",
  "company.companyExistsError": "Компанія вже існує",
  "company.country": "Країна",
  "company.localNumber": "Місцевий номер",
  "company.name": "Назва компанії",
  "company.nipNumber": "НІП",
  "company.notFound": "Компанію не знайдено в базі даних CDEIG",
  "company.postalCode": "Поштовий індекс",
  "company.questionUpdateModal": "Оновити компанію?",
  "company.street": "Вулиця",
  "company.updateAction": "У компанії відбулося оновлення",
  "company.updateCompany": "Оновлення існуючої компанії?",
  "company.vatId": "НІП",
  "component.cookiesPolicyLine.privacyPolicyText": "Політика конфіденційності",
  "component.cookiesPolicyLine.text":
    "Цей сайт використовує файли cookie, щоб забезпечити вам найвищий рівень обслуговування. Ваше подальше використання сайту означає, що ви погоджуєтесь на їх використання.",
  "component.footer.menu.about": "O в",
  "component.footer.menu.contact": "Зв'яжіться з нами",
  "component.footer.menu.cookiesPolicy": "Політика щодо файлів cookie",
  "component.footer.menu.documents": "Документалістика",
  "component.footer.menu.forGuest": "Для гостя",
  "component.footer.menu.forRestaurants": "Для ресторанів",
  "component.footer.menu.header.applicationDownload": "Завантажити додаток",
  "component.footer.menu.header.forClients": "Про MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Інформація",
  "component.footer.menu.header.socialMedia": "Соціальні мережі",
  "component.footer.menu.header.yourAccount": "Ваш рахунок",
  "component.footer.menu.howItWorks": "Як це працює?",
  "component.footer.menu.login": "Увійдіть в систему",
  "component.footer.menu.logout": "Вийдіть з системи",
  "component.footer.menu.priceList": "Прейскурант",
  "component.footer.menu.privacyPolicy": "Політика конфіденційності",
  "component.footer.menu.registration": "Реєстрація",
  "component.footer.menu.reservationHistory": "Ваші замовлення",
  "component.footer.menu.yourAccount": "Ваш рахунок",
  "component.header.menu.about": "O в",
  "component.header.menu.blog": "Новини MOJSTOLIK.PL",
  "component.header.menu.contact": "Зв'яжіться з нами",
  "component.header.menu.documents": "Документалістика",
  "component.header.menu.forGuest": "Для гостя",
  "component.header.menu.forRestaurants": "Для ресторанів",
  "component.header.menu.forRestaurants.offer": "Наша пропозиція",
  "component.header.menu.howItWorks": "Як це працює?",
  "component.header.menu.login": "Увійдіть в систему",
  "component.header.menu.logout": "Вийдіть з системи",
  "component.header.menu.mainPage": "Замовити",
  "component.header.menu.portal.restaurant": "Портал restauracji",
  "component.header.menu.priceList": "Прейскурант",
  "component.header.menu.reservationHistory": "Ваші замовлення",
  "component.header.menu.yourAccount": "Ваш рахунок",
  "config.communication.confirmationReservation": "Підтвердження бронювання",
  "config.communication.modificationReservation": "Зміна бронювання",
  "config.communication.notifTitle": "PUSH-повідомлення",
  "config.communication.rejectionReservation": "Скасування бронювання",
  "config.communication.remindNotification": "Нагадування про бронювання",
  "config.navbar.account": "Обліковий запис",
  "config.navbar.communication": "Комунікація",
  "config.navbar.configuration": "Конфігурація",
  "config.navbar.myaccount": "Обліковий запис",
  "config.navbar.mypreferences": "Уподобання",
  "configuration.changeLanguage": "Мова",
  "configuration.customerProfiles": "Профілі клієнтів",
  "configuration.employeeGroups": "Групи працівників",
  "configuration.help": "Допоможіть!",
  "configuration.restaurant": "Ресторан",
  "contactPage.header": "Зв'яжіться з нами",
  "contactPage.metadata.description": "Контактний опіс",
  "contactPage.metadata.keywords": "Ключові слова для контактів",
  "contactPage.metadata.title": "Зв'яжіться з нами",
  "contactPage.subheader": "Напишіть нам!",
  "cookiesPolicyPage.header": "Політика щодо файлів cookie",
  "cookiesPolicyPage.metadata.title": "Політика щодо файлів cookie",
  "cookiesPolicyPage.subheader": "Відповідна інформація.",
  "cuisine.american": "Американська кухня",
  "cuisine.arabic": "Арабська кухня",
  "cuisine.chinese": "Китайська кухня",
  "cuisine.polish": "Польська кухня",
  "cuisine.romania": "Румунська кухня",
  "cusine.chinese": "Китайська кухня",
  "cusine.polish": "Польська кухня",
  "customer.accountDetail": " акаунти",
  "customer.addEdit": "Додати / Редагувати",
  "customer.blocked": "Обліковий запис заблоковано. Можливість бронювання заблокована.",
  "customer.confirmText": "Замовити",
  "customer.createInvoice": "Створіть рахунок-фактуру",
  "customer.createNewProfile": "Додано новий профіль",
  "customer.createNewProfileModal": "Створити новий профіль?",
  "customer.deleteProfile": "Видалити профіль",
  "customer.description": "Опис",
  "customer.editCompany": "Редагувати компанію",
  "customer.editProfile": "Профіль оновлено",
  "customer.editReservingCustomerData": "Редагувати інформацію про вечірку",
  "customer.emailAddress": "Електронна пошта",
  "customer.errorProfileAction": "Профіль закріплюється за клієнтом",
  "customer.existError": "Користувач вже існує",
  "customer.firstCreateCompany": "*спочатку оберіть компанію",
  "customer.groupChooseLabel": "Виберіть",
  "customer.labels.blocked": "Заблоковано.",
  "customer.labels.customerName": "Ім'я",
  "customer.labels.emailLabel": "Електронна пошта",
  "customer.labels.firstNameLabel": "Ім'я",
  "customer.labels.groupLabel": "Grupa",
  "customer.labels.lastNameLabel": "Ім'я",
  "customer.labels.phoneNumber": "Номер телефону",
  "customer.labels.statusLabel": "Статус",
  "customer.listOfProfiles": "Список профілів",
  "customer.mail": "Електронна пошта",
  "customer.name": "Ім'я",
  "customer.newPasswordWasSent": "Новий пароль було надіслано SMS-повідомленням",
  "customer.noCustomer": "Немає користувача",
  "customer.noCustomers": "Немає клієнтів",
  "customer.noReservations": "Без бронювання",
  "customer.onePersonFound": "1 людина знайдена",
  "customer.password": "Пароль",
  "customer.password.mismatch": "Старе гасло не підходить",
  "customer.passwordHasChange": "Пароль змінено",
  "customer.permissionForHoldingPersonalData": "Згода на обробку персональних даних",
  "customer.profile": "Профіль",
  "customer.profileDetails": "Інформація про профіль",
  "customer.profileExistsError": "Профіль вже існує",
  "customer.registration.code": "Ваш код активації:: _CODE_.",
  "customer.registration.invalid": "Неправильний код активації",
  "customer.registration.sendByEmail":
    "Проблема з надсиланням SMS - код було надіслано на вказану електронну адресу.",
  "customer.remarks": "Коментарі",
  "customer.remarksToClient": "Примітки для замовника",
  "customer.remarksToReservation": "Примітки до бронювання",
  "customer.removeProfileAction": "Профіль видалено",
  "customer.removeProfileAction.error": "Профіль не видалено",
  "customer.reservationHistory": "Історія бронювання",
  "customer.reservingCustomerData": "Інформація про особу, яка здійснює бронювання",
  "customer.saveChanges": "Зберегти зміни?",
  "customer.surname": "Ім'я",
  "customer.telephone": "Номер телефону",
  "customer.telephoneNumber": "Номер телефону",
  "customer.updateClient": "Оновити дані клієнта?",
  "customer.updateProfileModal": "Оновити профіль?",
  "defaultValues.restaurant": "Ресторан",
  "deleteAccountModal.component.button.cancel": "Оголосити",
  "deleteAccountModal.component.button.confirm": "Схвалити",
  "deleteAccountModal.component.header": "Видалити обліковий запис",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Ви впевнені, що хочете видалити свій обліковий запис? Ваша історія бронювання також буде видалена.",
  "documentsPage.header": "Документалістика",
  "documentsPage.metadata.description": "Опис документів",
  "documentsPage.metadata.keywords": "Ключові документи",
  "documentsPage.metadata.title": "Документалістика",
  "documentsPage.subheader": "Відповідна інформація",
  "editReservation.send": "Надіслати",
  "editReservation.sendMessage": "Відправлено запит на зміну параметрів бронювання.",
  "email.already.exist": "Електронна пошта вже існує",
  "email.missing": "Обов'язкова наявність електронної пошти",
  "error": "Виникла помилка. Будь ласка, спробуйте пізніше.",
  "error.LocationNotFound": "Місцезнаходження не знайдено або вказано занадто мало символів",
  "error.cannotCreateReservation": "Не вдалося надіслати запит",
  "error.connectionWithServerLost": "Втрачено зв'язок з сервером.",
  "error.connectionWithServerLostTryLater":
    "З'єднання з сервером було втрачено. Будь ласка, повторіть спробу пізніше",
  "error.email.already.exist": "Електронна пошта вже існує",
  "error.facebook.account.already.exist":
    "Для цього номера вже створено обліковий запис MojStolik у Facebook. Використовуйте логін Facebook",
  "error.google.account.already.exist":
    "Для цього номера вже створено обліковий запис на MojStolik через Google. Використовуйте Google Логін",
  "error.notFoundAddress": "Місцезнаходження не знайдено або вказано занадто мало символів",
  "error.phone.already.exist":
    "Для цього номера вже створено обліковий запис на MojStolik. Увійдіть за номером телефону або скористайтеся опцією нагадування пароля.",
  "filters.doFilter": "Фільтр",
  "filters.fromToday": "Станом на сьогодні",
  "filters.toToday": "До сьогоднішнього дня",
  "filters.today": "Сьогодні",
  "footer.Next": "Далі",
  "footer.Previous": "Попередні",
  "footer.alertOnBack": "Відмовитися від змін?",
  "forGuestPage.header": "Для гостя",
  "forGuestPage.metadata.description": "Для відвідувача опис",
  "forGuestPage.metadata.keywords": "Для відвідувача ключові слова",
  "forGuestPage.metadata.title": "Для гостя",
  "forRestaurantsPage.form": "Ваша заявка відправлена. Ми зв'яжемося з вами найближчим часом.",
  "forRestaurantsPage.header": "Для ресторанів",
  "forRestaurantsPage.metadata.description": "Для опису ресторанів",
  "forRestaurantsPage.metadata.keywords": "Для ресторанів ключові слова",
  "forRestaurantsPage.metadata.title": "Для ресторанів",
  "forRestaurantsPage.subheader":
    "Дізнайтеся, які переваги система MojStolik надасть вашому ресторану.",
  "global.form.message.atLeastNineCharacters": "Потрібно щонайменше дев'ять символів",
  "global.form.message.invalidBirthDateFormat": "Неправильний формат дати народження",
  "global.form.message.invalidEmailFormat": "Неправильний формат адреси електронної пошти",
  "global.form.message.invalidPhoneFormat": "Неправильний формат телефону",
  "global.form.message.invalidPostCodeFormat": "Неправильний формат поштового індексу",
  "global.form.message.newPasswordsDoNotMatch": "Нові слогани відрізняються!",
  "global.form.message.requiredField": "Обов'язкове поле",
  "global.messages.sessionTimeout": "Сесія закінчилася",
  "global.time.minutes": "хвилину.",
  "group.removeErrorAction": "Група призначається користувачеві",
  "howItWorksPage.header": "Як це працює?",
  "howItWorksPage.metadata.description": "Опис того, як це працює",
  "howItWorksPage.metadata.keywords": "Як працюють ключові слова",
  "howItWorksPage.metadata.title": "Як це працює?",
  "howItWorksPage.subheader": "Крок за кроком",
  "image.notFound": "Зображення не знайдено",
  "indexPage.background.header": "Знайдіть найкращі ресторани у вашому місті,",
  "indexPage.background.headerExtend": "а потім забронювати столик:",
  "indexPage.button.bookNow": "Замовити",
  "indexPage.button.hideAdditionalFilters": "Приховати додаткові фільтри",
  "indexPage.button.readMore": "Прочитайте",
  "indexPage.button.search": "Пошук",
  "indexPage.button.showAdditionalFilters": "Додаткові фільтри",
  "indexPage.button.use": "Використання",
  "indexPage.header.blogs": "Вибрані статті",
  "indexPage.header.currentPromotions": "Спеціальні пропозиції та події",
  "indexPage.header.currentPromotionsExtend": "на вашому місці",
  "indexPage.header.mostPopular": "Найпопулярніші",
  "indexPage.header.mostPopularExtend": "на вашому місці",
  "indexPage.input.label.date": "Дані",
  "indexPage.input.label.hour": "Година.",
  "indexPage.input.label.location": "Місцезнаходження",
  "indexPage.input.label.persons": "Кількість людей",
  "indexPage.input.label.persons.optionExtend": "os.",
  "indexPage.input.label.priceLevel": "Рівень цін",
  "indexPage.input.label.rating": "Оцінка",
  "indexPage.input.label.rating.optionExtend": "зірки.",
  "indexPage.input.label.restaurantType": "Тип кухні",
  "indexPage.input.label.searchByName": "Пошук за назвою",
  "indexPage.input.noData": "Безрезультатно.",
  "indexPage.input.placeholder.select": "Виберіть",
  "indexPage.input.placeholder.type": "Увійдіть",
  "indexPage.input.placeholer.typeInOrSelect": "Введіть або виберіть",
  "invitation":
    "Доброго дня, вас запрошено до ресторану {{restaurant}} на {{date}} о {{time}}. Завантажте додаток MojStolik.co.uk, щоб дізнатися більше про ресторан: https://www.mojstolik.pl/pobierz/apka.html",
  "invoice.vatId": "ПІН-КОД: {{vatId}}",
  "login": "Логін",
  "login.accountDoesNotExist": "Облікового запису не існує",
  "login.accountName": "Ім'я користувача",
  "login.doLogin": "Логін",
  "login.forgotPassword": "Забули пароль?",
  "login.forgottenPasswordLink": "Забули пароль?",
  "login.generalTitle": "Лісозаготівля",
  "login.insertLoginData": "Введіть свої дані для входу",
  "login.insertManagerPassword": "Введіть адресу електронної пошти менеджера",
  "login.loggingToSystem": "Вхід в систему",
  "login.loginOrPasswordWrong": "Логін або пароль невірні",
  "login.loginText": "Логін",
  "login.logout": "Wyloguj",
  "login.logout.message": "Ви ввійшли в систему",
  "login.newAccount": "Новий обліковий запис",
  "login.newUpdateReady": "Доступна нова версія програми. Оновлення.",
  "login.noAppleAccount":
    "З цим обліковим записом Apple не пов'язано жодного облікового запису. Спробуйте інший метод входу або зареєструйте новий обліковий запис.",
  "login.noFacebookAccount":
    "З цим обліковим записом Facebook не пов'язано жодного облікового запису. Спробуйте інший метод входу або зареєструйте новий акаунт.",
  "login.noGoogleAccount":
    "З цим обліковим записом Google не пов'язано жодного облікового запису. Спробуйте інший метод входу або зареєструйте новий акаунт.",
  "login.passwordRecovery": "Відновлення пароля",
  "login.passwordSent.sms": "Ваш новий пароль: _PASSWORD_.",
  "login.passwordSent.sms.too.often":
    "Занадто часта спроба змінити пароль, спробуйте ще раз через хвилину",
  "login.passwordSentToEmail": "Новий пароль надіслано на вказану електронну адресу",
  "login.phoneNumberOrMail": "Номер телефону або адреса електронної пошти",
  "login.recover": "Отримано з",
  "login.resetPasswordLabel": "Адреса електронної пошти",
  "login.restaurantChanged": "Ресторан трансформувався",
  "login.thisAccountAlreadyExist": "Користувач вже існує",
  "login.updateRequired": "Вам потрібно оновити свій додаток. Він може не працювати належним чином",
  "login.userUnauthorized": "Логін або пароль невірні",
  "login.userUnauthorized.useApple":
    "Неправильний логін або пароль. Спробуйте увійти через Apple або скиньте пароль.",
  "login.userUnauthorized.useFacebook":
    "Неправильний логін або пароль. Спробуйте увійти через Facebook або скиньте пароль",
  "login.userUnauthorized.useGoogle":
    "Неправильний логін або пароль. Спробуйте увійти через Google або скиньте пароль.",
  "login.yourPassword": "Ваш пароль",
  "loginPage.anchor.passwordReset": "Скинути пароль",
  "loginPage.button.logIn": "Увійдіть в систему",
  "loginPage.button.register": "Зареєструватися",
  "loginPage.columns.header.socialLogin": "Увійдіть за допомогою",
  "loginPage.columns.header.standardLogin": "Стандартний логін",
  "loginPage.header": "Лісозаготівля",
  "loginPage.input.label.password": "Пароль",
  "loginPage.input.label.phoneOrEmail": "Номер телефону",
  "loginPage.message.logged": "Перепостив.",
  "loginPage.message.loggedViaApple": "Репост від Apple.",
  "loginPage.message.loggedViaFacebook": "Реблог через Facebook.",
  "loginPage.message.loggedViaGoogle": "Реблог через Google.",
  "loginPage.metadata.description": "Опис ведення журналу",
  "loginPage.metadata.keywords": "Ключові слова журналу",
  "loginPage.metadata.title": "Лісозаготівля",
  "loginPage.paragraph.alreadyLoggedIn": "Ви вже увійшли в систему.",
  "loginPage.paragraph.dontHaveAccountYet": "Ще не маєте облікового запису?",
  "loginPage.paragraph.registerNow": "Реєструйтеся!",
  "loginPage.subheader":
    "Як зареєстрований користувач, ви можете швидше бронювати столики та переглядати історію своїх замовлень.",
  "logoutPage.metadata.description": "Опис виходу з системи",
  "logoutPage.metadata.keywords": "Видалення ключових слів",
  "logoutPage.metadata.title": "Вийти з системи",
  "mail.confirm.message": "Підтвердіть свою електронну адресу, перейшовши за посиланням",
  "mail.confirm.title": "Повідомлення від MyTable.co.uk",
  "map.searchArea": "Обшукайте місцевість",
  "menu.areaControl": "Управління номерами",
  "menu.config": "Конфігурація",
  "menu.configPage": "Налаштування",
  "menu.configuration": "Конфігурація",
  "menu.currentReservations": "Поточні бронювання",
  "menu.customers": "Кленці",
  "menu.homePage": "Головна",
  "menu.invoice": "Рахунки-фактури",
  "menu.login": "Лісозаготівля",
  "menu.logout": "Wyloguj",
  "menu.myReserv": "Бронювання",
  "menu.myReservations": "Мої бронювання",
  "menu.newAccount": "Новий обліковий запис",
  "menu.passwordRecovery": "Відновлення пароля",
  "menu.reservationpage": "Нове бронювання",
  "menu.reservations": "Всі бронювання",
  "menu.resetPassword": "Нагадування про пароль",
  "menu.restaurant": "Ресторан",
  "menu.sale": "Акції",
  "menu.search": "Пошук",
  "myReservation.doRate": "Оцінити",
  "myReservation.doRateQuestion": "Ви хочете оцінити це бронювання на {{rate}}/5?",
  "myReservation.doRateSuccess": "Оцінка була надана",
  "myReservation.doRateTitle": "Як би ви оцінили своє бронювання?",
  "myReservation.doSuccessRemove": "Бронювання видалено зі списку",
  "myReservation.yourRate": "Ваша оцінка",
  "navbar.allReserv": "Усе.",
  "navbar.areaEdition": "Номерне видання",
  "navbar.cancelChangesReservation": "Скасувати зміну бронювання?",
  "navbar.cancelReservation": "Скасувати бронювання?",
  "navbar.changePass": "Зміна пароля",
  "navbar.clientPreview": "Погляд клієнта",
  "navbar.companyEdition": "Видання компанії",
  "navbar.configuration": "Конфігурація",
  "navbar.currentReserv": "Течія",
  "navbar.currentReservations": "Поточні бронювання",
  "navbar.customers": "Кленці",
  "navbar.dataCustomer": "Інформація про особу, яка здійснює бронювання",
  "navbar.defaultCancel": "Відмінити?",
  "navbar.employee": "Працівники",
  "navbar.historyReserv": "Історичний",
  "navbar.invoiceEdition": "Редагування інвойсу",
  "navbar.invoicePreview": "Попередній перегляд рахунків-фактур",
  "navbar.label.editReservation": "Зміна бронювання",
  "navbar.label.myProfile": "Мій профіль",
  "navbar.label.myReservation": "Мої бронювання",
  "navbar.label.reservation": "Бронювання",
  "navbar.label.reservationDetails": "Деталі бронювання",
  "navbar.label.sale": "Акції",
  "navbar.newReservation": "Нове бронювання",
  "navbar.reservationActualState": "Поточний стан бронювання",
  "navbar.reservationEdit": "Редагування бронювання",
  "navbar.reservationPreview": "Попередній перегляд бронювання",
  "navbar.reservations": "Бронювання",
  "notFoundPage.button.goToHomePage": "Перейти на головну сторінку",
  "notFoundPage.header": "Помилка 404",
  "notFoundPage.metadata.description": "404 - Опис сторінки не знайдено",
  "notFoundPage.metadata.title": "404 - Сторінку не знайдено!",
  "notFoundPage.subheader": "Запитувана URL-адреса",
  "notFoundPage.subheaderExtend": "не знайдено на сервері.",
  "notFoundPage.subheaderExtend2":
    "Спробуйте перевірити правильність введеної адреси або перейдіть за посиланням",
  "notFoundPage.subheaderExtend3": "домашня сторінка",
  "notification.body.Canceled": "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ було скасовано.",
  "notification.body.Completed":
    "Дякуємо за обробку вашого бронювання в _RESTAURANT_ в _DAY_ о _TIME_.",
  "notification.body.Confirmed": "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ підтверджено.",
  "notification.body.Rejected":
    "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ було скасовано. _MESSAGE_",
  "notification.body.WaitingForApproval":
    "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ очікує на підтвердження.",
  "notification.body.WaitingForConfirmation":
    "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ очікує на підтвердження.",
  "notification.invitation.accepted.body":
    "_NAME_ прийняв Ваше запрошення в _RESTAURANT_ в _DAY_ о _TIME_.",
  "notification.invitation.accepted.title": "Запрошення прийнято.",
  "notification.invitation.rejected.body":
    "_NAME_ відхилив ваше запрошення до _RESTAURANT_ у _DAY_ о _TIME_.",
  "notification.invitation.rejected.title": "Скасування запрошення.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Повідомлення від _RESTAURANT_",
  "notification.parameters.updated.body":
    "Ваше бронювання в _RESTAURANT_ на _DAY_ о _TIME_ було оновлено.",
  "notification.parameters.updated.title": "Оновлення бронювання",
  "onboarding.promotion.MFR2023.Advanced.description":
    "Акція MFR2023 включає безкоштовний пробний період. Протягом перших трьох місяців з моменту підписання договору абонентська плата не стягуватиметься, як зазначено в описі тарифних планів нижче. Акція призначена для нових клієнтів, поширюється лише на тарифні плани LIGHT та ADVANCED і діє з 25.04 по 20.05.2023.",
  "onboarding.promotion.MFR2023.AdvancedPro.description":
    "Акція не поширюється на тарифний план ADVANCED PRO.",
  "onboarding.promotion.MFR2023.Light.description":
    "Акція MFR2023 включає безкоштовний пробний період. Протягом перших трьох місяців з моменту підписання договору абонентська плата не стягуватиметься, як зазначено в описі тарифних планів нижче. Акція призначена для нових клієнтів, поширюється лише на тарифні плани LIGHT та ADVANCED і діє з 25.04 по 20.05.2023.",
  "onboarding.promotion.MFR2023.description":
    "Акція MFR2023 включає безкоштовний пробний період. Протягом перших трьох місяців з моменту підписання договору абонентська плата не стягуватиметься, як зазначено в описі тарифних планів нижче. Акція призначена для нових клієнтів, поширюється лише на тарифні плани LIGHT та ADVANCED і діє з 25.04 по 20.05.2023.",
  "onboarding.promotion.WAKACJE2023.Advanced.description":
    "Акція не поширюється на тарифний план ADVANCED.",
  "onboarding.promotion.WAKACJE2023.AdvancedPro.description":
    "Акція не поширюється на тарифний план ADVANCED PRO.",
  "onboarding.promotion.WAKACJE2023.Light.description":
    "HOLIDAY2023 - це ЛЕГКА пропозиція для нових клієнтів за 0 злотих у червні, липні та серпні 2023 року. Після закінчення цих місяців ви можете продовжувати співпрацювати з нами на стандартних умовах.",
  "onboarding.promotion.WAKACJE2023.description":
    "HOLIDAY2023 - це ЛЕГКА пропозиція для нових клієнтів за 0 злотих у червні, липні та серпні 2023 року. Після закінчення цих місяців ви можете продовжувати співпрацювати з нами на стандартних умовах.",
  "onboarding.promotion.promo-test.description": "Опис промо-тесту. Правила: _LINK_.",
  "onboarding.promotion.promo-test.terms.link": "https://tst-web.mojstolik.pl/pl",
  "password.errors.weak": "Пароль занадто простий",
  "passwordFormModal.component.button.cancel": "Оголосити",
  "passwordFormModal.component.button.confirm": "Схвалити",
  "passwordFormModal.component.header": "Введіть пароль до свого облікового запису",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Введіть пароль для підтвердження дії.",
  "payment.card": "Картка",
  "payment.cash": "готівка",
  "payment.item.description.per.person": "_PRODUCT_NAME_",
  "payment.item.description.per.reservation": "_PRODUCT_NAME_",
  "payment.otherType": "інший",
  "payment.title.generic": "Бронювання вимагає оплати",
  "payment.title.with.event": "Вартість заходу: _EVENT_NAME_.",
  "payment.transfer": "передача",
  "payment.type": "Спосіб оплати",
  "preference.category.cuisine": "Тип кухні",
  "preference.category.cuisine.polish": "Польські кухні",
  "preference.category.price": "Ціна",
  "previewImageModal.component.anchor.close": "Закрити",
  "previewRestaurantModal.component.anchor.close": "Закрити",
  "priceListPage.header": "Прейскурант",
  "priceListPage.metadata.description": "Опис прайс-листа",
  "priceListPage.metadata.keywords": "Прайс-лист за ключовим словом",
  "priceListPage.metadata.title": "Прейскурант",
  "privacyPolicyPage.header": "Політика конфіденційності",
  "privacyPolicyPage.metadata.title": "Політика конфіденційності",
  "privacyPolicyPage.subheader": "Відповідна інформація.",
  "promotions.emptyList": "Без просування по службі",
  "registration.activeCode": "Код активації",
  "registration.addressEmail": "Адреса електронної пошти",
  "registration.codeInformation":
    "На {{phone}} було надіслано код активації, введіть його нижче та підтвердіть за допомогою",
  "registration.createAccount": "Створити обліковий запис",
  "registration.createSuccess": "Обліковий запис створено",
  "registration.differentPasswords": "Паролі відрізняються",
  "registration.finishButton": "Завершіть реєстрацію",
  "registration.formIsInvalid":
    "Дані заповнені неправильно. Будь ласка, скористайтеся допомогою внизу.",
  "registration.generalTitle": "Реєстрація",
  "registration.phoneNumber": "Номер телефону",
  "registration.phoneToShort": "Номер занадто короткий",
  "registration.readAgreement": "Ознайомтеся з правилами",
  "registration.repeatPassword": "Повторити пароль",
  "registration.requiredFieldsFirstStep":
    "Номер телефону, електронна пошта та пароль є обов'язковими.",
  "registration.weakPassword": "Пароль занадто простий",
  "registrationPage.button.confirm": "Підтвердити",
  "registrationPage.button.register": "Зареєструватися",
  "registrationPage.button.sendCodeAgain": "Код для повторного відправлення",
  "registrationPage.columns.header.registerStandard": "Стандартна реєстрація",
  "registrationPage.columns.header.registerWith": "Реєструйтеся на",
  "registrationPage.header": "Реєстрація",
  "registrationPage.header.SMSConfirmation": "SMS-підтвердження",
  "registrationPage.header.statements": "Заяви",
  "registrationPage.input.label.agreementEvent":
    " Я ознайомився з умовами та положеннями заходу та акції, які можна знайти в розділі",
  "registrationPage.input.label.agreementEvent.extension": "за цим посиланням",
  "registrationPage.input.label.agreementReadWithdrawal":
    "Я ознайомився з умовами та положеннями Mojstolik.co.uk, зокрема з інструкціями щодо права на відкликання",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Умови та положення mojstolik.pl",
  "registrationPage.input.label.birthDate": "Дата народження",
  "registrationPage.input.label.city": "Місто",
  "registrationPage.input.label.email": "Адреса електронної пошти",
  "registrationPage.input.label.firstName": "Ім'я",
  "registrationPage.input.label.lastName": "Ім'я",
  "registrationPage.input.label.password": "Пароль",
  "registrationPage.input.label.passwordRepeat": "Повторити пароль",
  "registrationPage.input.label.phoneNumber": "Номер телефону",
  "registrationPage.input.label.postalCode": "Поштовий індекс",
  "registrationPage.input.label.streetAndLocalNumber": "Вулиця та номер квартири",
  "registrationPage.input.placeholder.birthDate": "Наприклад, 1976-10-25",
  "registrationPage.input.placeholder.city": "Наприклад, Варшава",
  "registrationPage.input.placeholder.code": "Введіть код",
  "registrationPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "registrationPage.input.placeholder.firstName": "Наприклад, Ян",
  "registrationPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.placeholder.postalCode": "Наприклад, 03-123",
  "registrationPage.input.placeholder.streetAndLocalNumber": "Наприклад, Grójecka 130/105",
  "registrationPage.message.accountCreated": "Обліковий запис створено.",
  "registrationPage.message.enterCodeFromSMS": "Розшифруйте код з SMS.",
  "registrationPage.message.registrationTimeExpired": "Час реєстрації закінчився",
  "registrationPage.message.sendCodeAgain": "СМС-код було надіслано ще раз.",
  "registrationPage.metadata.description": "Опис реєстрації",
  "registrationPage.metadata.keywords": "Реєстрація ключових слів",
  "registrationPage.metadata.title": "Реєстрація",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Введіть SMS-код, який ми надіслали на ваш номер телефону, щоб завершити реєстрацію.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Введіть SMS-код дня",
  "registrationPage.paragraph.optionalFields": "Необов'язкові поля",
  "registrationPage.paragraph.seconds": "second(-a)",
  "registrationPage.paragraph.sendCodeAgain": "СМС-код було надіслано ще раз.",
  "registrationPage.paragraph.timeIsOver": "Час вийшов.",
  "registrationPage.paragraph.timeLeft": "Залишилося",
  "registrationPage.subheader":
    "Як зареєстрований користувач, ви можете швидше бронювати столики та переглядати історію своїх замовлень.",
  "request.blocked": "Запит заблоковано, спробуйте пізніше",
  "reservation.addNewAction": "Створено нову хвилю",
  "reservation.addNote": "Додати коментар",
  "reservation.addRemarkQuestion": "Додати примітку до бронювання?",
  "reservation.alreadyRated": "Бронювання вже оцінено",
  "reservation.basicInformation": "Основна інформація",
  "reservation.cancelReservation": "Скасувати",
  "reservation.cancelReservationQuestion": "Ви впевнені, що хочете скасувати бронювання?",
  "reservation.changeReservation": "Зміна",
  "reservation.changeReservationParameters": "Змінити параметри бронювання",
  "reservation.changeStatusAction": "Статус змінено на",
  "reservation.chooseStatus": "Виберіть статус",
  "reservation.comunications": "Новини",
  "reservation.createReservation": "Створіть бронювання для",
  "reservation.defaultTime": "Бажаний час бронювання {{reservationTime}}.",
  "reservation.doReservation": "Замовити",
  "reservation.duration": "Тривалість",
  "reservation.durationTime": "Тривалість бронювання {{durationTime}}",
  "reservation.editData": "Редагувати дані",
  "reservation.editReservation": "Редагувати бронювання",
  "reservation.errorNewAction": "Не вдалося створити нове бронювання",
  "reservation.errorNotAvailableYet": "Бронювання поки що недоступне.",
  "reservation.errorPast": "Бронювання не може бути зроблено в минулому",
  "reservation.errorToLate": "Бронювання більше не доступне.",
  "reservation.filter.fromDate": "Оскільки",
  "reservation.filter.lastName": "Ім'я",
  "reservation.filter.toDate": "До яких пір",
  "reservation.forHowLong": "Тривалість",
  "reservation.generalTitle": "Бронювання",
  "reservation.group": "Група",
  "reservation.individual": "Фізична особа",
  "reservation.informationOnReservation": "Інформація про бронювання",
  "reservation.invitation": "Запросити",
  "reservation.labels.client": "Клієнт",
  "reservation.labels.date": "Кінець зв'язку",
  "reservation.labels.dateTo": "Робити",
  "reservation.labels.firstName": "Ім'я",
  "reservation.labels.lastName": "Ім'я",
  "reservation.labels.number": "Нр Рез.",
  "reservation.labels.numberOfPeople": "Кількість відвідувачів",
  "reservation.labels.phone": "Номер телефону",
  "reservation.labels.profil": "Профіль",
  "reservation.labels.remarks": "Коментарі",
  "reservation.labels.status": "Статус",
  "reservation.labels.tableNumber": "Кімната - стіл",
  "reservation.lessSpotThanPeople": "Було відібрано меншу кількість сайтів. Чи буде продовження?",
  "reservation.message": "Повідомлення від MyTable.co.uk",
  "reservation.messages": "Написати повідомлення",
  "reservation.morePeople": "Більше людей:",
  "reservation.newPersonToReservation": "Додати нову людину до бронювання?",
  "reservation.noFreeTables": "Немає вільних столів",
  "reservation.notExist": "Бронювання не існує",
  "reservation.numberOfGuests": "Кількість відвідувачів",
  "reservation.payment.pay": "Заплати",
  "reservation.payment.status": "Статус платежу",
  "reservation.payment.status.Cancelled": "Скасовано.",
  "reservation.payment.status.Cancelled.text": "Платіж скасовано",
  "reservation.payment.status.Completed": "Зрозумів.",
  "reservation.payment.status.Completed.text": "Оплата здійснена",
  "reservation.payment.status.Created": "Створено",
  "reservation.payment.status.Created.text": "Платіж створено",
  "reservation.payment.status.Expired": "Закінчився",
  "reservation.payment.status.Expired.text": "Термін дії платежу закінчився",
  "reservation.payment.status.ProcessingPayment": "Оброблено",
  "reservation.payment.status.ProcessingPayment.text": "Оплата в процесі",
  "reservation.payment.status.Unknown": "Невідомий статус",
  "reservation.payment.status.Unknown.text": "Невідомий статус платежу",
  "reservation.payment.status.WaitingForPayment": "В очікуванні",
  "reservation.payment.status.WaitingForPayment.text": "Очікується платіж",
  "reservation.paymentDetails": "Реквізити для оплати",
  "reservation.paymentForReservation": "Оплата бронювання",
  "reservation.paymentRequired": "Бронювання платне",
  "reservation.payments": "Платежі",
  "reservation.personsError": "Запит на бронювання для 0 осіб не може бути відправлений",
  "reservation.pickDurationTime": "Виберіть тривалість",
  "reservation.preferences": "Уподобання",
  "reservation.prepayment": "Передоплата",
  "reservation.remarks": "Коментарі",
  "reservation.remarksFromRestaurant": "Коментар ресторану",
  "reservation.remind.message":
    "Сервіс MojStolik.co.uk нагадує вам про бронювання столика в ресторані _RESTAURANT_ на _DATE_ для _PERSONS_. Щоб скасувати бронювання, натисніть на посилання: _LINK_ Послугу бронювання надає сервіс MojStolik.pl",
  "reservation.request.not.yet.available": "Бронювання ще не доступне.",
  "reservation.request.too.often": "Запит надіслано.",
  "reservation.reservationDate": "День",
  "reservation.spotsChosen": "Вибрані місця проведення",
  "reservation.status": "Статус",
  "reservation.status.Canceled": "Скасовано.",
  "reservation.status.Completed": "Бронювання зроблено",
  "reservation.status.Confirmed": "Бронювання підтверджено",
  "reservation.status.Delayed": "Затримується.",
  "reservation.status.NotCompleted": "Бронювання не виконано",
  "reservation.status.Rejected": "Бронювання скасовано",
  "reservation.status.WaitingForApproval": "Бронювання очікує на затвердження",
  "reservation.status.WaitingForConfirmation": "В очікуванні",
  "reservation.successRequest": "Запит на бронювання відправлено",
  "reservation.summaryCost": "Загальна сума",
  "reservation.updateAction": "Бронювання оновлено",
  "reservation.updateReservation": "Оновити бронювання?",
  "reservation.updateReservationButton": "Оновити бронювання",
  "reservation.whichHour": "Година.",
  "reservation.wholeRoom": "Уся кімната",
  "reservationHistoryPage.button.revoke": "Скасувати",
  "reservationHistoryPage.header": "Ваші замовлення",
  "reservationHistoryPage.metadata.description": "Опис вашого бронювання",
  "reservationHistoryPage.metadata.keywords": "Ваші ключові слова для бронювання",
  "reservationHistoryPage.metadata.title": "Ваші замовлення",
  "reservationHistoryPage.paragraph.additionalDescription": "Комунікація з рестораном",
  "reservationHistoryPage.paragraph.date": "Дані",
  "reservationHistoryPage.paragraph.duration": "Тривалість",
  "reservationHistoryPage.paragraph.hour": "Година.",
  "reservationHistoryPage.paragraph.persons": "Кількість осіб",
  "reservationHistoryPage.paragraph.personsShortcut": "os.",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "Бронювання не існує",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "Список бронювання порожній.",
  "reservationHistoryPage.subheader":
    "Тут ви можете переглянути свої попередні бронювання та керувати майбутніми бронюваннями.",
  "reservationMenu.allActive": "Всі активні",
  "reservationMenu.fromApp": "Запити до програми",
  "reservationMenu.toConfirmation": "Потрібно підтвердити",
  "reservationMenu.today": "На сьогодні",
  "reservationParams.restaurantName": "Назва приміщення",
  "reservationParams.yourLocalization": "Ваше місцезнаходження",
  "reservations.noReservations": "Без бронювання",
  "resetPassoword.generalTitle": "Нагадування про пароль",
  "resetPassoword.info":
    "Введіть номер телефону, на який було зареєстровано ваш обліковий запис. Пароль буде надіслано в SMS на цей номер",
  "resetPassoword.sendNewPassword": "Надіслати пароль",
  "resetPasswordPage.button.confirm": "Підтвердити",
  "resetPasswordPage.header": "Скинути пароль",
  "resetPasswordPage.input.label.phoneOrEmail": "Номер телефону",
  "resetPasswordPage.metadata.description": "Опис скидання пароля",
  "resetPasswordPage.metadata.keywords": "Скинути пароль до ключового слова",
  "resetPasswordPage.metadata.title": "Скинути пароль",
  "resetPasswordPage.subheader":
    "Заповніть форму нижче, і ми надішлемо вам новий пароль до облікового запису по SMS",
  "resetPasswordPage.subheader2": "Ми надішлемо вам новий пароль на ваш номер телефону",
  "resetPasswordPage.toast.error.passwordChanged": "Вказаний номер телефону є невірним.",
  "resetPasswordPage.toast.passwordChanged":
    "Новий пароль було надіслано у вигляді SMS на номер телефону, вказаний при реєстрації.",
  "restaurant.aboutUs": "O в",
  "restaurant.bankAccountNumber": "Номер банківського рахунку",
  "restaurant.buildingNumber": "Номер будинку",
  "restaurant.city": "Місто",
  "restaurant.closeHour": "Час закриття",
  "restaurant.companyName": "Назва компанії",
  "restaurant.dayFormat": "dd - день місяця",
  "restaurant.defaultReservationLength": "Тривалість бронювання за замовчуванням (год)",
  "restaurant.emailAddress": "Електронна пошта",
  "restaurant.emptyList": "Не знайдено ресторанів, що відповідають заданим критеріям пошуку.",
  "restaurant.greaterThenZeroError": "Повинно бути більше 0",
  "restaurant.insertRestaurant": "Увійдіть до ресторанів",
  "restaurant.legend": "Легенда:",
  "restaurant.localNumber": "Місцевий номер",
  "restaurant.logo": "Логотип",
  "restaurant.maximalReservationLength": "Максимальна тривалість бронювання (год)",
  "restaurant.menu.price": "{{price}} злотих",
  "restaurant.minimalPeopleForGroup": "Мінімальна кількість осіб на одне групове бронювання",
  "restaurant.monthFormat": "ММ - місяць",
  "restaurant.navbar.aboutRestaurant": "Ресторан",
  "restaurant.navbar.menu": "Меню",
  "restaurant.navbar.promotion": "Акції",
  "restaurant.navigate": "Перейти до ресторану",
  "restaurant.navigationApp": "Навігаційний додаток",
  "restaurant.nipNumber": "НІП",
  "restaurant.openHour": "Година роботи",
  "restaurant.pickDay": "Оберіть день",
  "restaurant.pickHour": "Виберіть час",
  "restaurant.pickPersons": "Виберіть кількість осіб",
  "restaurant.postalCode": "Поштовий індекс",
  "restaurant.price.expensive": "Дуже дорого",
  "restaurant.price.extralow": "Дуже дешево",
  "restaurant.price.low": "Дешево.",
  "restaurant.price.medium": "Не так дорого",
  "restaurant.productsServices": "Продукти / послуги",
  "restaurant.promotion.label": "Акції",
  "restaurant.questionUpdateRestaurant": "Оновити дані про ресторан?",
  "restaurant.rate": "Оцінка",
  "restaurant.restaurant": "Ресторан",
  "restaurant.saveNavigationPick": "Пам'ятайте про вибір",
  "restaurant.seriesNumberTemplate": "Шаблон нумерації рахунків-фактур",
  "restaurant.serverAddress": "Адреса сервера",
  "restaurant.street": "Вулиця",
  "restaurant.web.confirm": "Ви хочете відкрити веб-сайт?",
  "restaurant.writeCity": "Місто",
  "restaurant.yearEndFormat": "uu - кінець року, наприклад, 18",
  "restaurant.yearFullFormat": "uuu - повний рік, наприклад, 2018",
  "restaurantPage.anchor.edit": "Редагувати",
  "restaurantPage.anchor.login": "Логін",
  "restaurantPage.anchor.register": "реєстр",
  "restaurantPage.anchor.sendCodeAgain": "Код для повторного відправлення",
  "restaurantPage.button.cancel": "Оголосити",
  "restaurantPage.button.confirm": "Підтвердити",
  "restaurantPage.button.goToHomePage": "Повернутися на головну сторінку",
  "restaurantPage.button.pageWWW": "Веб-сайт",
  "restaurantPage.button.seeMenu": "Дивіться меню",
  "restaurantPage.button.showLess": "Показати менше",
  "restaurantPage.button.showMore": "Показати більше",
  "restaurantPage.header.gallery": "Фотогалерея",
  "restaurantPage.header.promotions": "Спеціальні пропозиції та події",
  "restaurantPage.header.reservationConfirmation": "Підтвердження бронювання",
  "restaurantPage.header.restaurantDescription": "Опис ресторану",
  "restaurantPage.header.statements": "Заяви",
  "restaurantPage.header.use": "Використання",
  "restaurantPage.header.yourPersonalData": "Ваші дані",
  "restaurantPage.header.yourReservation": "Ваше бронювання",
  "restaurantPage.input.label.agreementEvent":
    " Я ознайомився з умовами та положеннями заходу та акції, які можна знайти в розділі",
  "restaurantPage.input.label.agreementEvent.extension": "за цим посиланням",
  "restaurantPage.input.label.agreementMarketing":
    "Я хочу отримувати рекламні акції, спеціальні пропозиції, конкурсні пропозиції або інший маркетинговий контент від MojStolik, включаючи індивідуальну інформацію про послуги та продукти MojStolik, доступні в Клієнтському додатку, за допомогою електронного зв'язку. Ви можете відкликати цю згоду в будь-який час.",
  "restaurantPage.input.label.agreementMarketingThirdPart":
    " Я хочу отримувати рекламні акції, спеціальні пропозиції, конкурсні пропозиції або інший маркетинговий контент від MojStolik, включаючи індивідуальну інформацію про послуги та продукти Ресторану та Суб'єктів співпраці, зазначених на сайті mojstolik.co.uk, доступну в Клієнтській заявці, за допомогою електронного зв'язку. Ви можете відкликати цю згоду в будь-який час.",
  "restaurantPage.input.label.code": "Введіть код дня",
  "restaurantPage.input.label.date": "Дані",
  "restaurantPage.input.label.description": "Примітки до бронювання",
  "restaurantPage.input.label.email": "Адреса електронної пошти",
  "restaurantPage.input.label.firstName": "Ім'я",
  "restaurantPage.input.label.footer":
    "MojStolik використовує різні електронні канали зв'язку і веде свою діяльність за допомогою електронної пошти, телефону, SMS та MMS. Адміністратором ваших персональних даних є Table4Rest spółka z ograniczoną odpowiedzialnością з місцезнаходженням у Варшаві, 02-656 Варшава, вул. Ksawerów 21, KRS 0000752110, NIP 5213842139. Ваші персональні дані будуть оброблятися з метою направлення вам маркетингового контенту через електронні канали зв'язку. Для отримання додаткової інформації про обробку ваших персональних даних, включаючи ваші права, будь ласка, ознайомтеся з Політикою конфіденційності.",
  "restaurantPage.input.label.hour": "Година.",
  "restaurantPage.input.label.lastName": "Ім'я",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Я хочу отримувати рекламні акції, спеціальні пропозиції, конкурсні пропозиції або інший маркетинговий контент від MojStolik, включаючи індивідуальну інформацію про послуги та продукти Ресторану та Суб'єктів співпраці, зазначених на сайті mojstolik.co.uk, доступну в Клієнтській заявці, за допомогою електронного зв'язку. Ви можете відкликати цю згоду в будь-який час.",
  "restaurantPage.input.label.personalData":
    "Я заявляю, що ознайомлений і приймаю положення Умов надання послуг MojStolik за допомогою Клієнтського додатку.",
  "restaurantPage.input.label.personalData.extension": "Умови та положення MojStolik",
  "restaurantPage.input.label.persons": "Кількість людей",
  "restaurantPage.input.label.persons.optionExtend": "os.",
  "restaurantPage.input.label.phoneNumber": "Номер телефону",
  "restaurantPage.input.label.readWithdrawal":
    "Я ознайомився з умовами та положеннями Mojstolik.co.uk, зокрема з інструкціями щодо права на відкликання",
  "restaurantPage.input.label.readWithdrawal.extension": "Умови та положення mojstolik.pl",
  "restaurantPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "restaurantPage.input.placeholder.firstName": "Наприклад, Ян",
  "restaurantPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.typeIn": "Увійдіть",
  "restaurantPage.messages.codeResent": "СМС-код було надіслано ще раз.",
  "restaurantPage.paragraph.dataIsIncorrect": "Дані невірні?",
  "restaurantPage.paragraph.doYouHaveAnAccount":
    "Вже маєте обліковий запис або хочете зареєструватися?",
  "restaurantPage.paragraph.dontWontUsePromotion": "Я не хочу користуватися акціями",
  "restaurantPage.paragraph.noAvailableHours": "У цих критеріях немає вільних годин.",
  "restaurantPage.paragraph.noAvailableHoursExtension": "Оберіть іншу дату або кількість людей.",
  "restaurantPage.paragraph.or": " або",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Введіть код з SMS, яке ми надіслали на ваш номер телефону, щоб завершити процес бронювання столика.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Введіть код з повідомлення, яке ми надіслали на вашу електронну адресу, щоб завершити процес бронювання столика.",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Код було повторно надіслано через SMS. Не отримали код?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Авторизуйтеся через електронну пошту:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Відправте код на вказану електронну адресу.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "Код було надіслано на електронну адресу:",
  "restaurantPage.paragraph.tableReserved": "Бронювання прийнято",
  "restaurantPage.paragraph.tableReservedDescription": "Ваше бронювання на години прийнято.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "на",
  "restaurantPage.paragraph.tableWaitingForApproval": "Бронювання очікує на затвердження",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription": "Ваше замовлення на годину.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "на",
  "restaurantPreview.doReservation": "Замовити",
  "restaurantPromotionInfoModal.button.use": "Використання",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Деталі пропозиції",
  "room.firstRoomVisualization": "Спочатку створіть візуалізацію приміщення",
  "searchMapPage.button.list": "Ліста",
  "searchMapPage.button.map": "Карта",
  "searchMapPage.header": "Результати пошуку",
  "searchMapPage.map.button.refresh": "Оновити карту",
  "searchMapPage.metadata.description": "Опис результатів пошуку",
  "searchMapPage.metadata.keywords": "Результати пошуку за ключовими словами",
  "searchMapPage.metadata.title": "Результати пошуку",
  "searchPage.button.list": "Ліста",
  "searchPage.button.map": "Карта",
  "searchPage.header": "Результати пошуку",
  "searchPage.metadata.description": "Опис результатів пошуку",
  "searchPage.metadata.keywords": "Результати пошуку за ключовими словами",
  "searchPage.metadata.title": "Результати пошуку",
  "searchPage.paragraph.noData": "Немає результатів за даними критеріями.",
  "searchResultItem.component.button.book": "Замовити",
  "searchResultItem.component.button.cancel": "Оголосити",
  "searchResultItem.component.button.see": "Дивіться.",
  "searchResultItem.component.label.availableHours": "Доступні години",
  "shortcut.isClosed": "закрито",
  "shortcut.monday": "Пн.",
  "shortcut.tuesday": "Вт.",
  "shortcut.wednesday": "Ср.",
  "shortcut.thursday": "Чт.",
  "shortcut.friday": "Пт.",
  "shortcut.saturday": "Сб.",
  "shortcut.sunday": "Нд.",
  "sms.send.problem": "Проблема з надсиланням SMS, спробуйте через додаток",
  "social.appleloginBtn": " Увійдіть за допомогою Apple",
  "social.fbloginBtn": "Увійдіть в систему",
  "social.googleloginBtn": "Увійдіть в систему",
  "startPage.metadata.description": "Знайдіть найкращі ресторани у вашому місті.",
  "startPage.metadata.keywords": "бронювання столиків, пошукова система ресторанів, ресторани",
  "startPage.metadata.title": "MojStolik.pl",
  "status.Canceled": "Скасовано.",
  "status.Confirmed": "Підтверджено.",
  "status.Rejected": "Відхилено",
  "status.WaitingForConfirmation": "Очікуємо підтвердження",
  "token.expired": "Термін дії коду закінчився",
  "token.wrong": "Код не збігається або прострочений",
  "user.acceptAgreement": "Я приймаю умови надання послуг www.mojstolik.pl",
  "user.addNewAction": "Клієнт додав",
  "user.cityAddress": "Місто",
  "user.dateOfBirth": "Дата народження",
  "user.editAction": "Клієнт оновлений",
  "user.email": "Електронна пошта",
  "user.firstName": "Ім'я",
  "user.lastName": "Ім'я",
  "user.name": "Ім'я",
  "user.phone": "Номер телефону",
  "user.postalCode": "Поштовий індекс",
  "user.sessionExpire": "Ваш сеанс закінчився",
  "user.streetAddress": "Адреса",
  "user.successUpdate": "Оновлені персональні дані",
  "user.surname": "Ім'я",
  "user.updateQuestion": "Бажаєте оновити свої персональні дані?",
  "validation.fieldRequired": "Це поле обов'язкове для заповнення",
  "validation.passwordRequired": "Потрібен пароль",
  "validation.phoneRequired": "Необхідно вказати номер телефону"
};

export default backendTranslationsUK;
