import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import backendTranslationsEN from "./backendTranslationsEN.js";
import frontendTranslationsEN from "./frontendTranslationsEN.js";
import backendTranslationsPL from "./backendTranslationsPL.js";
import frontendTranslationsPL from "./frontendTranslationsPL.js";
import backendTranslationsDE from "./backendTranslationsDE.js";
import frontendTranslationsDE from "./frontendTranslationsDE.js";
import backendTranslationsES from "./backendTranslationsES.js";
import frontendTranslationsES from "./frontendTranslationsES.js";
import backendTranslationsUK from "./backendTranslationsUK.js";
import frontendTranslationsUK from "./frontendTranslationsUK.js";
import frontendTranslationsFR from "./frontendTranslationsFR.js";

i18n.use(initReactI18next).init({
  resources: {
    pl: {
      translation: Object.assign({}, backendTranslationsPL, frontendTranslationsPL)
    },

    en: {
      translation: Object.assign({}, backendTranslationsEN, frontendTranslationsEN)
    },

    de: {
      translation: Object.assign({}, backendTranslationsDE, frontendTranslationsDE)
    },

    es: {
      translation: Object.assign({}, backendTranslationsES, frontendTranslationsES)
    },

    uk: {
      translation: Object.assign({}, backendTranslationsUK, frontendTranslationsUK)
    },

    fr: {
      translation: Object.assign({}, frontendTranslationsFR)
    }
  },
  lng: "pl",
  fallbackLng: "pl",
  keySeparator: ">",
  nsSeparator: "|",
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
