import React from "react";
import { Translation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { routeService } from "services";
import {
  languageConstants,
  languageNameConstants,
} from "constants/languageConstants";

const WebLanguageMenu = (props) => {
  return (
    <>
      {languageConstants.map((lang) => {
        return (
          <div className="userMenu__item" key={`userMenu__${lang}`}>
            <Translation>
              {(t) => (
                <NavLink
                  to={routeService.getRoute("", lang === "pl" ? "" : lang)}
                  className="mobileMenu__itemAnchor"
                  activeClassName={
                    props.currentLanguage === lang ? "active" : "notactive"
                  }
                  onClick={() => {
                    props.changeLanguage(lang);
                    props.setShowingLanguageMenu(false);
                  }}
                >
                  <span className={`languageIcon languageIcon--${lang}`} />
                  <span className="language">
                    {languageNameConstants[lang]}
                  </span>
                </NavLink>
              )}
            </Translation>
          </div>
        );
      })}
    </>
  );
};

export default WebLanguageMenu;
