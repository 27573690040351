import Img from "react-image";
import { restaurantUtils } from "../../utils";
import React from "react";

export const Logo = props => {
    const { id, name, ext, city, className, type, imagePrefix,url } = props
    return (<Img
        src={url ? url : restaurantUtils.getImageSource(
            id.value,
            ext,
            type,
            imagePrefix
        )}
        loader={
            <div className="loaderMask">
                <span className="loaderMaskInside"></span>
            </div>
        }
        unloader={<div className="noImage"></div>}
        className={className}
        alt={`${name}, ${city} - logo`}
        title={`${name}, ${city}`}
    />)
}


export const CovidImage = () =>
    <div className="backgroundImage">
        <img src="/images/covid.jpg" style={{ visibility: "hidden", width: "100%", height: "100%" }}
            alt="MojStolik.pl - odpowiedzialna restauracja, odpowiedzialni goście, covid"
            title="MojStolik.pl - odpowiedzialna restauracja, odpowiedzialni goście"
        />
    </div>


export const ChristmasImage = () =>
    <div className="christmasBackgroundImage">
        <img src="/images/christmasBackground.jpg" style={{visibility: "hidden", width: "100%", height: "100%"}}
             alt="MojStolik.pl - oferta świąteczna naszych restauracji"
             title="MojStolik.pl - oferta świąteczna naszych restauracji"
        />
    </div>

export const EventImage = (response, notEventCloseAction) => {
    const link = response?.eventUrl?.includes("http") ? response.eventUrl : `http://${response.eventUrl}`
    return <div className="CoverCelebracje">
        <img src={response.bannerUrl} onClick={() => {
            if (response.eventUrl.length < 3) {
                notEventCloseAction()
            } else {
                window.location.href = link
            }
        }
        } style={{width: "100%", height: "100%"}}
             alt={`Degustacje & Celebracje - Wszyscy do stołu!`}
             title={`Degustacje & Celebracje - Wszyscy do stołu!`}
        />
    </div>
}
