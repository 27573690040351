const backendTranslationsPL = {
  "ByWindow": "Przy oknie",
    "Failed": "Nie powiodło się",
    "ForHowManyPeopleTable": "Liczba nakryć",
    "NewReservationCouldNotBeCreated": "Nie udało się utworzyć nowej rezerwacji",
    "NewReservationHasBeenCreated": "Stworzono nową rezerwację",
    "NonSmoking": "Dla niepalących",
    "Outdoor": "Na zewnątrz",
    "ReservationHasBeenUpdated": "Rezerwacja została zaktualizowana",
    "aboutPage.header": "O nas",
    "aboutPage.metadata.description": "o nas opis",
    "aboutPage.metadata.keywords": "o nas slowa kluczowe",
    "aboutPage.metadata.title": "O nas",
    "aboutPage.subheader": "Lorem ipsum dolor...",
    "acceptance.required": "Zaakceptuj wymagane zgody",
    "account.changePassword": "Zmień hasło",
    "account.confirmRemoveAccount": "Czy na pewno chcesz usunąć konto? Wszystkie niezrealizowane rezerwacje zostaną automatycznie odwołane.",
    "account.removeAccount": "Usuń konto",
    "account.removeRequest": "Aby usunąć konto wprowadź hasło",
    "accountPage.button.changePassword": "Zmień hasło",
    "accountPage.button.deleteAccount": "Usuń konto",
    "accountPage.button.save": "Zapisz",
    "accountPage.header": "Twoje konto",
    "accountPage.headerInColumn.yourPassword": "Twoje hasło",
    "accountPage.headerInColumn.yourPersonalData": "Twoje dane",
    "accountPage.input.label.birthDate": "Data urodzenia",
    "accountPage.input.label.city": "Miasto",
    "accountPage.input.label.email": "Adres e-mail",
    "accountPage.input.label.firstName": "Imię",
    "accountPage.input.label.lastName": "Nazwisko",
    "accountPage.input.label.newPassword": "Nowe hasło",
    "accountPage.input.label.newPasswordRepeat": "Nowe hasło (powtórz)",
    "accountPage.input.label.oldPassword": "Stare hasło",
    "accountPage.input.label.phoneNumber": "Numer telefonu",
    "accountPage.input.label.postalCode": "Kod pocztowy",
    "accountPage.input.label.streetAndLocalNumber": "Ulica i numer mieszkania",
    "accountPage.input.placeholder.birthDate": "Np. 1976-10-25",
    "accountPage.input.placeholder.city": "Np. Warszawa",
    "accountPage.input.placeholder.email": "Np. adres@email.pl",
    "accountPage.input.placeholder.firstName": "Np. Jan",
    "accountPage.input.placeholder.lastName": "Np. Kowalski",
    "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
    "accountPage.input.placeholder.postalCode": "Np. 03-123",
    "accountPage.input.placeholder.streetAndLocalNumber": "Np. Grójecka 130/105",
    "accountPage.label.changePassowordNotPossible": "Zmiana hasła jest niemożliwa, gdyż zalogowałeś się przez Facebook'a lub Google'a.",
    "accountPage.message.accountPermanentlyDeleted": "Twoje konto zostało permanentnie skasowane.",
    "accountPage.message.passwordChanged": "Hasło zostało zmienione.",
    "accountPage.message.personalDataUpdated": "Dane osobowe zostały zaktualizowane.",
    "accountPage.metadata.description": "Twoje konto opis",
    "accountPage.metadata.keywords": "Twoje konto słowa kluczowe",
    "accountPage.metadata.title": "Twoje konto",
    "accountPage.paragraph.optionalFields": "Pola opcjonalne",
    "accountPage.subheader": "Zmień swoje dane osobiste, aby proces Twojej realizacji przebiegał szybciej.",
    "accountPage.toast.error.disableBothChannels":
    "Co najmniej jedna z metod komunikacji (SMS, E-MAIL) musi pozostać aktywna!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Włączenie metody komunikacji wymaga potwierdzenia poprawności danych. Zapisz zmiany, aby dostać link weryfikacyjny na nowy adres email!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Włączenie metody komunikacji wymaga potwierdzenia poprawności danych. Zapisz zmiany, aby dostać link weryfikacyjny SMSem na Twój nowy numer telefonu!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "W celu włączenia nowej metody komunikacji, potwierdź swoje dane, wchodząc na link aktywacyjny, który został wysłany na Twój adres email!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "W celu włączenia nowej metody komunikacji, potwierdź swoje dane, wchodząc na link aktywacyjny, który został wysłany SMSem na podany numer telefonu!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "W celu weryfikacji Twoich danych, wejdź na link weryfikacyjny, który został wysłany na Twój adres email!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "W celu weryfikacji Twoich danych, wejdź na link weryfikacyjny, który został wysłany SMSem na Twój numer telefonu!",
    "agreement.canRevert": "mojstolik.pl informuje o możliwości wycofania zgody na przetwarzanie danych osobowych.",
    "agreement.commercialInformationContact": "Wyrażam zgodę na przesyłanie przez Table4Rest sp. z o.o. informacji handlowej za pomocą środków komunikacji elektronicznej, w szczególności na podany przeze mnie numer telefonu oraz adres poczty elektronicznej, w tym SMS i MMS.",
    "agreement.marketingContact": "Wyrażam zgodę na kontaktowanie się ze mną przez Table4Rest sp. z o. o.,  w tym SMS i MMS, jaki i kontakt z użyciem telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących dla celów marketingu bezpośredniego, w tym na podany przeze mnie numer telefonu oraz adres poczty elektronicznej.",
    "agreement.marketingDataUsage": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celach marketingowych usług i produktów własnych oraz innych podmiotów, które są oferowane za pośrednictwem aplikacji MójStolik.pl",
    "agreement.personalData": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o. o. w celu zawarcia umowy o świadczenie usług udostępniania i korzystania z aplikacji MójStolik.",
    "agreement.readWithdrawal": "Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
    "agreement.thirdPartDataUsage": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji.",
    "agreement.warningUncheck": "Odwołonie zgody uniemożliwia korzystanie z aplikacji. Aby ją odwołać, należy usunąć konto.",
    "api.connectionLost": "Połączenie z internetem zostało zerwane. Aplikacja zostanie przeładowana.",
    "app.readAgreement": "Regulamin usługi MojStolik.pl",
    "cancellationReservationModal.component.button.cancel": "Anuluj",
    "cancellationReservationModal.component.button.confirm": "Zatwierdź",
    "cancellationReservationModal.component.header": "Odwołanie rezerwacji",
    "cancellationReservationModal.component.paragraph.cancellationReservationDescription": "Czy na pewno chcesz odwołać rezerwację w restauracji {{restaurantName}} o godz. {{reservationHour}} w dniu {{reservationDate}}?",
    "changePass.lowerLetter": "- zawierać małe litery",
    "changePass.newPass": "Nowe hasło",
    "changePass.numeric": "- zawierać cyfry",
    "changePass.oldPass": "Stare hasło",
    "changePass.passHaveToBe": "Hasło musi:",
    "changePass.passLength": "- mieć długość co najmniej 6 znaków",
    "changePass.specialChar": "- zawierać znak specjalny np. '#@!'",
    "changePass.upperLetter": "- zawierać co najmniej 1 dużą literę",
    "common.accept": "Zatwierdź",
    "common.add": "Dodaj",
    "common.alertCloseWindow": "Zamkniesz okno wyboru",
    "common.back": "Cofnij",
    "common.bankAccountNumber": "Numer konta bankowego",
    "common.buildingNumber": "Numer budynku",
    "common.cancel": "Anuluj",
    "common.changeButton": "Zmień",
    "common.check": "Sprawdzam",
    "common.city": "Miasto",
    "common.clear": "Wyczyść",
    "common.close": "Zamknij",
    "common.confirm": "Potwierdź",
    "common.confirmDecline": "Potwierdź/odrzuć",
    "common.couldNotDownloadData": "Nie udało się pobrać danych. Odśwież aplikację.",
    "common.dayShort.friday": "pt",
    "common.dayShort.monday": "pn",
    "common.dayShort.saturday": "so",
    "common.dayShort.sunday": "nd",
    "common.dayShort.thursday": "cz",
    "common.dayShort.tuesday": "wt",
    "common.dayShort.wednesday": "śr",
    "common.delete": "Usuń",
    "common.detailsButton": "Szczegóły",
    "common.error": "Błąd",
    "common.gr": "gr",
    "common.help": "Pomoc",
    "common.hourShort": "godz.",
    "common.localNumber": "Numer lokalu",
    "common.name": "Nazwa",
    "common.new": "Nowy",
    "common.next": "Dalej",
    "common.nextButton": "Dalej",
    "common.noData": "Brak danych do wyświetlenia",
    "common.noValue": "Nie",
    "common.ok": "Ok",
    "common.password": "Hasło",
    "common.preferences": "Preferencje",
    "common.requiredInputError": "Pole jest wymagane",
    "common.save": "Zapisz",
    "common.search": "Szukaj",
    "common.send": "Wyślij",
    "common.shortPersons": "{{persons}} os.",
    "common.street": "Ulica",
    "common.successful": "Sukces",
    "common.undoButton": "Cofnij",
    "common.unit": "Jednostka",
    "common.vatId": "NIP",
    "common.yesValue": "Tak",
    "common.zipCode": "Kod pocztowy",
    "common.zl": "zł",
    "company.addNewAction": "Dodano nową firmę",
    "company.addNewCompany": "Dodać nową  firmę?",
    "company.addressData": "Dane adresowe",
    "company.buildingNumber": "Numer budynku",
    "company.city": "Miasto",
    "company.companyExistsError": "Firma już istnieje",
    "company.country": "Kraj",
    "company.localNumber": "Numer lokalu",
    "company.name": "Nazwa firmy",
    "company.nipNumber": "NIP",
    "company.notFound": "Firma nie została znaleziona w bazie CDEIG",
    "company.postalCode": "Kod pocztowy",
    "company.questionUpdateModal": "Zaktualizować firmę?",
    "company.street": "Ulica",
    "company.updateAction": "Firma została zaktualizowana",
    "company.updateCompany": "Zaktualizować istniejącą firmę?",
    "company.vatId": "NIP",
    "component.cookiesPolicyLine.privacyPolicyText": "Polityka prywatności",
    "component.cookiesPolicyLine.text": "Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze korzystanie ze strony oznacza, że zgadzsz się na ich użycie.",
    "component.footer.menu.about": "O nas",
    "component.footer.menu.contact": "Kontakt",
    "component.footer.menu.cookiesPolicy": "Polityka cookies",
    "component.footer.menu.documents": "Dokumenty",
    "component.footer.menu.forGuest": "Dla gościa",
    "component.footer.menu.forRestaurants": "Dla restauracji",
    "component.footer.menu.header.applicationDownload": "Pobierz aplikację",
    "component.footer.menu.header.forClients": "O MOJSTOLIK.pl",
    "component.footer.menu.header.informations": "Informacje",
    "component.footer.menu.header.socialMedia": "Social media",
    "component.footer.menu.header.yourAccount": "Twoje konto",
    "component.footer.menu.howItWorks": "Jak to działa?",
    "component.footer.menu.login": "Zaloguj się",
    "component.footer.menu.logout": "Wyloguj się",
    "component.footer.menu.priceList": "Cennik",
    "component.footer.menu.privacyPolicy": "Polityka prywatności",
    "component.footer.menu.registration": "Rejestracja",
    "component.footer.menu.reservationHistory": "Twoje rezerwacje",
    "component.footer.menu.yourAccount": "Twoje konto",
    "component.header.menu.about": "O nas",
    "component.header.menu.blog": "Nowości MOJSTOLIK.PL",
    "component.header.menu.contact": "Kontakt",
    "component.header.menu.documents": "Dokumenty",
    "component.header.menu.forGuest": "Dla gościa",
    "component.header.menu.forRestaurants": "Dla restauracji",
    "component.header.menu.forRestaurants.offer": "Nasza oferta",
    "component.header.menu.howItWorks": "Jak to działa?",
    "component.header.menu.login": "Zaloguj się",
    "component.header.menu.logout": "Wyloguj się",
    "component.header.menu.mainPage": "Rezerwuj",
    "component.header.menu.portal.restaurant": "Portal restauracji",
    "component.header.menu.priceList": "Cennik",
    "component.header.menu.reservationHistory": "Twoje rezerwacje",
    "component.header.menu.yourAccount": "Twoje konto",
    "config.communication.confirmationReservation": "Potwierdzenie rezerwacji",
    "config.communication.modificationReservation": "Zmiana rezerwacji",
    "config.communication.notifTitle": "Powiadomienia PUSH",
    "config.communication.rejectionReservation": "Odwołanie rezerwacji",
    "config.communication.remindNotification": "Przypomnienia o rezerwacji",
    "config.navbar.account": "Konto",
    "config.navbar.communication": "Komunikacja",
    "config.navbar.configuration": "Konfiguracja",
    "config.navbar.myaccount": "Konto",
    "config.navbar.mypreferences": "Preferencje",
    "configuration.changeLanguage": "Język",
    "configuration.customerProfiles": "Profile Klientów",
    "configuration.employeeGroups": "Grupy pracowników",
    "configuration.help": "Pomoc",
    "configuration.restaurant": "Restauracja",
    "contactPage.header": "Kontakt",
    "contactPage.metadata.description": "Kontakt opis",
    "contactPage.metadata.keywords": "Kontakt słowa kluczowe",
    "contactPage.metadata.title": "Kontakt",
    "contactPage.subheader": "Napisz do nas!",
    "cookiesPolicyPage.header": "Polityka Cookies",
    "cookiesPolicyPage.metadata.description": "",
    "cookiesPolicyPage.metadata.keywords": "",
    "cookiesPolicyPage.metadata.title": "Polityka Cookies",
    "cookiesPolicyPage.subheader": "Istotne informacje.",
    "cuisine.american": "Kuchnia amerykańska",
    "cuisine.arabic": "Kuchnia arabska",
    "cuisine.chinese": "Kuchnia chińska",
    "cuisine.polish": "Kuchnia polska",
    "cuisine.romania": "Kuchnia rumuńska",
    "cusine.chinese": "Kuchnia chińska",
    "cusine.polish": "Kuchnia polska",
    "customer.accountDetail": " konta",
    "customer.addEdit": "Dodaj / Edytuj",
    "customer.blocked": "Konto zostało zablokowane. Możliwość rezerwacji została zablokowana.",
    "customer.confirmText": "Rezerwuj",
    "customer.createInvoice": "Stwórz fakturę",
    "customer.createNewProfile": "Dodano nowy profil",
    "customer.createNewProfileModal": "Utworzyć nowy profil?",
    "customer.deleteProfile": "Usuń profil",
    "customer.description": "Opis",
    "customer.editCompany": "Edytuj firmę",
    "customer.editProfile": "Zaktualizowano profil",
    "customer.editReservingCustomerData": "Edytuj dane osoby rezerwującej",
    "customer.emailAddress": "E-mail",
    "customer.errorProfileAction": "Profil jest przypisany do klienta",
    "customer.existError": "Użytkownik już istnieje",
    "customer.firstCreateCompany": "*najpierw wybierz firmę",
    "customer.groupChooseLabel": "Wybierz",
    "customer.labels.blocked": "Zablokowany",
    "customer.labels.customerName": "Nazwa",
    "customer.labels.emailLabel": "Email",
    "customer.labels.firstNameLabel": "Imię",
    "customer.labels.groupLabel": "Grupa",
    "customer.labels.lastNameLabel": "Nazwisko",
    "customer.labels.phoneNumber": "Telefon",
    "customer.labels.statusLabel": "Status",
    "customer.listOfProfiles": "Lista profili",
    "customer.mail": "E-mail",
    "customer.name": "Imię",
    "customer.newPasswordWasSent": "Nowe hasło zostało wysłane w wiadomości SMS",
    "customer.noCustomer": "Brak użytkownika",
    "customer.noCustomers": "Brak klientów",
    "customer.noReservations": "Brak rezerwacji",
    "customer.onePersonFound": "Znaleziono 1 osobę",
    "customer.password": "Hasło",
    "customer.password.mismatch": "Stare hasło nie pasuje",
    "customer.passwordHasChange": "Hasło zostało zmienione",
    "customer.permissionForHoldingPersonalData": "Zgoda na przetwarzanie danych osobowych",
    "customer.profile": "Profil",
    "customer.profileDetails": "Szczegóły profilu",
    "customer.profileExistsError": "Profil już istnieje",
    "customer.registration.code": "Twój kod aktywacyjny to: _CODE_",
    "customer.registration.invalid": "Niepoprawny kod aktywacyjny",
    "customer.registration.sendByEmail": "Problem z wysłaniem SMS - kod został wysłany na podany email adres.",
    "customer.remarks": "Uwagi",
    "customer.remarksToClient": "Uwagi do klienta",
    "customer.remarksToReservation": "Uwagi do rezerwacji",
    "customer.removeProfileAction": "Usunięto profil",
    "customer.removeProfileAction.error": "Profil nie został usunięty",
    "customer.reservationHistory": "Historia rezerwacji",
    "customer.reservingCustomerData": "Dane osoby rezerwującej",
    "customer.saveChanges": "Zapisać zmiany?",
    "customer.surname": "Nazwisko",
    "customer.telephone": "Telefon",
    "customer.telephoneNumber": "Numer telefonu",
    "customer.updateClient": "Zaktualizować dane klienta?",
    "customer.updateProfileModal": "Zaktualizować profil?",
    "defaultValues.restaurant": "Restauracja",
    "deleteAccountModal.component.button.cancel": "Anuluj",
    "deleteAccountModal.component.button.confirm": "Zatwierdź",
    "deleteAccountModal.component.header": "Usuń konto",
    "deleteAccountModal.component.paragraph.deleteAccountDescription": "Czy na pewno chcesz skasować swoje konto? Historia Twoich rezerwacji również zostanie usunięta.",
    "documentsPage.header": "Dokumenty",
    "documentsPage.metadata.description": "Dokumenty opis",
    "documentsPage.metadata.keywords": "Dokumenty słowa kluczowe",
    "documentsPage.metadata.title": "Dokumenty",
    "documentsPage.subheader": "Istotne informacje",
    "editReservation.send": "Wyślij",
    "editReservation.sendMessage": "Wysłano prośbę o zmianę parametrów rezerwacji.",
    "email.already.exist": "Email już istnieje",
    "email.missing": "Email jest wymagany",
    "error": "Wystąpił błąd. Spróbuj ponownie później.",
    "error.LocationNotFound": "Lokalizacja nie znaleziona lub podano za mało znaków",
    "error.cannotCreateReservation": "Nie udało się wysłać prośby",
    "error.connectionWithServerLost": "Utracono połączenie z serwerem.",
    "error.connectionWithServerLostTryLater": "Utracono połączenie z serwerem. Spróbuj ponownie później",
    "error.email.already.exist": "Email już istnieje",
    "error.facebook.account.already.exist": "Na ten numer jest już założone konto w MojStolik przez Facebook. Użyj logowanie przez Facebook",
    "error.google.account.already.exist": "Na ten numer jest już założone konto w MojStolik przez Google. Użyj logowanie przez Google",
    "error.notFoundAddress": "Lokalizacja nie znaleziona lub podano za mało znaków",
    "error.phone.already.exist": "Na ten numer jest już założone konto w MojStolik. Zaloguj się poprzez numer telefonu, lub użyj opcji przypomnij hasło.",
    "filters.doFilter": "Filtruj",
    "filters.fromToday": "Od dzisiaj",
    "filters.toToday": "Do dzisiaj",
    "filters.today": "Dzisiaj",
    "footer.Next": "Następne",
    "footer.Previous": "Poprzednie",
    "footer.alertOnBack": "Porzucić zmiany?",
    "forGuestPage.header": "Dla gościa",
    "forGuestPage.metadata.description": "Dla gościa opis",
    "forGuestPage.metadata.keywords": "Dla gościa słowa kluczowe",
    "forGuestPage.metadata.title": "Dla gościa",
    "forGuestPage.subheader": "",
    "forRestaurantsPage.form": "Twoje zgłoszenie zostało wysłane. Wkrótce się odezwiemy.",
    "forRestaurantsPage.header": "Dla restauracji",
    "forRestaurantsPage.metadata.description": "Dla restauracji opis",
    "forRestaurantsPage.metadata.keywords": "Dla restauracji słowa kluczowe",
    "forRestaurantsPage.metadata.title": "Dla restauracji",
    "forRestaurantsPage.subheader": "Sprawdź jakie korzyści system MojStolik zapewni Twojej restauracji.",
    "global.form.message.atLeastNineCharacters": "Wymagane co najmniej dziewięć znaków",
    "global.form.message.invalidBirthDateFormat": "Nieprawidłowy format daty urodzenia",
    "global.form.message.invalidEmailFormat": "Nieprawidłowy format adresu e-mail",
    "global.form.message.invalidPhoneFormat": "Nieprawidłowy format telefonu",
    "global.form.message.invalidPostCodeFormat": "Nieprawidłowy format kodu pocztowego",
    "global.form.message.newPasswordsDoNotMatch": "Nowe hasła różnią się!",
    "global.form.message.requiredField": "Wymagane pole",
    "global.messages.sessionTimeout": "Sesja wygasła",
    "global.time.minutes": "minut",
    "group.removeErrorAction": "Grupa jest przypisana do użytkownika",
    "howItWorksPage.header": "Jak to działa?",
    "howItWorksPage.metadata.description": "Jak to działa opis",
    "howItWorksPage.metadata.keywords": "Jak to działa słowa kluczowe",
    "howItWorksPage.metadata.title": "Jak to działa?",
    "howItWorksPage.subheader": "Krok po kroku",
    "image.notFound": "Obrazka nie znaleziono",
    "indexPage.background.header": "Znajdź najlepsze restauracje w Twoim mieście,",
    "indexPage.background.headerExtend": "a następnie zarezerwuj stolik:",
    "indexPage.button.bookNow": "Zarezerwuj",
    "indexPage.button.hideAdditionalFilters": "Ukryj dodatkowe filtry",
    "indexPage.button.readMore": "Czytaj",
    "indexPage.button.search": "Szukaj",
    "indexPage.button.showAdditionalFilters": "Dodatkowe filtry",
    "indexPage.button.use": "Skorzystaj",
    "indexPage.header.blogs": "Wyróżnione artykuły",
    "indexPage.header.currentPromotions": "Oferty specjalne i wydarzenia",
    "indexPage.header.currentPromotionsExtend": "w Twojej lokalizacji",
    "indexPage.header.mostPopular": "Najbardziej popularne",
    "indexPage.header.mostPopularExtend": "w Twojej lokalizacji",
    "indexPage.input.label.date": "Data",
    "indexPage.input.label.hour": "Godzina",
    "indexPage.input.label.location": "Lokalizacja",
    "indexPage.input.label.persons": "Liczba osób",
    "indexPage.input.label.persons.optionExtend": "os.",
    "indexPage.input.label.priceLevel": "Poziom cenowy",
    "indexPage.input.label.rating": "Ocena",
    "indexPage.input.label.rating.optionExtend": "gwiazd.",
    "indexPage.input.label.restaurantType": "Rodzaj kuchni",
    "indexPage.input.label.searchByName": "Wyszukaj po nazwie",
    "indexPage.input.noData": "Brak wyników.",
    "indexPage.input.placeholder.select": "Wybierz",
    "indexPage.input.placeholder.type": "Wpisz",
    "indexPage.input.placeholer.typeInOrSelect": "Wpisz lub wybierz",
    "invitation": "Cześć, zapraszam Cię do restauracji {{restaurant}} w dniu {{date}} na godzinę {{time}}. Pobierz aplikację MojStolik.pl, aby dowiedzieć się więcej o lokalu: https://www.mojstolik.pl/pobierz/apka.html",
    "invoice.vatId": "NIP: {{vatId}}",
    "login": "Login",
    "login.accountDoesNotExist": "Konto nie istnieje",
    "login.accountName": "Nazwa użytkownika",
    "login.doLogin": "Zaloguj",
    "login.forgotPassword": "Nie pamiętasz hasła?",
    "login.forgottenPasswordLink": "Zapomniałeś hasło?",
    "login.generalTitle": "Logowanie",
    "login.insertLoginData": "Wprowadź dane logowania",
    "login.insertManagerPassword": "Wprawadź adres email menadżera",
    "login.loggingToSystem": "Logowanie do systemu",
    "login.loginOrPasswordWrong": "Login lub hasło nieprawidłowe",
    "login.loginText": "Zaloguj",
    "login.logout": "Wyloguj",
    "login.logout.message": "Zostałeś wylogowany",
    "login.newAccount": "Nowe konto",
    "login.newUpdateReady": "Jest dostępna nowa wersja aplikacji. Zaktualizuj.",
    "login.noAppleAccount": "Nie istnieje konto powiązane z tym kontem Apple. Spróbuj innej metody logowania lub zarejestruj nowe konto.",
    "login.noFacebookAccount": "Nie istnieje konto powiązane z tym kontem Facebook. Spróbuj innej metody logowania lub zarejestruj nowe konto.",
    "login.noGoogleAccount": "Nie istnieje konto powiązane z tym kontem Google. Spróbuj innej metody logowania lub zarejestruj nowe konto.",
    "login.passwordRecovery": "Odzyskiwanie hasła",
    "login.passwordSent.sms": "Twoje nowe hasło to: __PASSWORD__",
    "login.passwordSent.sms.too.often": "Zbyt częsta próba zmiana hasła, spróbu za chwilę",
    "login.passwordSentToEmail": "Nowe hasło zostało wysłane na podany adres e-mail",
    "login.phoneNumberOrMail": "Nr telefonu, lub adres e-mail",
    "login.recover": "Odzyskaj",
    "login.resetPasswordLabel": "Adres e-mail",
    "login.restaurantChanged": "Restauracja została zmieniona",
    "login.thisAccountAlreadyExist": "Użytkownik już istnieje",
    "login.updateRequired": "Musisz zaktualizować aplikację. Może nie działać poprawnie",
    "login.userUnauthorized": "Login lub hasło nieprawidłowe",
    "login.userUnauthorized.useApple": "Zły login lub hasło. Spróbuj zalogować się przez Apple lub zresetuj hasło.",
    "login.userUnauthorized.useFacebook": "Zły login lub hasło. Spróbuj zalogować się przez Facebook lub zresetuj hasło",
    "login.userUnauthorized.useGoogle": "Zły login lub hasło. Spróbuj zalogować się przez Google lub zresetuj hasło.",
    "login.yourPassword": "Twoje hasło",
    "loginPage.anchor.passwordReset": "Zresetuj hasło",
    "loginPage.button.logIn": "Zaloguj się",
    "loginPage.button.register": "Zarejestruj się",
    "loginPage.columns.header.socialLogin": "Zaloguj się za pomocą",
    "loginPage.columns.header.standardLogin": "Logowanie standardowe",
    "loginPage.header": "Logowanie",
    "loginPage.input.label.password": "Hasło",
    "loginPage.input.label.phoneOrEmail": "Numer telefonu",
    "loginPage.input.placeholder.password": "",
    "loginPage.input.placeholder.phoneOrEmail": "",
    "loginPage.message.logged": "Zalogowano.",
    "loginPage.message.loggedViaApple": "Zalogowano przez Apple'a.",
    "loginPage.message.loggedViaFacebook": "Zalogowano przez Facebook'a.",
    "loginPage.message.loggedViaGoogle": "Zalogowano przez Google'a.",
    "loginPage.metadata.description": "Logowanie opis",
    "loginPage.metadata.keywords": "Logowanie słowa kluczowe",
    "loginPage.metadata.title": "Logowanie",
    "loginPage.paragraph.alreadyLoggedIn": "Jesteś aktualnie zalogowany.",
    "loginPage.paragraph.dontHaveAccountYet": "Nie masz jeszcze konta?",
    "loginPage.paragraph.registerNow": "Zarejestruj się!",
    "loginPage.subheader": "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
    "logoutPage.metadata.description": "Wylogowanie opis",
    "logoutPage.metadata.keywords": "Wylogowanie słowa kluczowe",
    "logoutPage.metadata.title": "Wylogowanie",
    "mail.confirm.message": "Potwierdż adres email klikając na link",
    "mail.confirm.title": "Wiadomość od MojStolik.pl",
    "map.searchArea": "Przeszukaj obszar",
    "menu.areaControl": "Zarządzanie salami",
    "menu.config": "Konfiguracja",
    "menu.configPage": "Ustawienia",
    "menu.configuration": "Konfiguracja",
    "menu.currentReservations": "Rezerwacje bieżące",
    "menu.customers": "Klienci",
    "menu.homePage": "Główna",
    "menu.invoice": "Faktury",
    "menu.login": "Logowanie",
    "menu.logout": "Wyloguj",
    "menu.myReserv": "Rezerwacje",
    "menu.myReservations": "Moje rezerwacje",
    "menu.newAccount": "Nowe konto",
    "menu.passwordRecovery": "Odzyskanie hasła",
    "menu.reservationpage": "Nowa rezerwacja",
    "menu.reservations": "Wszystkie rezerwacje",
    "menu.resetPassword": "Przypomnienie hasła",
    "menu.restaurant": "Restauracja",
    "menu.sale": "Promocje",
    "menu.search": "Szukaj",
    "myReservation.doRate": "Oceń",
    "myReservation.doRateQuestion": "Czy chcesz ocenić tą rezerwację na {{rate}}/5?",
    "myReservation.doRateSuccess": "Ocena została wystawiona",
    "myReservation.doRateTitle": "Jak oceniasz swoją rezerwację?",
    "myReservation.doSuccessRemove": "Rezerwacja została usunięta z listy",
    "myReservation.yourRate": "Twoja ocena",
    "navbar.allReserv": "Wszystkie",
    "navbar.areaEdition": "Edycja sali",
    "navbar.cancelChangesReservation": "Anulować zmianę rezerwacji?",
    "navbar.cancelReservation": "Anulować rezerwację?",
    "navbar.changePass": "Zmiana hasła",
    "navbar.clientPreview": "Podgląd klienta",
    "navbar.companyEdition": "Edycja firmy",
    "navbar.configuration": "Konfiguracja",
    "navbar.currentReserv": "Bieżące",
    "navbar.currentReservations": "Rezerwacje bieżące",
    "navbar.customers": "Klienci",
    "navbar.dataCustomer": "Dane osoby rezerwującej",
    "navbar.defaultCancel": "Anulować?",
    "navbar.employee": "Pracownicy",
    "navbar.historyReserv": "Historyczne",
    "navbar.invoiceEdition": "Edycja faktury",
    "navbar.invoicePreview": "Podgląd faktur",
    "navbar.label.editReservation": "Zmiana rezerwacji",
    "navbar.label.myProfile": "Mój profil",
    "navbar.label.myReservation": "Moje rezerwacje",
    "navbar.label.reservation": "Rezerwacja",
    "navbar.label.reservationDetails": "Szczegóły rezerwacji",
    "navbar.label.sale": "Promocje",
    "navbar.newReservation": "Nowa rezerwacja",
    "navbar.reservationActualState": "Aktualny stan rezerwacji",
    "navbar.reservationEdit": "Edycja rezerwacji",
    "navbar.reservationPreview": "Podgląd rezerwacji",
    "navbar.reservations": "Rezerwacje",
    "notFoundPage.button.goToHomePage": "Przejdź do strony głównej",
    "notFoundPage.header": "Błąd 404",
    "notFoundPage.metadata.description": "404 - Strona nieznaleziona opis",
    "notFoundPage.metadata.title": "404 - Strona nieznaleziona!",
    "notFoundPage.subheader": "Żądany URL",
    "notFoundPage.subheaderExtend": "nie został znaleziony na serwerze.",
    "notFoundPage.subheaderExtend2": "Spróbuj sprawdzić, czy podany adres jest poprawny lub przejdź do",
    "notFoundPage.subheaderExtend3": "strony głównej",
    "notification.body.Canceled": "Twoja rezerwacja w  _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została odwołana.",
    "notification.body.Completed": "Dziękujemy za realizację rezerwacji w _RESTAURACJA_ w _DAY_ o godz: _TIME_.",
    "notification.body.Confirmed": "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została potwierdzona.",
    "notification.body.Rejected": "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została odwołana. _MESSAGE_",
    "notification.body.WaitingForApproval": "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ czeka na zatwierdzenie.",
    "notification.body.WaitingForConfirmation": "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ czeka na potwierdzenie.",
    "notification.invitation.accepted.body": "_NAME_ zaakceptował twoje zaproszenie w _RESTAURANT_ na _DAY_ o godz. _TIME_.",
    "notification.invitation.accepted.title": "Zaproszenie zaakceptowane.",
    "notification.invitation.rejected.body": "_NAME_ zrezygnował z twojego zaproszenia w _RESTAURANT_ na _DAY_ o godz. _TIME_.",
    "notification.invitation.rejected.title": "Rezygnacja z zaproszenia.",
    "notification.new.chat.body": "_MESSAGE_",
    "notification.new.chat.title": "Wiadomość od _RESTAURANT_",
    "notification.parameters.updated.body": "Twoja rezerwacja w _RESTAURANT_ w dniu _DAY_ o godz. _TIME_ została zaktualizowana.",
    "notification.parameters.updated.title": "Aktualizajca rezerwacji",
    "onboarding.promotion.MFR2023.Advanced.description": "W ramach promocji MFR2023 przewidziano bezpłatny okres testowy. W ciągu pierwszych trzech miesięcy od podpisania umowy nie będą pobierane opłaty abonamentowe wyszczególnione w opisie planów poniżej. Promocja jest przeznaczona dla nowych klientów i dotyczy wyłącznie planów LIGHT oraz ADVANCED i obowiązuje w terminie 25.04 - 20.05.2023.",
    "onboarding.promotion.MFR2023.AdvancedPro.description": "Promocja nie dotyczy planu ADVANCED PRO.",
    "onboarding.promotion.MFR2023.Light.description": "W ramach promocji MFR2023 przewidziano bezpłatny okres testowy. W ciągu pierwszych trzech miesięcy od podpisania umowy nie będą pobierane opłaty abonamentowe wyszczególnione w opisie planów poniżej. Promocja jest przeznaczona dla nowych klientów i dotyczy wyłącznie planów LIGHT oraz ADVANCED i obowiązuje w terminie 25.04 - 20.05.2023.",
    "onboarding.promotion.MFR2023.description": "W ramach promocji MFR2023 przewidziano bezpłatny okres testowy. W ciągu pierwszych trzech miesięcy od podpisania umowy nie będą pobierane opłaty abonamentowe wyszczególnione w opisie planów poniżej. Promocja jest przeznaczona dla nowych klientów i dotyczy wyłącznie planów LIGHT oraz ADVANCED i obowiązuje w terminie 25.04 - 20.05.2023.",
    "onboarding.promotion.WAKACJE2023.Advanced.description": "Promocja nie dotyczy planu ADVANCED.",
    "onboarding.promotion.WAKACJE2023.AdvancedPro.description": "Promocja nie dotyczy planu ADVANCED PRO.",
    "onboarding.promotion.WAKACJE2023.Light.description": "WAKACJE2023 to oferta LIGHT za 0 zł w miesiącach: czerwiec, lipiec i sierpień 2023 dla nowych Klientów. Po tych miesiącach zapraszamy do dalszej współpracy na standardowych warunkach.",
    "onboarding.promotion.WAKACJE2023.description": "WAKACJE2023 to oferta LIGHT za 0 zł w miesiącach: czerwiec, lipiec i sierpień 2023 dla nowych Klientów. Po tych miesiącach zapraszamy do dalszej współpracy na standardowych warunkach.",
    "onboarding.promotion.promo-test.description": "Opis promocji promo-test. Rgulamin: _LINK_",
    "onboarding.promotion.promo-test.terms.link": "https://tst-web.mojstolik.pl/pl",
    "password.errors.weak": "Hasło jest za proste",
    "passwordFormModal.component.button.cancel": "Anuluj",
    "passwordFormModal.component.button.confirm": "Zatwierdź",
    "passwordFormModal.component.header": "Wprowadź hasło do konta",
    "passwordFormModal.component.paragraph.deleteAccountDescription": "Wprowadź swoje hasło, aby potwierdzić wykonanie akcji.",
    "payment.card": "karta",
    "payment.cash": "gotówka",
    "payment.item.description.per.person": "_PRODUCT_NAME_",
    "payment.item.description.per.reservation": "_PRODUCT_NAME_",
    "payment.otherType": "inny",
    "payment.title.generic": "Rezerwacja wymaga płatności",
    "payment.title.with.event": "Opłata za wydarzenie: _EVENT_NAME_",
    "payment.transfer": "przelew",
    "payment.type": "Sposób płatności",
    "preference.category.cuisine": "Rodzaj Kuchni",
    "preference.category.cuisine.polish": "Kuchnie Polska",
    "preference.category.price": "Cena",
    "previewImageModal.component.anchor.close": "Zamknij",
    "previewRestaurantModal.component.anchor.close": "Zamknij",
    "priceListPage.header": "Cennik",
    "priceListPage.metadata.description": "Cennik opis",
    "priceListPage.metadata.keywords": "Cennik słowa kluczowe",
    "priceListPage.metadata.title": "Cennik",
    "privacyPolicyPage.header": "Polityka prywatności",
    "privacyPolicyPage.metadata.title": "Polityka prywatności",
    "privacyPolicyPage.subheader": "Istotne informacje.",
    "promotions.emptyList": "Brak promocji",
    "registration.activeCode": "Kod aktywacyjny",
    "registration.addressEmail": "Adres e-mail",
    "registration.codeInformation": "Na numer {{phone}} został przesłany kod aktywacyjny wprowadź go poniżej i zatwierdź przyciskiem",
    "registration.createAccount": "Utwórz konto",
    "registration.createSuccess": "Konto zostało utworzone",
    "registration.differentPasswords": "Hasła róźnią się",
    "registration.finishButton": "Zakończ rejestrację",
    "registration.formIsInvalid": "Dane nie zostały poprawnie wypełnione. Skorzystaj z pomocy na dole.",
    "registration.generalTitle": "Rejestracja",
    "registration.phoneNumber": "Numer telefonu",
    "registration.phoneToShort": "Numer jest za krótki",
    "registration.readAgreement": "Przeczytaj regulamin",
    "registration.repeatPassword": "Powtórz hasło",
    "registration.requiredFieldsFirstStep": "Numer telefonu, e-mail oraz hasło są obowiązkowe.",
    "registration.weakPassword": "Hasło jest za proste",
    "registrationPage.button.confirm": "Potwierdź",
    "registrationPage.button.register": "Zarejestruj się",
    "registrationPage.button.sendCodeAgain": "Wyślij kod ponownie",
    "registrationPage.columns.header.registerStandard": "Standardowa rejestracja",
    "registrationPage.columns.header.registerWith": "Zarejestruj się za pomocą",
    "registrationPage.header": "Rejestracja",
    "registrationPage.header.SMSConfirmation": "Potwierdzenie SMS",
    "registrationPage.header.statements": "Oświadczenia",
    "registrationPage.input.label.agreementEvent": " Zapoznałem się z regulaminem wydarzenia i promocji znajdujący się ",
    "registrationPage.input.label.agreementEvent.extension": "pod tym linkiem",
    "registrationPage.input.label.agreementReadWithdrawal": "Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
    "registrationPage.input.label.agreementReadWithdrawal.extension": "Regulamin mojstolik.pl",
    "registrationPage.input.label.birthDate": "Data urodzenia",
    "registrationPage.input.label.city": "Miasto",
    "registrationPage.input.label.email": "Adres e-mail",
    "registrationPage.input.label.firstName": "Imię",
    "registrationPage.input.label.lastName": "Nazwisko",
    "registrationPage.input.label.password": "Hasło",
    "registrationPage.input.label.passwordRepeat": "Powtórz hasło",
    "registrationPage.input.label.phoneNumber": "Numer telefonu",
    "registrationPage.input.label.postalCode": "Kod pocztowy",
    "registrationPage.input.label.streetAndLocalNumber": "Ulica i numer mieszkania",
    "registrationPage.input.placeholder.birthDate": "Np. 1976-10-25",
    "registrationPage.input.placeholder.city": "Np. Warszawa",
    "registrationPage.input.placeholder.code": "Wpisz kod",
    "registrationPage.input.placeholder.email": "Np. adres@email.pl",
    "registrationPage.input.placeholder.firstName": "Np. Jan",
    "registrationPage.input.placeholder.lastName": "Np. Kowalski",
    "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
    "registrationPage.input.placeholder.postalCode": "Np. 03-123",
    "registrationPage.input.placeholder.streetAndLocalNumber": "Np. Grójecka 130/105",
    "registrationPage.message.accountCreated": "Konto zostało utworzone.",
    "registrationPage.message.enterCodeFromSMS": "Przepisz kod z SMS-a.",
    "registrationPage.message.registrationTimeExpired": "Czas rejestracji upłynął",
    "registrationPage.message.sendCodeAgain": "Kod SMS został ponownie wysłany.",
    "registrationPage.metadata.description": "Rejestracja opis",
    "registrationPage.metadata.keywords": "Rejestracja słowa kluczowe",
    "registrationPage.metadata.title": "Rejestracja",
    "registrationPage.paragraph.SMSConfirmationDescription": "Wpisz kod SMS, który wysłaliśmy na Twój numer telefonu, aby dokończyć rejestrację.",
    "registrationPage.paragraph.enterSMSCodeFrom": "Wpisz kod SMS z dnia",
    "registrationPage.paragraph.optionalFields": "Pola opcjonalne",
    "registrationPage.paragraph.seconds": "sekund(-a)",
    "registrationPage.paragraph.sendCodeAgain": "Kod SMS został ponownie wysłany.",
    "registrationPage.paragraph.timeIsOver": "Czas się skończył.",
    "registrationPage.paragraph.timeLeft": "Pozostało",
    "registrationPage.subheader": "Będąc zalogowanym użytkownikiem możesz szybciej rezerwować stoliki oraz przeglądać historię swoich rezerwacji.",
    "request.blocked": "Zapytanie zostało zablokowane, spróbuj później",
    "reservation.addNewAction": "Stworzono nową rozerwację",
    "reservation.addNote": "Dodaj uwagę",
    "reservation.addRemarkQuestion": "Dodać uwagę do rezerwacji?",
    "reservation.alreadyRated": "Rezerwacja była już oceniona",
    "reservation.basicInformation": "Podstawowe informacje",
    "reservation.cancelReservation": "Odwołaj",
    "reservation.cancelReservationQuestion": "Czy na pewno chcesz odwołać rezerwację?",
    "reservation.changeReservation": "Zmień",
    "reservation.changeReservationParameters": "Zmień parametry rezerwacji",
    "reservation.changeStatusAction": "Zmieniono status na",
    "reservation.chooseStatus": "Wybierz status",
    "reservation.comunications": "Wiadomości",
    "reservation.createReservation": "Utworzyć rezerwację na",
    "reservation.defaultTime": "Preferowany czas rezerwacji {{reservationTime}}.",
    "reservation.doReservation": "Rezerwuj",
    "reservation.duration": "Czas trwania",
    "reservation.durationTime": "Czas trwania rezerwacji {{durationTime}}",
    "reservation.editData": "Edytuj dane",
    "reservation.editReservation": "Edytuj rezerwację",
    "reservation.errorNewAction": "Nie udało się utworzyć nowej rezerwacji",
    "reservation.errorNotAvailableYet": "Rezerwacja nie jest jeszcze dostępna.",
    "reservation.errorPast": "Nie można utworzyć rezerwacji w przeszłości",
    "reservation.errorToLate": "Rezerwacja nie jest już dostępna.",
    "reservation.filter.fromDate": "Od kiedy",
    "reservation.filter.lastName": "Nazwisko",
    "reservation.filter.toDate": "Do kiedy",
    "reservation.forHowLong": "Czas trwania",
    "reservation.generalTitle": "Rezerwacja",
    "reservation.group": "Grupowa",
    "reservation.individual": "Indywidualna",
    "reservation.informationOnReservation": "Informacje o rezerwacji",
    "reservation.invitation": "Zaproś",
    "reservation.labels.client": "Klient",
    "reservation.labels.date": "Termin",
    "reservation.labels.dateTo": "Do",
    "reservation.labels.firstName": "Imie",
    "reservation.labels.lastName": "Nazwisko",
    "reservation.labels.number": "Nr rez.",
    "reservation.labels.numberOfPeople": "Liczba gości",
    "reservation.labels.phone": "Telefon",
    "reservation.labels.profil": "Profil",
    "reservation.labels.remarks": "Uwagi",
    "reservation.labels.status": "Status",
    "reservation.labels.tableNumber": "Sala - stolik",
    "reservation.lessSpotThanPeople": "Wybrano mniejszą liczbę miejsc. Kontynuować?",
    "reservation.message": "Wiadomość od MojStolik.pl",
    "reservation.messages": "Napisz wiadomość",
    "reservation.morePeople": "Większa liczba osób:",
    "reservation.newPersonToReservation": "Dodać nową osobę do rezerwacji?",
    "reservation.noFreeTables": "Brak wolnych stolików",
    "reservation.notExist": "Rezerwacja nie istnieje",
    "reservation.numberOfGuests": "Liczba gości",
    "reservation.payment.pay": "Zapłać",
    "reservation.payment.status": "Status płatności",
    "reservation.payment.status.Cancelled": "Anulowana",
    "reservation.payment.status.Cancelled.text": "Płatność została anulowana",
    "reservation.payment.status.Completed": "Zrealizowana",
    "reservation.payment.status.Completed.text": "Zrealizowano płatność",
    "reservation.payment.status.Created": "Utworzona",
    "reservation.payment.status.Created.text": "Utworzono płatność",
    "reservation.payment.status.Expired": "Wygasła",
    "reservation.payment.status.Expired.text": "Płatność wygasła",
    "reservation.payment.status.ProcessingPayment": "Procesowana",
    "reservation.payment.status.ProcessingPayment.text": "Płatność w trakcie procesowania",
    "reservation.payment.status.Unknown": "Nieznany status",
    "reservation.payment.status.Unknown.text": "Nieznany status płatności",
    "reservation.payment.status.WaitingForPayment": "Oczekująca",
    "reservation.payment.status.WaitingForPayment.text": "Płatność oczekuje na zapłatę",
    "reservation.paymentDetails": "Szczegóły płatności",
    "reservation.paymentForReservation": "Płatność za rezerwację",
    "reservation.paymentRequired": "Rezerwacja wymaga opłaty",
    "reservation.payments": "Płatnosci",
    "reservation.personsError": "Nie można wysłać prośby o rezerwację dla 0 osób",
    "reservation.pickDurationTime": "Wybierz czas trwania",
    "reservation.preferences": "Preferencje",
    "reservation.prepayment": "Przedpłata",
    "reservation.remarks": "Uwagi",
    "reservation.remarksFromRestaurant": "Komentarz restauracji",
    "reservation.remind.message": "Serwis MojStolik.pl przypomina o rezerwacji w restauracji _RESTAURANT_ w terminie _DATE_ dla _PERSONS_. W celu odwołania rezerwacji kliknij w link: _LINK_ Usługa rezerwacji realizowana jest przez serwis MojStolik.pl",
    "reservation.request.not.yet.available": "Rezerwacje jeszcze nie dostępna.",
    "reservation.request.too.often": "Prośba właśnie wysłana",
    "reservation.reservationDate": "Dzień",
    "reservation.spotsChosen": "Wybrane miejsca",
    "reservation.status": "Status",
    "reservation.status.Canceled": "Odwołana",
    "reservation.status.Completed": "Rezerwacja zrealizowana",
    "reservation.status.Confirmed": "Rezerwacja została potwierdzona",
    "reservation.status.Delayed": "Opóźniona",
    "reservation.status.NotCompleted": "Rezerwacja niezrealizowana",
    "reservation.status.Rejected": "Rezerwacja została anulowana",
    "reservation.status.WaitingForApproval": "Rezerwacja oczekuje na zatwierdzenie",
    "reservation.status.WaitingForConfirmation": "Oczekująca",
    "reservation.successRequest": "Prośba o rezerwację została wysłana",
    "reservation.summaryCost": "Kwota całkowita",
    "reservation.updateAction": "Rezerwacja została zaktualizowana",
    "reservation.updateReservation": "Zaktualizować rezerwację?",
    "reservation.updateReservationButton": "Aktualizuj rezerwację",
    "reservation.whichHour": "Godzina",
    "reservation.wholeRoom": "Cała sala",
    "reservationHistoryPage.button.revoke": "Odwołaj",
    "reservationHistoryPage.header": "Twoje rezerwacje",
    "reservationHistoryPage.metadata.description": "Twoje rezerwacje opis",
    "reservationHistoryPage.metadata.keywords": "Twoje rezerwacje słowa kluczowe",
    "reservationHistoryPage.metadata.title": "Twoje rezerwacje",
    "reservationHistoryPage.paragraph.additionalDescription": "Komunikacja z restauracją",
    "reservationHistoryPage.paragraph.date": "Data",
    "reservationHistoryPage.paragraph.duration": "Czas trwania",
    "reservationHistoryPage.paragraph.hour": "Godzina",
    "reservationHistoryPage.paragraph.persons": "Ilość osób",
    "reservationHistoryPage.paragraph.personsShortcut": "os.",
    "reservationHistoryPage.paragraph.reservationIsEmpty": "Rezerwacja nie istnieje",
    "reservationHistoryPage.paragraph.reservationListIsEmpty": "Lista rezerwacji jest pusta.",
    "reservationHistoryPage.subheader": "W tym miejscu możesz przejrzeć swoje historyczne rezerwacje oraz zarządzać przyszłymi rezerwacjami.",
    "reservationMenu.allActive": "Wszystkie aktywne",
    "reservationMenu.fromApp": "Zapytania z aplikacji",
    "reservationMenu.toConfirmation": "Do potwierdzenia",
    "reservationMenu.today": "Na dzisiaj",
    "reservationParams.restaurantName": "Nazwa lokalu",
    "reservationParams.yourLocalization": "Twoja lokalizacja",
    "reservations.noReservations": "Brak rezerwacji",
    "resetPassoword.generalTitle": "Przypomnienie hasła",
    "resetPassoword.info": "Podaj numer telefonu, na który zostało zarejestrowane konto. Hasło zostanie wysłane SMS-em na ten numer",
    "resetPassoword.sendNewPassword": "Wyślij hasło",
    "resetPasswordPage.button.confirm": "Potwierdź",
    "resetPasswordPage.header": "Zresetuj hasło",
    "resetPasswordPage.input.label.phoneOrEmail": "Numer telefonu",
    "resetPasswordPage.metadata.description": "Zresetuj hasło opis",
    "resetPasswordPage.metadata.keywords": "Zresetuj hasło słowa kluczowe",
    "resetPasswordPage.metadata.title": "Zresetuj hasło",
    "resetPasswordPage.subheader": "Wypełnij poniższy formularz, a my wyślemy Ci SMS-em nowe hasło do konta",
    "resetPasswordPage.subheader2": "Wyślemy ci nowe hasło na Twój numer telefonu",
    "resetPasswordPage.toast.error.passwordChanged": "Podany nr telefonu jest błędny.",
    "resetPasswordPage.toast.passwordChanged": "Nowe hasło zostało wysłane SMSem na podany podczas rejestracji nr telefonu.",
    "restaurant.aboutUs": "O nas",
    "restaurant.bankAccountNumber": "Numer konta bankowego",
    "restaurant.buildingNumber": "Numer budynku",
    "restaurant.city": "Miasto",
    "restaurant.closeHour": "Godzina zamknięcia",
    "restaurant.companyName": "Nazwa firmy",
    "restaurant.dayFormat": "dd - dzień miesiąca",
    "restaurant.defaultReservationLength": "Domyślna długość rezerwacji (h)",
    "restaurant.emailAddress": "E-mail",
    "restaurant.emptyList": "Nie ma restauracji spełniających podane kryteria wyszukiwania.",
    "restaurant.greaterThenZeroError": "Musi być większe od 0",
    "restaurant.insertRestaurant": "Wprowadź restauracje",
    "restaurant.legend": "Legenda:",
    "restaurant.localNumber": "Numer lokalu",
    "restaurant.logo": "Logo",
    "restaurant.maximalReservationLength": "Maksymalna długość rezerwacji (h)",
    "restaurant.menu.price": "{{price}} zł",
    "restaurant.minimalPeopleForGroup": "Minimalna liczba osób na rezerwację grupową",
    "restaurant.monthFormat": "MM - miesiąc",
    "restaurant.navbar.aboutRestaurant": "Restauracja",
    "restaurant.navbar.menu": "Menu",
    "restaurant.navbar.promotion": "Promocje",
    "restaurant.navigate": "Nawiguj do restauracji",
    "restaurant.navigationApp": "Aplikacja do nawigacji",
    "restaurant.nipNumber": "NIP",
    "restaurant.openHour": "Godzina Otwarcia",
    "restaurant.pickDay": "Wybierz dzień",
    "restaurant.pickHour": "Wybierz godzinę",
    "restaurant.pickPersons": "Wybierz liczbę osób",
    "restaurant.postalCode": "Kod pocztowy",
    "restaurant.price.expensive": "Bardzo drogo",
    "restaurant.price.extralow": "Bardzo tanio",
    "restaurant.price.low": "Tanio",
    "restaurant.price.medium": "Nie tak drogo",
    "restaurant.productsServices": "Produkty / usługi",
    "restaurant.promotion.label": "Promocje",
    "restaurant.questionUpdateRestaurant": "Zaktualizować dane o restauracji?",
    "restaurant.rate": "Ocena",
    "restaurant.restaurant": "Restauracja",
    "restaurant.saveNavigationPick": "Zapamiętaj wybór",
    "restaurant.seriesNumberTemplate": "Szablon numeracji faktur",
    "restaurant.serverAddress": "Serwer adres",
    "restaurant.street": "Ulica",
    "restaurant.web.confirm": "Czy chcesz otworzyć stronę www?",
    "restaurant.writeCity": "Miasto",
    "restaurant.yearEndFormat": "uu - rok końcówka np. 18",
    "restaurant.yearFullFormat": "uuuu - pełen rok np. 2018",
    "restaurantPage.anchor.edit": "Edytuj",
    "restaurantPage.anchor.login": "Zaloguj",
    "restaurantPage.anchor.register": "zarejestruj się",
    "restaurantPage.anchor.sendCodeAgain": "Wyślij kod ponownie",
    "restaurantPage.button.cancel": "Anuluj",
    "restaurantPage.button.confirm": "Potwierdź",
    "restaurantPage.button.goToHomePage": "Powrót do strony głównej",
    "restaurantPage.button.pageWWW": "Strona WWW",
    "restaurantPage.button.seeMenu": "Zobacz menu",
    "restaurantPage.button.showLess": "Pokaż mniej",
    "restaurantPage.button.showMore": "Pokaż więcej",
    "restaurantPage.header.gallery": "Galeria zdjęć",
    "restaurantPage.header.promotions": "Oferty specjalne i wydarzenia",
    "restaurantPage.header.reservationConfirmation": "Potwierdzenie rezerwacji",
    "restaurantPage.header.restaurantDescription": "Opis restauracji",
    "restaurantPage.header.statements": "Oświadczenia",
    "restaurantPage.header.use": "Skorzystaj",
    "restaurantPage.header.yourPersonalData": "Twoje dane",
    "restaurantPage.header.yourReservation": "Twoja rezerwacja",
    "restaurantPage.input.label.agreementEvent": " Zapoznałem się z regulaminem wydarzenia i promocji znajdujący się ",
    "restaurantPage.input.label.agreementEvent.extension": "pod tym linkiem",
    "restaurantPage.input.label.agreementMarketing": "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach MojStolik dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
    "restaurantPage.input.label.agreementMarketingThirdPart": " Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach Restauracji i Podmiotów Współpracujących wskazanych na stronie mojstolik.pl, dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
    "restaurantPage.input.label.code": "Wpisz kod SMS z dnia",
    "restaurantPage.input.label.date": "Data",
    "restaurantPage.input.label.description": "Uwagi dot. rezerwacji",
    "restaurantPage.input.label.email": "Adres e-mail",
    "restaurantPage.input.label.firstName": "Imię",
    "restaurantPage.input.label.footer": "MojStolik korzysta z różnych kanałów komunikacji elektronicznej oraz prowadzi działalności z wykorzystaniem poczty elektronicznej, telefonów, SMS-ów i MMS-ów. Administratorem Twoich danych osobowych jest Table4Rest spółka z ograniczoną odpowiedzialnością z siedzibą w Warszawie, 02-656 Warszawa ulica Ksawerów 21, KRS 0000752110, NIP 5213842139. Twoje dane osobowe będą przetwarzane w celu kierowania do Ciebie treści marketingowych w kanałach komunikacji elektronicznej. Więcej informacji na temat przetwarzania danych osobowych, w tym przysługujących Ci praw, znajduje się w Polityce Prywatności.",
    "restaurantPage.input.label.hour": "Godzina",
    "restaurantPage.input.label.lastName": "Nazwisko",
    "restaurantPage.input.label.marketingAgreement3thPart": "Chcę otrzymywać od MojStolik promocje, oferty specjalne, oferty konkursów lub inne treści marketingowe, w tym dopasowane do mnie informacje o usługach i produktach Restauracji i Podmiotów Współpracujących wskazanych na stronie mojstolik.pl, dostępnych w Aplikacji dla Klienta, za pośrednictwem komunikacji elektronicznej. Możesz wycofać tę zgodę w każdej chwili.",
    "restaurantPage.input.label.personalData": "Oświadczam, że znam i akceptuję postanowienia Regulaminu świadczenia usług przez MojStolik za pośrednictwem Aplikacji dla Klienta. ",
    "restaurantPage.input.label.personalData.extension": "Regulamin MojStolik",
    "restaurantPage.input.label.persons": "Liczba osób",
    "restaurantPage.input.label.persons.optionExtend": "os.",
    "restaurantPage.input.label.phoneNumber": "Numer telefonu",
    "restaurantPage.input.label.readWithdrawal": "Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
    "restaurantPage.input.label.readWithdrawal.extension": "Regulamin mojstolik.pl",
    "restaurantPage.input.placeholder.email": "Np. adres@email.pl",
    "restaurantPage.input.placeholder.firstName": "Np. Jan",
    "restaurantPage.input.placeholder.lastName": "Np. Kowalski",
    "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
    "restaurantPage.input.placeholder.typeIn": "Wpisz",
    "restaurantPage.messages.codeResent": "Kod SMS został ponownie wysłany.",
    "restaurantPage.paragraph.dataIsIncorrect": "Dane są niepoprawne?",
    "restaurantPage.paragraph.doYouHaveAnAccount": "Masz już konto lub chciałbyś się zarejestrować?",
    "restaurantPage.paragraph.dontWontUsePromotion": "Nie chcę korzystać z promocji",
    "restaurantPage.paragraph.noAvailableHours": "Brak dostępnych godzin w tych kryteriach.",
    "restaurantPage.paragraph.noAvailableHoursExtension": "Wybierz inną datę lub liczbę osób.",
    "restaurantPage.paragraph.or": " lub ",
    "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Wpisz kod z wiadomości SMS, którą wysłaliśmy na Twój numer telefonu, aby dokończyć proces rezerwacji stolika",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Wpisz kod z wiadomości, którą wysłaliśmy na Twój adres e-mail, aby dokończyć proces rezerwacji stolika",
    "restaurantPage.paragraph.sendCodeAgainText":
    "Kod został ponownie wysłany SMS-em. Nie dostałeś kodu?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Zautoryzuj się poprzez e-mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Wyślij kod na podany adres e-mail.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "Kod został wysłany na adres e-mail:",
    "restaurantPage.paragraph.tableReserved": "Przyjęto rezerwację",
    "restaurantPage.paragraph.tableReservedDescription": "Została przyjęta twoja rezerwacja na godz.",
    "restaurantPage.paragraph.tableReservedDescriptionInDay": "w dniu",
    "restaurantPage.paragraph.tableWaitingForApproval": "Rezerwacja oczekuje na zatwierdzenie",
    "restaurantPage.paragraph.tableWaitingForApprovalDescription": "Twoja rezerwacja na godz. ",
    "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "w dniu",
    "restaurantPreview.doReservation": "Zarezerwuj",
    "restaurantPromotionInfoModal.button.use": "Skorzystaj",
    "restaurantPromotionInfoModal.paragraph.promotionDetails": "Szczegóły oferty",
    "room.firstRoomVisualization": "Najpierw stwórz wizualizację sali",
    "searchMapPage.button.list": "Lista",
    "searchMapPage.button.map": "Mapa",
    "searchMapPage.header": "Wyniki wyszukiwania",
    "searchMapPage.map.button.refresh": "Odśwież mapę",
    "searchMapPage.metadata.description": "Wyniki wyszukiwania opis",
    "searchMapPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
    "searchMapPage.metadata.title": "Wyniki wyszukiwania",
    "searchPage.button.list": "Lista",
    "searchPage.button.map": "Mapa",
    "searchPage.header": "Wyniki wyszukiwania",
    "searchPage.metadata.description": "Wyniki wyszukiwania opis",
    "searchPage.metadata.keywords": "Wyniki wyszukiwania słowa kluczowe",
    "searchPage.metadata.title": "Wyniki wyszukiwania",
    "searchPage.paragraph.noData": "Brak wyników dla podanych kryteriów.",
    "searchResultItem.component.button.book": "Zarezerwuj",
    "searchResultItem.component.button.cancel": "Anuluj",
    "searchResultItem.component.button.see": "Zobacz",
    "searchResultItem.component.label.availableHours": "Dostępne godziny",
    "shortcut.friday": "Pt.",
    "shortcut.isClosed": "nieczynne",
    "shortcut.monday": "Pon.",
    "shortcut.saturday": "Sob.",
    "shortcut.sunday": "Niedz.",
    "shortcut.thursday": "Czw.",
    "shortcut.tuesday": "Wt.",
    "shortcut.wednesday": "Śr.",
    "sms.send.problem": "Problem z wysłaniem wiadomości SMS, spróbuj proszę przez aplikację",
    "social.appleloginBtn": " Zaloguj się z Apple",
    "social.fbloginBtn": "Zaloguj się",
    "social.googleloginBtn": "Zaloguj się",
    "startPage.metadata.description": "Znajdź najlepsze restauracje w Twoim mieście.",
    "startPage.metadata.keywords": "rezerwacja stolika, wyszukiwarka restauracji, restauracje",
    "startPage.metadata.title": "MojStolik.pl",
    "status.Canceled": "Odwołana",
    "status.Confirmed": "Potwierdzona",
    "status.Rejected": "Odrzucona",
    "status.WaitingForConfirmation": "Oczekuje na potwierdzenie",
    "token.expired": "Kod wygasł",
    "token.wrong": "Kod nie zgadza się lub wygasł",
    "user.acceptAgreement": "Akceptuję regulamin usługi www.mojstolik.pl",
    "user.addNewAction": "Dodano klienta",
    "user.cityAddress": "Miejscowość",
    "user.dateOfBirth": "Data urodzenia",
    "user.editAction": "Zaktualizowano klienta",
    "user.email": "E-mail",
    "user.firstName": "Imię",
    "user.lastName": "Nazwisko",
    "user.name": "Imię",
    "user.phone": "Telefon",
    "user.postalCode": "Kod pocztowy",
    "user.sessionExpire": "Twoja sesja wygasła",
    "user.streetAddress": "Adres",
    "user.successUpdate": "Dane osobowe zostały zaktualizowane",
    "user.surname": "Nazwisko",
    "user.updateQuestion": "Czy chcesz zaktualizować dane osobowe?",
    "validation.fieldRequired": "To pole jest wymagane",
    "validation.passwordRequired": "Hasło jest wymagane",
    "validation.phoneRequired": "Numer telefonu jest wymagany"
};

export default backendTranslationsPL;
