import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  restaurantInfo: {
    name: '',
    address_street: '',
    address_buildingNumber: '',
    address_localNumber: '',
    address_postalCode: '',
    address_city: '',
    email: '',
    phoneNumber: '',
    webUrl: '',
    menuUrl: '',
    contactPerson_firstName: '',
    contactPerson_lastName: '',
    contactPerson_email: '',
    contactPerson_phoneNumber: '',
    contactPerson_role: 'Owner',
  },
  companyInfo: {
    name: '',
    address_street: '',
    address_buildingNumber: '',
    address_localNumber: '',
    address_postalCode: '',
    address_city: '',
    vatId: '',
    krs: '',
    regon: '',
    contactEmail: '',
    phoneNumber: '',
    invoiceEmail: '',
    webUrl: '',
    contactPerson_firstName: '',
    contactPerson_lastName: '',
    contactPerson_email: '',
    contactPerson_phoneNumber: '',
    contactPerson_role: 'Owner',
  },
  subscriptionPlan: '',
};

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    updateFormOne(state, action) {
      state.restaurantInfo = {
        name: action.payload.name,
        address_street: action.payload.address_street,
        address_buildingNumber: action.payload.address_buildingNumber,
        address_localNumber: action.payload.address_localNumber,
        address_postalCode: action.payload.address_postalCode,
        address_city: action.payload.address_city,
        email: action.payload.email,
        phoneNumber: action.payload.phoneNumber,
        webUrl: action.payload.webUrl,
        menuUrl: action.payload.menuUrl,
        contactPerson_firstName: action.payload.contactPerson_firstName,
        contactPerson_lastName: action.payload.contactPerson_lastName,
        contactPerson_email: action.payload.contactPerson_email,
        contactPerson_phoneNumber: action.payload.contactPerson_phoneNumber,
        contactPerson_role: action.payload.contactPerson_role,
      };
    },
    updateFormTwo(state, action) {
      state.companyInfo = {
        name: action.payload.name,
        address_street: action.payload.address_street,
        address_buildingNumber: action.payload.address_buildingNumber,
        address_localNumber: action.payload.address_localNumber,
        address_postalCode: action.payload.address_postalCode,
        address_city: action.payload.address_city,
        vatId: action.payload.vatId,
        krs: action.payload.krs,
        regon: action.payload.regon,
        contactEmail: action.payload.contactEmail,
        phoneNumber: action.payload.phoneNumber,
        invoiceEmail: action.payload.invoiceEmail,
        webUrl: action.payload.webUrl,
        contactPerson_firstName: action.payload.contactPerson_firstName,
        contactPerson_lastName: action.payload.contactPerson_lastName,
        contactPerson_email: action.payload.contactPerson_email,
        contactPerson_phoneNumber: action.payload.contactPerson_phoneNumber,
        contactPerson_role: action.payload.contactPerson_role,
      };
    },
    updateChosenOffer(state, action) {
      state.subscriptionPlan = action.payload;
    },
    resetFormData(state) {
      state.restaurantInfo = initialState.restaurantInfo;
      state.companyInfo = initialState.companyInfo;
      state.subscriptionPlan = initialState.subscriptionPlan;
    }
  },
});

export const formActions = formSlice.actions;
export default formSlice.reducer;
