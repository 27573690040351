import React from "react";
import { BrowserRouter } from "react-router-dom";
import Layout from "./layouts/Layout";
import "./styles/index.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { baseConstants } from "../src/constants";

function App() {
  return (
    <GoogleOAuthProvider clientId={baseConstants["GOOGLE_ID"]}>
      <BrowserRouter>
        <Layout />
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;
