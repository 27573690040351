const regexpValidatorConstants = {
  PASSWORD:
    "(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z$@$!%*?&].{7,}", // eslint-disable-line
  EMAIL: "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+[a-zA-Z]{2,4}$", // eslint-disable-line
  PHONE: "^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-s./0-9]*$", // eslint-disable-line
  POST_CODE: "^([0-9]{2})(-[0-9]{3})$", // eslint-disable-line
  BIRTH_DATE: "^[0-9]{4}-(0?[1-9]|1[012])-(0?[1-9]|[12][0-9]|3[01])$", // eslint-disable-line
};

export default regexpValidatorConstants;
