import React, { useEffect } from "react";
import { compose, withProps, withStateHandlers } from "recompose";
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import { InfoBox } from "react-google-maps/lib/components/addons/InfoBox";
import { baseConstants } from "../../constants";
import SearchMapResultItem from "../../components/searchMapResultItem/SearchMapResultItem";
import { withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./Map.scss";

const defaultStyles = [
  // {
  //   featureType: "administrative",
  //   stylers: [
  //     {
  //       lightness: 15
  //     }
  //   ]
  // },
  // {
  //   featureType: "landscape.man_made",
  //   stylers: [
  //     {
  //       color: "#f8f8f8"
  //     }
  //   ]
  // },
  // {
  //   featureType: "landscape.natural",
  //   stylers: [
  //     {
  //       saturation: -35
  //     },
  //     {
  //       lightness: 25
  //     }
  //   ]
  // },
  {
    featureType: "poi",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "road.highway",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    featureType: "transit",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  // {
  //   featureType: "water",
  //   stylers: [
  //     {
  //       saturation: 15
  //     },
  //     {
  //       lightness: -5
  //     }
  //   ]
  // },
  // {
  //   featureType: "water",
  //   elementType: "geometry",
  //   stylers: [
  //     {
  //       saturation: -55
  //     },
  //     {
  //       lightness: 30
  //     }
  //   ]
  // },
  // {
  //   featureType: "water",
  //   elementType: "labels",
  //   stylers: [
  //     {
  //       saturation: -15
  //     },
  //     {
  //       lightness: 25
  //     }
  //   ]
  // }
];

const MapContainer = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?key=${baseConstants["GOOGLE_MAP_KEY"]}&v=3.exp&libraries=geometry,drawing,places`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div className={'googleMapContainer'} />,
    mapElement: <div style={{ height: `100%` }} />,
    zoom: 12,
    center: { lat: 52.2216345, lng: 21.0087794 }
  }),
  withStateHandlers(() => ({
    isOpenMarker: false,
  }), {
    onToggleOpen: (state, google) => {
      return (markerItem, mapReference) => {
        if (markerItem) {
          //mapReference.setOptions({draggable: true});
        }
        return {
          isOpenMarker: markerItem ? markerItem.originalData.restaurant.info.alias.value : false,
        }
      };
    }
    
    
  }),
  withScriptjs,
  withGoogleMap
)((props) => {

  const { t } = useTranslation();
  let mapReference;

  useEffect(() => {
    if (!(props.markers && props.markers.length > 0)) {
      return;
    }

    let mapBounds = new window.google.maps.LatLngBounds();
    const markersLength = props.markers.length;
    for (let i = 0; i < markersLength; i++) {
      mapBounds.extend({
        lat: props.markers[i].restaurant.info.location.latitude,
        lng: props.markers[i].restaurant.info.location.longitude
      });
    }

    mapReference.fitBounds(mapBounds);
  }, [props.markers, mapReference]);

  const getMarkers = () => {
    if (!(props.markers && props.markers.length > 0)) {
      return [];
    }
    
    return props.markers.map(restaurantItem => {
      let newRestaurantItem = {
        originalData: restaurantItem,
        position: {
          lat: restaurantItem.restaurant.info.location && restaurantItem.restaurant.info.location.latitude,
          lng: restaurantItem.restaurant.info.location && restaurantItem.restaurant.info.location.longitude
        }
      }

      return newRestaurantItem;
    });
  };

  const RefreshButton = (props) => (
    <span className={`refreshMapButton${props.props.isOpenMarker ? ' refreshMapButton--hidden': ''}`} onClick={() => {
      if (typeof props.props.onRefresh === 'function') {
        props.props.onRefresh();
      }
    }}>{t('searchMapPage.map.button.refresh')}</span>
  );

  return (
    <>
      <GoogleMap
        ref={(ref) => { mapReference = ref; }}
        defaultZoom={props.zoom}
        defaultCenter={props.center}
        //defaultOptions={{}}
        onDragStart={() => {
          props.onToggleOpen(false, mapReference);
        }}
        {...props.mapOptions}
        options={{
          styles: defaultStyles,
          streetViewControl: false,
          overviewMapControl: false,
          rotateControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          minZoom: 6,
          maxZoom: 15,
          draggable: !props.isOpenMarker,
          disableDoubleClickZoom: props.isOpenMarker,
          zoomControl: !props.isOpenMarker
        }}
      >
        <RefreshButton mapRef={mapReference} props={props} />

        {getMarkers().map((markerItem) => (
          <Marker
            key={`marker--${markerItem.originalData.restaurant.info.alias.value}`}
            position={markerItem.position}
            icon={{
              url: Array.isArray(markerItem.originalData.availableTimes) && markerItem.originalData.availableTimes.length > 0 ? '/images/active-pointer-map.svg' : '/images/inactive-pointer-map.svg',
              scaledSize: props.isOpenMarker && props.isOpenMarker === markerItem.originalData.restaurant.info.alias.value ? new window.google.maps.Size(16, 16) : new window.google.maps.Size(16, 16),
            }}
            zIndex={props.isOpenMarker && props.isOpenMarker === markerItem.originalData.restaurant.info.alias.value ? 2 : 1}
            onClick={() => {
              props.onToggleOpen(markerItem, mapReference)
            }}
            clickable={props.disabled ? false : true}
          >
            {props.isOpenMarker === markerItem.originalData.restaurant.info.alias.value && <InfoBox
              onCloseClick={() => {
                props.onToggleOpen(false, mapReference)
              }}
              options={{ closeBoxURL: `/images/icon-close-modal.svg`, enableEventPropagation: true, zIndex: 999, disableAutoPan: false, pixelOffset: new window.google.maps.Size(-108,0) }}
            >
                <SearchMapResultItem
                  size={'small'}
                  key={
                    markerItem.originalData.restaurant.info.alias.value
                  }
                  singleRestaurant={markerItem.originalData}
                  currentLanguage={props.currentLanguage}
                  queryStringParams={props.queryStringParameters}
                  configuration={props.configuration}
                  history={props.history || null}
                />
            </InfoBox>}
          </Marker>
        ))}
      </GoogleMap>
    </>
    
  )
});
 
export default withRouter(MapContainer);
