import React, { useState, useRef } from "react";
import classnames from "classnames";
import Slider from "react-slick";
import useAsyncEffect from "use-async-effect";
import "./HorizontalScrollMenuAlternative.scss";
import { useTranslation } from "react-i18next";
import { FiChevronLeft } from "react-icons/fi";
import { FiChevronRight } from "react-icons/fi";

const HorizontalScrollMenuAlternative = (props) => {
  const { data, selected, onSelect } = props;
  const uniqueKey = props.specificKey || "horizontalScrollMenuAlternative";
  let sliderRef = useRef();
  const { t } = useTranslation();

  let dataEnabled = data;

  if (data.length > 3) {
    dataEnabled = [];

    if (data[0].disabled) {
      if (data[1].disabled) {
        dataEnabled.push({
          label: "-",
          value: "-",
          disabled: true,
          isPaid: false,
        });
      } else {
        dataEnabled.push(data[0]);
      }
    }

    const enabledIndexFirst = data.findIndex((d) => !d.disabled);

    if (enabledIndexFirst > -1) {
      dataEnabled.push(data[enabledIndexFirst]);
    }

    let enabledIndex = -1;

    if (enabledIndexFirst < data.length) {
      for (let i = enabledIndexFirst + 1; i < data.length; i++) {
        if (!data[i].disabled) {
          dataEnabled.push(data[i]);
        } else {
          if (i < enabledIndex) {
            continue;
          }

          enabledIndex = data.findIndex((d, index) => !d.disabled && index > i);

          if (enabledIndex !== -1) {
            if (i + 2 < enabledIndex && i + 1 < enabledIndex) {
              dataEnabled.push({
                label: "-",
                value: "-",
                disabled: true,
                isPaid: false,
              });
              dataEnabled.push({
                label: "-",
                value: "-",
                disabled: true,
                isPaid: false,
              });
            } else if (i + 1 < enabledIndex) {
              dataEnabled.push(data[i]);
              dataEnabled.push(data[i + 1]);
            } else {
              dataEnabled.push(data[i]);
            }
          } else {
            if (i + 1 < data.length) {
              dataEnabled.push({
                label: "-",
                value: "-",
                disabled: true,
                isPaid: false,
              });
            } else {
              dataEnabled.push(data[i]);
            }
            break;
          }
        }
      }
    }

    if (dataEnabled.findIndex((d) => !d.disabled) === -1) {
      dataEnabled = [];
    }
  }

  const getItemValue = (selected) => {
    const availableItems = dataEnabled.filter((dataItem) => !dataItem.disabled);
    let foundElement = availableItems.find(
      (dataItem) => dataItem.value === selected
    );
    if (!foundElement) {
      return availableItems.length > 0 ? availableItems[0].value : null;
    }
    return foundElement.value;
  };

  const [selectedItem, setSelectedItem] = useState(getItemValue(selected));

  const getItemIndex = (selected) => {
    let foundIndex = dataEnabled.findIndex((dataItem) => {
      return dataItem.value === selected && !dataItem.disabled;
    });

    if (foundIndex === -1) {
      foundIndex = dataEnabled.findIndex((dataItem) => !dataItem.disabled);
    }

    return foundIndex;
  };

  useAsyncEffect(() => {
    const indexData = getItemIndex(selected);
    const itemValue = getItemValue(selected);

    setTimeout(() => {
      if (
        sliderRef &&
        sliderRef.current &&
        typeof sliderRef.current.slickGoTo === "function"
      ) {
        sliderRef.current.slickGoTo(indexData > 2 ? indexData : indexData - 1);
      }
    }, 500);
    setSelectedItem(itemValue);
    if (typeof onSelect === "function") {
      onSelect({ value: itemValue }, uniqueKey);
    }
  }, [
    selected,
    dataEnabled.findIndex((d) => d.value === selected && !d.disabled),
    //, sliderRef
  ]);

  const sliderSettings = {
    dots: false,
    infinite: false,
    speed: 200,
    // slidesToShow: 4,
    slidesToScroll: 8,
    variableWidth: true,
    cssEase: "ease",
    adaptiveHeight: true,
    centerMode: false,
    focusOnSelect: false,
    swipeToSlide: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          //   slidesToShow: 4,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 480,
        settings: {
          //   slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 380,
        settings: {
          slidesToScroll: 3,
        },
      },
    ],
    // afterChange: () => {
    //   setSelectedItem
    //   //this.setState(state => ({ updateCount: state.updateCount + 1 }))
    // },
    // beforeChange: (current, next) => this.setState({ slideIndex: next })
  };

  const onItemClickHandler = (itemData, indexData) => {
    if (
      sliderRef &&
      sliderRef.current &&
      typeof sliderRef.current.slickGoTo === "function"
    ) {
      sliderRef.current.slickGoTo(indexData > 0 ? indexData - 1 : indexData);
    }
    if (typeof onSelect === "function") {
      onSelect(itemData, uniqueKey);
    }
  };

  const onPreviousClickHandler = () => {
    if (
      sliderRef &&
      sliderRef.current &&
      typeof sliderRef.current.slickPrev === "function"
    ) {
      sliderRef.current.slickPrev();
    }
  };

  const onNextClickHandler = () => {
    if (
      sliderRef &&
      sliderRef.current &&
      typeof sliderRef.current.slickNext === "function"
    ) {
      sliderRef.current.slickNext();
    }
  };

  return dataEnabled?.length === 0 ? (
    <p className="noAvailableHours">
      <span className="redColor">
        {t("restaurantPage.paragraph.noAvailableHoursChooseDifferent")}
      </span>
    </p>
  ) : (
    <div className="horizontalScrollMenuAlternativeContainer">
      <span
        className={classnames({
          horizontalScrollbar__previous: true,
        })}
        onClick={() => onPreviousClickHandler()}
      ><FiChevronLeft /></span>
      <div className="horizontalScrollbar__content">
        <Slider
          ref={(slider) => (sliderRef.current = slider)}
          {...sliderSettings}
          className={classnames({
            hourSlider: true,
          })}
        >
          {dataEnabled.map((singleElement, singleElementIterator) => {
            return (
              <div
                className={classnames({
                  "horizontalScrollbar__content-item": true,
                  "horizontalScrollbar__content-item--active":
                    singleElement.value ===
                    selectedItem /*&& !singleElement.disabled*/,
                  "horizontalScrollbar__content-item--disabled":
                    singleElement.disabled,
                })}
                key={`${uniqueKey}${singleElement.value}`}
                onClick={() =>
                  onItemClickHandler(singleElement, singleElementIterator)
                }
              >
                {singleElement.label}
                {/* {singleElement.isPaid && "$"} */}
              </div>
            );
          })}
        </Slider>
      </div>
      <span
        className="horizontalScrollbar__next"
        onClick={() => onNextClickHandler()}
      ><FiChevronRight /></span>
    </div>
  );
};

export default HorizontalScrollMenuAlternative;
