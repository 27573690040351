import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import "./DropDownListSuggest.scss";
import Select from "react-select";

const DropDownListSuggest = (props) => {
  const { t } = useTranslation();
  const { required } = props;
  const type = props.type ? ` dropDownListSuggest--${props.type}` : "";
  const label = props.label ? `${props.label}` : null;
  const hasIcon = props.icon;
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const containerRef = useRef(null);
  const customStyles = {
    control: (base, state) => ({
      ...base,
    }),
    menu: (base) => ({
      ...base,
    }),
    menuList: (base) => ({}),
  };

  return (
    <div className="dropDownListSuggestContainer">
      <label ref={containerRef}>
        {hasIcon && (
          <span
            className="buttonIcon"
            onClick={props.icon.onClick || null}
            onKeyUp={props.icon.onKeyUp || null}
            onKeyDown={props.icon.onKeyDown || null}
            data-tip={props.icon.tooltip || ""}
          >
            {props.icon.icon}
          </span>
        )}
        {props.children}
        {label && (
          <p className="dropDownListSuggestLabel">
            {label}
            {required ? <span className="required-sign">*</span> : ""}
          </p>
        )}
        <Select
          cacheOptions
          defaultOptions
          loadOptions={props.loadOptionsHandler || null}
          value={props.value}
          required={required}
          onChange={props.onChange || null}
          {...props}
          className={`dropDownListSuggest${type} ${props.className}`}
          autoFocus={props.autoFocus}
          styles={customStyles}
          placeholder={
            props.placeholder || t("indexPage.input.placeholer.typeInOrSelect")
          }
          noOptionsMessage={() => t("indexPage.input.noData")}
          isClearable={props.isClearable || false}
          classNamePrefix="react-select"
          readOnly={props.readonly || false}
          disabled={props.disabled || false}
          onKeyDown={props.onKeyDown || null}
          isSearchable={props.isSearchable || false}
          menuIsOpen={menuIsOpen}
          // maxMenuHeight={120}
          onMenuOpen={(event) => {
            setMenuIsOpen(true);
            setTimeout(() => {
              const selectMenuElement = containerRef.current.querySelector(
                ".react-select__menu .react-select__menu-list"
              );
              const selectedElement = selectMenuElement
                ? selectMenuElement.querySelector(
                    ".react-select__option.react-select__option--is-selected"
                  )
                : null;
              const selectedElementOffset = selectedElement
                ? selectedElement.offsetTop
                : 0;
              if (selectMenuElement) {
                selectMenuElement.scrollTop = selectedElementOffset;
              }
            }, 10);
          }}
          onMenuClose={() => {
            setMenuIsOpen(false);
          }}
          // menuIsOped={true}
        />
      </label>
    </div>
  );
};

export default DropDownListSuggest;
