import classnames from "classnames";
import i18n from "i18next";
import React, { useState } from "react";
import { Translation } from "react-i18next";
import Ink from "react-ink";
import { NavLink, withRouter, useLocation } from "react-router-dom";
import Logo from "../../images/logo.png";
import { connect } from "react-redux";
import { configFetched } from "../../actions";
import "./Header.scss";
import useAsyncEffect from "use-async-effect";
import { requestService, routeService } from "./../../services";
import queryString from "query-string";
import WebLanguageMenu from "./WebLanguageMenu";
import MobileLanguageMenu from "./MobileLanguageMenu";

const userMenuData = [
  {
    routeName: "RESERVATION_HISTORY",
    translationKey: "component.header.menu.reservationHistory"
  },
  {
    routeName: "YOUR_ACCOUNT",
    translationKey: "component.header.menu.yourAccount"
  },
  {
    routeName: "LOGOUT",
    translationKey: "component.header.menu.logout"
  }
];

const ForRestaurantPages = [
  // {
  //   routeName: "COVID",
  //   translationKey: "component.header.menu.covid.restaurant",
  // },
  // {
  //   routeName: "COVID_CLIENT",
  //   translationKey: "component.header.menu.covid.client",
  // },
];

const forRestaurantsNews = [
  {
    routeName: "BLOG",
    translationKey: "component.header.menu.blog"
  },
  {
    routeName: "FOR_RESTAURANTS",
    translationKey: "component.header.menu.forRestaurants.offer"
  }
];

const mainMenuData = [
  {
    routeName: "START",
    translationKey: "component.header.menu.mainPage"
  },
  {
    routeName: "ABOUT",
    translationKey: "component.header.menu.about"
  }
];

const mobileMenuData = [
  {
    routeName: "START",
    translationKey: "component.header.menu.mainPage"
  },
  {
    routeName: "FOR_RESTAURANTS",
    translationKey: "component.header.menu.forRestaurants.offer"
  },
  {
    routeName: "BLOG",
    translationKey: "component.header.menu.blog"
  },
  {
    routeName: "ABOUT",
    translationKey: "component.header.menu.about"
  }
];

const Header = (props) => {
  const loggedUserDataLS = JSON.parse(localStorage.getItem("loggedUserData") || "{}");

  // const reservUserLogged = useSelector((state) => state.reservLogin.isLogin);

  const configurationData = props.config;
  const [showingMobileMenu, setShowingMobileMenu] = useState(false);
  const [showingLanguageMenu, setShowingLanguageMenu] = useState(false);
  const [showingMobileLanguageMenu, setShowingMobileLanguageMenu] = useState(false);
  const [showingMobileUserMenu, setShowingMobileUserMenu] = useState(false);
  const [showingUserMenu, setShowingUserMenu] = useState(false);
  const [showingCovidMenu, setShowingCovidMenu] = useState(false);
  const [showingNewsMenu, setShowingNewsMenu] = useState(false);
  const [showingEventMenu, setShowingEventMenu] = useState(false);
  const [activeEvents, setActiveEvents] = useState([]);
  const { search } = useLocation();
  const values = queryString.parse(search);

  const currentLanguage = i18n.language;
  const isIndexPage =
    !props.location.pathname ||
    props.location.pathname === "/" ||
    props.location.pathname === `/${currentLanguage}`;

  const changeLanguage = (language) => {
    const url =
      currentLanguage === "pl"
        ? "/"
        : `/${currentLanguage}`; /* routeService.getRouteAfterLangChange(
      props.location.pathname,
      currentLanguage,
      language
    ); */
    i18n.changeLanguage(language);

    localStorage.setItem("MS_WEB_RESTAURANT_CLIENT_PORTAL_LANG", language);
    props.history.push(url);
  };

  const hideMenu = () => {
    setShowingUserMenu(false);
    setShowingCovidMenu(false);
    setShowingEventMenu(false);
    setShowingNewsMenu(false);
    setShowingLanguageMenu(false);
  };

  const userIsLogged = loggedUserDataLS && loggedUserDataLS.mainPhone;

  const hamburgerMenuOnClick = () => {
    const newValue = !showingMobileMenu;
    setShowingMobileMenu(newValue);
    setShowingMobileUserMenu(false);
    setShowingLanguageMenu(false);
    setShowingMobileLanguageMenu(false);
  };
  const languageMobileMenuOnClick = () => {
    setShowingMobileMenu(false);
    setShowingMobileLanguageMenu(!showingMobileLanguageMenu);
  };
  const userMenuOnClick = () => {
    setShowingMobileMenu(false);
    setShowingLanguageMenu(false);
    setShowingMobileLanguageMenu(false);
    if (userIsLogged) {
      const newValue = !showingMobileUserMenu;
      setShowingMobileUserMenu(newValue);
    } else {
      props.history.push(routeService.getRoute("LOGIN", currentLanguage));
    }
  };
  const hideAllMobileMenus = () => {
    setShowingMobileUserMenu(false);
    setShowingMobileMenu(false);
    setShowingLanguageMenu(false);
    setShowingMobileLanguageMenu(false);
  };
  const goToLogin = () => {
    if (!window.location.pathname.includes("logowanie")) {
      const location = { ...window.location };
      localStorage.setItem("URLAfterLogin", `${location.pathname}${location.search}`);
    }
    hideAllMobileMenus();
  };

  const googlePlayURL = configurationData && configurationData["googlePlayUrl"];
  const appStoreURL = configurationData && configurationData["appStoreUrl"];

  useAsyncEffect(async () => {
    const resp = await requestService.getActiveEvent();
    setActiveEvents(resp);
  }, []);

  if (values.plugin) {
    return null;
  }

  const hideEventsMenu = activeEvents?.every((event) => !event?.menuName) || true;

  return (
    <React.Fragment>
      {/* Mobile */}
      <div
        className={classnames({
          mobileUserMenu: true,
          "mobileUserMenu--show": showingMobileUserMenu
        })}
        onClick={hideAllMobileMenus}
      >
        {userIsLogged && (
          <React.Fragment>
            {userMenuData.map((userMenuIem) => (
              <div
                className="mobileUserMenu__item"
                key={`mobileUserMenu__${userMenuIem.routeName}`}
              >
                <Translation>
                  {(t) => (
                    <NavLink
                      to={routeService.getRoute(userMenuIem.routeName, currentLanguage)}
                      className="mobileUserMenu__itemAnchor"
                      activeClassName="active"
                    >
                      {t(userMenuIem.translationKey)}
                      <Ink />
                    </NavLink>
                  )}
                </Translation>
              </div>
            ))}
          </React.Fragment>
        )}
      </div>
      {/* Mobile */}
      <div
        className={classnames({
          mobileMenu: true,
          "mobileMenu--show": showingMobileMenu
        })}
        onClick={hideAllMobileMenus}
      >
        {[...mobileMenuData].map((menuItem, i) => (
          <div className="mobileMenu__item" key={`mobileMenu__${menuItem.routeName}-${i}`}>
            {renderTranslation(menuItem, currentLanguage, "mobileMenu__itemAnchor")}
          </div>
        ))}
        <Translation>
          {(t) => (
            <div className="mobileMenu__item">
              <a
                className="mobileMenu__itemAnchor"
                href={process.env.REACT_APP__PORTAL_MOJ_STOLIK}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("component.header.menu.portal.restaurant")}
              </a>
            </div>
          )}
        </Translation>
        {activeEvents.map((menuItem, i) => {
          if (menuItem.menuName) {
            return (
              <div className="mobileMenu__item" key={`mobileMenu__${menuItem.landingPageUrl}-${i}`}>
                {renderMobileEvent(menuItem, "mobileMenu__itemAnchor")}
              </div>
            );
          }
          return null;
        })}
        <div className="mobileMenu__item--downloadApplication">
          <a href={googlePlayURL} target="_blank" rel="noopener noreferrer" className="googlePlay">
            &nbsp;
          </a>
          <a href={appStoreURL} target="_blank" rel="noopener noreferrer" className="appStore">
            &nbsp;
          </a>
        </div>
      </div>
      {/* Mobiile */}
      <div
        className={classnames({
          mobileMenu: true,
          "mobileMenu--show": showingMobileLanguageMenu
        })}
        onClick={hideAllMobileMenus}
      >
        <div className="mobileMenu__item--languages">
          <MobileLanguageMenu
            hideAllMobileMenus={hideAllMobileMenus}
            changeLanguage={changeLanguage}
            currentLanguage={currentLanguage}
          />
        </div>
      </div>

      <div className="header">
        <div
          className={classnames({
            userMenuBackground: true,
            "userMenuBackground--opened":
              showingUserMenu ||
              showingCovidMenu ||
              showingNewsMenu ||
              showingEventMenu ||
              showingLanguageMenu
          })}
          onClick={() => hideMenu()}
        ></div>
        <div className="header__container">
          <div className="header-content">
            {/* Logo */}
            <div className="headerItem " style={{ marginRight: "16px" }}>
              {isIndexPage && (
                <img
                  src={Logo}
                  className="logo"
                  alt="MojStolik.pl"
                  onClick={() => {
                    hideAllMobileMenus();
                    if (typeof window === "undefined") {
                      return;
                    }
                    window.scrollTo(0, 0);
                  }}
                />
              )}
              {!isIndexPage && (
                <NavLink
                  to={routeService.getRoute("", currentLanguage)}
                  activeClassName="active"
                  title="Strona główna"
                  onClick={hideAllMobileMenus}
                >
                  <img src={Logo} alt="MojStolik.pl" className="logo" />
                </NavLink>
              )}
            </div>

            <div style={{ display: "flex" }}>
              <div className="headerItem headerItem--mainMenu">
                <ul className="mainMenu">
                  <li className="mainMenu__item" key={`desktopMenu__${mainMenuData[0].routeName}`}>
                    {renderTranslation(mainMenuData[0], currentLanguage)}
                  </li>
                  <div className="headerItem headerItem--button">
                    <Translation>
                      {(t) => (
                        <React.Fragment>
                          <div className="loggedUserMenuWrapper">
                            <span
                              className={classnames(
                                {
                                  loggedUserAnchor: true,
                                  "loggedUserAnchor--opened": showingNewsMenu
                                },
                                "covidButton"
                              )}
                              onClick={() => setShowingNewsMenu(!showingNewsMenu)}
                            >
                              {t("component.header.menu.forRestaurants")}
                            </span>
                            <div
                              className={classnames({
                                userMenu: true,
                                "userMenu--opened": showingNewsMenu
                              })}
                            >
                              {forRestaurantsNews.map((userMenuIem) => (
                                <div
                                  className="userMenu__item"
                                  key={`forRestaurantsNews__${userMenuIem.routeName}`}
                                >
                                  <Translation>
                                    {(t) => (
                                      <NavLink
                                        to={routeService.getRoute(
                                          userMenuIem.routeName,
                                          currentLanguage
                                        )}
                                        className="mobileMenu__itemAnchor"
                                        activeClassName="active"
                                        onClick={() => setShowingNewsMenu(false)}
                                      >
                                        {t(userMenuIem.translationKey)}
                                      </NavLink>
                                    )}
                                  </Translation>
                                </div>
                              ))}
                              <div className="userMenu__item">
                                <a
                                  className="mobileMenu__itemAnchor"
                                  href={process.env.REACT_APP__PORTAL_MOJ_STOLIK}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {t("component.header.menu.portal.restaurant")}
                                </a>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      )}
                    </Translation>
                  </div>

                  {ForRestaurantPages.length > 0 && (
                    <div className="headerItem headerItem--button">
                      <Translation>
                        {(t) => (
                          <React.Fragment>
                            <div className="loggedUserMenuWrapper">
                              <span
                                className={classnames(
                                  {
                                    loggedUserAnchor: true,
                                    "loggedUserAnchor--opened": showingCovidMenu
                                  },
                                  "covidButton"
                                )}
                                onClick={() => setShowingCovidMenu(!showingCovidMenu)}
                              >
                                {t("component.header.menu.covid")}
                              </span>
                              <div
                                className={classnames({
                                  userMenu: true,
                                  "userMenu--opened": showingCovidMenu
                                })}
                              >
                                {ForRestaurantPages.map((userMenuIem) => (
                                  <div
                                    className="userMenu__item"
                                    key={`userMenu__${userMenuIem.routeName}`}
                                  >
                                    <Translation>
                                      {(t) => (
                                        <NavLink
                                          to={routeService.getRoute(
                                            userMenuIem.routeName,
                                            currentLanguage
                                          )}
                                          className="mobileMenu__itemAnchor"
                                          activeClassName="active"
                                          onClick={() => setShowingCovidMenu(false)}
                                        >
                                          {t(userMenuIem.translationKey)}
                                        </NavLink>
                                      )}
                                    </Translation>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </Translation>
                    </div>
                  )}
                  {!hideEventsMenu && (
                    <div className="headerItem headerItem--button">
                      <Translation>
                        {(t) => (
                          <React.Fragment>
                            <div className="loggedUserMenuWrapper">
                              <span
                                className={classnames(
                                  {
                                    loggedUserAnchor: true,
                                    "loggedUserAnchor--opened": showingEventMenu
                                  },
                                  "covidButton"
                                )}
                                onClick={() => setShowingEventMenu(!showingEventMenu)}
                              >
                                {t("component.header.menu.events")}
                              </span>
                              <div
                                className={classnames({
                                  userMenu: true,
                                  "userMenu--opened": showingEventMenu
                                })}
                              >
                                {activeEvents.map((position) => {
                                  if (position.landingPageUrl.length > 3)
                                    return (
                                      <div
                                        className="userMenu__item"
                                        key={`userMenu__${position.id}`}
                                      >
                                        <a
                                          href={checkHttp(position.landingPageUrl)}
                                          rel="noopener noreferrer"
                                          target="_blank"
                                        >
                                          {position.menuName}
                                        </a>
                                      </div>
                                    );
                                  return <div key={`userMenu__${position.id}`} />;
                                })}
                              </div>
                            </div>
                          </React.Fragment>
                        )}
                      </Translation>
                    </div>
                  )}

                  <li className="mainMenu__item" key={`desktopMenu__${mainMenuData[1].routeName}`}>
                    {renderTranslation(mainMenuData[1], currentLanguage)}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style={{ display: "flex" }}>
            <div className="headerItem headerItem--button">
              <Translation>
                {(t) => (
                  <React.Fragment>
                    {userIsLogged && (
                      <div className="loggedUserMenuWrapper">
                        <span
                          className={classnames({
                            loggedUserAnchor: true,
                            "loggedUserAnchor--opened": showingUserMenu
                          })}
                          onClick={() => setShowingUserMenu(!showingUserMenu)}
                        >
                          {loggedUserDataLS.firstName} {loggedUserDataLS.lastName}
                        </span>
                        <div
                          className={classnames({
                            userMenu: true,
                            "userMenu--opened": showingUserMenu
                          })}
                        >
                          {userMenuData.map((userMenuItem) => (
                            <div
                              className="userMenu__item"
                              key={`userMenu__${userMenuItem.routeName}`}
                            >
                              <Translation>
                                {(t) => (
                                  <NavLink
                                    to={routeService.getRoute(
                                      userMenuItem.routeName,
                                      currentLanguage
                                    )}
                                    className="mobileMenu__itemAnchor"
                                    activeClassName="active"
                                    onClick={() => setShowingUserMenu(false)}
                                  >
                                    {t(userMenuItem.translationKey)}
                                  </NavLink>
                                )}
                              </Translation>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {!userIsLogged && (
                      <NavLink
                        to={routeService.getRoute("LOGIN", currentLanguage)}
                        activeClassName="active"
                        className="headerButton headerButton"
                        onClick={goToLogin}
                      >
                        <span>{t("component.header.menu.login")}</span>
                        <Ink />
                      </NavLink>
                    )}
                  </React.Fragment>
                )}
              </Translation>
            </div>
            <div className="headerItem headerItem--button menuLanguage">
              <Translation>
                {(t) => (
                  <React.Fragment>
                    <div className="loggedUserMenuWrapper">
                      <span
                        className={classnames({
                          loggedUserAnchor: true,
                          "loggedUserAnchor--opened": showingLanguageMenu
                        })}
                        onClick={() => setShowingLanguageMenu(!showingLanguageMenu)}
                      >
                        <span className={"languageIcon languageIcon--" + currentLanguage}></span>
                        <Ink />
                      </span>
                      <div
                        className={classnames({
                          userMenu: true,
                          "userMenu--opened": showingLanguageMenu
                        })}
                      >
                        <WebLanguageMenu
                          changeLanguage={changeLanguage}
                          setShowingLanguageMenu={setShowingLanguageMenu}
                          currentLanguage={currentLanguage}
                        />
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </Translation>
            </div>
            <div className="headerItem headerItem--language">
              <span
                className={classnames({
                  anchor: true,
                  active: true
                })}
                onClick={languageMobileMenuOnClick}
              >
                <span className={"languageIcon languageIcon--" + currentLanguage}></span>
              </span>
            </div>

            <div className="headerItem headerItem--userPanel">
              <span
                className={classnames({
                  userPanelIcon: true,
                  "userPanelIcon--close": showingMobileUserMenu
                })}
                onClick={userMenuOnClick}
              >
                <Ink />
              </span>
            </div>
            <div className="headerItem headerItem--hamburgerMenu">
              <span
                className={classnames({
                  hamburgerMenuIcon: true,
                  "hamburgerMenuIcon--close": showingMobileMenu
                })}
                onClick={hamburgerMenuOnClick}
              >
                <Ink />
              </span>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const renderTranslation = (menuItem, currentLanguage, style, activeClassName) => {
  return (
    <Translation>
      {(t) => (
        <NavLink
          to={
            menuItem.routeName === "CHRISTMAS"
              ? routeService.getRoute(menuItem.routeName, currentLanguage).replace("/:id", "")
              : routeService.getRoute(menuItem.routeName, currentLanguage)
          }
          className={style ? style : ""}
          activeClassName={classnames(
            menuItem.routeName === "START" ? "" : "active",
            activeClassName ? activeClassName : ""
          )}
        >
          {t(menuItem.translationKey)}
          <Ink />
        </NavLink>
      )}
    </Translation>
  );
};

const renderMobileEvent = (menuItem, style, activeClassName) => {
  return (
    <Translation>
      {(t) => (
        <a
          href={checkHttp(menuItem.landingPageUrl)}
          rel="noopener noreferrer"
          target="_blank"
          className={style ? style : ""}
        >
          {t("component.header.menu.event") + menuItem.menuName}
          <Ink />
        </a>
      )}
    </Translation>
  );
};

const mapStateToProps = (state) => {
  return {
    config: state.config
  };
};
const mapDispatchToProps = { configFetched };

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

const checkHttp = (url) => {
  if (url.includes("http")) {
    return url;
  } else {
    return `http://${url}`;
  }
};
