const backendTranslationsEN = {
  "ByWindow": "At the window",
  "Failed": "Failed",
  "ForHowManyPeopleTable": "Covers",
  "NewReservationCouldNotBeCreated": "We could not create the new reservation",
  "NewReservationHasBeenCreated": "The new reservation has been created",
  "NonSmoking": "Non smoking",
  "Outdoor": "Outside",
  "ReservationHasBeenUpdated": "The reservation has been updated",
  "aboutPage.header": "About",
  "aboutPage.metadata.description": "About us description",
  "aboutPage.metadata.keywords": "About us keywords",
  "aboutPage.metadata.title": "About us",
  "aboutPage.subheader": "Lorem ipsum dolor...",
  "acceptance.required": "Accept required agreements",
  "account.changePassword": "Change password",
  "account.confirmRemoveAccount": "Are you sure you want to delete the account? All your reservations will be automatically rejected.",
  "account.removeAccount": "Delete account",
  "account.removeRequest": "To delete your account, enter your password",
  "accountPage.button.changePassword": "Change password",
  "accountPage.button.deleteAccount": "Delete account",
  "accountPage.button.save": "Save",
  "accountPage.header": "Your account",
  "accountPage.headerInColumn.yourPassword": "Your password",
  "accountPage.headerInColumn.yourPersonalData": "Your personal data",
  "accountPage.input.label.birthDate": "Date of birth",
  "accountPage.input.label.city": "City",
  "accountPage.input.label.email": "E-mail address",
  "accountPage.input.label.firstName": "First name",
  "accountPage.input.label.lastName": "Last name",
  "accountPage.input.label.newPassword": "New password",
  "accountPage.input.label.newPasswordRepeat": "New password (repeat)",
  "accountPage.input.label.oldPassword": "Old password",
  "accountPage.input.label.phoneNumber": "Phone number",
  "accountPage.input.label.postalCode": "Post code",
  "accountPage.input.label.streetAndLocalNumber": "Street and local number",
  "accountPage.input.placeholder.birthDate": "E.g. 1976-10-25",
  "accountPage.input.placeholder.city": "E.g. Warsaw",
  "accountPage.input.placeholder.email": "E.g. address@email.com",
  "accountPage.input.placeholder.firstName": "E.g. George",
  "accountPage.input.placeholder.lastName": "E.g. Jones",
  "accountPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "accountPage.input.placeholder.postalCode": "E.g. 03-123",
  "accountPage.input.placeholder.streetAndLocalNumber": "E.g. Grójecka 130/105",
  "accountPage.label.changePassowordNotPossible": "Changing the password is not possible because you logged in via Facebook or Google.",
  "accountPage.message.accountPermanentlyDeleted": "Your account has been permanently deleted.",
  "accountPage.message.passwordChanged": "Password has been changed.",
  "accountPage.message.personalDataUpdated": "Personal details have been updated.",
  "accountPage.metadata.description": "Your account description",
  "accountPage.metadata.keywords": "Your account keywords",
  "accountPage.metadata.title": "Your account",
  "accountPage.paragraph.optionalFields": "Optional fields",
  "accountPage.subheader": "Change your personal data so that the process of your implementation is faster.",
  "accountPage.toast.error.disableBothChannels":
    "At least one communication method (SMS, EMAIL) must remain active!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Enabling the communication method requires confirmation of data accuracy. Save the changes to receive a verification link at the new email address!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Enabling the communication method requires confirmation of data accuracy. Save the changes to receive a verification SMS link to your new phone number!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "To enable the new communication method, confirm your data by clicking the activation link sent to your email address!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "To enable the new communication method, confirm your data by clicking the activation link sent via SMS to the provided phone number!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "To verify your data, click the verification link sent to your email address!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "To verify your data, click the verification link sent via SMS to your phone number!",
  "agreement.canRevert": "mojstolik.pl informuje o możliwości wycofania zgody na przetwarzanie danych osobowych.",
  "agreement.commercialInformationContact": "Wyrażam zgodę na przesyłanie przez Table4Rest sp. z o.o. informacji handlowej za pomocą środków komunikacji elektronicznej, w szczególności na podany przeze mnie numer telefonu oraz adres poczty elektronicznej, w tym SMS i MMS.",
  "agreement.marketingContact": "Wyrażam zgodę na kontaktowanie się ze mną przez Table4Rest sp. z o. o.,  w tym SMS i MMS, jaki i kontakt z użyciem telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących dla celów marketingu bezpośredniego, w tym na podany przeze mnie numer telefonu oraz adres poczty elektronicznej.",
  "agreement.marketingDataUsage": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez Table4Rest sp. z o.o. w celach marketingowych usług i produktów własnych oraz innych podmiotów, które są oferowane za pośrednictwem aplikacji MójStolik.pl",
  "agreement.personalData": "I hereby consent to the processing of the personal data that I have provided and declare my agreement with by Table4Rest sp. z o. o. in order to conclude a contract for the provision of services for sharing and using the MójStolik application.",
  "agreement.readWithdrawal": "Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
  "agreement.thirdPartDataUsage": "Wyrażam zgodę na przetwarzanie moich danych osobowych przez wybrane przeze mnie restauracje i inne lokale gastronomiczne w celu dokonania w nich rezerwacji.",
  "agreement.warningUncheck": "Odwołonie zgody uniemożliwia korzystanie z aplikacji. Aby ją odwołać, należy usunąć konto.",
  "api.connectionLost": "Internet connection has been lost. The application will be loaded again",
  "app.readAgreement": "Terms and conditions of the MojStolik.pl service",
  "cancellationReservationModal.component.button.cancel": "cancel",
  "cancellationReservationModal.component.button.confirm": "Confirm",
  "cancellationReservationModal.component.header": "Cancellation of reservation",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription": "Are you sure you want to cancel your reservation at {{restaurantName}} at {{reservationHour}} on {{reservationDate}}?",
  "changePass.lowerLetter": "- contains lowercase letters",
  "changePass.newPass": "New password",
  "changePass.numeric": "- contain numbers",
  "changePass.oldPass": "Current password",
  "changePass.passHaveToBe": "Password should",
  "changePass.passLength": "- have at least 6 characters",
  "changePass.specialChar": "- contain a special character like '#@!'",
  "changePass.upperLetter": "- contain at least 1 capital letter",
  "common.accept": "Confirm",
  "common.add": "Add",
  "common.alertCloseWindow": "you will close selection window",
  "common.back": "Back",
  "common.bankAccountNumber": "Bank Account Number",
  "common.buildingNumber": "Building number",
  "common.cancel": "Cancel",
  "common.changeButton": "Change",
  "common.check": "Checking",
  "common.city": "City",
  "common.clear": "Clear",
  "common.close": "Close",
  "common.confirm": "Confirm",
  "common.confirmDecline": "Confirm/Reject",
  "common.couldNotDownloadData": "The data was not downloaded. Please refresh the application",
  "common.dayShort.friday": "Fri",
  "common.dayShort.monday": "Mon",
  "common.dayShort.saturday": "Sat",
  "common.dayShort.sunday": "Sun",
  "common.dayShort.thursday": "Thu",
  "common.dayShort.tuesday": "Tue",
  "common.dayShort.wednesday": "Wed",
  "common.delete": "Remove",
  "common.detailsButton": "Details",
  "common.error": "Error",
  "common.gr": "gr",
  "common.help": "Help",
  "common.hourShort": "hour",
  "common.localNumber": "Restaurant number",
  "common.name": "Name",
  "common.new": "New",
  "common.next": "Continue",
  "common.nextButton": "Next",
  "common.noData": "There are no data to display",
  "common.noValue": "No",
  "common.ok": "OK",
  "common.password": "Password",
  "common.preferences": "Preference",
  "common.requiredInputError": "Field required",
  "common.save": "Save",
  "common.search": "Search",
  "common.send": "Send",
  "common.shortPersons": "{{persons}} p.",
  "common.street": "Street",
  "common.successful": "Success",
  "common.undoButton": "Back",
  "common.unit": "Unit",
  "common.vatId": "Tax Number",
  "common.yesValue": "Yes",
  "common.zipCode": "Post code",
  "common.zl": "PLN",
  "company.addNewAction": "New Company has been added",
  "company.addNewCompany": "Do you want to add new Company?",
  "company.addressData": "Address details",
  "company.buildingNumber": "Building number",
  "company.city": "City",
  "company.companyExistsError": "Company already exists",
  "company.country": "Country",
  "company.localNumber": "Restaurant number",
  "company.name": "Company name",
  "company.nipNumber": "VAT number",
  "company.notFound": "Company does not found in CDEIG",
  "company.postalCode": "Post code",
  "company.questionUpdateModal": "Update company details?",
  "company.street": "Street",
  "company.updateAction": "Company details have been updated",
  "company.updateCompany": "Update already existing company?",
  "company.vatId": "Tax Number",
  "component.cookiesPolicyLine.privacyPolicyText": "Privacy policy",
  "component.cookiesPolicyLine.text": "This site uses cookies to provide services at the highest level. By continuing to use the site, you agree to their use.",
  "component.footer.menu.about": "About",
  "component.footer.menu.contact": "Contact",
  "component.footer.menu.cookiesPolicy": "Cookies policy",
  "component.footer.menu.documents": "Documents",
  "component.footer.menu.forGuest": "For visitor",
  "component.footer.menu.forRestaurants": "For restaurants",
  "component.footer.menu.header.applicationDownload": "Download",
  "component.footer.menu.header.forClients": "ABOUT MOJSTOLIK.PL",
  "component.footer.menu.header.informations": "Informations",
  "component.footer.menu.header.socialMedia": "Social media",
  "component.footer.menu.header.yourAccount": "Your account",
  "component.footer.menu.howItWorks": "How it works?",
  "component.footer.menu.login": "Log in",
  "component.footer.menu.logout": "Log out",
  "component.footer.menu.priceList": "Price list",
  "component.footer.menu.privacyPolicy": "Privacy policy",
  "component.footer.menu.registration": "Registration",
  "component.footer.menu.reservationHistory": "Your reservations",
  "component.footer.menu.yourAccount": "Your account",
  "component.header.menu.about": "About",
  "component.header.menu.blog": "News from MOJSTOLIK.PL",
  "component.header.menu.contact": "Contact",
  "component.header.menu.documents": "Documents",
  "component.header.menu.forGuest": "For visitor",
  "component.header.menu.forRestaurants": "For restaurants",
  "component.header.menu.forRestaurants.offer": "Our offer",
  "component.header.menu.howItWorks": "How it works?",
  "component.header.menu.login": "Log in",
  "component.header.menu.logout": "Log out",
  "component.header.menu.mainPage": "Book a table",
  "component.header.menu.portal.restaurant": "Restaurant portal",
  "component.header.menu.priceList": "Price list",
  "component.header.menu.reservationHistory": "Your reservations",
  "component.header.menu.yourAccount": "Your account",
  "config.communication.confirmationReservation": "Reservation confirm",
  "config.communication.modificationReservation": "Change of reservation",
  "config.communication.notifTitle": "PUSH notifications",
  "config.communication.rejectionReservation": "Canceling a reservation",
  "config.communication.remindNotification": "Reminders about booking",
  "config.navbar.account": "Account",
  "config.navbar.communication": "Communication",
  "config.navbar.configuration": "Configuration",
  "config.navbar.myaccount": "Account",
  "config.navbar.mypreferences": "Preference",
  "configuration.changeLanguage": "Language",
  "configuration.customerProfiles": "Guests profiles",
  "configuration.employeeGroups": "Employees Groups",
  "configuration.help": "Help",
  "configuration.restaurant": "Restaurant",
  "contactPage.header": "Contact",
  "contactPage.metadata.description": "Contact description",
  "contactPage.metadata.keywords": "Contact keywords",
  "contactPage.metadata.title": "Contact",
  "contactPage.subheader": "Lorem ipsum dolor...",
  "cookiesPolicyPage.header": "Cookies policy",
  "cookiesPolicyPage.metadata.description": "",
  "cookiesPolicyPage.metadata.keywords": "",
  "cookiesPolicyPage.metadata.title": "Cookies policy",
  "cookiesPolicyPage.subheader": "Important information.",
  "cuisine.american": "American cuisine",
  "cuisine.arabic": "Arabic cuisine",
  "cuisine.chinese": "Chinese cuisine",
  "cuisine.polish": "Polish cuisine",
  "cuisine.romania": "Romanian cuisine",
  "cusine.chinese": "Chinese cuisine",
  "cusine.polish": "Polish cuisine",
  "customer.accountDetail": "Account details",
  "customer.addEdit": "Add / Modify",
  "customer.blocked": "Your account has been blocked. You cannot book.",
  "customer.confirmText": "Reservation",
  "customer.createInvoice": "Create invoice",
  "customer.createNewProfile": "New profile has been created",
  "customer.createNewProfileModal": "Create new profile?",
  "customer.deleteProfile": "Delete profile",
  "customer.description": "Description",
  "customer.editCompany": "Edit company details",
  "customer.editProfile": "Profile has been updated",
  "customer.editReservingCustomerData": "Edit the details of person that did reservation",
  "customer.emailAddress": "e-mail",
  "customer.errorProfileAction": "The profile is already assigned to the Guest",
  "customer.existError": "Guest already exists",
  "customer.firstCreateCompany": "*first please chose the company",
  "customer.groupChooseLabel": "Select",
  "customer.labels.blocked": "Blocked",
  "customer.labels.customerName": "Name",
  "customer.labels.emailLabel": "E-mail",
  "customer.labels.firstNameLabel": "Name",
  "customer.labels.groupLabel": "Group",
  "customer.labels.lastNameLabel": "Surname",
  "customer.labels.phoneNumber": "Phone",
  "customer.labels.statusLabel": "status",
  "customer.listOfProfiles": "List of profiles",
  "customer.mail": "e-mail",
  "customer.name": "Name",
  "customer.newPasswordWasSent": "The new password has been send by SMS message",
  "customer.noCustomer": "Guest does not exist",
  "customer.noCustomers": "No Guests",
  "customer.noReservations": "No reservation",
  "customer.onePersonFound": "1 person has been found",
  "customer.password": "Password",
  "customer.password.mismatch": "Wrong current password",
  "customer.passwordHasChange": "Password has been changed",
  "customer.permissionForHoldingPersonalData": "Confirmation for the approval to process personal data",
  "customer.profile": "Profile",
  "customer.profileDetails": "Profile details",
  "customer.profileExistsError": "Profile already exists",
  "customer.registration.code": "Your activation code: _CODE_",
  "customer.registration.invalid": "Invalid activation code",
  "customer.registration.sendByEmail": "Problem with SMS - code has been send to provided email.",
  "customer.remarks": "Remarks",
  "customer.remarksToClient": "Remarks about Guest",
  "customer.remarksToReservation": "Remarks to the reservation",
  "customer.removeProfileAction": "Profile has been deleted",
  "customer.removeProfileAction.error": "Profile has not been deleted",
  "customer.reservationHistory": "Reservation History",
  "customer.reservingCustomerData": "Details of person doing the reservation",
  "customer.saveChanges": "Save changes?",
  "customer.surname": "Surname",
  "customer.telephone": "Phone",
  "customer.telephoneNumber": "Phone number",
  "customer.updateClient": "Update Guest details?",
  "customer.updateProfileModal": "Update the profile?",
  "defaultValues.restaurant": "Restaurant",
  "deleteAccountModal.component.button.cancel": "Cancel",
  "deleteAccountModal.component.button.confirm": "Confirm",
  "deleteAccountModal.component.header": "Delete account",
  "deleteAccountModal.component.paragraph.deleteAccountDescription": "Are you sure you want to delete your account? Your booking history will also be deleted.",
  "documentsPage.header": "Documents",
  "documentsPage.metadata.description": "Documents description",
  "documentsPage.metadata.keywords": "Documents keywords",
  "documentsPage.metadata.title": "Documents",
  "documentsPage.subheader": "Lorem ipsum dolor...",
  "editReservation.send": "Send",
  "editReservation.sendMessage": "A request to change the reservation parameters has been sent.",
  "email.already.exist": "The email address already exists",
  "email.missing": "Email address missing",
  "error": "Error occurred. Please try again later.",
  "error.LocationNotFound": "Location not found or not enough characters provided",
  "error.cannotCreateReservation": "Your request could not be sent",
  "error.connectionWithServerLost": "Connection lost",
  "error.connectionWithServerLostTryLater": "Connection lost. Try again later",
  "error.email.already.exist": "The email address already exists",
  "error.facebook.account.already.exist": "A Facebook account has already been created for this number. Use Facebook login",
  "error.google.account.already.exist": "A Google account has already been created for this number. Use Google login",
  "error.notFoundAddress": "Location not found or not enough characters provided",
  "error.phone.already.exist": "The number already exists. Log in by phone number or use the restore password option.",
  "filters.doFilter": "Filter",
  "filters.fromToday": "From today",
  "filters.toToday": "Until today",
  "filters.today": "Today",
  "footer.Next": "Next",
  "footer.Previous": "Previous",
  "footer.alertOnBack": "Drop changes?",
  "forGuestPage.header": "For visitor",
  "forGuestPage.metadata.description": "For guest description",
  "forGuestPage.metadata.keywords": "For guest keywords",
  "forGuestPage.metadata.title": "For guest",
  "forGuestPage.subheader": "",
  "forRestaurantsPage.form": "Your application has been sent. We'll contact you soon.",
  "forRestaurantsPage.header": "For restaurants",
  "forRestaurantsPage.metadata.description": "For restaurants description",
  "forRestaurantsPage.metadata.keywords": "For restaurants keywords",
  "forRestaurantsPage.metadata.title": "For restaurants",
  "forRestaurantsPage.subheader": "The offer is directed to the restaurant.",
  "global.form.message.atLeastNineCharacters": "At least nine characters required",
  "global.form.message.invalidBirthDateFormat": "Invalid date of birth format",
  "global.form.message.invalidEmailFormat": "Invalid e-mail address format",
  "global.form.message.invalidPhoneFormat": "Invalid phone number format",
  "global.form.message.invalidPostCodeFormat": "Invalid post code format",
  "global.form.message.newPasswordsDoNotMatch": "New passwords do not match!",
  "global.form.message.requiredField": "Required field",
  "global.messages.sessionTimeout": "Session has expired",
  "global.time.minutes": "minutes",
  "group.removeErrorAction": "The group is already assigned to the user",
  "howItWorksPage.header": "How it works?",
  "howItWorksPage.metadata.description": "How it works description",
  "howItWorksPage.metadata.keywords": "How it works keywords",
  "howItWorksPage.metadata.title": "How it works?",
  "howItWorksPage.subheader": "Step by step",
  "image.notFound": "Image not found",
  "indexPage.background.header": "Find the best restaurants in your city",
  "indexPage.background.headerExtend": "and then book a table:",
  "indexPage.button.bookNow": "Book now",
  "indexPage.button.hideAdditionalFilters": "Hide additional filters",
  "indexPage.button.readMore": "Read more",
  "indexPage.button.search": "Search",
  "indexPage.button.showAdditionalFilters": "Additional filters",
  "indexPage.button.use": "Take advantage",
  "indexPage.header.blogs": "Featured Articles",
  "indexPage.header.currentPromotions": "Special offers and events",
  "indexPage.header.currentPromotionsExtend": "in your location",
  "indexPage.header.mostPopular": "Most popular",
  "indexPage.header.mostPopularExtend": "in your city",
  "indexPage.input.label.date": "Date",
  "indexPage.input.label.hour": "Hour",
  "indexPage.input.label.location": "Location",
  "indexPage.input.label.persons": "Number of person",
  "indexPage.input.label.persons.optionExtend": "p.",
  "indexPage.input.label.priceLevel": "Price level",
  "indexPage.input.label.rating": "Rating",
  "indexPage.input.label.rating.optionExtend": "star(-s)",
  "indexPage.input.label.restaurantType": "Cuisine type",
  "indexPage.input.label.searchByName": "Search by name",
  "indexPage.input.noData": "No results.",
  "indexPage.input.placeholder.select": "Select",
  "indexPage.input.placeholder.type": "Type in",
  "indexPage.input.placeholer.typeInOrSelect": "Type in or select",
  "invitation": "Hi, I invite you to {{restaurant}} on {{date}} at {{time}}. Download the MojStolik.pl application to read more about the place: https://www.mojstolik.pl/pobierz/apka.html.",
  "invoice.vatId": "VAT ID: {{vatId}}",
  "login": "Login",
  "login.accountDoesNotExist": "The account does not exist",
  "login.accountName": "User name",
  "login.doLogin": "Log in",
  "login.forgotPassword": "Forgot password?",
  "login.forgottenPasswordLink": "Do you forgot the password?",
  "login.generalTitle": "Log in",
  "login.insertLoginData": "Please insert login details",
  "login.insertManagerPassword": "Please put manager email address",
  "login.loggingToSystem": "Login to the system",
  "login.loginOrPasswordWrong": "Login or password is incorrect",
  "login.loginText": "Login",
  "login.logout": "Logout",
  "login.logout.message": "You have been logout",
  "login.newAccount": "New account",
  "login.newUpdateReady": "There is new version of application available. Please update",
  "login.noAppleAccount": "There is no account associated with this Apple account. Try another login method or register a new account.",
  "login.noFacebookAccount": "There is no account associated with this Facebook account. Try another login method or register a new account.",
  "login.noGoogleAccount": "There is no account associated with this Google account. Try another login method or register a new account.",
  "login.passwordRecovery": "Password recovery",
  "login.passwordSent.sms": "The new password is: __PASSWORD__",
  "login.passwordSent.sms.too.often": "Too often try to change the password, try again in a moment",
  "login.passwordSentToEmail": "The new password has been sent for the given email address",
  "login.phoneNumberOrMail": "Phone number or email address",
  "login.recover": "Recover",
  "login.resetPasswordLabel": "E-mail address",
  "login.restaurantChanged": "The restaurant has been changed",
  "login.thisAccountAlreadyExist": "The user already exist",
  "login.updateRequired": "You need to update your application. It might work not correctly",
  "login.userUnauthorized": "Login or password is incorrect",
  "login.userUnauthorized.useApple": "Bad login or password. Try to login by Apple or restore password.",
  "login.userUnauthorized.useFacebook": "Bad login or password. Try to login by Facebook or restore password",
  "login.userUnauthorized.useGoogle": "Bad login or password. Try to login by Google or restore password.",
  "login.yourPassword": "Your password",
  "loginPage.anchor.passwordReset": "Password reset",
  "loginPage.button.logIn": "Log in",
  "loginPage.button.register": "Register now",
  "loginPage.columns.header.socialLogin": "Log in with",
  "loginPage.columns.header.standardLogin": "Standard log in",
  "loginPage.header": "Log in",
  "loginPage.input.label.password": "Password",
  "loginPage.input.label.phoneOrEmail": "Phone number",
  "loginPage.input.placeholder.password": "",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.message.logged": "Logged.",
  "loginPage.message.loggedViaApple": "Logged in with Apple.",
  "loginPage.message.loggedViaFacebook": "Logged in with Facebook.",
  "loginPage.message.loggedViaGoogle": "Logged in with Google.",
  "loginPage.metadata.description": "Log in description",
  "loginPage.metadata.keywords": "Log in keywords",
  "loginPage.metadata.title": "Log in",
  "loginPage.paragraph.alreadyLoggedIn": "You are already logged in.",
  "loginPage.paragraph.dontHaveAccountYet": "You dont have an account yet?",
  "loginPage.paragraph.registerNow": "Register now!",
  "loginPage.subheader": "As a logged in user you can reserve tables faster and view your booking history.",
  "logoutPage.metadata.description": "Log out description",
  "logoutPage.metadata.keywords": "Log out keywords",
  "logoutPage.metadata.title": "Log out",
  "mail.confirm.message": "Confirm your email address by clicking on the link",
  "mail.confirm.title": "MojStolik.pl message",
  "map.searchArea": "Search the area",
  "menu.areaControl": "Rooms management",
  "menu.config": "Configuration",
  "menu.configPage": "Settings",
  "menu.configuration": "Configuration",
  "menu.currentReservations": "Current reservation",
  "menu.customers": "Guests",
  "menu.homePage": "Main",
  "menu.invoice": "Invoices",
  "menu.login": "Login",
  "menu.logout": "Log out",
  "menu.myReserv": "Reservation",
  "menu.myReservations": "My reservations",
  "menu.newAccount": "My account",
  "menu.passwordRecovery": "Password recover",
  "menu.reservationpage": "New reservation",
  "menu.reservations": "All reservations",
  "menu.resetPassword": "Password reminder",
  "menu.restaurant": "Restaurant",
  "menu.sale": "Promotions",
  "menu.search": "Search",
  "myReservation.doRate": "Rate",
  "myReservation.doRateQuestion": "Would you like to rate reservation {{rate}}/5?",
  "myReservation.doRateSuccess": "The rating has been issued",
  "myReservation.doRateTitle": "How do you rate your booking?",
  "myReservation.doSuccessRemove": "The reservation has been removed from the list",
  "myReservation.yourRate": "Your rate",
  "navbar.allReserv": "All",
  "navbar.areaEdition": "Room edit",
  "navbar.cancelChangesReservation": "Cancel the reservation change?",
  "navbar.cancelReservation": "Cancel reservation?",
  "navbar.changePass": "Change password",
  "navbar.clientPreview": "Guest view",
  "navbar.companyEdition": "Edit company details",
  "navbar.configuration": "Configuration",
  "navbar.currentReserv": "Current",
  "navbar.currentReservations": "Current reservations",
  "navbar.customers": "Guests",
  "navbar.dataCustomer": "Details of person doing the reservation",
  "navbar.defaultCancel": "Cancel?",
  "navbar.employee": "Employees",
  "navbar.historyReserv": "Historical",
  "navbar.invoiceEdition": "Invoice edit",
  "navbar.invoicePreview": "Invoice view",
  "navbar.label.editReservation": "Change of reservation",
  "navbar.label.myProfile": "My profile",
  "navbar.label.myReservation": "My reservations",
  "navbar.label.reservation": "Reservation",
  "navbar.label.reservationDetails": "Reservation details",
  "navbar.label.sale": "Promotions",
  "navbar.newReservation": "New Reservation",
  "navbar.reservationActualState": "Current reservation status",
  "navbar.reservationEdit": "Edit reservation",
  "navbar.reservationPreview": "Reservation view",
  "navbar.reservations": "Reservations",
  "notFoundPage.button.goToHomePage": "Go to main page",
  "notFoundPage.header": "Error 404",
  "notFoundPage.metadata.description": "404 not found description",
  "notFoundPage.metadata.title": "404 - Not found page!",
  "notFoundPage.subheader": "Requested URL",
  "notFoundPage.subheaderExtend": "was not found on the server.",
  "notFoundPage.subheaderExtend2": "Try to check if the address provided is correct or go to",
  "notFoundPage.subheaderExtend3": "main page",
  "notification.body.Canceled": "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been canceled.",
  "notification.body.Completed": "Thank you for reservation in _RESTAURANT_ at _DAY_ _TIME_.",
  "notification.body.Confirmed": "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been confirmed.",
  "notification.body.Rejected": "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ has been cancelled. _MESSAGE_",
  "notification.body.WaitingForApproval": "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ is waiting for approval.",
  "notification.body.WaitingForConfirmation": "Your reservations in _RESTAURANT_ at _DAY_ _TIME_ is waiting for confirmation.",
  "notification.invitation.accepted.body": "_NAME_ has accepted your invitation in _RESTAURANT_ at _DAY_ _TIME_.",
  "notification.invitation.accepted.title": "Invitation accepted.",
  "notification.invitation.rejected.body": "_NAME_ has canceled your invitation in _RESTAURANT_ at _DAY_ _TIME_.",
  "notification.invitation.rejected.title": "Zaproszenie odrzucone.",
  "notification.new.chat.body": "_MESSAGE_",
  "notification.new.chat.title": "Message from _RESTAURANT_",
  "notification.parameters.updated.body": "Your reservation in _RESTAURANT_ at _DAY_:_TIME_ has been updated.",
  "notification.parameters.updated.title": "Reservation update",
  "onboarding.promotion.MFR2023.Advanced.description": "The MFR2023 promotion includes a free trial period. During the first three months after signing the contract, there will be no subscription fees listed in the description of the plans below. The promotion is for new customers and applies only to LIGHT and ADVANCED plans and is valid from 25.04 - 20.05.2023.",
  "onboarding.promotion.MFR2023.AdvancedPro.description": "The promotion does not apply to the ADVANCED PRO plan.",
  "onboarding.promotion.MFR2023.Light.description": "The MFR2023 promotion includes a free trial period. During the first three months after signing the contract, there will be no subscription fees listed in the description of the plans below. The promotion is for new customers and applies only to LIGHT and ADVANCED plans and is valid from 25.04 - 20.05.2023.",
  "onboarding.promotion.MFR2023.description": "The MFR2023 promotion includes a free trial period. During the first three months after signing the contract, there will be no subscription fees listed in the description of the plans below. The promotion is for new customers and applies only to LIGHT and ADVANCED plans and is valid from 25.04 - 20.05.2023.",
  "onboarding.promotion.WAKACJE2023.Advanced.description": "The promotion does not apply to the ADVANCED plan.",
  "onboarding.promotion.WAKACJE2023.AdvancedPro.description": "The promotion does not apply to the ADVANCED PRO plan.",
  "onboarding.promotion.WAKACJE2023.Light.description": "WAKACJE2023 is a LIGHT offer for 0 PLN in the months of June, July and August 2023 for new customers. After these months, you are welcome to continue cooperation on standard terms.",
  "onboarding.promotion.WAKACJE2023.description": "WAKACJE2023 is a LIGHT offer for 0 PLN in the months of June, July and August 2023 for new customers. After these months, you are welcome to continue cooperation on standard terms.",
  "onboarding.promotion.promo-test.description": "promo-test description. Terms of Services: _LINK_",
  "onboarding.promotion.promo-test.terms.link": "https://tst-web.mojstolik.pl/en",
  "password.errors.weak": "Password is too weak",
  "passwordFormModal.component.button.cancel": "Cancel",
  "passwordFormModal.component.button.confirm": "Confirm",
  "passwordFormModal.component.header": "Enter the account password",
  "passwordFormModal.component.paragraph.deleteAccountDescription": "Enter your password to confirm the action.",
  "payment.card": "credit card",
  "payment.cash": "cash",
  "payment.item.description.per.person": "_PRODUCT_NAME_",
  "payment.item.description.per.reservation": "_PRODUCT_NAME_",
  "payment.otherType": "other",
  "payment.title.generic": "Payment is required for the reservation",
  "payment.title.with.event": "Payment for event: _EVENT_NAME_",
  "payment.transfer": "money transfer",
  "payment.type": "Payment method",
  "preference.category.cuisine": "Cuisine",
  "preference.category.cuisine.polish": "Polish Cuisine",
  "preference.category.price": "Price",
  "previewImageModal.component.anchor.close": "Close",
  "previewRestaurantModal.component.anchor.close": "Close",
  "priceListPage.header": "Price list",
  "priceListPage.metadata.description": "Price list description",
  "priceListPage.metadata.keywords": "Price list keywords",
  "priceListPage.metadata.title": "Price list",
  "privacyPolicyPage.header": "Privacy policy",
  "privacyPolicyPage.metadata.title": "Privacy policy",
  "privacyPolicyPage.subheader": "Important information.",
  "promotions.emptyList": "No promotions",
  "registration.activeCode": "Activation code",
  "registration.addressEmail": "Email address",
  "registration.codeInformation": "The activation code has been sent to the given phone number {{phone}}, enter it below and confirm with the button",
  "registration.createAccount": "New account",
  "registration.createSuccess": "Account has been created",
  "registration.differentPasswords": "The passwords do not match",
  "registration.finishButton": "Finish registration",
  "registration.formIsInvalid": "The data has not been filled correctly. Use the help at the bottom.",
  "registration.generalTitle": "Registration",
  "registration.phoneNumber": "Phone number",
  "registration.phoneToShort": "Number too short",
  "registration.readAgreement": "Read the regulations",
  "registration.repeatPassword": "Confirm password",
  "registration.requiredFieldsFirstStep": "The phone number, e-mail and password are mandatory.",
  "registration.weakPassword": "Password is too weak",
  "registrationPage.button.confirm": "Confirm",
  "registrationPage.button.register": "Register",
  "registrationPage.button.sendCodeAgain": "Resend code",
  "registrationPage.columns.header.registerStandard": "Register standard",
  "registrationPage.columns.header.registerWith": "Register with",
  "registrationPage.header": "Registration",
  "registrationPage.header.SMSConfirmation": "SMS confirmation",
  "registrationPage.header.statements": "Statements",
  "registrationPage.input.label.agreementEvent": "[EN] Zapoznałem się z regulaminem wydarzenia i promocji znajdujący się ",
  "registrationPage.input.label.agreementEvent.extension": "at this link",
  "registrationPage.input.label.agreementReadWithdrawal": "[EN] Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
  "registrationPage.input.label.agreementReadWithdrawal.extension": "agreement with the customer mojstolik.pl",
  "registrationPage.input.label.birthDate": "Date of birth",
  "registrationPage.input.label.city": "City",
  "registrationPage.input.label.email": "E-mail address",
  "registrationPage.input.label.firstName": "First name",
  "registrationPage.input.label.lastName": "Last name",
  "registrationPage.input.label.password": "Password",
  "registrationPage.input.label.passwordRepeat": "Password repeat",
  "registrationPage.input.label.phoneNumber": "Phone number",
  "registrationPage.input.label.postalCode": "Post code",
  "registrationPage.input.label.streetAndLocalNumber": "Street and local number",
  "registrationPage.input.placeholder.birthDate": "E.g. 1976-10-25",
  "registrationPage.input.placeholder.city": "E.g. Warsaw",
  "registrationPage.input.placeholder.code": "Type in code",
  "registrationPage.input.placeholder.email": "E.g. address@email.com",
  "registrationPage.input.placeholder.firstName": "E.g. George",
  "registrationPage.input.placeholder.lastName": "E.g. Jones",
  "registrationPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "registrationPage.input.placeholder.postalCode": "E.g. 03-123",
  "registrationPage.input.placeholder.streetAndLocalNumber": "E.g. Grójecka 130/105",
  "registrationPage.message.accountCreated": "Account has been created.",
  "registrationPage.message.enterCodeFromSMS": "Enter the code from the SMS.",
  "registrationPage.message.registrationTimeExpired": "Registration time has expired",
  "registrationPage.message.sendCodeAgain": "The SMS code has been resent.",
  "registrationPage.metadata.description": "Registration description",
  "registrationPage.metadata.keywords": "Registration keywords",
  "registrationPage.metadata.title": "Registration",
  "registrationPage.paragraph.SMSConfirmationDescription": "Enter the SMS code we sent to your phone number to complete registration.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Enter the SMS code from",
  "registrationPage.paragraph.optionalFields": "Optional fields",
  "registrationPage.paragraph.seconds": "second(-s)",
  "registrationPage.paragraph.sendCodeAgain": "The SMS code has been resent.",
  "registrationPage.paragraph.timeIsOver": "The time is over.",
  "registrationPage.paragraph.timeLeft": "Left",
  "registrationPage.subheader": "As a logged in user you can reserve tables faster and view your booking history.",
  "request.blocked": "Request has been blocked, please try again later.",
  "reservation.addNewAction": "The new reservation has been created",
  "reservation.addNote": "Add note",
  "reservation.addRemarkQuestion": "Add note to the reservation",
  "reservation.alreadyRated": "Reservation has been already rated",
  "reservation.basicInformation": "Basic information",
  "reservation.cancelReservation": "Cancel",
  "reservation.cancelReservationQuestion": "Are you sure you want to cancel the booking?",
  "reservation.changeReservation": "Change",
  "reservation.changeReservationParameters": "Change reservation details",
  "reservation.changeStatusAction": "Status changed on",
  "reservation.chooseStatus": "Select status",
  "reservation.comunications": "Messages",
  "reservation.createReservation": "Create reservation on",
  "reservation.defaultTime": "Preferred booking time {{reservationTime}}.",
  "reservation.doReservation": "Book",
  "reservation.duration": "Duration",
  "reservation.durationTime": "Duration of booking  {{durationTime}}",
  "reservation.editData": "Edit data",
  "reservation.editReservation": "Edit reservation",
  "reservation.errorNewAction": "The new reservation could not be created",
  "reservation.errorNotAvailableYet": "Reservation is not available yet.",
  "reservation.errorPast": "The new reservation could not be created in past",
  "reservation.errorToLate": "Reservation is no longer available.",
  "reservation.filter.fromDate": "From",
  "reservation.filter.lastName": "Surname",
  "reservation.filter.toDate": "Till",
  "reservation.forHowLong": "Duration",
  "reservation.generalTitle": "Booking",
  "reservation.group": "Group",
  "reservation.individual": "Individual",
  "reservation.informationOnReservation": "Guest information",
  "reservation.invitation": "Invite",
  "reservation.labels.client": "Client",
  "reservation.labels.date": "Date",
  "reservation.labels.dateTo": "For",
  "reservation.labels.firstName": "Name",
  "reservation.labels.lastName": "Surname",
  "reservation.labels.number": "Reservation number",
  "reservation.labels.numberOfPeople": "Number of Guests",
  "reservation.labels.phone": "Phone",
  "reservation.labels.profil": "Profile",
  "reservation.labels.remarks": "Remarks",
  "reservation.labels.status": "Status",
  "reservation.labels.tableNumber": "Room - table",
  "reservation.lessSpotThanPeople": "The selected number of seats is smaller - continue?",
  "reservation.message": "MojStolik.pl message",
  "reservation.messages": "Write the message",
  "reservation.morePeople": "Greater number of people",
  "reservation.newPersonToReservation": "Add new person to the reservation?",
  "reservation.noFreeTables": "There are no available tables",
  "reservation.notExist": "Reservation does not exist",
  "reservation.numberOfGuests": "Number of Guests",
  "reservation.payment.pay": "Pay",
  "reservation.payment.status": "Payment status",
  "reservation.payment.status.Cancelled": "Cancelled",
  "reservation.payment.status.Cancelled.text": "Payment has been canceled",
  "reservation.payment.status.Completed": "Completed",
  "reservation.payment.status.Completed.text": "Payment completed",
  "reservation.payment.status.Created": "Created",
  "reservation.payment.status.Created.text": "Payment created",
  "reservation.payment.status.Expired": "Expired",
  "reservation.payment.status.Expired.text": "Payment expired",
  "reservation.payment.status.ProcessingPayment": "Processing",
  "reservation.payment.status.ProcessingPayment.text": "Payment is in progress",
  "reservation.payment.status.Unknown": "Unknown status",
  "reservation.payment.status.Unknown.text": "Unknown payment status",
  "reservation.payment.status.WaitingForPayment": "Awaiting",
  "reservation.payment.status.WaitingForPayment.text": "Payment is awaiting for payment",
  "reservation.paymentDetails": "Payment details",
  "reservation.paymentForReservation": "Payment for the reservation",
  "reservation.paymentRequired": "Reservation requires a payment",
  "reservation.payments": "Payments",
  "reservation.personsError": "Can not send a booking request for 0 persons",
  "reservation.pickDurationTime": "Choose the duration",
  "reservation.preferences": "Preferences",
  "reservation.prepayment": "Prepayment",
  "reservation.remarks": "Remarks",
  "reservation.remarksFromRestaurant": "Restaurant comments",
  "reservation.remind.message": "The website MojStolik.pl reminds about booking at the _RESTAURANT_ on _DATE_ for _PERSONS_. To cancel a reservation, please click the link: _LINK_ . Booking service is provided by MojStolik.pl",
  "reservation.request.not.yet.available": "Reservation is not available yet.",
  "reservation.request.too.often": "The request just sent",
  "reservation.reservationDate": "Day",
  "reservation.spotsChosen": "Selected seats",
  "reservation.status": "Status",
  "reservation.status.Canceled": "Rejected",
  "reservation.status.Completed": "Booking completed",
  "reservation.status.Confirmed": "The reservation has been confirmed",
  "reservation.status.Delayed": "Delayed",
  "reservation.status.NotCompleted": "Unrealized booking",
  "reservation.status.Rejected": "The reservation was canceled",
  "reservation.status.WaitingForApproval": "The reservation is awaiting for approval",
  "reservation.status.WaitingForConfirmation": "Awaiting",
  "reservation.successRequest": "A reservation request has been sent",
  "reservation.summaryCost": "Total amount",
  "reservation.updateAction": "The reservation has been updated",
  "reservation.updateReservation": "Updated reservation?",
  "reservation.updateReservationButton": "Cancel reservation",
  "reservation.whichHour": "Time",
  "reservation.wholeRoom": "The whole room",
  "reservationHistoryPage.button.revoke": "Revoke",
  "reservationHistoryPage.header": "Your reservations",
  "reservationHistoryPage.metadata.description": "Your reservations description",
  "reservationHistoryPage.metadata.keywords": "Your reservations keywords",
  "reservationHistoryPage.metadata.title": "Your reservations",
  "reservationHistoryPage.paragraph.additionalDescription": "Communication with restaurant",
  "reservationHistoryPage.paragraph.date": "Date",
  "reservationHistoryPage.paragraph.duration": "Duration",
  "reservationHistoryPage.paragraph.hour": "Hour",
  "reservationHistoryPage.paragraph.persons": "Number of person",
  "reservationHistoryPage.paragraph.personsShortcut": "p.",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "The reservation does not exist",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "The reservation list is empty.",
  "reservationHistoryPage.subheader": "Here you can view your historical reservations and manage future reservations.",
  "reservationMenu.allActive": "All active",
  "reservationMenu.fromApp": "Requests from the application",
  "reservationMenu.toConfirmation": "To be confirmed",
  "reservationMenu.today": "For today",
  "reservationParams.restaurantName": "Restaurant name",
  "reservationParams.yourLocalization": "Your localization",
  "reservations.noReservations": "No reservation",
  "resetPassoword.generalTitle": "Password reminder",
  "resetPassoword.info": "Enter the phone number to which the account was registered. The password will be sent via SMS to this number",
  "resetPassoword.sendNewPassword": "Send password",
  "resetPasswordPage.button.confirm": "Confirm",
  "resetPasswordPage.header": "Password reset",
  "resetPasswordPage.input.label.phoneOrEmail": "Phone number",
  "resetPasswordPage.metadata.description": "Password reset description",
  "resetPasswordPage.metadata.keywords": "Password reset keywords",
  "resetPasswordPage.metadata.title": "Password reset",
  "resetPasswordPage.subheader": "Fill out the form below and we will send you a new account password via SMS",
  "resetPasswordPage.subheader2": "We will send you a new password to your phone number",
  "resetPasswordPage.toast.error.passwordChanged": "The phone number provided is incorrect.",
  "resetPasswordPage.toast.passwordChanged": "The new password was sent via SMS to the phone number provided during registration.",
  "restaurant.aboutUs": "About us",
  "restaurant.bankAccountNumber": "Bank Account Number",
  "restaurant.buildingNumber": "Restaurant number",
  "restaurant.city": "City",
  "restaurant.closeHour": "Closed time",
  "restaurant.companyName": "Company name",
  "restaurant.dayFormat": "Dd – day of month",
  "restaurant.defaultReservationLength": "Predefined duration of the reservation",
  "restaurant.emailAddress": "E-mail",
  "restaurant.emptyList": "There are no restaurants that match your search criteria.",
  "restaurant.greaterThenZeroError": "Must be greater than 0",
  "restaurant.insertRestaurant": "Please insert Restaurant name",
  "restaurant.legend": "Legend",
  "restaurant.localNumber": "Restaurant number",
  "restaurant.logo": "Logo",
  "restaurant.maximalReservationLength": "Max duration of the reservation",
  "restaurant.menu.price": "{{price}} zł",
  "restaurant.minimalPeopleForGroup": "Min number of guests for group reservation",
  "restaurant.monthFormat": "MM - month",
  "restaurant.navbar.aboutRestaurant": "Restaurant",
  "restaurant.navbar.menu": "Menu",
  "restaurant.navbar.promotion": "Promotions",
  "restaurant.navigate": "Navigate to the restaurant",
  "restaurant.navigationApp": "Navigation application",
  "restaurant.nipNumber": "Tax Number",
  "restaurant.openHour": "Opening hour",
  "restaurant.pickDay": "Choose the date",
  "restaurant.pickHour": "Choose time",
  "restaurant.pickPersons": "Choose the number of persons",
  "restaurant.postalCode": "Post code",
  "restaurant.price.expensive": "Very expensive",
  "restaurant.price.extralow": "Extra cheap",
  "restaurant.price.low": "Cheap",
  "restaurant.price.medium": "Medium",
  "restaurant.productsServices": "Products / services",
  "restaurant.promotion.label": "Promotions",
  "restaurant.questionUpdateRestaurant": "You want to update the details about restaurant?",
  "restaurant.rate": "Rate",
  "restaurant.restaurant": "Restaurant",
  "restaurant.saveNavigationPick": "Remember the choice",
  "restaurant.seriesNumberTemplate": "Invoice numbering template",
  "restaurant.serverAddress": "server address",
  "restaurant.street": "Street",
  "restaurant.web.confirm": "Do you want to open a website?",
  "restaurant.writeCity": "City",
  "restaurant.yearEndFormat": "uu – short year, e.g. 18 ",
  "restaurant.yearFullFormat": "uuuu – long year, e.g. 2018",
  "restaurantPage.anchor.edit": "Edit",
  "restaurantPage.anchor.login": "Login",
  "restaurantPage.anchor.register": "register",
  "restaurantPage.anchor.sendCodeAgain": "Resend code",
  "restaurantPage.button.cancel": "Cancel",
  "restaurantPage.button.confirm": "Confirm",
  "restaurantPage.button.goToHomePage": "Back to main page",
  "restaurantPage.button.pageWWW": "Web WWW",
  "restaurantPage.button.seeMenu": "See menu",
  "restaurantPage.button.showLess": "Show less",
  "restaurantPage.button.showMore": "Show more",
  "restaurantPage.header.gallery": "Gallery",
  "restaurantPage.header.promotions": "Special offers and events",
  "restaurantPage.header.reservationConfirmation": "Reservation Confirmation",
  "restaurantPage.header.restaurantDescription": "Restaurant description",
  "restaurantPage.header.statements": "Statements",
  "restaurantPage.header.use": "Use",
  "restaurantPage.header.yourPersonalData": "Your personal data",
  "restaurantPage.header.yourReservation": "Your reservation",
  "restaurantPage.input.label.agreementEvent": "[EN] Zapoznałem się z regulaminem wydarzenia i promocji znajdujący się ",
  "restaurantPage.input.label.agreementEvent.extension": "at this link",
  "restaurantPage.input.label.agreementMarketing": "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information about MojStolik's services and products available on the Customer Application, via electronic communication. You may withdraw this consent at any time.",
  "restaurantPage.input.label.agreementMarketingThirdPart": "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information to me about the services and products of the Restaurant and Cooperating Entities indicated on mojstolik.co.uk, available in the Customer Application, via electronic communication. You may withdraw this consent at any time.",
  "restaurantPage.input.label.code": "Enter the SMS code from",
  "restaurantPage.input.label.date": "Date",
  "restaurantPage.input.label.description": "Comments regarding the reservation",
  "restaurantPage.input.label.email": "E-mail address",
  "restaurantPage.input.label.firstName": "First name",
  "restaurantPage.input.label.footer": "MojStolik uses a variety of electronic communication channels and conducts activities using email, telephone, SMS and MMS. The administrator of your personal data is Table4Rest spółka z ograniczoną odpowiedzialnością with its registered office in Warsaw, 02-656 Warsaw ul. Ksawerów 21, KRS 0000752110, NIP 5213842139. Your personal data will be processed for the purpose of directing marketing content to you in electronic communication channels. For more information on the processing of your personal data, including your rights, please see the Privacy Policy.",
  "restaurantPage.input.label.hour": "Hour",
  "restaurantPage.input.label.lastName": "Last name",
  "restaurantPage.input.label.marketingAgreement3thPart": "I want to receive promotions, special offers, competition offers or other marketing content from MojStolik, including tailored information to me about the services and products of the Restaurant and Cooperating Entities indicated on mojstolik.co.uk, available in the Customer Application, via electronic communication. You may withdraw this consent at any time.",
  "restaurantPage.input.label.personalData": "I declare that I am aware of and accept the provisions of the Terms and Conditions for the provision of services by MojStolik via the Customer Application. ",
  "restaurantPage.input.label.personalData.extension": "(Terms and Conditions)",
  "restaurantPage.input.label.persons": "Number of person",
  "restaurantPage.input.label.persons.optionExtend": "p.",
  "restaurantPage.input.label.phoneNumber": "Phone number",
  "restaurantPage.input.label.readWithdrawal": "[EN] Zapoznałem się z regulaminem Mojstolik.pl w szczególności z pouczeniem o prawie odstąpienia od umowy",
  "restaurantPage.input.label.readWithdrawal.extension": "Terms and Conditions",
  "restaurantPage.input.placeholder.email": "E.g. address@email.com",
  "restaurantPage.input.placeholder.firstName": "E.g. George",
  "restaurantPage.input.placeholder.lastName": "E.g. Jones",
  "restaurantPage.input.placeholder.phoneNumber": "E.g. 600 500 400",
  "restaurantPage.input.placeholder.typeIn": "Type in",
  "restaurantPage.messages.codeResent": "The SMS code has been resent.",
  "restaurantPage.paragraph.dataIsIncorrect": "The data is incorrect?",
  "restaurantPage.paragraph.doYouHaveAnAccount": "Do you already have an account or would like to register?",
  "restaurantPage.paragraph.dontWontUsePromotion": "I don't want to use the promotion",
  "restaurantPage.paragraph.noAvailableHours": "No hours available in these criteria.",
  "restaurantPage.paragraph.noAvailableHoursExtension": "Choose a different date or number of person.",
  "restaurantPage.paragraph.or": " or ",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Enter the code from the SMS we sent to your phone number to complete the table reservation process.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Enter the code from the message we sent to your email address to complete the table reservation process.",
  "restaurantPage.paragraph.sendCodeAgainText":
    "The code has been sent again via SMS. Didn't receive the code?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Authorize yourself via e-mail:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Send the code to the provided e-mail address.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "The code has been sent to the e-mail address:",
  "restaurantPage.paragraph.tableReserved": "A table was reserved",
  "restaurantPage.paragraph.tableReservedDescription": "Your table has been reserved at",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "on",
  "restaurantPage.paragraph.tableWaitingForApproval": "The booking is waiting for approval",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription": "Your booking for",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "on",
  "restaurantPreview.doReservation": "Reserve",
  "restaurantPromotionInfoModal.button.use": "Use",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Offer details",
  "room.firstRoomVisualization": "First please create the room visualization",
  "searchMapPage.button.list": "List",
  "searchMapPage.button.map": "Map",
  "searchMapPage.header": "Search results",
  "searchMapPage.map.button.refresh": "Refresh map",
  "searchMapPage.metadata.description": "Search results map description",
  "searchMapPage.metadata.keywords": "Search results map keywords",
  "searchMapPage.metadata.title": "Search results map",
  "searchPage.button.list": "List",
  "searchPage.button.map": "Map",
  "searchPage.header": "Search results",
  "searchPage.metadata.description": "Search results description",
  "searchPage.metadata.keywords": "Search results keywords",
  "searchPage.metadata.title": "Search results",
  "searchPage.paragraph.noData": "No results for the given criteria.",
  "searchResultItem.component.button.book": "Book now",
  "searchResultItem.component.button.cancel": "Cancel",
  "searchResultItem.component.button.see": "See more",
  "searchResultItem.component.label.availableHours": "Available hours",
  "shortcut.friday": "Fr.",
  "shortcut.isClosed": "closed",
  "shortcut.monday": "Mo.",
  "shortcut.saturday": "Sa.",
  "shortcut.sunday": "Su.",
  "shortcut.thursday": "Th.",
  "shortcut.tuesday": "Tu.",
  "shortcut.wednesday": "We.",
  "sms.send.problem": "Problem with sending SMS. Please try again later or use mobile application",
  "social.appleloginBtn": " Sign in with Apple",
  "social.fbloginBtn": "Login with Facebook",
  "social.googleloginBtn": "Login with Google",
  "startPage.metadata.description": "Find the best restaurants in your city.",
  "startPage.metadata.keywords": "table reservation, restaurant search engine, restaurants",
  "startPage.metadata.title": "MojStolik.pl",
  "status.Canceled": "Rejected",
  "status.Confirmed": "Confirmed",
  "status.Rejected": "Rejected",
  "status.WaitingForConfirmation": "Waiting for confirmation",
  "token.expired": "Token expired",
  "token.wrong": "Wrong token provided",
  "user.acceptAgreement": "I accept the terms of service www.mojstolik.pl/en",
  "user.addNewAction": "Guest has been added",
  "user.cityAddress": "City",
  "user.dateOfBirth": "Date of birth",
  "user.editAction": "Guest has been updated",
  "user.email": "E-mail",
  "user.firstName": "First name",
  "user.lastName": "Last name",
  "user.name": "Name",
  "user.phone": "Phone",
  "user.postalCode": "Postal code",
  "user.sessionExpire": "Your session expired",
  "user.streetAddress": "Address",
  "user.successUpdate": "Personal data has been updated",
  "user.surname": "Surname",
  "user.updateQuestion": "Do you want to update your personal data?",
  "validation.fieldRequired": "This field is required",
  "validation.passwordRequired": "Password is required",
  "validation.phoneRequired": "Phone number is required"
};

export default backendTranslationsEN;