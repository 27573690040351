const routeConstants = {
  START: "/",
  FOR_RESTAURANTS: "/dla-restauracji",
  FOR_RESTAURANTS_ALLOFFERS: "/dla-restauracji-oferty",
  FOR_RESTAURANTS_FORM: "/dla-restauracji-formularz",
  FOR_RESTAURANTS_SUCCESS: "/dla-restauracji-success",
  BLOG: "/blog",
  COVID: "/odpowiedzialnarestauracja",
  COVID_CLIENT: "/odpowiedzialnigoscie",
  ARTICLE_BLOG: "/article/:title/:id",
  FOR_GUESTS: "/dla-goscia",
  HOW_IT_WORKS: "/jak-to-dziala",
  PRICE_LIST: "/cennik",
  CONTACT: "/kontakt",
  DOCUMENTS: "/dokumenty",
  ABOUT: "/o-nas",
  RESTAURANT_PROFILE: "/restauracja/:name/:uuid/",
  ACCOUNT: "/konto",
  LOGIN: "/logowanie",
  LOGOUT: "/wylogowanie",
  REGISTRATION: "/rejestracja",
  RESERVATION: "/reservation",
  COOKIES_POLICY: "/polityka/cookies",
  PRIVACY_POLICY: "/polityka/prywatnosci",
  SEARCH: "/wyniki-wyszukiwania",
  SEARCH_FILTERS:
    "/wyniki-wyszukiwania-old/osob,:persons/lokalizacja,:location/data,:date/godzina,:hour/nazwa,:name/typ,:restaurantType/ocena,:rating/zakres-cenowy,:priceLevel,:event/wydarzenie,:product/produkt",
  SEARCH_MAP: "/wyniki-wyszukiwania-mapa",
  SEARCH_MAP_FILTERS:
    "/wyniki-wyszukiwania-mapa-old/osob,:persons/lokalizacja,:location/data,:date/godzina,:hour/nazwa,:name/typ,:restaurantType/ocena,:rating/zakres-cenowy,:priceLevel",
  RESERVATION_HISTORY: "/historia-rezerwacji",
  YOUR_ACCOUNT: "/konto",
  RESET_PASSWORD: "/reset-hasla",
  CHRISTMAS: "/oferta-swiateczna/:id?",
  SURVEY: "/survey/:uuid",
  _EN: {
    START: "",
    BLOG: "/blog",
    FOR_RESTAURANTS: "/for-restaurants",
    FOR_RESTAURANTS_ALLOFFERS: "/for-restaurants-offers",
    FOR_RESTAURANTS_FORM: "/for-restaurants-form",
    FOR_RESTAURANTS_SUCCESS: "/for-restaurants-success",
    COVID: "/covid-for-restaurants",
    COVID_CLIENT: "/covid-for-guests",
    FOR_GUESTS: "/for-guests",
    HOW_IT_WORKS: "/how-it-works",
    PRICE_LIST: "/price-list",
    CONTACT: "/contact",
    DOCUMENTS: "/documents",
    ABOUT: "/about",
    RESTAURANT_PROFILE: "/restaurant/:name/:uuid/",
    ACCOUNT: "/account",
    LOGIN: "/login",
    LOGOUT: "/logout",
    REGISTRATION: "/registration",
    COOKIES_POLICY: "/cookies-policy",
    PRIVACY_POLICY: "/privacy-policy",
    SEARCH: "/search",
    SEARCH_FILTERS:
      "/search-old/persons,:persons/location,:location/date,:date/hour,:hour/name,:name/restaurant-type,:restaurantType/rating,:rating/price-level,:priceLevel,:event/wydarzenie,:product/produkt",
    SEARCH_MAP: "/search-map",
    SEARCH_MAP_FILTERS:
      "/search-map-old/persons,:persons/location,:location/date,:date/hour,:hour/name,:name/restaurant-type,:restaurantType/rating,:rating/price-level,:priceLevel",
    RESERVATION_HISTORY: "/reservations-history",
    YOUR_ACCOUNT: "/account",
    RESET_PASSWORD: "/password-reset",
    RESERVATION: "/reservation",
    SURVEY: "/survey/:uuid",
  },
};

export default routeConstants;
