import { configureStore } from "@reduxjs/toolkit";
import { config } from "reducers/config";
import offersReducer from "reducers/forRestaurantsPage/offersData";
import formReducer from "reducers/forRestaurantsPage/formData";
import registerReducer from "reducers/registerData";
import sessionStorage from "redux-persist/es/storage/session";
import reservLoginReducer from "reducers/reservLogin";
import { persistReducer, persistStore } from "redux-persist";
import thunk from "redux-thunk";

const persistConfig = {
  key: "root",
  storage: sessionStorage,
};

const offersPersistedReducer = persistReducer(persistConfig, offersReducer);

export const store = configureStore({
  reducer: {
    config: config,
    offers: offersPersistedReducer,
    form: formReducer,
    reservLogin: reservLoginReducer,
    register: registerReducer,
  },
  middleware: [thunk],
});

export const persistor = persistStore(store);

export const resetStore = async () => {
  await persistor.purge();
  store.dispatch(resetStore());
  await persistor.flush();
};
