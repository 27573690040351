export const config = (state = null, action) => {
  switch (action.type) {
    case "FETCH_CONFIG_SUCCESS":
      return {
        ...action.config,
      };
    default:
      return state;
  }
};

export const register = (state = null, action) => {
  switch (action.type) {
    case "FETCH_REGISTER_SUCCESS":
      return {
        ...action.register,
      };
    default:
      return state;
  }
};
