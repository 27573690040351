import React from "react";
import classnames from "classnames";
import {
  languageConstants,
  languageNameConstants,
} from "constants/languageConstants";

const MobileLanguageMenu = (props) => {
  return (
    <>
      {languageConstants.map((lang) => {
        return (
          <div
            className="languageItem"
            key={`mobileLanguageMenu__${lang}`}
            onClick={() => {
              props.hideAllMobileMenus();
              props.changeLanguage(lang);
            }}
          >
            <span
              className={classnames({
                anchor: true,
                active: props.currentLanguage === lang,
              })}
            >
              <span className={`languageIcon languageIcon--${lang}`}>
                {languageNameConstants[lang]}
              </span>
            </span>
          </div>
        );
      })}
    </>
  );
};

export default MobileLanguageMenu;
