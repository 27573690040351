const frontendTranslationsUK = {
  "seo.subpage.main.title": "Онлайн бронювання столиків - MojStolik.pl",
  "seo.subpage.main.description":
    "Замовляйте столик онлайн у найпопулярніших ресторанах Польщі. Завантажте додаток, оберіть місце, де ви хочете замовити столик, і забронюйте його. З MojStolik.pl це дуже просто.",
  "seo.subpage.forRestaurant.title": "Платформа для бронювання ресторанів - MojStolik.pl",
  "seo.subpage.forRestaurant.description":
    "MojStolik.pl - це платформа для управління бронюванням ресторанів. Всі бронювання в 1 місці. Додаткова платформа для комунікації з гостями ресторану.",
  "seo.subpage.aboutUs.title": "Надзвичайно простий додаток для тих, хто любить їсти поза домом.",
  "seo.subpage.aboutUs.description":
    "Сотні найпопулярніших і найповажніших ресторанів країни та тисячі користувачів, які користуються платформою MojStolik.co.uk. Нехай столик чекає на вас, а не навпаки.",
  "seo.subpage.howItWork.title":
    "Завантажуйте додаток MojStolik.co.uk на свій телефон та бронюйте столики",
  "seo.subpage.howItWork.description":
    "Лише кілька кліків - і столик у ресторані чекає на вас. Ви самі вирішуєте, коли, де і з ким ви хочете пообідати чи повечеряти. Завантажуйте додаток і бронюйте столики.",
  "seo.subpage.documents.title":
    "Дізнайтеся, які переваги ви отримаєте від співпраці з MojStolik.pl",
  "seo.subpage.documents.description":
    "Не знаєте, чи варто це робити? Ознайомтеся з основними моментами в документах MojStolik.co.uk. Договори, маркетингові пропозиції та переваги співпраці.",
  "seo.subpage.privacyPolicy.title": "MojStolik.pl як контролер персональних даних",
  "seo.subpage.privacyPolicy.description":
    "Прочитайте важливу інформацію та дізнайтеся більше про платформу MojStolik.pl",
  "seo.subpage.cookiesPolicy.title": "MojStolik.pl - відкрий для себе ресторани у своєму районі",
  "seo.subpage.cookiesPolicy.description":
    "MojStolik.co.uk використовує файли cookie. Відкрийте для себе найвідоміші ресторани у вашому регіоні разом з нами.",
  "seo.subpage.registration.title":
    "Зареєструйся та користуйся додатком вже сьогодні. MojStolik.pl",
  "seo.subpage.registration.description":
    "Забронювати столик ще ніколи не було так просто. Зручна оплата, будь-який час та кількість осіб. Зареєструйтеся та користуйтеся MojStolik.pl вже сьогодні.",
  "seo.subpage.login.title": "Заходьте в додаток і вибирайте ресторани з MojStolik.co.uk",
  "seo.subpage.login.description":
    "У нашому додатку ви можете знайти найпопулярніші ресторани по всій Польщі. Завантажте додаток, увійдіть до системи та скористайтеся надпростою системою бронювання столиків.",
  "seo.subpage.covid.restaurant.title": "COVID-19 - відповідальний ресторан",
  "seo.subpage.covid.restaurant.description":
    "Рішення для ресторанів, які допоможуть зберегти ваш бізнес у ці важкі часи!",
  "seo.subpage.covid.client.title": "COVID-19 - Відповідальне ставлення до відвідувачів",
  "seo.subpage.covid.client.description": "Посібник для відповідальних гостей ресторанів",
  "forRestaurantsPage.subheader":
    "Побачивши ваш ресторан у цій групі, гості знають, що вони можуть розраховувати на високу якість обслуговування.",
  "forRestaurantsPage.platform": "MOJSTOLIK.PL - ЦЕ",
  "forRestaurantsPage.1.strong": "Це кінець ери книги та олівця у вашому ресторані!",
  "forRestaurantsPage.2.strong":
    "це інноваційна відповідь на потреби все більш нетерплячого та цифрового суспільства,",
  "forRestaurantsPage.2.text":
    "Насолоджуйтесь розширеними можливостями планшетного додатку та інтерфейсу браузера. Забудьте про книгу бронювання і перейдіть до універсального рішення для будь-якого типу ресторану.",
  "forRestaurantsPage.3.strong": "це інтелектуальне управління всіма вашими бронюваннями,",
  "forRestaurantsPage.3.text":
    "Завдяки системі MOJSTOLIK.PL ви мінімізуєте ризик порожніх столів, за якими повинні сидіти гості:",
  "forRestaurantsPage.4.strong": "це збір інформації про ваших відвідувачів,",
  "forRestaurantsPage.4.text":
    "що дозволяє вам бути в постійному контакті з ними і дивувати їх своєю уважністю до їхніх потреб і звичок,",
  "forRestaurantsPage.5.strong": "це ваша професійна база даних,",
  "forRestaurantsPage.5.text": " і ми забезпечуємо його безпеку та відповідність вимогам RODO,",
  "forRestaurantsPage.6.strong": "це сучасний інструмент для контакту з вашими відвідувачами,",
  "forRestaurantsPage.6.text":
    ' підтверджувати візит і нагадувати про себе навіть під час "локдауну",',
  "forRestaurantsPage.7.strong": "є проявом вашої відповідальності під час COVID-19",
  "forRestaurantsPage.7.text": " ви контактуєте з відвідувачами та керуєте обмеженнями,",
  "forRestaurantsPage.8.strong": "організувати та впорядкувати роботу команди,",
  "forRestaurantsPage.9.strong": "можливість автоматичного телефонного бронювання столиків",
  "forRestaurantsPage.9.text":
    ' в ресторані в будь-який час дня і ночі, не залучаючи команду. З послугою IRV "ніхто не піде з порожніми руками" і нікому не доведеться чекати на відповідь,',
  "forRestaurantsPage.10.strong":
    "це система, розроблена у співпраці з деякими з найбільш відвідуваних ресторанів Польщі.",
  "forRestaurantsPage.10.text": " Ми слухаємо і задовольняємо ваші потреби,",
  "forRestaurantsPage.11.strong":
    "це постійно доступна служба підтримки в Польщі та щоденна маркетингова та PR-підтримка,",
  "forRestaurantsPage.12.strong": "це технологічна підготовка,",
  "forRestaurantsPage.13.strong": "це компанія найсвідоміших рестораторів Польщі,",
  "forRestaurantsPage.13.text": "які знають, як ефективно управляти своїм бізнесом,",
  "forRestaurantsPage.14.text": "набагато більше...",
  "forRestaurantsPage.form.title":
    "Якщо ви ще не визначилися і хочете дізнатися більше про платформу MojStolik.pl, заповніть, будь ласка, цю коротку форму, і ми зв'яжемося з вами:",
  "covid.restaurant.1.strong": "ПЛАН Б:.",
  "covid.restaurant.1.text":
    "Підготуйте вже зараз СВІЙ план дій на випадок збільшення кількості захворювань - подумайте, які маневри та альтернативи у вас є на випадок повторного введення різних ступенів обмежень, як ви реорганізуєте свою роботу, які процедури запровадите, як будете підтримувати контакт з гостями, управляти фінансовими ресурсами та планувати роботу своїх людей. Ви станете багатшими на свій весняний досвід. Не дивуйтеся вдруге.",
  "covid.restaurant.2.strong": "БЕЗПЕКА ТА ГІГІЄНА тепер є стандартом, але...:",
  "covid.restaurant.2.text":
    "Не залишайте цю тему без уваги. Переконайтеся, що всі рекомендовані процедури безпеки гостей і персоналу працюють бездоганно - маски на обличчях офіціантів, дезінфекція столів після кожного гостя, електронне меню як опція для гостей, процедури миття та дезінфекції рук, дотримання достатньої відстані між столами і максимально простий безготівковий розрахунок. Не бійтеся ввічливо проінструктувати гостей про те, як поводитися у вашому ресторані.",
  "covid.restaurant.3.strong": "БУДУВАТИ СВОЮ ГРОМАДУ:",
  "covid.restaurant.3.text":
    "Наші спостереження показують, що ті ресторани, які зуміли створити навколо себе спільноту і під час локдауну підтримували зв'язок зі своїми гостями через соціальні мережі або текстові повідомлення з додатку MojStolik.pl, набагато краще впоралися з новою реальністю, ніж ті, які не комунікували належним чином. Тож переконайтеся, що ви вже маєте постійний, цікавий і надійний контакт зі своїми гостями - подбайте про соціальні мережі, свій веб-сайт і подумайте, як ще ви зможете спілкуватися, коли це буде потрібно. На щастя, уряд під час травневих робіт скасував обов'язок для кожного ресторану мати базу даних своїх гостей, але як відповідальний ресторатор ви повинні створити таку базу. Тут дуже корисно використовувати платформу MojStolik.pl - вона максимально спрощує збір і зберігання даних (ім'я, електронна пошта, телефон) ваших гостей відповідно до RODO. Дані належать ресторану.",
  "covid.restaurant.4.strong": "ПОТРІБНЕ БРОНЮВАННЯ?",
  "covid.restaurant.4.text":
    "У багатьох країнах Європи та світу побутує думка, що до ресторану можна піти лише тоді, коли ви заздалегідь забронювали столик. Тож будьте готові до такого розвитку подій і подбайте про технології.",
  "covid.restaurant.5.strong": "ПОСТАРАЙТЕСЯ, ЩОБ ВАШІ ГОСТІ ЗНАЛИ ПРО",
  "covid.restaurant.5.text":
    "Через соціальні мережі, а також інші канали повідомте, чому важливо дотримуватися правил безпеки в ресторані та які процедури ви запровадили у вашому закладі, щоб мінімізувати ризик інфікування. Також повідомте їм, що певна їхня поведінка може призвести до збитків, які ви не можете собі дозволити в цей складний час. В основному це стосується невикористаних бронювань, через які ви втрачаєте час і гроші. Деякі люди просто не усвідомлюють, наскільки це велика проблема для рестораторів. З платформою Mojstolik.co.uk процес скасування бронювання стає дуже простим для гостей і повністю автоматичним з боку ресторану.",
  "covid.restaurant.6.strong": "ШУКАТИ ЗАОЩАДЖЕННЯ:",
  "covid.restaurant.6.text":
    "Особливо сьогодні, кожен гість - це скарб, і не дозволяйте йому чекати, поки зайнятий офіціант забере його замовлення, який з обмеженим штатом має набагато більше обов'язків і може не мати часу підбігти до телефону. Наші підрахунки показують, що система бронювання економить понад 20 годин роботи офіціанта або адміністратора на місяць, оскільки близько 50% замовлень автоматично переходять з телефону в інтернет і збільшують навантаження на вашу команду. Чиста економія!",
  "covid.restaurant.7.strong": "ОПТИМІЗАЦІЯ ВИКОРИСТАННЯ ПРИМІЩЕНЬ:",
  "covid.restaurant.7.text":
    "В умовах зниження заповнюваності ресторанів оптимізація управління бронюванням і завантаженням столиків має вирішальне значення для бізнесу. Ознайомтеся з технологічними рішеннями під ключ, які полегшать контроль над замовленнями, що надходять з усіх каналів комунікації: веб-сайт, додаток, соціальні мережі, телефон, візит до ресторану. Таким рішенням є платформа MojStolik.pl.",
  "covid.restaurant.8.strong": 'МІНІМІЗАЦІЯ ЕФЕКТУ "НЕ ПОКАЗУ":',
  "covid.restaurant.8.text":
    "Бронювання, яке не скасовано, але гість не з'являється, є прокляттям для рестораторів. У той час, коли заповнюваність закладів дозволена лише на 55-75%, така поведінка гостей має реальні фінансові наслідки. Тому намагайтеся мінімізувати ризик таких випадків. MojStolik.pl надсилає гостям SMS-нагадування про бронювання. Скасування - це просто питання натискання на посилання. Використовуючи це рішення, ви збільшуєте можливість повного використання потенціалу вашого ресторану і уникаєте втрат. У Лондоні постачальники послуг громадського харчування збунтувалися, тому що зараз не час втрачати можливість заробітку.",
  "covid.restaurant.9.strong": "ЗАХИСТИТИ МЕНЮ:",
  "covid.restaurant.9.text":
    "Рекомендується, щоб меню в ресторанах були одноразовими і викидалися щоразу, коли вони потрапляють до чиїхось рук. Такі обмеження запроваджені як обов'язкові, наприклад, у деяких штатах США. Це не економічно і не екологічно. Так чому б не розглянути варіант представлення свого меню на смартфоні гостя? Користувачі MojStolik.pl вже мають таку можливість. Офіціант отримує можливість запропонувати меню гостям, які завантажили додаток і таким чином ознайомилися з ним. Також можна відправити SMS з посиланням перед візитом з додатку і попросити гостей вже зараз потрапити в настрій вечора, вибираючи страви.☺ Додаток також можна завантажити за столом за допомогою QR-коду. Швидкий, сучасний та креативний варіант для деяких гостей. Його варто запропонувати.",
  "covid.restaurant.10.strong": "ПЕРЕОБЛАДНАТИ КУХНЮ:",
  "covid.restaurant.10.text":
    "Меню повинно бути досить коротким і здебільшого базуватися на сезонних, місцевих або просто легкодоступних продуктах. Інші варіанти є ризикованими, якщо тільки ви не налаштовані на додаткове меню, яке можна легко замінити. Якщо виникнуть проблеми і нові обмеження, такі як далекі подорожі або закриті кордони, у вас не буде проблем з постачанням і отриманням продуктів для шеф-кухаря. Переобладнувати кухню з дня на день буде набагато простіше. Витрати також не є незначними.",
  "covid.restaurant.11.strong": "ЗАВАНТАЖИТИ ВІДПОВІДАЛЬНИХ ПОСТАЧАЛЬНИКІВ:",
  "covid.restaurant.11.text":
    "Шукайте партнерів у бізнесі. Компанії, які допоможуть, зрозуміють і запропонують нові рішення. Шукайте постачальників якісних продуктів поблизу вашого бізнесу - покладатися на далекі поставки та важкодоступні продукти сьогодні надто ризиковано. Дізнатися, що компанії роблять для своїх ресторанів/партнерів, можна на сайті www.fortalks.pl.",
  "covid.restaurant.header": "ПОСІБНИК ДЛЯ ВІДПОВІДАЛЬНОГО РЕСТОРАНУ",
  "covid.restaurant.title": "ПОСІБНИК ДЛЯ ВІДПОВІДАЛЬНОГО РЕСТОРАНУ",
  "covid.restaurant.text1":
    "Ми всі знаємо, що ресторани в Польщі борються за виживання, тому вони здебільшого мають високі стандарти роботи, гігієни та спілкування з гостями. Давайте зробимо ще один крок вперед. Платформа MojStolik.pl та команда FOR Solutions розробили систему та пакет готових рішень на випадок нових обмежень та можливої другої хвилі пандемії. Мета посібника - допомогти ресторанам вижити в бізнесі, а також максимально знизити небезпеку зараження коронавірусом.",
  "covid.restaurant.text2":
    "Перша хвиля коронавірусу та локдаун, що послідував за нею, стали справжнім шоком для ринку громадського харчування. Знадобився певний час, щоб усім оговтатися і перейти до роботи в новій реальності. Зараз ми вже звикли до ситуації, збагатилися знаннями та досвідом, як діяти в кризових ситуаціях. Однак у власних інтересах та інтересах своїх гостей ресторани разом з гостями повинні зробити все можливе, щоб запобігти поширенню вірусу. Ми підбили підсумки того, як підготувати ваш ресторан до роботи в умовах зростаючої кількості інфекцій:",
  "covid.restaurant.welcome1": "Drogi Restauratorze",
  "covid.restaurant.welcome2":
    "Ось 11 рішень, які допоможуть зберегти ваш бізнес у ці складні часи!",
  "covid.restaurant.summary":
    "Сподіваємося, що ця добірка порад допоможе адаптувати ваш ресторан до нових реалій і підготуватися до будь-яких труднощів. Якщо у вас виникнуть додаткові запитання, ми будемо раді допомогти. Якщо ви хочете отримати більше інформації про співпрацю з нами, заповніть, будь ласка, форму. Ми передзвонимо вам або напишемо.",
  "covid.restaurant.greetings": "Ми хочемо допомогти, тому об'єднуємо зусилля!",
  "covid.restaurant.team": "Команда MojStolik.co.uk та FOR Solutions.",
  "covid.client.page.title": "ПУТІВНИК ДЛЯ ВІДПОВІДАЛЬНИХ ВІДВІДУВАЧІВ",
  "covid.client.dear.guest": "Шановний гостю",
  "covid.client.description":
    " Жоден ресторан не може існувати без вас. Це все для вас. Якщо ви не будете підтримувати гастрономічний бізнес, він зникне з вулиць наших міст. І погодьтеся, світ без барів і ресторанів був би страшенно сумним і порожнім, адже посиденьки за столом об'єднують, розважають і розвивають наше соціальне життя.",
  "covid.client.description2":
    "Тому ми закликаємо - підтримуйте улюблені ресторани, відвідуйте їх, але робіть це з головою. В епоху коронавірусу наша колективна соціальна відповідальність неймовірно важлива. Також зверніть увагу на деякі ваші вчинки, які завдають реальної шкоди ресторанам. Ми наводимо приклади, щоб допомогти ресторанам працювати ефективніше. І, нарешті, пам'ятайте про всі правила безпеки, щоб допомогти ресторанам підтримувати високі стандарти роботи, гігієни та організації. Вони дбають про це! Подбайте і про себе!",
  "covid.client.description3":
    "Тож як відповідально проводити час в улюблених ресторанах? MojStolik.co.uk та FOR Solutions підготували для вас кілька порад:",
  "covid.client.summary":
    "Дотримуючись цих простих правил, ви забезпечите безпеку собі та команді вашого улюбленого ресторану, а перебуваючи там і насолоджуючись їхніми смачними стравами, ви реально підтримаєте їх і допоможете їм пережити важкий час. Картина наших міст без ресторанів була б трагічною....",
  "covid.client.1.strong": "ОСНОВНІ ПРИНЦИПИ ГІГІЄНИ -",
  "covid.client.1.text":
    "Тобто, коли ви заходите до закладу, одягайте маску і знімайте її, тільки коли сідаєте за стіл, дезінфікуйте руки перед входом (ресторани зобов'язані мати дозатор з рідиною на вході). Коли ви йдете в туалет, знову закривайте рот і ніс маскою. Ці пункти дійсно є ключовими для зниження ризику зараження COVID-19.",
  "covid.client.2.strong": "СОЦІАЛЬНА ДИСТАНЦІЯ -",
  "covid.client.2.text":
    "Ми знаємо, що тримати правильну дистанцію незручно і ми часто забуваємо про це, але достатньо простих правил: не заважати команді і не змінювати положення столів без дозволу офіціанта або менеджера. Вони встановлені для забезпечення вашої безпеки.",
  "covid.client.3.strong": "ВИБІР СТОЛУ -",
  "covid.client.3.text":
    "коли ви заходите до ресторану (в масці!), зачекайте, поки офіціант проведе вас до потрібного столика. Це полегшить його роботу і допоможе вам безпечно розсадити гостей. Якщо ви можете вибрати місце самостійно, сідайте лише за столики, позначені як дезінфіковані.",
  "covid.client.4.strong": "БРОНЮВАННЯ -",
  "covid.client.4.text":
    "якщо ви знаєте, що сьогодні ввечері йдете в ресторан, наприклад, забронюйте столик заздалегідь. Це полегшує працівникам ресторану планування розсаджування ваших гостей, а також планування забезпечення та роботи персоналу. Крім того, це максимально скорочує час вашого контакту з персоналом, що приносить користь як вам, так і йому. Ви можете легко і швидко забронювати столик в обраному вами ресторані за допомогою додатку MojStolik.co.uk. Навіщо чекати на столик, якщо він може почекати на вас?",
  "covid.client.5.strong": "СКАСУЙТЕ БРОНЮВАННЯ, ЯКЩО НЕ МОЖЕТЕ ПРИЙТИ!",
  "covid.client.5.text":
    "Не з'явитися в ресторані, незважаючи на те, що ви забронювали столик, - це не тільки, м'яко кажучи, неввічливо, але й наражає ресторатора на реальні збитки. Він не може нікому пересадити вас за столик, тому що, зрештою, він заброньований. Він врахував вас і ваших товаришів по вечері при плануванні резервації. Така ситуація є для нього реальною фінансовою втратою, а зараз, коли відвідуваність ресторану все одно зменшується, це особливо гостро відчувається. Платформа MojStolik.pl полегшить цю ситуацію і для вас - вона не тільки нагадає вам текстовим повідомленням, о котрій годині і де на вас чекає шеф-кухар, але також максимально спростить вам скасування бронювання в разі необхідності. Все на відстані одного кліку!",
  "covid.client.6.strong": "БЕЗКОНТАКТНЕ МЕНЮ -",
  "covid.client.6.text":
    "якщо у вас є додаток для бронювання столиків на вашому смартфоні, він також дозволить вам переглянути меню без необхідності брати ресторанний папір в руки. Якщо у вас немає додатку, завантажте його за допомогою спеціальних QR-кодів, які є на столиках.",
  "covid.client.7.strong": "ОПЛАЧУВАТИ БЕЗКОНТАКТНО -",
  "covid.client.7.text":
    "уникайте прямого контакту та оплати готівкою. Сплачуйте кредитною карткою, блістером або телефоном.",
  "covid.client.8.strong": "НЕ КРИТИКУЙТЕ -",
  "covid.client.8.text":
    "це все ще дуже складний час для індустрії, яка бореться за виживання практично щодня. Тож утримайтеся від публічної критики і зосередьтеся на позитивних емоціях від застілля. Хороші емоції зараз дуже потрібні всім нам, а особливо ресторанам, які намагаються вижити вдвічі важче.",
  "covid.client.9.strong": "ПІДТРИМКА -.",
  "covid.client.9.text":
    "якщо ви залишилися задоволені своїм візитом, обов'язково розкажіть про нього у своїх соціальних мережах, зробіть INSTA STORIES і отримуйте задоволення від розхвалювання страв та залучення інших гостей до вашого улюбленого ресторану!",
  "forRestaurantsPage.global.perMonth": "злотих/місяць",
  "forRestaurantsPage.global.perMonth.short": "зл/міс.",
  "forRestaurantsPage.global.button.choose": "ВИБІР",
  "forRestaurantsPage.global.button.next": "НАСТУПНИЙ",
  "forRestaurantsPage.global.button.back": "ПОВЕРНЕННЯ",
  "forRestaurantsPage.global.button.accept": "ПРИЙНЯТО",
  "forRestaurantsPage.global.button.discover": "ПЕРЕГЛЯНУТИ ДОСТУПНІ ТАРИФНІ ПЛАНИ",
  "forRestaurantsPage.global.button.send": "ВІДПРАВИТИ",
  "forRestaurantsPage.global.button.learnMore": "Читати далі",
  "forRestaurantsPage.apiError": "Технічні проблеми. Будь ласка, спробуйте пізніше.",
  "forRestaurantsPage.1.header": "MOJSTOLIK.CO.UK - ЦЕ БІЛЬШЕ, НІЖ СИСТЕМА",
  "forRestaurantsPage.1.subsection.1.header": "ПОРТАЛ ТА ДОДАТОК ДЛЯ ВІДВІДУВАЧІВ",
  "forRestaurantsPage.1.subsection.1.text":
    "Проста і зручна платформа та додаток, що дозволяє миттєво забронювати столик у вашому ресторані.",
  "forRestaurantsPage.1.subsection.2.header": "ТЕЛЕФОННА СИСТЕМА (IVR)",
  "forRestaurantsPage.1.subsection.2.text":
    "Телефонна система (IVR) прийматиме замовлення замість того, щоб ваша команда могла присвятити себе обслуговуванню гостей у ресторані.",
  "forRestaurantsPage.1.subsection.3.header": "ПОРТАЛ ТА ДОДАТОК ДЛЯ РЕСТОРАНІВ",
  "forRestaurantsPage.1.subsection.3.text":
    "Керуйте бронюваннями та столиками автоматично або вручну з планшетного додатку або порталу ресторану.",
  "forRestaurantsPage.2.header": "КЕРУВАТИ ЧЕРЕЗ ДОДАТОК АБО БРАУЗЕР",
  "forRestaurantsPage.3.header": "ЗАБУДЬТЕ ПРО НЕЯВКУ",
  "forRestaurantsPage.3.listItem.1": "SMS-підтвердження та нагадування про бронювання",
  "forRestaurantsPage.3.listItem.2": "Швидкі та прості варіанти скасування",
  "forRestaurantsPage.3.listItem.3": "Аналіз історичної активності відвідувачів",
  "forRestaurantsPage.4.header": "ЕФЕКТИВНО СПРЯМОВУВАТИ ТРАФІК ДО РЕСТОРАНУ",
  "forRestaurantsPage.5.header": "ЩО ПРО НАС ГОВОРЯТЬ РЕСТОРАТОРИ?",
  "forRestaurantsPage.5.opinion.1.title": "Маріуш Дяковський, власник ресторану Old House:",
  "forRestaurantsPage.5.opinion.1.text":
    'Співпраця з MojStolik.co.uk стала справжньою революцією для "Старого дому". Ні я, ні команда ресторану більше не можемо собі уявити повернення до епохи "книги та олівця". Насправді, MojStolik.pl тестував свою платформу на нашому ресторані. У нас були деякі побоювання, але ми вирішили ризикнути. І абсолютно про це не шкодуємо! Всі наші зауваження та пропозиції були реалізовані відразу. Завдяки тестуванню на "живому організмі" ця система дійсно відповідає потребам ресторанів з великою відвідуваністю, неймовірно полегшує роботу і скорочує час. Більше ніяких ручок, олівців, коректорів, які склеюють сторінки, нерозбірливих почерків чи непрактичних журналів. Тепер все організовано і зручно у використанні. Я також додам, що платформа практично бездоганна і, якщо потрібно, служба підтримки працює бездоганно.',
  "forRestaurantsPage.5.opinion.2.title": "Марек Катрич, операційний директор STIXX Bar&Grill:",
  "forRestaurantsPage.5.opinion.2.text":
    "MojStolik.pl - це дуже сучасна та інноваційна система, завдяки якій ми, як великий ресторан, можемо ефективно управляти бронюванням і розміщенням наших гостей, що значно спрощує роботу нашої команди. Ми однозначно рекомендуємо цю систему, ми постійно вдосконалюємо її функціональність, вона інтуїтивно зрозуміла і дуже проста у використанні, вона скоротила час, який ми витрачаємо на бронювання і пошук гостей, що особливо корисно у випадку високої заповнюваності.",
  "forRestaurantsPage.5.opinion.3.title": "Роберт Голуба, бренд-менеджер Bombaj Masala:",
  "forRestaurantsPage.5.opinion.3.text":
    'Чому ми вирішили використовувати систему MojStolik.co.uk в наших ресторанах? Хороші відгуки в індустрії плюс дійсно приємний і професійний підхід до нового клієнта. Система дуже інтуїтивно зрозуміла, але знадобилося багато часу на навчання персоналу, поки кожен співробітник ресторану не навчився користуватися панеллю, тому проблем з впровадженням "Мій столик" в ресторані не виникло. Це пройшло без жодних проблем, що, мабуть, нечувано для нового програмного забезпечення. Першого дня з великою кількістю гостей і замовлень в нашому ресторані було достатньо, щоб переконатися в перевагах рішень в системі MojStolik.pl. Я впевнений, що це дозволило нам (і продовжує це робити) приймати гостей більш гладко і, отже, обслуговувати більше гостей. Це, природно, позначається на кінцевому результаті. Якби через кілька тижнів хтось наказав мені повернутися до роботи з традиційною книгою бронювання, я б відчув себе... у 20-му столітті.',
  "forRestaurantsPage.6.header": "ОЗНАЙОМИТИСЯ З ПЛАНАМИ MOJSTOLIK.PL",
  "forRestaurantsPage.6.subheader":
    "Обирайте план для свого ресторану та починайте працювати з нами.",
  "forRestaurantsPage.discountCodeInput": "Введіть промокод",
  "forRestaurantsPage.discountCodeButton": "Використання",
  "forRestaurantsPage.discountCodeLoading": "Йде зарядка...",
  "forRestaurantsPage.discountCodeSuccess": "Промокод завантажено",
  "error.promotion.not.exists": "Вказаний промокод не існує",
  "forRestaurantsPage.allOffers": "Для детального порівняння тарифних планів MOJSTOLIK.PL",
  "forRestaurantsPage.allOffers.orange": "Іди сюди.",
  "forRestaurantsPage.offers.offerItem.1.header": "СВІТЛО",
  "forRestaurantsPage.offers.offerItem.1.text":
    "Для ресторанів, які цінують простоту автоматизації процесів збору замовлень в режимі реального часу.",
  "forRestaurantsPage.offers.offerItem.2.header": "РОЗШИРЕНИЙ",
  "forRestaurantsPage.offers.offerItem.2.text":
    "Для ресторанів, які хочуть керувати залами та столиками на додаток до самих бронювань.",
  "forRestaurantsPage.offers.offerItem.3.header": "РОЗШИРЕНИЙ",
  "forRestaurantsPage.offers.offerItem.3.header.orange": "PRO",
  "forRestaurantsPage.offers.offerItem.3.text":
    "Для великих ресторанів, де необхідна повна автоматизація всіх каналів комунікації з гостями.",
  "forRestaurantsPage.offers.subscription": "Абонемент",
  "forRestaurantsPage.offers.commission": "Комісія",
  "forRestaurantsPage.offers.commission.currency": "zł",
  "forRestaurantsPage.offers.header": "Додаткові опції",
  "forRestaurantsPage.offers.additional-options.prepayments": "Модуль передоплати за бронювання",
  "forRestaurantsPage.offers.additional-options.ivr":
    "Послуга автоматичного телефонного бронювання (IVR) з додатковими 300 SMS/місяць",
  "forRestaurantsPage.offers.additional-options.smsPackage":
    "Додатковий пакет SMS - 1000 шт/місяць",
  "forRestaurantsPage.offers.mostPopular": "Найпоширеніший вибір",
  "forRestaurantsPage.offers.pricingSetting1":
    "Вищевказані ціни застосовуються для річного розрахункового періоду. Перейдіть на щомісячну тарифікацію:",
  "forRestaurantsPage.offers.pricingSetting2":
    "Вищевказані ціни застосовуються для місячного розрахункового періоду. Перейдіть на річну тарифікацію:",
  "forRestaurantsPage.offers.bulletPoint.1":
    "1 Усі ціни в пропозиції вказані в злотих нетто, до них додається встановлений законом ПДВ за ставкою, чинною на день виставлення рахунку.",
  "forRestaurantsPage.offers.bulletPoint.2":
    "2 У випадку річної моделі оплати представлена щомісячна плата, тоді як платіж є одноразовим за 12 місяців наперед.",
  "forRestaurantsPage.offers.bulletPoint.3":
    "3 Маркетингова знижка надається у розмірі 50 злотих за вирахуванням щомісячної плати, за умови, що ресторан ділиться посиланням на бронювання через MOJSTOLIK.PL у соціальних мережах ресторану щонайменше 2 рази на місяць.",
  "forRestaurantsPage.offers.bulletPoint.4":
    "4 Вартість SMS поза підпискою становить 0,09 злотих нетто.",
  "forRestaurantsPage.offers.bulletPoint.5":
    "5 При використанні Модуля передоплати платіжний оператор стягує комісію в розмірі 1,59% від суми транзакції за кожну транзакцію.",
  "forRestaurantsPageAllOffers.header": "Ознайомтеся з планами MOJSTOLIK.PL",
  "forRestaurantsPageAllOffers.subheader":
    "Обирайте план для свого ресторану та починайте працювати з нами.",
  "forRestaurantsPageAllOffers.offer.1": "СВІТЛО",
  "forRestaurantsPageAllOffers.offer.2": "РОЗШИРЕНИЙ",
  "forRestaurantsPageAllOffers.offer.3": "РОЗШИРЕНИЙ",
  "forRestaurantsPageAllOffers.offer.3.orange": "PRO",
  "forRestaurantsPageAllOffers.listHeader.1": "Доступні клієнту канали бронювання",
  "forRestaurantsPageAllOffers.listHeader.2": "Керування бронюванням ресторанів",
  "forRestaurantsPageAllOffers.listHeader.3": "Конфігурація профілю ресторану",
  "forRestaurantsPageAllOffers.listHeader.4": "Доступність ресторанів від об'єктів",
  "forRestaurantsPageAllOffers.listHeader.5": "Додаткові послуги",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.1": "Бронювання на сайті www.mojstolik.pl",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.2":
    "Бронювання зі спеціального мобільного додатку MojStolik",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.3": "Бронювання на сайті ресторану",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.4": "Бронювання за допомогою Google Maps",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.5": "Бронювання з Facebook",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.6":
    "Підтвердження/нагадування про бронювання через SMS/сповіщення у додатку**",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.7": "Автоматична обробка телефонних замовлень",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.8": "Бронювання з інстаграму ресторану",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.9":
    "Бронювання з електронної скриньки (автовідповідач)",
  "forRestaurantsPageAllOffers.listHeader.1.listItem.10":
    "Посилання / QR-коди в статтях та PR-матеріалах ресторанів",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.1": "Список бронювання - табличний вигляд",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.2": "Бронювання на мапі залу",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.3": "Діаграма Ганта (графік бронювання)",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.4": "Деталі бронювання та редагування",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.5": "Розширене управління бронюванням",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.6": "Щоденний звіт про бронювання",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.7": "Обробка передоплати за бронювання",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.8": "Обслуговування на дому",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.9":
    "Історія бронювання / аналіз вподобань та поведінки гостей",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.10": "Мінімізація ефекту неявки",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.11": "Операція в реальному часі",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.12":
    "База даних клієнтів ресторану / відповідність RODO",
  "forRestaurantsPageAllOffers.listHeader.2.listItem.13":
    "БЕЗПЕКА ДАННИХ / Зашифрована система та різні рівні доступу на основі ролей",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.1":
    "Візитна картка та індивідуальний профіль ресторану на клієнтському порталі та мобільному додатку",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.2": "Описова інформація про ресторан",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.3": "Графічні матеріали",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.4": "Управління годинами доступності",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.5": "Перерви та спеціальні дні",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.6": "Параметризація прийняття бронювання",
  "forRestaurantsPageAllOffers.listHeader.3.listItem.7": "Конфігурація приміщення та столів",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.1": "Управлінський портал для ресторанів",
  "forRestaurantsPageAllOffers.listHeader.4.listItem.2": "Ресторанний додаток для планшета",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.1": "Довідкова служба та технічна підтримка",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.2":
    "Тренінг з впровадження для персоналу ресторану",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.3":
    "Присутність / просування ресторанів у соціальних мережах MojStolik.pl",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4":
    "Кількість SMS-повідомлень, доступних у підписці",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.light": "100 SMS/міс.",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advanced": "300 SMS/міс.",
  "forRestaurantsPageAllOffers.listHeader.5.listItem.4.advancedPro": "600 SMS/міс.",
  "forRestaurantsPageAllOffers.listItem.optional": "Опція",
  "forRestaurantsPageForm.downloadButton": "ЗАВАНТАЖИТИ ДАНІ",
  "forRestaurantsPageForm.header": "Тут ви починаєте працювати з mojstolik.co.uk",
  "forRestaurantPageForm.apiError":
    "Помилка при завантаженні даних заявки. Будь ласка, повторіть спробу пізніше і приносимо вибачення за незручності",
  "forRestaurantPageForm.paramsError":
    "Вибачте, деталі пропозиції не вдалося відобразити. Щоб переглянути наші пропозиції, перейдіть за посиланням",
  "forRestaurantPageForm.paramsError.link": "сторінки пропозицій",
  "forRestaurantsPageForm.chosenPlan": "Обраний тарифний план MOJSTOLIK.PL",
  "forRestaurantsPageForm.discountCode": "Підвищення:",
  "forRestaurantsPageForm.payment.yearly":
    "Виставлення рахунків за обраним планом здійснюється на щорічній основі.",
  "forRestaurantsPageForm.payment.monthly":
    "Виставлення рахунків за обраним тарифним планом відбувається щомісяця.",
  "forRestaurantsPageForm.additionalOptions": "Вибрані додаткові опції:",
  "forRestaurantsPageForm.formHeader.1": "Надайте основну інформацію про ресторан",
  "forRestaurantsPageForm.formHeader.2": "Введіть дані контактної особи",
  "forRestaurantsPageForm.formHeader.3": "Надайте детальну інформацію про Угоду",
  "forRestaurantsPageForm.formHeader.4": "Введіть дані особи, яка представляє організацію",
  "forRestaurantsPageForm.formHeader.5": "Договір MOJSTOLIK.PL",
  "forRestaurantsPageForm.restaurantName": "Назва ресторану",
  "forRestaurantsPageForm.street": "Вулиця",
  "forRestaurantsPageForm.apartment": "Місцевий",
  "forRestaurantsPageForm.postalCode": "Поштовий індекс",
  "forRestaurantsPageForm.city": "Місто",
  "forRestaurantsPageForm.email": "Адреса електронної пошти",
  "forRestaurantsPageForm.phoneNumber": "Номер телефону",
  "forRestaurantsPageForm.website": "Адреса веб-сайту",
  "forRestaurantsPageForm.website.menu": "Адреса сайту з меню ресторану",
  "forRestaurantsPageForm.name": "Ім'я",
  "forRestaurantsPageForm.surname": "Ім'я",
  "forRestaurantsPageForm.title": "Назва",
  "forRestaurantsPageForm.entity": "Назва організації",
  "forRestaurantsPageForm.email.invoice": "Адреса електронної пошти для виставлення рахунку",
  "forRestaurantsPageForm.email.contact": "Контактна адреса електронної пошти",
  "forRestaurantsPageForm.proxy": "Тип довіреності",
  "forRestaurantsPageForm.select.owner": "Власник",
  "forRestaurantsPageForm.select.manager": "Менеджер",
  "forRestaurantsPageForm.select.boardMember": "Член Правління",
  "forRestaurantsPageForm.select.other": "Інше",
  "forRestaurantsPageForm.contract": "Зміст договору",
  "forRestaurantsPageForm.pdfLink": "Завантажити pdf",
  "forRestaurantsPageForm.statement.1":
    "Я даю згоду на обробку моїх персональних даних компанією Table4Rest sp. z o.o. з метою укладення договору про надання послуг з надання та використання додатку MojStolik.pl.",
  "forRestaurantsPageForm.statement.2":
    "Я приймаю умови вищезазначеного договору та правила і положення MOJSTOLIK.PL і заявляю, що я уповноважений укласти договір про надання послуг з надання та використання додатку MojStolik.pl від імені ресторану.",
  "forRestaurantsPageForm.loading": "Ваша анкета відправлена...",
  "forRestaurantsPageForm.success": "Відправлення форми успішно завершено",
  "forRestaurantsPageForm.nipError": "Введіть правильний номер ПДВ",
  "forRestaurantsPageForm.loadingData": "Завантаження даних триває...",
  "forRestaurantsPageForm.error": "Виникла помилка.",
  "forRestaurantsPageForm.dataSuccess": "Дані завантажено правильно",
  "forRestaurantsPageForm.generatingContract": "Триває генерація контрактів...",
  "forRestaurantsPageSuccess.header": "Дякуємо за вибір Mojstolik.pl",
  "forRestaurantsPageSuccess.text.1":
    "ЧЕРЕЗ ДЕЯКИЙ ЧАС НА ЕЛЕКТРОННУ АДРЕСУ, ЯКУ ВИ ВКАЗАЛИ НА ПОПЕРЕДНЬОМУ КРОЦІ, БУДЕ НАДІСЛАНО КОНТРАКТНИЙ ДОКУМЕНТ.",
  "forRestaurantsPageSuccess.text.2":
    "Ми також підготуємо та надамо вам необхідні інструкції, щоб ви могли налаштувати свій ресторан у системі та розпочати спільну роботу.",
  "forRestaurantsPageSuccess.text.3": "Побачимося там!",
  "forRestaurantsPageSuccess.button": "Повернутися на головну сторінку",
  "global.messages.sessionTimeout": "Сесія закінчилася",
  "global.form.message.requiredField": "Це поле обов'язкове для заповнення",
  "global.form.message.vatIDNumber": "ІПН повинен містити 10 цифр",
  "global.form.message.invalidEmailFormat": "Неправильний формат адреси електронної пошти",
  "global.form.message.invalidPhoneFormat": "Неправильний формат номера телефону",
  "global.form.message.invalidBirthDateFormat": "Неправильний формат дати народження",
  "global.form.message.invalidPostCodeFormat":
    "Неправильний формат поштового індексу. Очікуваний XX-XXX",
  "global.form.message.invalidNipFormat": "Неправильний формат номера ПДВ",
  "global.form.message.invalidKrsFormat": "Неправильний формат номера KRS",
  "global.form.message.invalidRegonFormat": "Неправильний формат номера REGON",
  "global.form.message.invalidUrlFormat": "Неправильний формат веб-адреси",
  "global.form.message.newPasswordsDoNotMatch": "Нові слогани відрізняються!",
  "global.form.message.atLeastNineCharacters": "Потрібно щонайменше дев'ять символів",
  "global.time.minutes": "хвилину.",
  "aboutPage.metadata.title": "O в",
  "aboutPage.metadata.description": "Варіанти",
  "aboutPage.metadata.keywords": "про нас ключові слова",
  "accountPage.metadata.title": "Ваш рахунок",
  "accountPage.metadata.description": "Опис вашого облікового запису",
  "accountPage.metadata.keywords": "Ключові слова вашого облікового запису",
  "contactPage.metadata.title": "Зв'яжіться з нами",
  "contactPage.metadata.description": "Контактний опіс",
  "contactPage.metadata.keywords": "Ключові слова для контактів",
  "documentsPage.metadata.title": "Документалістика",
  "documentsPage.metadata.description": "Опис документів",
  "documentsPage.metadata.keywords": "Ключові документи",
  "forGuestPage.metadata.title": "Для гостя",
  "forGuestPage.metadata.description": "Для відвідувача опис",
  "forGuestPage.metadata.keywords": "Для відвідувача ключові слова",
  "howItWorksPage.metadata.title": "Як це працює?",
  "howItWorksPage.metadata.description": "Опис того, як це працює",
  "howItWorksPage.metadata.keywords": "Як працюють ключові слова",
  "loginPage.metadata.title": "Лісозаготівля",
  "loginPage.metadata.description": "Опис ведення журналу",
  "loginPage.metadata.keywords": "Ключові слова журналу",
  "resetPasswordPage.metadata.title": "Скинути пароль",
  "resetPasswordPage.metadata.description": "Опис скидання пароля",
  "resetPasswordPage.metadata.keywords": "Скинути пароль до ключового слова",
  "logoutPage.metadata.title": "Вийти з системи",
  "logoutPage.metadata.description": "Опис виходу з системи",
  "logoutPage.metadata.keywords": "Видалення ключових слів",
  "notFoundPage.metadata.title": "404 - Сторінку не знайдено!",
  "notFoundPage.metadata.description": "404 - Опис сторінки не знайдено",
  "priceListPage.metadata.title": "Прейскурант",
  "priceListPage.metadata.description": "Опис прайс-листа",
  "priceListPage.metadata.keywords": "Прайс-лист за ключовим словом",
  "registrationPage.metadata.title": "Реєстрація",
  "registrationPage.metadata.description": "Опис реєстрації",
  "registrationPage.metadata.keywords": "Реєстрація ключових слів",
  "reservationHistoryPage.metadata.title": "Ваші замовлення",
  "reservationHistoryPage.metadata.description": "Опис вашого бронювання",
  "reservationHistoryPage.metadata.keywords": "Ваші ключові слова для бронювання",
  "searchMapPage.metadata.title": "Результати пошуку",
  "searchMapPage.metadata.description": "Опис результатів пошуку",
  "searchMapPage.metadata.keywords": "Результати пошуку за ключовими словами",
  "searchPage.metadata.title": "Результати пошуку",
  "searchPage.metadata.description": "Опис результатів пошуку",
  "searchPage.metadata.keywords": "Результати пошуку за ключовими словами",
  "privacyPolicyPage.metadata.title": "Політика конфіденційності",
  "cookiesPolicyPage.metadata.title": "Політика щодо файлів cookie",
  "shortcut.isClosed": "закрито",
  "shortcut.monday": "Пн.",
  "shortcut.tuesday": "Вт.",
  "shortcut.wednesday": "Ср.",
  "shortcut.thursday": "Чт.",
  "shortcut.friday": "Пт.",
  "shortcut.saturday": "Сб.",
  "shortcut.sunday": "Нд.",
  "defaultValues.restaurant": "Ресторан",
  "restaurantPromotionInfoModal.button.use": "Використання",
  "restaurantPromotionInfoModal.paragraph.promotionDetails": "Деталі пропозиції",
  "previewImageModal.component.anchor.close": "Закрити",
  "previewRestaurantModal.component.anchor.close": "Закрити",
  "deleteAccountModal.component.header": "Видалити обліковий запис",
  "deleteAccountModal.component.paragraph.deleteAccountDescription":
    "Ви впевнені, що хочете видалити свій обліковий запис? Ваша історія бронювання також буде видалена.",
  "deleteAccountModal.component.button.confirm": "Схвалити",
  "deleteAccountModal.component.button.cancel": "Оголосити",
  "cancellationReservationModal.component.header": "Скасування бронювання",
  "cancellationReservationModal.component.paragraph.cancellationReservationDescription":
    "Ви дійсно хочете скасувати бронювання столика в ресторані {{restaurantName}} о {{reservationHour}} на {{reservationDate}}?",
  "cancellationReservationModal.component.paragraph.cancellationPaidReservationDescription":
    "Плата, пов'язана з бронюванням, була перерахована ресторану. Будь ласка, зв'яжіться безпосередньо з рестораном для можливого відшкодування.",
  "cancellationReservationModal.component.button.confirm": "Схвалити",
  "cancellationReservationModal.component.button.cancel": "Оголосити",
  "searchResultItem.component.label.availableHours": "Доступні години",
  "searchResultItem.component.label.futureReservationDaysError":
    "Дистанційне бронювання можливе на {{days}} днів наперед",
  "searchResultItem.component.label.noAvailableTables":
    "За вибраними параметрами пошуку (дата, час, кількість людей) цей ресторан не пропонує столики для бронювання.",
  "searchResultItem.component.button.book": "Замовити",
  "searchResultItem.component.button.cancel": "Оголосити",
  "searchResultItem.component.button.see": "Дивіться.",
  "passwordFormModal.component.header": "Введіть пароль до свого облікового запису",
  "passwordFormModal.component.paragraph.deleteAccountDescription":
    "Введіть пароль для підтвердження дії.",
  "passwordFormModal.component.button.confirm": "Схвалити",
  "passwordFormModal.component.button.cancel": "Оголосити",
  "cuisine.romania": "Румунська кухня",
  "cuisine.american": "Американська кухня",
  "cuisine.arabic": "Арабська кухня",
  "cuisine.chinese": "Китайська кухня",
  "cuisine.polish": "Польська кухня",
  "cusine.chinese": "Китайська кухня",
  "cusine.polish": "Польська кухня",
  "component.cookiesPolicyLine.text":
    "Цей сайт використовує файли cookie, щоб забезпечити вам найвищий рівень обслуговування. Ваше подальше використання сайту означає, що ви погоджуєтесь на їх використання.",
  "component.cookiesPolicyLine.privacyPolicyText": "Політика конфіденційності",
  "component.header.menu.mainPage": "Замовити",
  "component.header.menu.forRestaurants": "Для ресторанів",
  "component.header.menu.portal.restaurant": "Портал restauracji",
  "component.header.menu.forRestaurants.offer": "Наша пропозиція",
  "component.header.menu.blog": "Новини MOJSTOLIK.PL",
  "component.header.menu.christmas": "СВЯТКОВІ ПРОПОЗИЦІЇ",
  "component.header.menu.forGuest": "Для гостя",
  "component.header.menu.howItWorks": "Як це працює?",
  "component.header.menu.priceList": "Прейскурант",
  "component.header.menu.contact": "Зв'яжіться з нами",
  "component.header.menu.documents": "Документалістика",
  "component.header.menu.about": "O в",
  "component.header.menu.login": "Увійдіть в систему",
  "component.header.menu.logout": "Вийдіть з системи",
  "component.header.menu.reservationHistory": "Ваші замовлення",
  "component.header.menu.yourAccount": "Ваш рахунок",
  "component.header.menu.covid": "Посібники з COVID-19",
  "component.header.menu.covid.restaurant": "Путівник по ресторанах",
  "component.header.menu.covid.client": "Путівник для відвідувачів",
  "component.header.menuMobile.covid.restaurant": "Посібник з COVID-19 для ресторанів",
  "component.header.menuMobile.covid.client": "Посібник для відвідувачів щодо COVID-19",
  "component.header.menu.events": "Події",
  "component.header.menu.event": "Подія -",
  "component.footer.menu.header.forClients": "Про MOJSTOLIK.pl",
  "component.footer.menu.header.informations": "Інформація",
  "component.footer.menu.header.yourAccount": "Ваш рахунок",
  "component.footer.menu.header.socialMedia": "Соціальні мережі",
  "component.footer.menu.header.applicationDownload": "Завантажити додаток",
  "component.footer.menu.forRestaurants": "Для ресторанів",
  "component.footer.menu.forGuest": "Для гостя",
  "component.footer.menu.howItWorks": "Як це працює?",
  "component.footer.menu.priceList": "Прейскурант",
  "component.footer.menu.contact": "Зв'яжіться з нами",
  "component.footer.menu.documents": "Документалістика",
  "component.footer.menu.about": "O в",
  "component.footer.menu.login": "Увійдіть в систему",
  "component.footer.menu.reservationHistory": "Ваші замовлення",
  "component.footer.menu.yourAccount": "Ваш рахунок",
  "component.footer.menu.logout": "Вийдіть з системи",
  "component.footer.menu.registration": "Реєстрація",
  "component.footer.menu.privacyPolicy": "Політика конфіденційності",
  "component.footer.menu.cookiesPolicy": "Політика щодо файлів cookie",
  "indexPage.background.header": "Знайдіть найкращі ресторани у вашому місті,",
  "indexPage.header.currentPromotions": "Спеціальні пропозиції та події",
  "indexPage.header.currentPromotionsExtend": "на вашому місці",
  "indexPage.header.blogs": "Вибрані статті",
  "indexPage.header.mostPopular": "Найпопулярніші",
  "indexPage.header.mostPopularExtend": "на вашому місці",
  "indexPage.background.headerExtend": "а потім забронювати столик:",
  "indexPage.input.label.location": "Місцезнаходження",
  "indexPage.input.label.date": "Дані",
  "indexPage.input.label.hour": "Година.",
  "indexPage.input.label.persons": "Кількість людей",
  "indexPage.input.label.persons.optionExtend": "oc.",
  "indexPage.input.label.restaurantType": "Тип кухні",
  "indexPage.input.label.rating": "Оцінка",
  "indexPage.input.label.rating.optionExtend": "зірки.",
  "indexPage.input.label.priceLevel": "Рівень цін",
  "indexPage.input.label.searchByName": "Пошук за назвою",
  "indexPage.input.placeholder.select": "Виберіть",
  "indexPage.input.placeholder.type": "Увійдіть",
  "indexPage.input.placeholer.typeInOrSelect": "Введіть або виберіть",
  "indexPage.input.noData": "Безрезультатно.",
  "indexPage.button.search": "Пошук",
  "indexPage.button.showAdditionalFilters": "Додаткові фільтри",
  "indexPage.button.hideAdditionalFilters": "Приховати додаткові фільтри",
  "indexPage.button.use": "Використання",
  "indexPage.button.promotion": "просування",
  "indexPage.button.bookNow": "Замовити",
  "indexPage.button.readMore": "Прочитайте",
  "accountPage.header": "Ваш рахунок",
  "accountPage.subheader":
    "Змініть свої особисті дані, щоб пришвидшити процес виконання замовлення.",
  "accountPage.headerInColumn.yourPersonalData": "Ваші дані",
  "accountPage.headerInColumn.yourPassword": "Ваш пароль",
  "accountPage.input.label.firstName": "Ім'я",
  "accountPage.input.placeholder.firstName": "Наприклад, Ян",
  "accountPage.input.label.lastName": "Ім'я",
  "accountPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "accountPage.input.label.phoneNumber": "Номер телефону",
  "accountPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "accountPage.input.label.email": "Адреса електронної пошти",
  "accountPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "accountPage.paragraph.optionalFields": "Необов'язкові поля",
  "accountPage.input.label.birthDate": "Дата народження",
  "accountPage.input.placeholder.birthDate": "Наприклад, 1976-10-25",
  "accountPage.input.label.postalCode": "Поштовий індекс",
  "accountPage.input.placeholder.postalCode": "Наприклад, 03-123",
  "accountPage.input.label.city": "Місто",
  "accountPage.input.placeholder.city": "Наприклад, Варшава",
  "accountPage.input.label.streetAndLocalNumber": "Вулиця та номер квартири",
  "accountPage.input.placeholder.streetAndLocalNumber": "Наприклад, Grójecka 130/105",
  "accountPage.input.label.channelsOfCommunication": "Методи комунікації:",
  "accountPage.button.save": "Зберегти",
  "accountPage.button.deleteAccount": "Видалити обліковий запис",
  "accountPage.input.label.oldPassword": "Старе гасло.",
  "accountPage.input.label.newPassword": "Новий пароль",
  "accountPage.input.label.newPasswordRepeat": "Новий пароль (повторити)",
  "accountPage.button.changePassword": "Змінити пароль",
  "accountPage.message.personalDataUpdated": "Особисті дані були оновлені.",
  "accountPage.message.accountPermanentlyDeleted": "Ваш обліковий запис видалено назавжди.",
  "accountPage.message.passwordChanged": "Пароль було змінено.",
  "accountPage.label.changePasswordNotPossible":
    "Змінити пароль неможливо, оскільки ви увійшли через Facebook або Google.",
  "accountPage.toast.error.disableBothChannels":
    "Щонайменше один спосіб зв'язку (SMS, E-MAIL) повинен залишатися активним!",
  "accountPage.toast.error.enableAfterChange.mail":
    "Увімкнення способу зв'язку вимагає підтвердження коректності даних. Збережіть зміни, щоб отримати посилання для підтвердження на нову електронну адресу!",
  "accountPage.toast.error.enableAfterChange.phone":
    "Увімкнення способу зв'язку вимагає підтвердження коректності даних. Збережіть зміни, щоб отримати посилання для підтвердження через SMS на ваш новий номер телефону!",
  "accountPage.toast.error.enableBeforeActivation.mail":
    "Щоб увімкнути новий спосіб зв'язку, підтвердьте свої дані, перейшовши за активаційним посиланням, надісланим на вашу електронну адресу!",
  "accountPage.toast.error.enableBeforeActivation.phone":
    "Щоб увімкнути новий спосіб зв'язку, підтвердьте свої дані, перейшовши за активаційним посиланням, надісланим через SMS на вказаний номер телефону!",
  "accountPage.toast.success.activationLinkRemainder.mail":
    "Щоб підтвердити свої дані, перейдіть за посиланням для верифікації, яке надіслано на вашу електронну адресу!",
  "accountPage.toast.success.activationLinkRemainder.phone":
    "Щоб підтвердити свої дані, перейдіть за посиланням для верифікації, яке надіслано через SMS на ваш номер телефону!",
  "accountPage.channels.disclaimer":
    "* Для редагування номера телефону або електронної пошти потрібно відключити модуль зв'язку, який редагується.",
  "accountPage.confirmSms.header": "Підтвердження достовірності даних",
  "accountPage.confirmSms.paragraph":
    "Для підтвердження ваших даних введіть код, надісланий SMS на вказаний номер телефону!",
  "reservationHistoryPage.sendMessage": "Надіслати повідомлення",
  "reservationHistoryPage.send": "Надіслати",
  "reservationHistoryPage.cancel": "Оголосити",
  "reservationChatPage.send": "Надіслати",
  "reservationChatPage.writeMessage": "Введіть своє повідомлення",
  "reservationHistoryPage.header": "Ваші замовлення",
  "reservationHistoryPage.subheader":
    "Тут ви можете переглянути свої попередні бронювання та керувати майбутніми бронюваннями.",
  "reservationHistoryPage.paragraph.personsShortcut": "oc.",
  "reservationHistoryPage.button.revoke": "Скасувати",
  "reservationHistoryPage.button.pay": "Заплати",
  "reservationHistoryPage.paragraph.additionalDescription": "Комунікація з рестораном",
  "reservationHistoryPage.paragraph.date": "Дані",
  "reservationHistoryPage.paragraph.duration": "Тривалість",
  "reservationHistoryPage.paragraph.hour": "Година.",
  "reservationHistoryPage.paragraph.persons": "Кількість людей",
  "reservationHistoryPage.paragraph.reservationIsEmpty": "Бронювання не існує",
  "reservationHistoryPage.paragraph.reservationListIsEmpty": "Список бронювання порожній.",
  "reservationHistoryPage.paragraph.status": "Статус",
  "reservationPage.paragraph.lateInfo":
    "Зверніть увагу, у разі запізнення столик чекає на гостя 15 хвилин!",
  "reservationHistoryPage.paragraph.payment.status": "Статус платежу",
  "searchPage.header": "Результати пошуку",
  "searchPage.button.map": "Карта",
  "searchPage.button.list": "Ліста",
  "searchPage.paragraph.noData": "Немає результатів за даними критеріями.",
  "searchMapPage.map.button.refresh": "Оновити карту",
  "searchMapPage.header": "Результати пошуку",
  "searchMapPage.button.map": "Карта",
  "searchMapPage.button.list": "Ліста",
  "loginPage.header": "Лісозаготівля",
  "loginPage.subheader":
    "Як зареєстрований користувач, ви можете швидше бронювати столики та переглядати історію своїх замовлень.",
  "loginPage.columns.header.standardLogin": "Стандартний логін",
  "loginPage.columns.header.socialLogin": "Увійдіть за допомогою",
  "loginPage.input.label.phoneOrEmail": "Номер телефону/адреса електронної пошти",
  "loginPage.input.placeholder.phoneOrEmail": "",
  "loginPage.input.label.password": "Пароль",
  "loginPage.input.placeholder.password": "",
  "loginPage.anchor.passwordReset": "Скинути пароль",
  "loginPage.button.logIn": "Увійдіть в систему",
  "loginPage.paragraph.dontHaveAccountYet": "Ще не маєте облікового запису?",
  "modal.login.dontHaveAccountYet": "У вас ще немає облікового запису",
  "loginPage.paragraph.registerNow": "Реєструйтеся!",
  "loginPage.button.register": "Зареєструватися",
  "loginPage.message.logged": "Перепостив.",
  "loginPage.message.loggedViaFacebook": "Реблог через Facebook.",
  "loginPage.message.loggedViaApple": "Репост від Apple.",
  "loginPage.message.loggedViaGoogle": "Реблог через Google.",
  "loginPage.paragraph.alreadyLoggedIn": "Ви вже увійшли в систему.",
  "resetPasswordPage.header": "Скинути пароль",
  "resetPasswordPage.subheader":
    "Заповніть форму нижче, і ми надішлемо вам новий пароль на ваш обліковий запис через SMS або електронну пошту.",
  "resetPasswordPage.subheader2": "Ми надішлемо вам новий пароль на ваш номер телефону",
  "resetPasswordPage.input.label.phoneOrEmail": "Введіть номер телефону або адресу електронної пошти",
  "resetPasswordPage.button.confirm": "Підтвердити",
  "resetPasswordPage.toast.passwordChanged":
    "Новий пароль було надіслано на вказаний номер телефону/адресу електронної пошти.",
  "resetPasswordPage.toast.error.passwordChanged": "Номер телефону/адреса електронної пошти є неправильними.",
  "cookiesPolicyPage.header": "Політика щодо файлів cookie",
  "cookiesPolicyPage.subheader": "Відповідна інформація.",
  "cookiesPolicyPage.point.1":
    "Веб-сайт не збирає жодної інформації автоматично, за винятком інформації, що міститься в файлах cookie.",
  "cookiesPolicyPage.point.2":
    'Файли cookie (так звані "cookies") - це ІТ-дані, зокрема текстові файли, які зберігаються в кінцевому обладнанні Користувача послуг і призначені для використання на Веб-сайті. Файли cookie зазвичай містять назву веб-сайту, з якого вони походять, час їх зберігання на кінцевому обладнанні та унікальний номер.',
  "cookiesPolicyPage.point.3":
    "Суб'єктом, який розміщує файли cookie на термінальному обладнанні Користувача Сайту та отримує до них доступ, є власник mojstolik.pl Table4Rest Sp. z o.o. з юридичною адресою: вул. Ksawerów 21, 02-656 Варшава.",
  "cookiesPolicyPage.point.4": "Файли cookie використовуються для:",
  "cookiesPolicyPage.point.4a":
    "адаптація змісту сторінок Веб-сайту до уподобань Користувача та оптимізація використання веб-сайтів; зокрема, ці файли дозволяють розпізнавати пристрій Користувача Веб-сайту та відповідним чином відображати веб-сайт, адаптований до його/її індивідуальних потреб;",
  "cookiesPolicyPage.point.4b":
    "створення статистики, яка допомагає нам зрозуміти, як відвідувачі Веб-сайту використовують веб-сайти, що дозволяє нам покращувати їх структуру та зміст;",
  "cookiesPolicyPage.point.4c":
    "збереження сесії Користувача Сервісу (після входу в систему), завдяки чому Користувачеві не потрібно повторно вводити логін і пароль на кожній підсторінці Сервісу;",
  "cookiesPolicyPage.point.5":
    'Веб-сайт використовує два основних типи файлів cookie: "сесійні" (сесійні файли cookie) та "постійні" (постійні файли cookie). "Сесійні" файли cookie - це тимчасові файли, які зберігаються на кінцевому обладнанні Користувача до моменту його виходу з системи, залишення веб-сайту або вимкнення програмного забезпечення (веб-браузера). "Постійні" файли cookie зберігаються на кінцевому обладнанні Користувача протягом часу, зазначеного в параметрах файлів cookie, або до їх видалення Користувачем.',
  "cookiesPolicyPage.point.6": "В рамках Сервісу використовуються наступні типи файлів cookie:",
  "cookiesPolicyPage.point.6a":
    '"необхідні" файли cookie, які уможливлюють використання послуг, доступних на Веб-сайті, наприклад, файли cookie для автентифікації, які використовуються для послуг, що вимагають автентифікації на Веб-сайті;',
  "cookiesPolicyPage.point.6b":
    "файли cookie, що використовуються в цілях безпеки, наприклад, для виявлення зловживань з автентифікацією на Веб-сайті;",
  "cookiesPolicyPage.point.6c":
    '"performance" файли cookie, що дозволяють збирати інформацію про те, як використовуються сторінки Веб-сайту;',
  "cookiesPolicyPage.point.6d":
    '"функціональні" файли cookie, що дозволяють "запам\'ятовувати" обрані Користувачем налаштування та персоналізувати інтерфейс Користувача, наприклад, щодо обраної Користувачем мови або регіону походження, розміру шрифту, дизайну веб-сайту тощо;',
  "cookiesPolicyPage.point.6e":
    '"рекламні" файли cookie, що дозволяють надавати Користувачам рекламний контент, більш адаптований до їхніх інтересів.',
  "cookiesPolicyPage.point.7":
    "У багатьох випадках програмне забезпечення для перегляду веб-сторінок (веб-браузер) за замовчуванням дозволяє зберігати файли cookie на кінцевому пристрої Користувача. Користувачі Сайту можуть у будь-який час змінити свої налаштування щодо файлів cookie. Ці налаштування можуть бути змінені, зокрема, таким чином, щоб заблокувати автоматичну обробку файлів cookie в налаштуваннях веб-браузера або інформувати про їх щоразу розміщення в обладнанні Користувача Сервісу. Детальна інформація про можливість і способи використання файлів cookie доступна в налаштуваннях вашого програмного забезпечення (веб-браузера).",
  "cookiesPolicyPage.point.8":
    "Оператор Сайту інформує вас про те, що обмеження на використання файлів cookie можуть вплинути на деякі функціональні можливості, доступні на Сайті.",
  "privacyPolicyPage.header": "Політика конфіденційності",
  "privacyPolicyPage.subheader": "Відповідна інформація.",
  "privacyPolicyPage.point.one": "ЗАГАЛЬНІ ПОЛОЖЕННЯ",
  "privacyPolicyPage.point.one.1":
    "Адміністратором даних є Table4Rest Sp. z o.o. з місцезнаходженням у Варшаві 02-656, вул. Ksawerów 21, внесений до Реєстру підприємців під номером KRS: 0000752110, реєстраційні справи якого зберігаються в СУДІ РЕЄСТРАЦІЙНОМУ ДЛЯ МІСТА ВАРШАВА У ВАРШАВІ, XIII ЕКОНОМІЧНИЙ ВІДДІЛ НАЦІОНАЛЬНОГО РЕЄСТРУ NIP: 5213842139 REGON: 381529521.",
  "privacyPolicyPage.point.one.2":
    "Для тлумачення термінів застосовується глосарій Умов або як описано в Політиці конфіденційності (якщо це безпосередньо випливає з опису).",
  "privacyPolicyPage.point.one.3":
    'З метою кращого сприйняття Політики конфіденційності термін "Користувач" замінено на "Ви", "Адміністратор" - на "ми". Термін "RODO" означає Регламент (ЄС) 2016/679 Європейського Парламенту та Ради від 27 квітня 2016 року про захист фізичних осіб при обробці персональних даних і про вільний рух таких даних та про скасування Директиви 95/46/ЄС.',
  "privacyPolicyPage.point.one.4":
    "Ми поважаємо право на приватність і дбаємо про безпеку даних. Для цього ми використовуємо, серед іншого, безпечний протокол шифрування зв'язку (SSL).",
  "privacyPolicyPage.point.one.5":
    "Персональні дані, надані у формі на цільовій сторінці, вважаються конфіденційними і не доступні стороннім особам.",
  "privacyPolicyPage.point.two": "КОНТРОЛЕР",
  "privacyPolicyPage.point.two.1":
    "Постачальником послуг є адміністратор осіб, які скористалися контактною формою на веб-сайті або скористалися іншою формою зв'язку (електронною поштою, телефоном, мобільним додатком, плагіном дзвінка для замовлення, іншими формами зв'язку).",
  "privacyPolicyPage.point.two.2": "Персональні дані обробляються:",
  "privacyPolicyPage.point.two.2a": "a. відповідно до законодавства про захист даних,",
  "privacyPolicyPage.point.two.2b": "b. відповідно до впровадженої Політики конфіденційності,",
  "privacyPolicyPage.point.two.2c":
    "c. в обсязі та з метою, необхідною для укладення, формування, зміни або розірвання Договору та належного надання Послуг,",
  "privacyPolicyPage.point.two.2d":
    "d. в обсязі та з метою, необхідних для реалізації законних інтересів (законних цілей), і обробка не завдає шкоди правам і свободам суб'єкта даних.",
  "privacyPolicyPage.point.two.3":
    "Кожен суб'єкт даних (якщо ми є контролером даних) має право на доступ, виправлення, видалення або обмеження обробки, право на заперечення, право подати скаргу до наглядового органу.",
  "privacyPolicyPage.point.two.4":
    "Зв'язатися з особою, відповідальною за обробку персональних даних в організації Постачальника послуг, можна електронною поштою за адресою: kontakt@mojstolik.pl.",
  "privacyPolicyPage.point.two.5":
    "Ми залишаємо за собою право обробляти ваші дані після припинення дії Угоди або відкликання згоди лише в обсязі, необхідному для пред'явлення можливих претензій у суді, або якщо національне законодавство, законодавство ЄС або міжнародне законодавство зобов'язує нас зберігати дані.",
  "privacyPolicyPage.point.two.6":
    "Постачальник послуг має право надавати персональні дані Користувача та інші дані Користувача суб'єктам, уповноваженим відповідним законодавством (наприклад, правоохоронним органам).",
  "privacyPolicyPage.point.two.7":
    "Видалення персональних даних може відбутися в результаті відкликання згоди або подання юридично допустимого заперечення проти обробки персональних даних.",
  "privacyPolicyPage.point.two.8":
    "Постачальник послуг не повинен надавати персональні дані іншим особам, крім тих, які уповноважені відповідним законодавством.",
  "privacyPolicyPage.point.two.9":
    "Персональні дані обробляються лише уповноваженими нами особами або обробниками, з якими ми тісно співпрацюємо.",
  "restaurantPage.button.pageWWW": "Веб-сайт",
  "restaurantPage.button.seeMenu": "Дивіться меню",
  "restaurantPage.button.confirm": "Підтвердити",
  "restaurantPage.button.cancel": "Оголосити",
  "restaurantPage.button.choose": "Виберіть",
  "restaurantPage.button.back": "Повернення",
  "restaurantPage.button.showMore": "Показати більше",
  "restaurantPage.button.showMore.promotion": "Читати далі",
  "restaurantPage.button.showLess": "Показати менше",
  "restaurantPage.button.goToHomePage": "Повернутися на головну сторінку",
  "restaurantPage.button.reservationDetails": "Деталі бронювання",
  "restaurantPage.button.goToPaymentPage": "Перейдіть до платіжної панелі",
  "restaurantPage.paymentPage.info":
    "Ви будете негайно перенаправлені до платіжного оператора Przelewy24.pl",
  "restaurantPage.header.restaurantDescription": "Опис ресторану",
  "restaurantPage.header.bookingPolicy": "Політика бронювання",
  "restaurantPage.header.gallery": "Фотогалерея",
  "restaurantPage.header.yourReservation": "Ваше бронювання",
  "restaurantPage.header.yourPersonalData": "Ваші дані",
  "restaurantPage.header.ctnWithoutLogin": "Продовжити без входу в систему",
  "restaurantPage.header.login": "Лісозаготівля",
  "restaurantPage.button.login": "Увійдіть в систему",
  "restaurantPage.button.orLoginWith": "Або увійдіть за допомогою",
  "restaurantPage.header.createAccount":
    "Створіть обліковий запис на MojStolik.co.uk і отримуйте прибуток:",
  "restaurantPage.header.createAccount.benefit.1": "Швидкий спосіб бронювання",
  "restaurantPage.header.createAccount.benefit.2": "Повна історія бронювання",
  "restaurantPage.header.createAccount.benefit.3": "Доступ до унікальних акцій",
  "restaurantPage.button.createAccount": "Створіть обліковий запис MojStolik.pl",
  "restaurantPage.header.statements": "Заяви",
  "restaurantPage.header.statement": "Заява",
  "restaurantPage.header.promotions": "Спеціальні пропозиції та події",
  "restaurantPage.header.use": "Використання",
  "restaurantPage.header.cancel": "Здавайся!",
  "restaurantPage.header.reservationConfirmation": "Підтвердження бронювання",
  "restaurantPage.header.reservationsPersons": "Бронювання від {{min}} до {{max}} осіб",
  "restaurantPage.paragraph.reservationConfirmationDescription.phone":
    "Введіть код з SMS, яке ми надіслали на ваш номер телефону, щоб завершити процес бронювання столика.",
  "restaurantPage.paragraph.reservationConfirmationDescription.mail":
    "Введіть код з повідомлення, яке ми надіслали на вашу електронну адресу, щоб завершити процес бронювання столика.",
  "restaurantPage.input.label.code": "Введіть код дня",
  "restaurantPage.paragraph.noAvailableHoursChooseDifferent":
    "Для обраних критеріїв (дата, кількість людей, акція) немає доступних таблиць. Будь ласка, виберіть іншу дату, кількість людей або акцію.",
  "restaurantPage.paragraph.noAvailableHours": "Немає вільних столів у вибраних критеріях.",
  "restaurantPage.paragraph.noAvailableHoursExt": "Оберіть іншу дату або кількість людей.",
  "restaurantPage.input.label.date": "Дані",
  "restaurantPage.input.label.hour": "Година.",
  "restaurantPage.input.label.persons": "Кількість людей",
  "restaurantPage.input.label.persons.optionExtend": "oc.",
  "restaurantPage.input.label.firstName": "Ім'я",
  "restaurantPage.input.label.vatIdNumber": "НІП",
  "restaurantPage.input.label.address": "Адреса",
  "restaurantPage.input.label.companyName": "Назва компанії",
  "restaurantPage.input.label.street": "Вулиця",
  "restaurantPage.input.label.buildingNumber": "Будівля",
  "restaurantPage.input.label.localNumber": "Місцевий",
  "restaurantPage.input.label.postalCode": "Поштовий індекс",
  "restaurantPage.input.label.city": "Місто",
  "restaurantPage.input.label.country": "Країна",
  "restaurantPage.get.company.data": "Завантажити",
  "restaurantPage.input.label.lastName": "Ім'я",
  "restaurantPage.input.label.phoneNumber": "Номер телефону",
  "restaurantPage.input.label.email": "Адреса електронної пошти",
  "restaurantPage.input.label.password": "Введіть пароль",
  "restaurantPage.input.label.passwordRepeat": "Повторити пароль",
  "restaurantPage.input.label.description": "Примітки до бронювання",
  "agreements.allAgreements": "Відмітьте все.",
  "agreements.invoice": "Введіть дані рахунку-фактури",
  "restaurantPage.input.label.personalData":
    "Я заявляю, що ознайомлений і приймаю положення Умов надання послуг MojStolik за допомогою Клієнтського додатку.",
  "restaurantPage.input.label.personalData.extension": " Умови та положення MojStolik",
  "restaurantPage.input.label.readWithdrawal":
    "Я ознайомився з умовами та положеннями MojStolik.co.uk, зокрема з інструкціями щодо права на відкликання.",
  "restaurantPage.input.label.readWithdrawal.extension": "Умови та положення mojstolik.pl",
  "restaurantPage.input.label.agreementMarketing":
    "Я хочу отримувати рекламні акції, спеціальні пропозиції, конкурсні пропозиції або інший маркетинговий контент від MojStolik, включаючи індивідуальну інформацію про послуги та продукти MojStolik, доступні в Клієнтському додатку, за допомогою електронного зв'язку. Ви можете відкликати цю згоду в будь-який час.",
  "restaurantPage.input.label.marketingAgreement3thPart":
    "Я хочу отримувати рекламні акції, спеціальні пропозиції, конкурсні пропозиції або інший маркетинговий контент від MojStolik, включаючи індивідуальну інформацію про послуги та продукти Ресторану та Суб'єктів співпраці, зазначених на сайті mojstolik.co.uk, доступну в Клієнтській заявці, за допомогою електронного зв'язку. Ви можете відкликати цю згоду в будь-який час.",
  "agreement.eventAgreement":
    "Я ознайомився та приймаю умови спеціальної пропозиції, розміщеної в розділі",
  "registrationPage.input.label.eventAgreementUrl.extension": "за цим посиланням",
  "restaurantPage.input.placeholder.typeIn": "Увійдіть",
  "restaurantPage.input.placeholder.firstName": "Наприклад, Ян",
  "restaurantPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "restaurantPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "restaurantPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "restaurantPage.paragraph.fillOrLogin":
    "Заповніть решту даних або завантажте через Facebook, Google або Apple",
  "restaurantPage.paragraph.doYouWantToRegister":
    "Якщо ви хочете створити обліковий запис на MOJSTOLIK.PL, будь ласка, вкажіть пароль",
  "restaurantPage.paragraph.dataIsIncorrect": "Дані невірні?",
  "restaurantPage.paragraph.sendCodeAgainText":
    "Код було повторно надіслано через SMS. Не отримали код?",
  "restaurantPage.paragraph.sendCodeViaEmail.bold": "Авторизуйтеся через електронну пошту:",
  "restaurantPage.paragraph.sendCodeViaEmail.link": "Відправте код на вказану електронну адресу.",
  "restaurantPage.paragraph.sendCodeViaEmail.sent": "Код було надіслано на електронну адресу:",
  "restaurantPage.anchor.sendCodeAgain": "Код для повторного відправлення",
  "restaurantPage.anchor.edit": "Редагувати",
  "restaurantPage.anchor.login": "Логін",
  "restaurantPage.paragraph.or": " або",
  "restaurantPage.anchor.register": "реєстр",
  "restaurantPage.paragraph.dontWontUsePromotion": "Я не хочу користуватися акціями",
  "restaurantPage.messages.codeResent": "СМС-код було надіслано ще раз.",
  "restaurantPage.header.reservationRecieved": "Прийом бронювань",
  "restaurantPage.summary.paragraph.firstLastName": "Ім'я. Прізвище:",
  "restaurantPage.summary.paragraph.phoneNumber": "Номер телефону:",
  "restaurantPage.summary.paragraph.email": "Електронна пошта:",
  "restaurantPage.summary.paragraph.restName": "Назва ресторану:",
  "restaurantPage.summary.paragraph.date": "Дедлайн:",
  "restaurantPage.summary.paragraph.date.time": "годин.",
  "restaurantPage.summary.paragraph.duration": "Тривалість:",
  "restaurantPage.summary.paragraph.persons": "Кількість відвідувачів:",
  "restaurantPage.summary.paragraph.totalCost": "Загальна сума передоплати:",
  "restaurantPage.summary.special.offer": "Спеціальна пропозиція:",
  "restaurantPage.summary.paragraph.state": "Статус:",
  "restaurantPage.paragraph.tableReserved": "Столик зарезервовано",
  "reservation.status.Canceled": "Скасовано.",
  "reservation.status.Completed": "Бронювання зроблено",
  "reservation.status.Confirmed": "Бронювання підтверджено",
  "reservation.status.Delayed": "Затримується.",
  "reservation.status.NotCompleted": "Бронювання не виконано",
  "reservation.status.Rejected": "Бронювання скасовано",
  "reservation.status.WaitingForApproval": "Бронювання очікує на затвердження",
  "reservation.status.WaitingForConfirmation": "В очікуванні",
  "restaurantPage.paragraph.tableReservedDescription": "Ваш столик заброньовано на одну годину.",
  "restaurantPage.paragraph.tableReservedDescriptionInDay": "на",
  "restaurantPage.paragraph.tableWaitingForApproval": "Бронювання очікує на затвердження",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription1": "Ваше замовлення на годину.",
  "restaurantPage.paragraph.tableWaitingForApprovalDescription2":
    "Якщо ви не отримали підтвердження бронювання протягом декількох годин, будь ласка, зв'яжіться безпосередньо з рестораном",
  "restaurantPage.paragraph.tableWaitingForApprovalDescriptionInDay": "на",
  "restaurantPage.paragraph.tableWaitingForApprovalPaymentInformation":
    "Після підтвердження бронювання на вказаний номер телефону буде надіслано повідомлення з посиланням для оплати. Бронювання є дійсним з моменту отримання підтвердження оплати",
  "restaurantPage.paragraph.tableReservedPaymentInformation": "Бронювання повинно бути оплачено",
  "restaurantPage.input.label.footer":
    "MojStolik використовує різні електронні канали зв'язку і веде свою діяльність за допомогою електронної пошти, телефону, SMS та MMS. Адміністратором ваших персональних даних є Table4Rest spółka z ograniczoną odpowiedzialnością з місцезнаходженням у Варшаві, 02-656 Варшава, вул. Ksawerów 21, KRS 0000752110, NIP 5213842139. Ваші персональні дані будуть оброблятися з метою направлення вам маркетингового контенту через електронні канали зв'язку. Для отримання додаткової інформації про обробку ваших персональних даних, включаючи ваші права, будь ласка, ознайомтеся з Політикою конфіденційності.",
  "forGuestPage.header": "Для гостя",
  "howItWorksPage.header": "Як це працює?",
  "howItWorksPage.subheader": "Крок за кроком",
  "howItWorksPage.content.title": "Бронювання з мобільного додатку",
  "howItWorksPage.content.step1": "Крок 1",
  "howItWorksPage.content.step1.content": "Клієнт шукає ресторани в мобільному додатку і",
  "howItWorksPage.content.step1.content.strong": "бачить доступні столи в режимі реального часу.",
  "howItWorksPage.content.step2": "Крок 2",
  "howItWorksPage.content.step2.content.strong":
    "Менеджер бачить заявку на бронювання в своїй панелі",
  "howItWorksPage.content.step2.content":
    " Він знає, чи надійшов запит від нового клієнта, чи від постійного, і чи є якісь побажання щодо столика.",
  "howItWorksPage.content.step3": "Крок 3",
  "howItWorksPage.content.step3.content": "Клієнт приходить до ресторану, де",
  "howItWorksPage.content.step3.content.strong":
    "вітаються на ім'я. Персонал має повну інформацію про клієнта",
  "howItWorksPage.content.step3.contentb":
    " - знає, чи є він їхнім постійним відвідувачем, чи завітав до них уперше.",
  "howItWorksPage.phone.title": "Бронювання по телефону",
  "howItWorksPage.content.phone.step1": "Крок 1",
  "howItWorksPage.content.phone.step1.content1": "Клієнт",
  "howItWorksPage.content.phone.step1.content.strong": "телефонує до обраного вами ресторану",
  "howItWorksPage.content.phone.step1.content2": "із запитом на бронювання.",
  "howItWorksPage.content.phone.step2": "Крок 2",
  "howItWorksPage.content.phone.step2.content.strong":
    "Менеджер може бачити доступність столика в режимі реального часу на своїй панелі управління",
  "howItWorksPage.content.phone.step2.content":
    ". Коли менеджер вводить номер телефону клієнта, він одразу бачить його дані або заповнює/вводить дані нового клієнта.",
  "howItWorksPage.content.phone.step3": "Крок 3",
  "howItWorksPage.content.phone.step3.content": "Клієнт приходить до ресторану, де",
  "howItWorksPage.content.phone.step3.content.strong":
    "персонал вітається на ім'я, має повну інформацію про клієнта",
  "howItWorksPage.content.phone.step3.contentb":
    " - знає, чи є він їхнім постійним відвідувачем, чи завітав до них уперше.",
  "priceListPage.header": "Прейскурант",
  "priceListPage.content.link.1": "Для отримання інформації про ціни, будь ласка",
  "priceListPage.content.link.2": "Контакти",
  "contactPage.header": "Зв'яжіться з нами",
  "contactPage.subheader": "Напишіть нам!",
  "contactPage.content.title": "Контактні дані",
  "documentsPage.header": "Документалістика",
  "documentsPage.header.for.clients": "Для клієнта",
  "documentsPage.header.for.restaurants": "Для ресторанів",
  "documentsPage.subheader": "Відповідна інформація",
  "documentsPage.for.clients.document.1":
    "Регламент надання послуг MojStolik за допомогою Клієнтського додатку",
  "documentsPage.for.clients.document.2": "Інформація про обробку персональних даних",
  "documentsPage.header.for.restaurants.document.1":
    "Умови надання додатку MojStolik для ресторанів",
  "documentsPage.header.for.restaurants.document.2":
    "Договір доручення на обробку персональних даних Ресторану",
  "documentsPage.header.for.restaurants.document.3": "Персональні дані mojstolik.co.uk",
  "aboutPage.header": "O в",
  "aboutPage.applicationName": "Mojstolik.pl",
  "aboutPage.application.description":
    " - це польська система, створена групою друзів - потаємних любителів смачної їжі та мистецтва прийому гостей у ресторанах. Це технологічне рішення, яке допомагає створювати спільноту навколо місць, привабливих гарною кухнею та повагою до часу і звичок своїх лояльних гостей. MojStolik.pl - це зростаюча група ресторанів, які працюють разом, щоб польська гастрономія асоціювалася з відмінною якістю, увагою до деталей і повагою до любителів проводити час за столом з друзями, родиною або діловими партнерами. Засновники системи переклали свою ресторанну пристрасть на технологічну мову і таким чином створили гастрономічний портал і додаток, який об'єднує людей за столиками найцінніших і найпопулярніших ресторанів Польщі.",
  "aboutPage.application.header1":
    " Хочете забронювати столик у ресторані? Тепер це можна зробити через додаток на телефоні або з мультиресторанного порталу.",
  "aboutPage.application.description1":
    "Mojstolik.pl - це нова комплексна платформа бронювання, нещодавно доступна для варшавських ресторанів. Вона дозволяє закладам громадського харчування зручно керувати бронюванням столиків у режимі реального часу та інтегрує всі канали комунікації з гостями: телефон, електронну пошту, мобільний додаток MojStolik і так званий walk-in. Завдяки їй менеджери ресторанів мають доступ до динамічного перегляду приміщення, самостійно приймають рішення щодо планування залу, кількості вільних місць та часу, на який можна забронювати кожен столик. Додатковою перевагою системи є механізм SMS-підтвердження бронювання з можливістю скасування за посиланням та надсилання SMS-нагадувань гостям.",
  "aboutPage.application.header2": "MojStolik.co.uk: планшет замість книжки для бронювання",
  "aboutPage.application.description2":
    "MojStolik.pl - це також інструмент, який допомагає ресторанам будувати лояльність клієнтів. Система дозволяє створювати базу даних гостей, аналізувати історію замовлень, а лічильник відвідувань показує, скільки разів та чи інша особа відвідувала ресторан. Під час бронювання гості мають можливість додавати коментарі, наприклад, вподобання щодо столика, улюблених страв чи вин. Нова платформа дозволяє безпечно зберігати дані клієнтів відповідно до вимог RODO, чого не можна зробити в паперовій книзі бронювання, яку використовують ресторани.",
  "aboutPage.application.description2b":
    "Додаток використовують понад 100 ресторанів по всій Польщі, серед яких: Stary Dom, Baczewskie Restaurant, Stixx Bar & Grill, Pełną Parą, Hotel Warszawa, Flaming & Co, NINE'S Bar and Restaurant, ORZO, Bydło i Powidło, GURU, Regina Bar, Vilano, Bibenda, Tel Aviv, Osteria Piemonte, Magari, The Park, Dziurka od Klucza, Gardens by Fort, Japonka, Kago Sushi, BARaWINO, Magari, а також багато інших ресторанів, які незабаром додадуться до списку.",
  "aboutPage.application.header3": "MojStolik - новий додаток для бронювання столиків",
  "aboutPage.application.description3":
    " MojStolik - це новий мобільний додаток, який дозволяє швидко та зручно забронювати столик у ресторані. Додаток дозволяє миттєво перевірити наявність вільних місць та зарезервувати столик на певну годину, оскільки зв'язується з рестораном у режимі реального часу. Ви можете додати до бронювання особливе прохання або коментар про ваш улюблений столик у ресторані, інформацію про алергію або інші вподобання, а ресторан може скористатися функцією чату, щоб повідомити вам, чи можливе виконання вашого запиту. У додатку MojStolik ми також можемо перевірити актуальне меню ресторану і отримати доступ до спеціальних акцій, які ресторани підготували для нас.",
  "aboutPage.contact.data": "Контактні дані",
  "aboutPage.contact.street": "ul.",
  "registrationPage.header": "Реєстрація",
  "registrationPage.subheader":
    "Як зареєстрований користувач, ви можете швидше бронювати столики та переглядати історію своїх замовлень.",
  "registrationPage.columns.header.registerStandard": "Стандартна реєстрація",
  "registrationPage.columns.header.registerWith": "Реєструйтеся на",
  "registrationPage.input.label.firstName": "Ім'я",
  "registrationPage.input.placeholder.firstName": "Наприклад, Ян",
  "registrationPage.input.label.lastName": "Ім'я",
  "registrationPage.input.placeholder.lastName": "Наприклад, Ковальський",
  "registrationPage.input.label.phoneNumber": "Номер телефону",
  "registrationPage.input.placeholder.phoneNumber": "Np. 600 500 400",
  "registrationPage.input.label.email": "Адреса електронної пошти",
  "registrationPage.input.placeholder.email": "Наприклад, adres@email.pl",
  "registrationPage.paragraph.optionalFields": "Необов'язкові поля",
  "registrationPage.input.label.birthDate": "Дата народження",
  "registrationPage.input.placeholder.birthDate": "Наприклад, 1976-10-25",
  "registrationPage.input.label.postalCode": "Поштовий індекс",
  "registrationPage.input.placeholder.postalCode": "Наприклад, 03-123",
  "registrationPage.input.label.city": "Місто",
  "registrationPage.input.placeholder.city": "Наприклад, Варшава",
  "registrationPage.input.label.streetAndLocalNumber": "Вулиця та номер квартири",
  "registrationPage.input.placeholder.streetAndLocalNumber": "Наприклад, Grójecka 130/105",
  "registrationPage.input.label.password": "Пароль",
  "registrationPage.input.label.passwordRepeat": "Повторити пароль",
  "registrationPage.header.statements": "Заяви",
  "registrationPage.input.label.agreementPersonalData":
    "Я даю згоду на обробку моїх персональних даних компанією Table4Rest sp. z o.o. та обраними мною ресторанами та закладами громадського харчування з метою виконання предмету договору відповідно до інформації, що міститься в документі.",
  "registrationPage.input.label.agreementPersonalData.extension":
    "Інформація про обробку персональних даних клієнтів",
  "registrationPage.input.label.agreementReadWithdrawal":
    "Я ознайомився з умовами та положеннями MojStolik.co.uk, зокрема з інструкціями щодо права на відкликання.",
  "registrationPage.input.label.agreementReadWithdrawal.extension":
    "Умови та положення mojstolik.pl",
  "registrationPage.input.label.agreementMarketing": "Маркетингова згода",
  "registrationPage.input.label.agreementMarketing.extension": "Згода",
  "registrationPage.input.label.promotionReadWithdrawal.extension": "Правила та умови Акції",
  "registrationPage.input.label.agreementThirdPartDataUsage":
    "Я даю згоду на обробку моїх персональних даних ресторанами та іншими закладами громадського харчування, обраними мною з метою здійснення в них бронювання",
  "registrationPage.input.label.agreementThirdPartDataUsage.extension": "за цим посиланням",
  "registrationPage.button.register": "Зареєструватися",
  "registrationPage.message.sendCodeAgain": "СМС-код було надіслано ще раз.",
  "registrationPage.message.accountCreated": "Обліковий запис створено.",
  "registrationPage.message.registrationTimeExpired": "Час реєстрації закінчився",
  "registrationPage.message.enterCodeFromSMS": "Розшифруйте код з SMS.",
  "registrationPage.paragraph.timeIsOver": "Час вийшов.",
  "registrationPage.paragraph.timeLeft": "Залишилося",
  "registrationPage.paragraph.seconds": "second(-a)",
  "registrationPage.header.SMSConfirmation": "SMS-підтвердження",
  "registrationPage.paragraph.SMSConfirmationDescription":
    "Введіть SMS-код, який ми надіслали на ваш номер телефону, щоб завершити реєстрацію.",
  "registrationPage.paragraph.enterSMSCodeFrom": "Введіть SMS-код дня",
  "registrationPage.input.placeholder.code": "Введіть код",
  "registrationPage.paragraph.sendCodeAgain": "СМС-код було надіслано ще раз.",
  "registrationPage.button.sendCodeAgain": "Код для повторного відправлення",
  "registrationPage.button.confirm": "Підтвердити",
  "notFoundPage.header": "Помилка 404",
  "notFoundPage.subheader": "Запитувана URL-адреса",
  "notFoundPage.subheaderExtend": "не знайдено на сервері.",
  "notFoundPage.subheaderExtend2":
    "Спробуйте перевірити правильність введеної адреси або перейдіть за посиланням",
  "notFoundPage.subheaderExtend3": "домашня сторінка",
  "notFoundPage.button.goToHomePage": "Перейти на головну сторінку",
  "global.toast.payment.count": "Необхідні товари не вибрано",
  "paymentInfo.description.label": "Ім'я",
  "paymentInfo.amount.label": "Ціна за одиницю.",
  "paymentInfo.quantity.label": "Номер",
  "paymentInfo.total.label": "Всього",
  "paymentInfo.nocalculation": "Платежі не потрібні",
  "paymentInfo.summarycost.label": "Заплатити:",
  "paymentInfo.required.products": "Обов'язкові продукти",
  "paymentInfo.optional.products": "Додаткові продукти",
  "paymentInfo.recalculate": "Перерахувати",
  "paymentInfo.amount.to.paid": "Загальна сума до сплати",
  "paymentInfo.info":
    "Загальна кількість обов'язкових продуктів повинна дорівнювати кількості осіб у бронюванні.",
  "paymentInfo.info1": "Вам все одно доведеться обирати",
  "paymentInfo.info3": "продукт",
  "paymentInfo.info4": "продукти",
  "paymentInfo.package.title": "Базовий пакет",
  "paymentInfo.select.product": "Виберіть продукт",
  "paymentInfo.select.basic.product": "Виберіть базовий продукт",
  "paymentInfo.select.required.product": "Виберіть обов'язкові продукти",
  "paymentInfo.too.much.products": "Ви не можете вибрати більше товарів",
  "restaurantPage.button.confirmAndPay": "Підтвердити та оплатити",
  "restaurantPage.button.closestTime": "Найближча дата",
  "paymentInfo.title": "Звинувачення",
  "reservation.payment.status.Created": "Створено",
  "reservation.payment.status.WaitingForPayment": "В очікуванні",
  "reservation.payment.status.ProcessingPayment": "Під час процесу",
  "reservation.payment.status.Completed": "Зрозумів.",
  "reservation.payment.status.Cancelled": "Скасовано.",
  "reservation.payment.status.Expired": "Закінчився",
  "reservation.payment.status.Unknown": "Невідомий статус",
  "custom.form.required": "Обов'язкове поле",
  "custom.button.send": "Надіслати",
  "custom.button.hide": "Колапс",
  "custom.button.show": "Розгорнути",
  "reservation.confirmation.payment.count": "Перерахуйте продукти",
  "reservation.confirmation.selected.promotion": "Вибрана акція",
  "forRestaurantsPage.form.toast.success": "Ваші контактні дані надіслано.",
  "forRestaurantsPage.form.toast.error": "Виникла помилка, будь ласка, спробуйте ще раз.",
  "forRestaurantsPage.form.email.error": "Неправильна адреса електронної пошти",
  "forRestaurantsPage.label.name": "ІМ'Я",
  "forRestaurantsPage.label.restaurant": "РЕСТОРАН:",
  "forRestaurantsPage.label.email": "ЕЛЕКТРОННА ПОШТА:",
  "forRestaurantsPage.label.phone": "ТЕЛЕФОН:",
  "forRestaurantsPage.header.agreement": "ЗАЯВИ",
  "forRestaurantsPage.label.agreement":
    " Я даю згоду на обробку моїх персональних даних компанією Table4Rest sp. z o. o. з метою укладення договору про надання послуг з надання та використання додатку MojStolik.pl і для відправки пропозиції в електронному вигляді.",
  "surveyPage.header.title": "Опитувальник для клієнтів",
  "surveyPage.header.description":
    "Ваш зворотній зв'язок - це найважливіше для нас, це те, що дозволяє нам розвиватися. Будь ласка, поділіться з нами своєю думкою:",
  "surveyPage.button.complete": "Готово.",
  "surveyPage.button.back": "Повернення",
  "surveyPage.button.next": "Далі",
  "surveyPage.surveyCompleteMessage": "Дякуємо за участь в опитуванні!",
  "surveyPage.emptySurveyMessage":
    "Під час завантаження даних опитування сталася помилка. Будь ласка, повторіть спробу пізніше.",
  "surveyPage.error": "Виникла помилка.",
  "surveyPage.results.sending": "Наразі триває публікація ваших відповідей...",
  "surveyPage.results.success": "Ваша анкета відправлена",
  "surveyPage.filledAlert.header": "Ця анкета вже заповнена"
};

export default frontendTranslationsUK;
