import baseConstants from "../constants/baseConstants";

const getImageSource = (restaurantUUID, logoExtension) => {
    return restaurantUUID && logoExtension
        ? `/image/${restaurantUUID}/smallImage.${logoExtension}`
        : `/images/${baseConstants['NO_IMAGE']}`;
};

const imageUtils = {
    getImageSource
};

export default imageUtils;


export const addParameterQuery = (url, parameter) => {
    if (url.includes('?')) {
        return `${url}&${parameter}`
    }
    return `${url}?${parameter}`
}
