import React from "react";
import "./Paginator.scss";
import classnames from 'classnames';

const Paginator = props => {
  const currentPage = parseInt(props.currentPage) || 0;
  const dataLength = props.dataLength || 0;
  const itemsPerPage = props.itemsPerPage || 10;
  const uniqueKey = props.uniqueKey || 'page';

  const getPages = () => {
    const pages = Math.ceil(parseInt(dataLength) / parseInt(itemsPerPage));
    let pagesData = [];
    for (let index = 0; index < pages; index++) {
      pagesData.push({ label: (index+1), value: index });
    }
    return pagesData;
  };

  const onPageClickHandler = (pageData, uniqueKey) => {
    if (typeof props.onPageClick === 'function') {
      props.onPageClick(pageData, uniqueKey);
    }
  };

  const onPreviousPageClickHandler = () => {
    if (typeof props.onPageClick === 'function') {
      const previousPage = currentPage > 0 ? currentPage - 1 : 0;
      props.onPageClick({
        label: (previousPage + 1),
        value: previousPage
      }, uniqueKey);
    }
  };

  const onNextPageClickHandler = () => {
    if (typeof props.onPageClick === 'function') {
      const pagesLength = getPages().length;
      const nextPage = currentPage < pagesLength -1 ? currentPage + 1 : pagesLength - 1;
      props.onPageClick({
        label: (nextPage + 1),
        value: nextPage
      }, uniqueKey);
    }
  };

  return (
    <div className="pagination">
      <div className="pagination__item pagination__item--previous" onClick={() => onPreviousPageClickHandler()}></div>
      {getPages().map((page) => (
        <div key={`${uniqueKey}--${page.value}`} className={classnames({
          "pagination__item": true,
          "pagination__item--active": parseInt(page.value) === currentPage
        })} onClick={() => onPageClickHandler(page, uniqueKey)}>{page.label}</div>
      ))}
      <div className="pagination__item pagination__item--next" onClick={() => onNextPageClickHandler()}></div>
    </div>
  );
};

export default Paginator;
