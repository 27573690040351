import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: 0,
};

const reservLoginSlice = createSlice({
  name: "reservLogin",
  initialState,
  reducers: {
    loginSuccess(state) {
      state.isLogin = state.isLogin + 1;
    },
  },
});

export const reservLoginActions = reservLoginSlice.actions;
export default reservLoginSlice.reducer;
